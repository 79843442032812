import { Divider } from 'primereact/divider'
import { forwardRef, useEffect, useState } from 'react'
import { useForm, Controller, SubmitHandler } from 'react-hook-form'
import { InputText } from 'primereact/inputtext'
import { MultiSelect } from 'primereact/multiselect'
import { TreeSelect } from 'primereact/treeselect'
import { Checkbox } from 'primereact/checkbox'
import { classNames } from 'primereact/utils'
import {
  useFormCapabilityData,
  useFormGeoDataMulti,
  useFormQuestionPackTypeScopeData,
  useFormTagData,
} from '../../common/formHooks'
import { UpdateQuestionPack, useQuestionPackUpdateMutation } from '../../redux/api'
import { useAppDispatch, useAppSelector } from '../../common/hooks'
import { Dropdown } from 'primereact/dropdown'
import QuestionPackDetailsSkeleton from './QuestionPackDetailsSkeleton'
import { RadioButton } from 'primereact/radiobutton'
import { setToastMessage } from '../../redux/app'
import { InputTextarea } from 'primereact/inputtextarea'

interface FormData {
  name: string
  displayName: string
  description: string | null
  questionPackTypeScope: number
  mustHave: boolean
  defaultSelected: boolean
  originGlobal: boolean
  originGeo: number
  global: boolean
  geos: number[]
  capabilities: { [key: number]: boolean }
  tags: number[]
  rfiTypesCompany: boolean
  rfiTypesProduct: boolean
}

interface Props {
  questionPack: QuestionPack
  toggleEdit: () => void
}

const QuestionPackDetailsEdit = forwardRef<HTMLFormElement, Props>(
  ({ questionPack, toggleEdit }: Props, ref: React.ForwardedRef<HTMLFormElement>): JSX.Element => {
    const workspaceId = useAppSelector((state) => state.app.workspace!.id)
    const [updateQuestionPackType] = useQuestionPackUpdateMutation()
    const [geosCheckbox, setGeosCheckbox] = useState<boolean>(false)
    const dispatch = useAppDispatch()

    const {
      control,
      formState: { errors },
      getValues,
      setValue,
      handleSubmit,
      resetField,
      watch,
    } = useForm<FormData>({
      defaultValues: {
        name: questionPack.name,
        displayName: questionPack.displayName,
        description: questionPack.description,
        originGlobal: questionPack.geo.type === null,
        originGeo: questionPack.geo.type !== null ? questionPack.geo.id : undefined,
      },
    })

    const watchMustHave = watch('mustHave')

    useEffect(() => {
      if (watchMustHave) {
        setValue('defaultSelected', true)
      }
    }, [watchMustHave])

    const formTagData = useFormTagData(questionPack, resetField)
    const formGeoData = useFormGeoDataMulti(questionPack, resetField)
    const formCapabilityData = useFormCapabilityData(questionPack, resetField)
    const formQuestionPackTypeScope = useFormQuestionPackTypeScopeData(questionPack, resetField)

    useEffect(() => {
      setGeosCheckbox(formGeoData.defaultGeos.length > 0 ? true : false)
    }, [formGeoData.defaultGeos])

    const geosOnChangeHandler = () => {
      setGeosCheckbox(!geosCheckbox)
    }

    const onSubmit: SubmitHandler<FormData> = async (formData) => {
      const updateData: UpdateQuestionPack = {
        name: formData.name,
        displayName: formData.displayName,
        description: formData.description,
        questionPackType: formQuestionPackTypeScope.convert(formData.questionPackTypeScope!),
        isMustHave: formData.mustHave,
        isDefaultSelected: formData.defaultSelected,
        geo: formGeoData.convertSingle(formData.originGlobal, formData.originGeo),
        geos: formGeoData.convert(formData.global, formData.geos),
        capabilities: formCapabilityData.convert(formData.capabilities),
        tags: formTagData.convert(formData.tags),
        isRfiTypeCompany: formData.rfiTypesCompany,
        isRfiTypeProduct: formData.rfiTypesProduct,
      }
      try {
        await updateQuestionPackType({
          workspaceId,
          questionPackId: questionPack?.id!,
          updateQuestionPack: updateData,
        }).unwrap()
        toggleEdit()
        dispatch(
          setToastMessage({
            severity: 'success',
            summary: 'Changes saved successfully.',
          }),
        )
      } catch {
        dispatch(
          setToastMessage({
            severity: 'error',
            summary: 'Changes could not be saved.',
          }),
        )
      }
    }

    return formTagData.isLoading || formGeoData.isLoading ? (
      <QuestionPackDetailsSkeleton />
    ) : (
      <form ref={ref} onSubmit={handleSubmit(onSubmit)} className="p-fluid">
        <div className="card mt-5">
          <div className="formgrid grid">
            <div className="field col-12 xl:col-6">
              <label htmlFor="name" className={classNames({ 'p-error': errors.name })}>
                Name:
              </label>
              <Controller
                name="name"
                control={control}
                rules={{
                  required: { value: true, message: 'Name is a required field' },
                  maxLength: { value: 255, message: 'Name must be less than 256 characters' },
                }}
                render={({ field, fieldState }) => (
                  <InputText
                    id={field.name}
                    value={field.value}
                    onChange={field.onChange}
                    onBlur={(e) => {
                      field.onChange(e.target.value.trim())
                    }}
                    placeholder="Enter name"
                    className={classNames({ 'p-invalid': fieldState.error })}
                  />
                )}
              />
              {errors.name && <small className="p-error">{errors.name?.message}</small>}
            </div>
          </div>
        </div>
        <div className="formgrid grid">
          <div className="field col-12 md:col-6">
            <label htmlFor="name" className={classNames({ 'p-error': errors.description })}>
              Description:
            </label>
            <Controller
              name="description"
              control={control}
              render={({ field, fieldState }) => (
                <InputTextarea
                  id={field.name}
                  value={field.value || ''}
                  onChange={field.onChange}
                  onBlur={(e) => {
                    field.onChange(e.target.value.trim() === '' ? null : e.target.value.trim())
                  }}
                  placeholder="Enter description (optional)"
                  className={classNames({ 'p-invalid': fieldState.error })}
                  rows={7}
                />
              )}
            />
            {errors.description && <small className="p-error">{errors.description?.message}</small>}
          </div>
        </div>
        <Divider />

        <div className="card mb-5">
          <div className="flex card-container overflow-hidden gap-4">
            <div className="flex-auto col-6">
              <div className="field grid">
                <div className="col-12 mb-2 xl:col-2 xl:mb-0 font-medium align-self-center">
                  <label htmlFor="name" className={classNames({ 'p-error': errors.displayName })}>
                    Display Name:
                  </label>
                </div>
                <div className="col-12 xl:col-10 pr-0">
                  <Controller
                    name="displayName"
                    control={control}
                    rules={{
                      required: { value: true, message: 'Display Name is a required field' },
                      maxLength: {
                        value: 255,
                        message: 'Display Name must be less than 256 characters',
                      },
                    }}
                    render={({ field, fieldState }) => (
                      <InputText
                        id={field.name}
                        value={field.value}
                        onChange={field.onChange}
                        onBlur={(e) => {
                          field.onChange(e.target.value.trim())
                        }}
                        placeholder="Enter display name"
                        className={classNames({ 'p-invalid': fieldState.error })}
                      />
                    )}
                  />
                  {errors.displayName && (
                    <small className="p-error">{errors.displayName?.message}</small>
                  )}
                </div>
              </div>

              <div className="field grid">
                <div className="col-12 mb-2 xl:col-2 xl:mb-0 font-medium align-self-center">
                  <label
                    htmlFor="name"
                    className={classNames({ 'p-error': errors.questionPackTypeScope })}
                  >
                    Type (Scope):
                  </label>
                </div>
                <div className="col-12 xl:col-10 pr-0">
                  <Controller
                    name="questionPackTypeScope"
                    control={control}
                    defaultValue={formQuestionPackTypeScope.defaultQuestionPackType}
                    render={({ field, fieldState }) => (
                      <Dropdown
                        disabled={formQuestionPackTypeScope == undefined}
                        id={field.name}
                        value={field.value}
                        onChange={(e) => field.onChange(e.value)}
                        placeholder={'Select type (scope)'}
                        options={formQuestionPackTypeScope.options}
                        optionLabel="label"
                        optionValue="value"
                        filter={true}
                        resetFilterOnHide={true}
                        scrollHeight={'400px'}
                        className={classNames({ 'p-invalid': fieldState.error })}
                      />
                    )}
                  />
                  {errors.questionPackTypeScope && (
                    <small className="p-error">{errors.questionPackTypeScope?.message}</small>
                  )}
                </div>
              </div>

              <div className="field grid">
                <label
                  htmlFor="rfiTypesCompany"
                  className={classNames('col-12 mb-2 xl:col-2 xl:mb-0 font-medium')}
                >
                  RFI Types:
                </label>

                <div className="flex col-12 xl:col-10 align-self-center pl-0 mb-2">
                  <div className="col-12">
                    <Controller
                      name="rfiTypesCompany"
                      control={control}
                      defaultValue={questionPack.isRfiTypeCompany}
                      rules={{
                        validate: (value) => {
                          return !value && !getValues('rfiTypesProduct')
                            ? 'At least one RFI type must be selected'
                            : true
                        },
                      }}
                      render={({ field, fieldState }) => (
                        <Checkbox
                          inputId={field.name}
                          onChange={(e) => {
                            field.onChange(e.checked)
                            if (e.checked) errors.rfiTypesProduct = undefined
                          }}
                          checked={field.value}
                          className={classNames({ 'p-invalid': fieldState.error })}
                        />
                      )}
                    />

                    <label
                      className={classNames(
                        { 'p-error': errors.rfiTypesCompany },
                        'ml-2 mb-2 xl:mb-0 font-medium',
                      )}
                    >
                      Company
                    </label>
                  </div>
                </div>

                <label
                  htmlFor="rfiTypesProduct"
                  className={classNames('col-12 mb-2 xl:col-2 xl:mb-0 font-medium')}
                ></label>

                <div className="flex col-12 xl:col-10 align-self-center pl-0">
                  <div className="col-12">
                    <Controller
                      name="rfiTypesProduct"
                      control={control}
                      defaultValue={questionPack.isRfiTypeProduct}
                      rules={{
                        validate: (value) => {
                          return !value && !getValues('rfiTypesCompany')
                            ? 'At least one RFI type must be selected'
                            : true
                        },
                      }}
                      render={({ field, fieldState }) => (
                        <Checkbox
                          inputId={field.name}
                          onChange={(e) => {
                            field.onChange(e.checked)
                            if (e.checked) errors.rfiTypesCompany = undefined
                          }}
                          checked={field.value}
                          className={classNames({ 'p-invalid': fieldState.error })}
                        />
                      )}
                    />

                    <label
                      className={classNames(
                        { 'p-error': errors.rfiTypesProduct },
                        'ml-2 mb-2 xl:mb-0 font-medium',
                      )}
                    >
                      Product
                    </label>
                    <br />
                    {(errors.rfiTypesProduct || errors.rfiTypesCompany) && (
                      <small className="p-error">{errors.rfiTypesProduct?.message}</small>
                    )}
                  </div>
                </div>
              </div>

              <div className="field grid">
                <label
                  htmlFor="mustHave"
                  className={classNames('col-12 mb-2 xl:col-2 xl:mb-0 font-medium')}
                >
                  Options:
                </label>

                <div className="flex col-12 xl:col-10 align-self-center pl-0 mb-2">
                  <div className="col-12">
                    <Controller
                      name="mustHave"
                      control={control}
                      defaultValue={questionPack.isMustHave}
                      render={({ field, fieldState }) => (
                        <Checkbox
                          inputId={field.name}
                          onChange={(e) => {
                            field.onChange(e.checked)
                          }}
                          checked={field.value}
                          className={classNames({ 'p-invalid': fieldState.error })}
                        />
                      )}
                    />
                    {errors.mustHave && (
                      <small className="p-error">{errors.mustHave?.message}</small>
                    )}

                    <label
                      className={classNames(
                        { 'p-error': errors.mustHave },
                        'ml-2 mb-2 xl:mb-0 font-medium',
                      )}
                    >
                      Must-have
                    </label>
                  </div>
                </div>

                <label
                  htmlFor="defaultSelected"
                  className={classNames('col-12 mb-2 xl:col-2 xl:mb-0 font-medium')}
                ></label>

                <div className="flex col-12 xl:col-10 align-self-center pl-0">
                  <div className="col-12">
                    <Controller
                      name="defaultSelected"
                      control={control}
                      defaultValue={questionPack.isDefaultSelected}
                      render={({ field, fieldState }) => (
                        <Checkbox
                          inputId={field.name}
                          onChange={(e) => field.onChange(watchMustHave ? true : e.checked)}
                          checked={watchMustHave ? true : field.value}
                          className={classNames({ 'p-invalid': fieldState.error })}
                          disabled={watchMustHave ? true : false}
                        />
                      )}
                    />
                    {errors.defaultSelected && (
                      <small className="p-error">{errors.defaultSelected?.message}</small>
                    )}

                    <label
                      className={classNames(
                        { 'p-error': errors.defaultSelected },
                        'ml-2 mb-2 xl:mb-0 font-medium',
                      )}
                    >
                      Selected by default
                    </label>
                  </div>
                </div>
              </div>

              {/* Markets - Radio Variant ...*/}
              <div className="field grid">
                <label
                  htmlFor="markets"
                  className={classNames(
                    { 'p-error': errors.originGeo },
                    'col-12 xl:col-2 mb-2 xl:mb-0 font-medium',
                  )}
                >
                  Origin Market:
                </label>

                <div className="flex col-12 xl:col-10 align-self-center pl-0">
                  <div className="col-12">
                    <Controller
                      name="originGlobal"
                      control={control}
                      render={({ field, fieldState, formState }) => (
                        <>
                          <RadioButton
                            inputId={field.name}
                            name="originGlobalRadio"
                            onChange={(e) => {
                              field.onChange(e.checked)
                              if (e.checked) {
                                resetField('originGlobal', { defaultValue: true })
                              }
                            }}
                            value={true}
                            checked={formState.defaultValues?.originGlobal === true}
                            className={classNames({ 'p-invalid': fieldState.error })}
                          />
                        </>
                      )}
                    />
                    {errors.originGlobal && (
                      <small className="p-error">{errors.originGlobal?.message}</small>
                    )}

                    <label
                      className={
                        (classNames({ 'p-error': errors.originGlobal }),
                        'ml-2 mb-2 xl:mb-0 font-medium')
                      }
                    >
                      Global
                    </label>
                  </div>
                </div>
              </div>

              <div className="field grid">
                <div className="flex col-12 xl:col-offset-2 align-self-center p-0">
                  <div className="flex col-4 xl:col-2 align-self-center">
                    <Controller
                      name="originGlobal"
                      control={control}
                      render={({ field, fieldState, formState }) => (
                        <>
                          <RadioButton
                            inputId={field.name}
                            name="originGlobalRadio"
                            onChange={(e) => {
                              field.onChange(e.checked)
                              if (e.checked) {
                                resetField('originGlobal', { defaultValue: false })
                              }
                            }}
                            value={false}
                            checked={formState.defaultValues?.originGlobal === false}
                            className={classNames({ 'p-invalid': fieldState.error })}
                          />
                        </>
                      )}
                    />

                    <div className="ml-2 xl:ml-0 mb-2 xl:mb-0 xl:col-2 font-medium ">
                      <label className={classNames({ 'p-error': errors.originGeo })}>Market:</label>
                    </div>
                  </div>

                  <div className="col-8 xl:col-8 p-0">
                    <Controller
                      name="originGeo"
                      control={control}
                      render={({ field, fieldState, formState }) => (
                        <>
                          <Dropdown
                            value={field.value}
                            options={formGeoData.options}
                            onChange={(e) => {
                              field.onChange(e.value)
                              resetField('originGlobal', { defaultValue: false })
                            }}
                            optionLabel="label"
                            optionGroupLabel="label"
                            optionGroupChildren="items"
                            filter={true}
                            resetFilterOnHide={true}
                            placeholder="Select market"
                            scrollHeight={'400px'}
                            disabled={formState.defaultValues?.originGlobal === true}
                          />
                        </>
                      )}
                    />
                    {errors.originGeo && (
                      <small className="p-error">{errors.originGeo?.message}</small>
                    )}
                  </div>
                </div>
              </div>
              {/* ... Markets - Radio Variant */}

              {/* Markets - Checkbox Variant ...*/}
              <div className="field grid">
                <label
                  htmlFor="markets"
                  className={classNames(
                    { 'p-error': errors.geos },
                    'col-12 mb-2 xl:col-2 xl:mb-0 font-medium',
                  )}
                >
                  Exclusive Markets:
                </label>

                <div className="flex col-12 xl:col-10 align-self-center pl-0">
                  <div className="col-12">
                    <Controller
                      name="global"
                      control={control}
                      defaultValue={formGeoData.defaultGlobal}
                      render={({ field, fieldState }) => (
                        <Checkbox
                          inputId={field.name}
                          onChange={(e) => field.onChange(e.checked)}
                          checked={field.value}
                          className={classNames({ 'p-invalid': fieldState.error })}
                        />
                      )}
                    />
                    {errors.global && <small className="p-error">{errors.global?.message}</small>}

                    <label
                      className={classNames(
                        { 'p-error': errors.global },
                        'ml-2 mb-2 xl:mb-0 font-medium',
                      )}
                    >
                      Global
                    </label>
                  </div>
                </div>
              </div>

              <div className="field grid">
                <div className="flex col-12 xl:col-offset-2 align-self-center p-0">
                  <div className="flex col-4 xl:col-2 align-self-center">
                    <Checkbox checked={geosCheckbox} onChange={geosOnChangeHandler} />
                    <div className="ml-2 mb-2 xl:mb-0 font-medium">
                      <label className={classNames({ 'p-error': errors.geos })}>Markets:</label>
                    </div>
                  </div>

                  <div className="col-8 xl:col-8 p-0">
                    <Controller
                      name="geos"
                      control={control}
                      defaultValue={formGeoData.defaultGeos}
                      render={({ field, fieldState, formState }) => (
                        <MultiSelect
                          id={field.name}
                          value={field.value}
                          options={formGeoData.options}
                          onChange={(e) => field.onChange(e.value)}
                          optionLabel="label"
                          optionGroupLabel="label"
                          optionGroupChildren="items"
                          placeholder="Select markets"
                          showSelectAll={true}
                          display="chip"
                          filter={true}
                          resetFilterOnHide={true}
                          scrollHeight={'400px'}
                          className={classNames({ 'p-invalid': fieldState.error })}
                          disabled={!geosCheckbox}
                        />
                      )}
                    />
                    {errors.geos && <small className="p-error">{errors.geos?.message}</small>}
                  </div>
                </div>
              </div>
              {/* ... Markets - Checkbox Variant */}

              {/* Capabilities - Checkbox Variant ...*/}
              <div className="field grid">
                <label
                  htmlFor="capabilities"
                  className={classNames(
                    { 'p-error': errors.capabilities },
                    'col-12 mb-2 xl:col-2 xl:mb-0 font-medium',
                  )}
                >
                  Exclusive Capabilities:
                </label>
                <div className="col-12 xl:col-10 pr-0">
                  <Controller
                    name="capabilities"
                    control={control}
                    defaultValue={formCapabilityData.defaultCapabilities}
                    render={({ field, fieldState }) => (
                      <TreeSelect
                        id={field.name}
                        value={field.value}
                        options={formCapabilityData.options}
                        onChange={(e) => field.onChange(e.value as { [key: number]: boolean })}
                        selectionMode="multiple"
                        metaKeySelection={false}
                        placeholder="Select capabilities (optional)"
                        display="chip"
                        filter={true}
                        resetFilterOnHide={true}
                        scrollHeight={'400px'}
                        className={classNames({ 'p-invalid': fieldState.error })}
                      />
                    )}
                  />
                  {errors.capabilities && (
                    <small className="p-error">{errors.capabilities?.message}</small>
                  )}
                </div>
              </div>
              {/* Capabilities - Checkbox Variant ...*/}
            </div>

            <div className="flex-auto col-6">
              <div className="field grid">
                <label
                  htmlFor="tags"
                  className={classNames(
                    { 'p-error': errors.tags },
                    'col-12 mb-2 xl:col-2 xl:mb-0 font-medium',
                  )}
                >
                  Required Tags:
                </label>
                <div className="col-12 xl:col-10">
                  <Controller
                    name="tags"
                    control={control}
                    defaultValue={formTagData.defaultTags}
                    render={({ field, fieldState }) => (
                      <MultiSelect
                        id={field.name}
                        value={field.value}
                        options={formTagData.options}
                        onChange={(e) => field.onChange(e.value)}
                        optionLabel="label"
                        optionGroupLabel="label"
                        optionGroupChildren="items"
                        placeholder="Select tags (optional)"
                        showSelectAll={true}
                        display="chip"
                        filter={true}
                        resetFilterOnHide={true}
                        scrollHeight={'400px'}
                        className={classNames({ 'p-invalid': fieldState.error })}
                      />
                    )}
                  />
                  {errors.tags && <small className="p-error">{errors.tags?.message}</small>}
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    )
  },
)

export default QuestionPackDetailsEdit

import React from 'react'
import ParticipantList from '../Participant/ParticipantList'
import { useAppSelector } from '../../common/hooks'
import { useParticipantListByProductQuery } from '../../redux/api'

interface Props {
  productId: number
}

const ProductTabProductRfis = ({ productId }: Props): JSX.Element => {
  const workspaceId = useAppSelector((state) => state.app.workspace!.id)

  const { data, isFetching } = useParticipantListByProductQuery({
    workspaceId,
    productId,
  })

  return (
    <ParticipantList
      toolbarLeft={<h1>{'Product RFIs'}</h1>}
      data={data}
      isLoading={isFetching}
      showFilter={true}
    />
  )
}

export default ProductTabProductRfis

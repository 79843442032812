import { useEffect, useState } from 'react'
import { useQuestionListByRfiQuery } from '../redux/api'
import { useAppSelector } from './hooks'
import TreeNode from 'primereact/treenode'

export interface QuestionPackTreeNodeData {
  id: number
  name: string
  search: string
  type: string | null
  market: Geo | null
  capabilities: Capability[] | null
}

export const questionPacksToTreeNodes = (questionPacks: QuestionPack[]) => {
  return questionPacks.map((questionPack: QuestionPack) => {
    const children = (questionPack.questions || []).map((question: Question) => {
      return {
        key: `${questionPack.id}-${question.id}`,
        data: {
          id: question.id,
          name: question.phrase,
          search: `${question.phrase} ${question.description}`,
          type: question.type,
          market: null,
          capabilities: null,
        },
      }
    })
    return {
      key: `${questionPack.id}`,
      data: {
        id: questionPack.id,
        name: questionPack.name,
        search: '',
        type: null,
        market: questionPack.geo,
        capabilities: questionPack.capabilities,
      },
      children,
    }
  })
}

// QUESTION BY RFI - TREE TABLE DATA ...

export const useQuestionByRfiTreeTableData = (
  rfi: Rfi,
): {
  isLoading: boolean
  data: TreeNode[]
} => {
  const workspaceId = useAppSelector((state) => state.app.workspace!.id)
  const { data, isLoading } = useQuestionListByRfiQuery({ workspaceId, rfiId: rfi.id })
  const [value, setValue] = useState<TreeNode[]>([])

  useEffect(() => {
    if (!isLoading) {
      setValue(questionPacksToTreeNodes(data || []))
    }
  }, [isLoading])

  return { data: value, isLoading }
}

// ... QUESTION BY RFI - TREE TABLE DATA

// QUESTIONNAIRE - GROUPED DATA ...

export interface QuestionAnswerSection extends QuestionPackType {
  questionPacks: QuestionPack[]
}

export const useQuestionAnswerData = (data: QuestionPack[]): QuestionAnswerSection[] => {
  const sections = data
    .map((questionPack): QuestionAnswerSection => {
      return { ...questionPack.questionPackType, questionPacks: [] }
    })
    .filter(
      (filterItem, filterIndex, self) =>
        self.findIndex((findItem) => findItem.id === filterItem.id) === filterIndex,
    )

  data.every((questionPack) =>
    sections[
      sections.findIndex((section) => section.id === questionPack.questionPackType.id)
    ].questionPacks.push(questionPack),
  )

  return sections
}

// ... QUESTIONNAIRE - GROUPED DATA

import {
  FormProductDataOption,
  useFormProductsForAddParticipantPicklist,
} from '../../common/formHooks'
import TagList, { NORMALIZE_PRESETS } from '../TagList'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { PickList } from 'primereact/picklist'
import { Toolbar } from 'primereact/toolbar'
import DispatcherButton, { DispatcherButtonPresets } from '../DispatcherButton'
import { CreateParticipant, useParticipantsCreateMutation } from '../../redux/api'
import { useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../common/hooks'
import { setToastMessage } from '../../redux/app'
import createStyles from '../../styles/create.module.scss'
import { Skeleton } from 'primereact/skeleton'

interface FormData {
  products: FormProductDataOption[]
}

interface Props {
  rfi: Rfi
  onAdded: () => void
}

const itemTemplate = (item: FormProductDataOption) => {
  return (
    <div key={item.name} className={'ve-picklist-item'}>
      <div className={'inline-flex flex-wrap column-gap-3'}>
        <div className={'inline-flex flex-wrap font-bold line-height-3'}>{item.name}</div>
        <div className={'inline-flex gap-1'}>
          <div className={'line-height-3'}>Capabilities:</div>
          <TagList
            items={item.capabilities}
            emptyVal={'(none)'}
            normalize={NORMALIZE_PRESETS.POS}
            maxShown={5}
          />
        </div>
      </div>
      <div className={'block text-sm text-color-secondary font-italic'}>{item.company.name}</div>
    </div>
  )
}

const ParticipantAddProduct = ({ rfi, onAdded }: Props): JSX.Element => {
  const workspaceId = useAppSelector((state) => state.app.workspace!.id)
  const [submitted, setSubmitted] = useState(false)
  const dispatch = useAppDispatch()
  const [createParticipants] = useParticipantsCreateMutation()

  const { control, handleSubmit } = useForm<FormData>({
    defaultValues: {
      products: [],
    },
  })

  const formProductsData = useFormProductsForAddParticipantPicklist(rfi)

  const submitFormData: SubmitHandler<FormData> = async (formData) => {
    setSubmitted(true)
    const data: CreateParticipant[] = formData.products.map((item) => {
      return {
        rfi: {
          id: rfi.id,
        },
        company: {
          id: item.company.id,
        },
        product: {
          id: item.id,
        },
      }
    })
    try {
      await createParticipants({
        workspaceId,
        data,
      })
      dispatch(
        setToastMessage({
          severity: 'success',
          summary: 'Participants added successfully.',
        }),
      )
    } catch {
      dispatch(
        setToastMessage({
          severity: 'error',
          summary: 'Could not add participants.',
        }),
      )
      setSubmitted(false)
    }
    onAdded()
  }

  return (
    <div className={createStyles.main}>
      <form className="p-fluid" onSubmit={handleSubmit(submitFormData)}>
        <div key="participants" className="field grid">
          <div className="col-12">
            <Controller
              name="products"
              control={control}
              render={({ field }) =>
                formProductsData.isLoading ? (
                  <Skeleton height="342px" />
                ) : (
                  <PickList
                    source={formProductsData.source}
                    target={formProductsData.target}
                    itemTemplate={itemTemplate}
                    sourceHeader="Available Products"
                    targetHeader="Selected Products"
                    showSourceControls={false}
                    showTargetControls={false}
                    sourceStyle={{ height: '342px' }}
                    targetStyle={{ height: '342px' }}
                    onChange={(event) => {
                      field.onChange(event.target)
                      formProductsData.setSource(event.source)
                      formProductsData.setTarget(event.target)
                    }}
                    filterBy="name,company.name"
                    sourceFilterPlaceholder="Filter by name"
                    targetFilterPlaceholder="Filter by name"
                  />
                )
              }
            />
          </div>
        </div>
        <Toolbar
          right={
            <DispatcherButton
              buttonProps={{
                disabled: formProductsData.target.length === 0 || submitted,
                label: formProductsData.target.length > 1 ? 'Add Participants' : 'Add Participant',
                icon: 'pi pi-arrow-right',
                iconPos: 'right',
                type: 'submit',
              }}
              className={DispatcherButtonPresets.FILLED_ROUNDED_PRIMARY}
            />
          }
        />
      </form>
    </div>
  )
}

export default ParticipantAddProduct

import { Tooltip } from 'primereact/tooltip'
import { Toolbar } from 'primereact/toolbar'
import DispatcherButton, { DispatcherButtonPresets } from '../DispatcherButton'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useFormCapabilityData, useFormGeoDataMulti, useFormTagData } from '../../common/formHooks'
import { classNames } from 'primereact/utils'
import { InputTextarea } from 'primereact/inputtextarea'
import { Checkbox } from 'primereact/checkbox'
import { useEffect, useState } from 'react'
import { TreeSelect } from 'primereact/treeselect'
import { MultiSelect } from 'primereact/multiselect'
import { Tag } from 'primereact/tag'
import QuestionDetailsSkeleton from './QuestionDetailsSkeleton'
import { Update } from '@reduxjs/toolkit'
import { UpdateQuestion } from '../../redux/api'

interface FormData {
  id: number
  phrase: string
  description: string | null
  dimensionsExpr: string
  labelsExpr: string | null
  mustHave: boolean
  defaultSelected: boolean
  originGlobal: boolean
  originGeo: number
  global: boolean
  geos: number[]
  capabilities: { [key: number]: boolean }
  tags: number[]
  rfiTypesCompany: boolean
  rfiTypesProduct: boolean
}

interface Props {
  questionData: UpdateQuestion
  setQuestionData: React.Dispatch<React.SetStateAction<UpdateQuestion>>
  setStep: React.Dispatch<React.SetStateAction<number>>
}

const QuestionEditStepOne = ({ questionData, setStep, setQuestionData }: Props): JSX.Element => {
  const {
    control,
    formState: { errors },
    handleSubmit,
    getValues,
    setValue,
    resetField,
    watch,
  } = useForm<FormData>({
    reValidateMode: 'onBlur',
    defaultValues: {
      phrase: questionData.phrase,
      description: questionData.description,
      dimensionsExpr: questionData.dimensionsExpr,
      labelsExpr: questionData.labelsExpr,
      mustHave: questionData.isMustHave,
      defaultSelected: questionData.isDefaultSelected,
      rfiTypesCompany: questionData.isRfiTypeCompany,
      rfiTypesProduct: questionData.isRfiTypeProduct,
    },
  })

  const watchMustHave = watch('mustHave')
  const [geosCheckbox, setGeosCheckbox] = useState<boolean>(false)

  useEffect(() => {
    if (watchMustHave) {
      setValue('defaultSelected', true)
    }
  }, [watchMustHave])

  const formTagData = useFormTagData(questionData, resetField)
  const formGeoData = useFormGeoDataMulti(questionData, resetField)
  const formCapabilityData = useFormCapabilityData(questionData, resetField)

  useEffect(() => {
    setGeosCheckbox(formGeoData.defaultGeos.length > 0 ? true : false)
  }, [formGeoData.defaultGeos])

  const geosOnChangeHandler = () => {
    setGeosCheckbox(!geosCheckbox)
  }

  const submitFormData: SubmitHandler<FormData> = async (formData) => {
    setQuestionData({
      ...questionData,
      phrase: formData.phrase,
      description: formData.description,
      dimensionsExpr: formData.dimensionsExpr,
      labelsExpr: formData.labelsExpr,
      isRfiTypeCompany: formData.rfiTypesCompany,
      isRfiTypeProduct: formData.rfiTypesProduct,
      isMustHave: formData.mustHave,
      isDefaultSelected: formData.defaultSelected,
      geos: formGeoData.convert(formData.global, formData.geos),
      capabilities: formCapabilityData.convert(formData.capabilities),
      tags: formTagData.convert(formData.tags),
    })
    setStep((step) => step + 1)
  }

  return formTagData.isLoading || formGeoData.isLoading ? (
    <QuestionDetailsSkeleton />
  ) : (
    <form className="p-fluid" onSubmit={handleSubmit(submitFormData)}>
      <Tooltip target=".infoTooltip" />
      <div className={'flex align-items-center flex-row'}>
        <h3>General Information</h3>
      </div>
      <div className="card">
        <div className="field grid">
          <label
            htmlFor="phrase"
            className={classNames(
              { 'p-error': errors.phrase },
              'col-12 mb-2 md:col-2 md:mb-0 lg:col-2 align-items-start flex-column',
            )}
          >
            Phrase:
          </label>
          <div className="col-12 md:col-10 lg:col-10">
            <Controller
              name="phrase"
              control={control}
              rules={{
                required: { value: true, message: 'Phrase is a required field' },
              }}
              render={({ field, fieldState }) => (
                <Controller
                  name="phrase"
                  control={control}
                  render={({ field }) => (
                    <InputTextarea
                      id={field.name}
                      value={field.value || ''}
                      onChange={field.onChange}
                      onBlur={(e) => {
                        field.onChange(e.target.value.trim() === '' ? '' : e.target.value.trim())
                      }}
                      placeholder="Enter phrase"
                      rows={7}
                    />
                  )}
                />
              )}
            />
            {errors.phrase && <small className="p-error">{errors.phrase?.message}</small>}
          </div>
        </div>

        <div className="field grid">
          <label
            htmlFor="description"
            className={
              'description col-12 mb-2 md:col-2 md:mb-0 lg:col-2 align-items-start flex-column'
            }
          >
            Description:
          </label>
          <div className="col-12 md:col-10 lg:col-10">
            <Controller
              name="description"
              control={control}
              render={({ field }) => (
                <InputTextarea
                  id={field.name}
                  value={field.value || ''}
                  onChange={field.onChange}
                  onBlur={(e) => {
                    field.onChange(e.target.value.trim() === '' ? null : e.target.value.trim())
                  }}
                  placeholder="Enter description (optional)"
                  rows={7}
                />
              )}
            />
          </div>
        </div>

        <div className="field grid">
          <label
            htmlFor="dimensionsExpr"
            className={'col-12 mb-2 md:col-2 md:mb-0 lg:col-2 align-items-start flex-column'}
          >
            Dimensions Expression:
          </label>
          <div className="col-12 md:col-10 lg:col-10">
            <Controller
              name="dimensionsExpr"
              control={control}
              render={({ field }) => (
                <InputTextarea
                  id={field.name}
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={(e) => {
                    field.onChange(e.target.value.trim() === '' ? '1' : e.target.value.trim())
                  }}
                  placeholder="Enter Dimensions Expression"
                  rows={1}
                />
              )}
            />
          </div>
        </div>

        <div className="field grid">
          <label
            htmlFor="labelsExpr"
            className={'col-12 mb-2 md:col-2 md:mb-0 lg:col-2 align-items-start flex-column'}
          >
            Labels Expression:
          </label>
          <div className="col-12 md:col-10 lg:col-10">
            <Controller
              name="labelsExpr"
              control={control}
              render={({ field }) => (
                <InputTextarea
                  id={field.name}
                  value={field.value || ''}
                  onChange={field.onChange}
                  onBlur={(e) => {
                    field.onChange(e.target.value.trim() === '' ? null : e.target.value.trim())
                  }}
                  placeholder="Enter Labels Expression (optional)"
                  rows={1}
                />
              )}
            />
          </div>
        </div>

        <div className="field grid">
          <label
            htmlFor="rfiTypesCompany"
            className={classNames(
              'col-12 mb-2 md:col-2 md:mb-0 lg:col-2 align-items-start flex-column',
            )}
          >
            Type (Scope):
          </label>

          <Tag
            value={questionData.type.toLowerCase().replace(/_/g, ' ') || '(none)'}
            className={'bg-bluegray-500 ml-2'}
          />
        </div>
        <div className="field grid">
          <label
            htmlFor="rfiTypesCompany"
            className={classNames('col-12 mb-2 xl:col-2 xl:mb-0 font-medium')}
          >
            RFI Types:
          </label>

          <div className="flex col-12 xl:col-10 align-self-center pl-0 mb-2">
            <div className="col-12">
              <Controller
                name="rfiTypesCompany"
                control={control}
                defaultValue={questionData.isRfiTypeCompany}
                rules={{
                  validate: (value) => {
                    return !value && !getValues('rfiTypesProduct')
                      ? 'At least one RFI type must be selected'
                      : true
                  },
                }}
                render={({ field, fieldState }) => (
                  <Checkbox
                    inputId={field.name}
                    onChange={(e) => {
                      field.onChange(e.checked)
                      if (e.checked) errors.rfiTypesProduct = undefined
                    }}
                    checked={field.value}
                    className={classNames({ 'p-invalid': fieldState.error })}
                  />
                )}
              />

              <label
                className={classNames(
                  { 'p-error': errors.rfiTypesCompany },
                  'ml-2 mb-2 xl:mb-0 font-medium',
                )}
              >
                Company
              </label>
            </div>
          </div>

          <label
            htmlFor="rfiTypesProduct"
            className={classNames('col-12 mb-2 xl:col-2 xl:mb-0 font-medium')}
          ></label>

          <div className="flex col-12 xl:col-10 align-self-center pl-0">
            <div className="col-12">
              <Controller
                name="rfiTypesProduct"
                control={control}
                defaultValue={questionData.isRfiTypeProduct}
                rules={{
                  validate: (value) => {
                    return !value && !getValues('rfiTypesCompany')
                      ? 'At least one RFI type must be selected'
                      : true
                  },
                }}
                render={({ field, fieldState }) => (
                  <Checkbox
                    inputId={field.name}
                    onChange={(e) => {
                      field.onChange(e.checked)
                      if (e.checked) errors.rfiTypesCompany = undefined
                    }}
                    checked={field.value}
                    className={classNames({ 'p-invalid': fieldState.error })}
                  />
                )}
              />

              <label
                className={classNames(
                  { 'p-error': errors.rfiTypesProduct },
                  'ml-2 mb-2 xl:mb-0 font-medium',
                )}
              >
                Product
              </label>
              <br />
              {(errors.rfiTypesProduct || errors.rfiTypesCompany) && (
                <small className="p-error">{errors.rfiTypesProduct?.message}</small>
              )}
            </div>
          </div>
        </div>

        <div className="field grid">
          <label
            htmlFor="mustHave"
            className={classNames('col-12 mb-2 xl:col-2 xl:mb-0 font-medium')}
          >
            Options:
          </label>

          <div className="flex col-12 xl:col-10 align-self-center pl-0 mb-2">
            <div className="col-12">
              <Controller
                name="mustHave"
                control={control}
                defaultValue={questionData.isMustHave}
                render={({ field, fieldState }) => (
                  <Checkbox
                    inputId={field.name}
                    onChange={(e) => {
                      field.onChange(e.checked)
                    }}
                    checked={field.value}
                    className={classNames({ 'p-invalid': fieldState.error })}
                  />
                )}
              />
              {errors.mustHave && <small className="p-error">{errors.mustHave?.message}</small>}

              <label
                className={classNames(
                  { 'p-error': errors.mustHave },
                  'ml-2 mb-2 xl:mb-0 font-medium',
                )}
              >
                Must-have
              </label>
            </div>
          </div>

          <label
            htmlFor="defaultSelected"
            className={classNames('col-12 mb-2 xl:col-2 xl:mb-0 font-medium')}
          ></label>

          <div className="flex col-12 xl:col-10 align-self-center pl-0">
            <div className="col-12">
              <Controller
                name="defaultSelected"
                control={control}
                defaultValue={questionData.isDefaultSelected}
                render={({ field, fieldState }) => (
                  <Checkbox
                    inputId={field.name}
                    onChange={(e) => field.onChange(watchMustHave ? true : e.checked)}
                    checked={watchMustHave ? true : field.value}
                    className={classNames({ 'p-invalid': fieldState.error })}
                    disabled={watchMustHave ? true : false}
                  />
                )}
              />
              {errors.defaultSelected && (
                <small className="p-error">{errors.defaultSelected?.message}</small>
              )}

              <label
                className={classNames(
                  { 'p-error': errors.defaultSelected },
                  'ml-2 mb-2 xl:mb-0 font-medium',
                )}
              >
                Selected by default
              </label>
            </div>
          </div>
        </div>

        {/* Markets - Checkbox Variant ...*/}
        <div className="field grid">
          <label
            htmlFor="global"
            className={classNames(
              { 'p-error': errors.geos },
              'col-12 mb-2 xl:col-2 xl:mb-0 font-medium',
            )}
          >
            Exclusive Markets:
          </label>

          <div className="flex col-12 xl:col-10 align-self-center pl-0">
            <div className="col-12">
              <Controller
                name="global"
                control={control}
                defaultValue={formGeoData.defaultGlobal}
                render={({ field, fieldState }) => (
                  <Checkbox
                    inputId={field.name}
                    onChange={(e) => field.onChange(e.checked)}
                    checked={field.value}
                    className={classNames({ 'p-invalid': fieldState.error })}
                  />
                )}
              />
              {errors.global && <small className="p-error">{errors.global?.message}</small>}

              <label
                className={classNames(
                  { 'p-error': errors.global },
                  'ml-2 mb-2 xl:mb-0 font-medium',
                )}
              >
                Global
              </label>
            </div>
          </div>
        </div>

        <div className="field grid">
          <div className="flex col-10 xl:col-offset-2 align-self-center p-0">
            <div className="flex col-4 xl:col-2 align-self-center">
              <Checkbox checked={geosCheckbox} onChange={geosOnChangeHandler} />
              <div className="ml-2 mb-2 xl:mb-0 font-medium">
                <label className={classNames({ 'p-error': errors.geos })}>Markets:</label>
              </div>
            </div>

            <div className="col-10 xl:col-10">
              <Controller
                name="geos"
                control={control}
                defaultValue={formGeoData.defaultGeos}
                render={({ field, fieldState, formState }) => (
                  <MultiSelect
                    inputId={field.name}
                    value={field.value}
                    options={formGeoData.options}
                    onChange={(e) => {
                      field.onChange(e.value)
                    }}
                    optionLabel="label"
                    optionGroupLabel="label"
                    optionGroupChildren="items"
                    placeholder="Select markets"
                    showSelectAll={true}
                    display="chip"
                    filter={true}
                    resetFilterOnHide={true}
                    scrollHeight={'400px'}
                    className={classNames({ 'p-invalid': fieldState.error })}
                    disabled={!geosCheckbox}
                  />
                )}
              />
              {errors.geos && <small className="p-error">{errors.geos?.message}</small>}
            </div>
          </div>
        </div>
        {/* ... Markets - Checkbox Variant */}

        {/* Capabilities - Checkbox Variant ...*/}
        <div className="field grid">
          <label
            htmlFor="capabilities"
            className={classNames(
              { 'p-error': errors.capabilities },
              'col-12 mb-2 xl:col-2 xl:mb-0 font-medium',
            )}
          >
            Exclusive Capabilities:
          </label>
          <div className="col-12 xl:col-10">
            <Controller
              name="capabilities"
              control={control}
              defaultValue={formCapabilityData.defaultCapabilities}
              render={({ field, fieldState }) => (
                <TreeSelect
                  inputId={field.name}
                  value={field.value}
                  options={formCapabilityData.options}
                  onChange={(e) => field.onChange(e.value as { [key: number]: boolean })}
                  selectionMode="multiple"
                  metaKeySelection={false}
                  placeholder="Select capabilities (optional)"
                  display="chip"
                  filter={true}
                  resetFilterOnHide={true}
                  scrollHeight={'400px'}
                  className={classNames({ 'p-invalid': fieldState.error })}
                />
              )}
            />
            {errors.capabilities && (
              <small className="p-error">{errors.capabilities?.message}</small>
            )}
          </div>
        </div>
        {/* Capabilities - Checkbox Variant ...*/}

        <div className="field grid">
          <label
            htmlFor="tags"
            className={classNames(
              { 'p-error': errors.tags },
              'col-12 mb-2 xl:col-2 xl:mb-0 font-medium',
            )}
          >
            Required Tags:
          </label>
          <div className="col-12 xl:col-10">
            <Controller
              name="tags"
              control={control}
              defaultValue={formTagData.defaultTags}
              render={({ field, fieldState }) => (
                <MultiSelect
                  inputId={field.name}
                  value={field.value}
                  options={formTagData.options}
                  onChange={(e) => field.onChange(e.value)}
                  optionLabel="label"
                  optionGroupLabel="label"
                  optionGroupChildren="items"
                  placeholder="Select tags (optional)"
                  showSelectAll={true}
                  display="chip"
                  filter={true}
                  resetFilterOnHide={true}
                  scrollHeight={'400px'}
                  className={classNames({ 'p-invalid': fieldState.error })}
                />
              )}
            />
            {errors.tags && <small className="p-error">{errors.tags?.message}</small>}
          </div>
        </div>
      </div>

      <Toolbar
        right={
          <DispatcherButton
            buttonProps={{
              label: 'Next',
              icon: 'pi pi-arrow-right',
              iconPos: 'right',
              type: 'submit',
            }}
            className={DispatcherButtonPresets.FILLED_ROUNDED_PRIMARY}
          />
        }
      />
    </form>
  )
}

export default QuestionEditStepOne

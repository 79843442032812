import { Divider } from 'primereact/divider'
import { forwardRef, useEffect, useState } from 'react'
import { useForm, Controller, SubmitHandler } from 'react-hook-form'
import { InputText } from 'primereact/inputtext'
import { MultiSelect } from 'primereact/multiselect'
import { TreeSelect } from 'primereact/treeselect'
import { Checkbox } from 'primereact/checkbox'
import { classNames } from 'primereact/utils'
import { useFormCapabilityData, useFormGeoDataMulti, useFormTagData } from '../../common/formHooks'
import {
  UpdateProduct,
  useLazyProductExistsByCompanyQuery,
  useProductUpdateMutation,
} from '../../redux/api'
import { useAppDispatch, useAppSelector } from '../../common/hooks'
import ProductDetailsSkeleton from './ProductDetailsSkeleton'
import { setToastMessage } from '../../redux/app'
import { InputTextarea } from 'primereact/inputtextarea'

interface FormData {
  name: string
  description: string | null
  aliases: string | null
  global: boolean
  geos: number[]
  capabilities: { [key: number]: boolean }
  tags: number[]
}

interface Props {
  product: Product
  toggleEdit: () => void
}

const ProductDetailsEdit = forwardRef<HTMLFormElement, Props>(
  ({ product, toggleEdit }: Props, ref: React.ForwardedRef<HTMLFormElement>): JSX.Element => {
    const workspaceId = useAppSelector((state) => state.app.workspace!.id)

    const [nameExists] = useLazyProductExistsByCompanyQuery()
    const [updateProduct] = useProductUpdateMutation()

    const dispatch = useAppDispatch()

    const [geoCheckbox, setGeosCheckbox] = useState<boolean>(false)

    useEffect(() => {
      setGeosCheckbox(product.geos.length > 0)
    }, [product])

    const geosCheckboxHandler = (geosCheckBoxState: boolean) => {
      setGeosCheckbox(geosCheckBoxState)
    }

    const {
      control,
      formState: { errors },
      handleSubmit,
      resetField,
    } = useForm<FormData>({
      reValidateMode: 'onBlur',
      defaultValues: {
        name: product.name,
        description: product.description,
        aliases: product.aliases,
      },
    })

    const formTagData = useFormTagData(product, resetField)
    const formGeoData = useFormGeoDataMulti(product, resetField)
    const formCapabilityData = useFormCapabilityData(product, resetField)

    const onSubmit: SubmitHandler<FormData> = async (formData) => {
      const updateData: UpdateProduct = {
        name: formData.name.trim(),
        description: formData.description,
        aliases: formData.aliases,
        geos: formGeoData.convert(formData.global, formData.geos),
        capabilities: formCapabilityData.convert(formData.capabilities),
        tags: formTagData.convert(formData.tags),
      }
      try {
        await updateProduct({ workspaceId, productId: product?.id!, updateProduct: updateData })
        toggleEdit()
        dispatch(
          setToastMessage({
            severity: 'success',
            summary: 'Changes saved successfully.',
          }),
        )
      } catch {
        dispatch(
          setToastMessage({
            severity: 'error',
            summary: 'Changes could not be saved.',
          }),
        )
      }
    }

    return formTagData.isLoading || formGeoData.isLoading ? (
      <ProductDetailsSkeleton />
    ) : (
      <form ref={ref} onSubmit={handleSubmit(onSubmit)} className="p-fluid">
        <div className="card mt-5">
          <div className="formgrid grid">
            <div className="field col-12 md:col-6">
              <label htmlFor="name" className={classNames({ 'p-error': errors.name })}>
                Name:
              </label>
              <Controller
                name="name"
                control={control}
                rules={{
                  required: { value: true, message: 'Name is a required field' },
                  maxLength: { value: 255, message: 'Name must be less than 256 characters' },
                  validate: async (value) => {
                    if (value === product.name) return true
                    const companyId = product.company.id
                    return (await nameExists({
                      workspaceId,
                      companyId,
                      name: value,
                    }).unwrap())
                      ? 'A product with this name already exists within this company'
                      : true
                  },
                }}
                render={({ field, fieldState }) => (
                  <InputText
                    id={field.name}
                    value={field.value}
                    onChange={field.onChange}
                    onBlur={(e) => {
                      field.onChange(e.target.value.trim())
                    }}
                    placeholder="Enter name"
                    className={classNames({ 'p-invalid': fieldState.error })}
                  />
                )}
              />
              {errors.name && <small className="p-error">{errors.name?.message}</small>}
            </div>
          </div>

          <div className="formgrid grid">
            <div className="field col-12 md:col-6">
              <label htmlFor="name">Description:</label>
              <Controller
                name="description"
                control={control}
                render={({ field }) => (
                  <InputTextarea
                    id={field.name}
                    value={field.value || ''}
                    onChange={field.onChange}
                    onBlur={(e) => {
                      field.onChange(e.target.value.trim() === '' ? null : e.target.value.trim())
                    }}
                    placeholder="Enter description (optional)"
                    rows={7}
                  />
                )}
              />
            </div>
          </div>
        </div>

        <Divider />

        <div className="card mb-5">
          <div className="flex card-container overflow-hidden gap-4">
            <div className="flex-auto col-6">
              <div className="field grid">
                <label htmlFor="aliases" className={'col-12 mb-2 md:col-2 md:mb-0 font-medium'}>
                  Aliases:
                </label>
                <div className="col-12 md:col-10">
                  <Controller
                    name="aliases"
                    control={control}
                    render={({ field }) => (
                      <InputText
                        id={field.name}
                        value={field.value || ''}
                        onChange={field.onChange}
                        onBlur={(e) => {
                          field.onChange(
                            e.target.value.trim() === '' ? null : e.target.value.trim(),
                          )
                        }}
                        placeholder={'Enter aliases (optional)'}
                      />
                    )}
                  />
                </div>
              </div>

              <div className="field grid">
                <label
                  htmlFor="markets"
                  className={classNames(
                    { 'p-error': errors.geos },
                    'col-12 mb-2 xl:col-2 xl:mb-0 font-medium',
                  )}
                >
                  Available Markets:
                </label>

                <div className="flex col-12 xl:col-10 align-self-center pl-0">
                  <div className="col-12 xl:col-10">
                    <Controller
                      name="global"
                      control={control}
                      defaultValue={formGeoData.defaultGlobal}
                      render={({ field, fieldState }) => (
                        <Checkbox
                          inputId={field.name}
                          onChange={(e) => field.onChange(e.checked)}
                          checked={field.value}
                          className={classNames({ 'p-invalid': fieldState.error })}
                        />
                      )}
                    />
                    {errors.global && <small className="p-error">{errors.global?.message}</small>}

                    <label
                      className={classNames(
                        { 'p-error': errors.global },
                        'ml-2 mb-2 xl:mb-0 font-medium',
                      )}
                    >
                      Global
                    </label>
                  </div>
                </div>
              </div>

              <div className="field grid">
                <div className="flex col-12 xl:col-10 xl:col-offset-2 align-self-center pl-0">
                  <div className="flex col-3 xl:col-3 align-self-center">
                    <Checkbox
                      checked={geoCheckbox}
                      onChange={() => geosCheckboxHandler(!geoCheckbox)}
                    />
                    <div className="ml-2 mb-2 xl:mb-0 font-medium">
                      <label className={classNames({ 'p-error': errors.geos })}>Markets</label>
                    </div>
                  </div>

                  <div className="col-9 xl:col-9 pr-0">
                    <Controller
                      name="geos"
                      control={control}
                      defaultValue={formGeoData.defaultGeos}
                      render={({ field, fieldState }) => (
                        <MultiSelect
                          id={field.name}
                          value={field.value}
                          options={formGeoData.options}
                          onChange={(e) => field.onChange(e.value)}
                          optionLabel="label"
                          optionGroupLabel="label"
                          optionGroupChildren="items"
                          placeholder="Select markets"
                          showSelectAll={true}
                          display="chip"
                          filter={true}
                          resetFilterOnHide={true}
                          scrollHeight={'400px'}
                          className={classNames({ 'p-invalid': fieldState.error })}
                          disabled={!geoCheckbox}
                        />
                      )}
                    />
                    {errors.geos && <small className="p-error">{errors.geos?.message}</small>}
                  </div>
                </div>
              </div>

              <div className="field grid">
                <label
                  htmlFor="capabilities"
                  className={classNames(
                    { 'p-error': errors.capabilities },
                    'col-12 mb-2 md:col-2 md:mb-0 font-medium',
                  )}
                >
                  Capabilities:
                </label>
                <div className="col-12 md:col-10">
                  <Controller
                    name="capabilities"
                    control={control}
                    defaultValue={formCapabilityData.defaultCapabilities}
                    render={({ field, fieldState }) => (
                      <TreeSelect
                        id={field.name}
                        value={field.value}
                        options={formCapabilityData.options}
                        onChange={(e) => field.onChange(e.value as { [key: number]: boolean })}
                        selectionMode="multiple"
                        metaKeySelection={false}
                        placeholder="Select capabilities (optional)"
                        display="chip"
                        filter={true}
                        resetFilterOnHide={true}
                        scrollHeight={'400px'}
                        className={classNames({ 'p-invalid': fieldState.error })}
                      />
                    )}
                  />
                  {errors.capabilities && (
                    <small className="p-error">{errors.capabilities?.message}</small>
                  )}
                </div>
              </div>
            </div>
            <div className="flex-auto col-6">
              <div className="field grid">
                <label
                  htmlFor="tags"
                  className={classNames(
                    { 'p-error': errors.tags },
                    'col-12 mb-2 md:col-2 md:mb-0 font-medium',
                  )}
                >
                  Tags:
                </label>
                <div className="col-12 md:col-10">
                  <Controller
                    name="tags"
                    control={control}
                    defaultValue={formTagData.defaultTags}
                    render={({ field, fieldState }) => (
                      <MultiSelect
                        id={field.name}
                        value={field.value}
                        options={formTagData.options}
                        onChange={(e) => field.onChange(e.value)}
                        optionLabel="label"
                        optionGroupLabel="label"
                        optionGroupChildren="items"
                        placeholder="Select tags (optional)"
                        showSelectAll={true}
                        display="chip"
                        filter={true}
                        resetFilterOnHide={true}
                        scrollHeight={'400px'}
                        className={classNames({ 'p-invalid': fieldState.error })}
                      />
                    )}
                  />
                  {errors.tags && <small className="p-error">{errors.tags?.message}</small>}
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    )
  },
)

export default ProductDetailsEdit

import React from 'react'
import { TabPanel, TabView } from 'primereact/tabview'
import QuestionPackTabQuestions from './QuestionPackTabQuestions'

interface Props {
  questionPack: QuestionPack
}

const QuestionPackTabs = ({ questionPack }: Props): JSX.Element => {
  return (
    <TabView>
      <TabPanel header="Questions">
        <QuestionPackTabQuestions questionPackId={questionPack.id} />
      </TabPanel>
    </TabView>
  )
}

export default QuestionPackTabs

import { useAppSelector } from '../../common/hooks'
import { useQuestionPackQuery } from '../../redux/api'
import { useParams } from 'react-router-dom'
import { useEditMode } from '../../common/dispatcherButtonHooks'
import QuestionPackToolbar from '../../components/QuestionPack/QuestionPackToolbar'
import QuestionPackDetailsSkeleton from '../../components/QuestionPack/QuestionPackDetailsSkeleton'
import QuestionPackTabs from '../../components/QuestionPack/QuestionPackTabs'
import QuestionPackDetailsEdit from '../../components/QuestionPack/QuestionPackDetailsEdit'
import QuestionPackDetails from '../../components/QuestionPack/QuestionPackDetails'
import NotFoundPage from '../http/404NotFoundPage'

const QuestionPackDetailsPage = (): JSX.Element => {
  const urlParams = useParams()
  const workspaceId = useAppSelector((state) => state.app.workspace!.id)
  const editMode = useEditMode()

  const { data: questionPack, isLoading: isQuestionPackLoading } = useQuestionPackQuery({
    workspaceId: workspaceId,
    itemId: urlParams.id!,
  })

  return (
    <div className="px-4 py-5 md:px-6 lg:px-8">
      {isQuestionPackLoading ? (
        <QuestionPackDetailsSkeleton />
      ) : questionPack === undefined ? (
        <NotFoundPage />
      ) : (
        <>
          <QuestionPackToolbar questionPack={questionPack} editMode={editMode} />
          {editMode.enabled ? (
            <QuestionPackDetailsEdit
              questionPack={questionPack}
              toggleEdit={editMode.toggle}
              ref={editMode.formRef}
            />
          ) : (
            <>
              <QuestionPackDetails questionPack={questionPack} />
              <QuestionPackTabs questionPack={questionPack} />
            </>
          )}
        </>
      )}
    </div>
  )
}

export default QuestionPackDetailsPage

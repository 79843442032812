import styles from '../../styles/list.module.scss'
import DataTableProvider, { DataTablePropsPresets } from '../DataTable/DataTableProvider'
import { Toolbar } from 'primereact/toolbar'
import { Card } from 'primereact/card'
import { useAppSelector, useDataTableFilter } from '../../common/hooks'
import { actionsColumn, simpleColumn } from '../DataTable/columnProvider'
import { useUrlCopyToClipboard, useToogleFavorite } from '../../common/dispatcherButtonHooks'
import {
  useAddParticipantModal,
  useArchiveModal,
  useCanSendModal,
  useDeleteModal,
  useExtendModal,
  useWithdrawModal,
} from '../../common/modalHooks'
import { Link } from 'react-router-dom'
import { ProgressBar } from 'primereact/progressbar'
import { Tag } from 'primereact/tag'
import { RfiStatus } from '../../enums'
import ProgressTag from '../ProgressTag'
import ParticipantToolbar from './ParticipantToolbar'

interface Props {
  toolbarLeft?: JSX.Element
  hiddenColumns?: number[]
  isLoading?: boolean
  data?: Participant[]
  showFilter?: boolean
  rfi: Rfi
}

const ParticipantListRfiDetailsPage = ({
  toolbarLeft = <h1>Participants</h1>,
  hiddenColumns = [],
  isLoading = true,
  data,
  showFilter = false,
  rfi,
}: Props): JSX.Element => {
  const workspaceId = useAppSelector((state) => state.app.workspace!.id)

  const addParticipantModal = useAddParticipantModal(rfi, 'Add Participant')

  const toggleFavorite = useToogleFavorite('PARTICIPANT')
  const copyToClipboard = useUrlCopyToClipboard('PARTICIPANT')
  const canSendButton = useCanSendModal()
  const extendButton = useExtendModal('PARTICIPANT')
  const withdrawButton = useWithdrawModal('PARTICIPANT')
  const archiveButton = useArchiveModal('PARTICIPANT')
  const deleteButton = useDeleteModal('PARTICIPANT')

  const columnsCompanyType = [
    simpleColumn({
      columnProps: {
        header: 'Company',
        field: 'company.name',
        sortable: true,
        className: styles['first-column'],
        style: { width: '30%' },
        body: (participant: Participant) => {
          return <Link to={`/participant/${participant.id}`}>{participant.company.name}</Link>
        },
      },
    }),
    simpleColumn({
      columnProps: {
        header: 'Status',
        field: 'status', // wait for implementation
        sortable: true,
        className: styles['column'],
        style: { width: '20%' },
        body: (participant: Participant) => {
          return participant.status === RfiStatus.IN_PROGRESS ? (
            <ProgressTag status={participant.status} progress={participant.progress} />
          ) : (
            <Tag
              className="bg-primary-reverse border-1"
              value={String(participant.status).replace(/_/g, ' ')}
            ></Tag>
          )
        },
      },
    }),
    simpleColumn({
      columnProps: {
        header: 'End Date',
        field: 'endDate',
        sortable: true,
        className: styles['column'],
        body: (participant: Participant) => {
          return <span>{participant.endDate}</span>
        },
      },
    }),
    actionsColumn({
      columnProps: {
        align: 'right',
        alignHeader: 'center',
        className: styles['action-column'],
        style: { width: '40%' },
      },
      dispatcherButtonsProps: [
        ...toggleFavorite.dispatcherButtonProps,
        ...copyToClipboard.dispatcherButtonProps,
        ...canSendButton.dispatcherButtonProps,
        ...extendButton.dispatcherButtonProps,
        ...withdrawButton.dispatcherButtonProps,
        ...archiveButton.dispatcherButtonProps,
        ...deleteButton.dispatcherButtonProps,
      ],
    }),
  ].filter((column, index) => !hiddenColumns.includes(index))

  const columnsProductType = [
    simpleColumn({
      columnProps: {
        header: 'Product',
        field: 'product.name',
        sortable: true,
        className: styles['first-column'],
        style: { width: '30%' },
        body: (participant: Participant) => {
          return <Link to={`/participant/${participant.id}`}>{participant.product!.name}</Link>
        },
      },
    }),
    simpleColumn({
      columnProps: {
        header: 'Company',
        field: 'company.name',
        sortable: true,
        className: styles['column'],
        style: { width: '30%' },
        body: (participant: Participant) => {
          return <Link to={`/company/${participant.company.id}`}>{participant.company.name}</Link>
        },
      },
    }),
    simpleColumn({
      columnProps: {
        header: 'Status',
        field: 'status', // wait for implementation
        sortable: true,
        className: styles['column'],
        style: { width: '20%' },
        body: (participant: Participant) => {
          return participant.status === RfiStatus.IN_PROGRESS ? (
            <ProgressTag status={participant.status} progress={participant.progress} />
          ) : (
            <Tag
              className="bg-primary-reverse border-1"
              value={String(participant.status).replace(/_/g, ' ')}
            ></Tag>
          )
        },
      },
    }),
    simpleColumn({
      columnProps: {
        header: 'End Date',
        field: 'endDate',
        sortable: true,
        className: styles['column'],
        body: (participant: Participant) => {
          return <span>{participant.endDate}</span>
        },
      },
    }),
    actionsColumn({
      columnProps: {
        align: 'right',
        alignHeader: 'center',
        className: styles['action-column'],
        style: { width: '40%' },
      },
      dispatcherButtonsProps: [
        ...toggleFavorite.dispatcherButtonProps,
        ...copyToClipboard.dispatcherButtonProps,
        ...canSendButton.dispatcherButtonProps,
        ...extendButton.dispatcherButtonProps,
        ...withdrawButton.dispatcherButtonProps,
        ...archiveButton.dispatcherButtonProps,
        ...deleteButton.dispatcherButtonProps,
      ],
    }),
  ].filter((column, index) => !hiddenColumns.includes(index))

  let dataTableProps = DataTablePropsPresets.listPage(data)

  if (showFilter) {
    data = data?.map((participant) => {
      return {
        ...participant,
      }
    })
    dataTableProps =
      rfi.type === 'COMPANY'
        ? {
            ...DataTablePropsPresets.listPage(data),
            ...useDataTableFilter(['company.name', 'status', 'endDate']),
          }
        : {
            ...DataTablePropsPresets.listPage(data),
            ...useDataTableFilter(['product.name', 'company.name', 'status', 'endDate']),
          }
  }

  return (
    <>
      {extendButton.modal}
      {canSendButton.modal}
      {addParticipantModal.modal}
      <div className={styles.list}>
        <Toolbar left={toolbarLeft} right={addParticipantModal.dispatcherButton} />
        <Card>
          <DataTableProvider
            dataTableProps={dataTableProps}
            columns={rfi.type === 'COMPANY' ? columnsCompanyType : columnsProductType}
            isLoading={isLoading}
          />
        </Card>
      </div>
    </>
  )
}

export default ParticipantListRfiDetailsPage

import { forwardRef } from 'react'
import { ActionsColumn, CreatorColumn, SimpleColumn, TagListColumn } from './columnProvider'
import { TreeTable, TreeTableProps } from 'primereact/treetable'

interface Presets {
  listPage: (value?: any[]) => TreeTableProps
}

export const TreeTablePropsPresets: Presets = {
  listPage: (value) => {
    return {
      value: value,
      paginator: false,
      className: 've-treetablelist',
    }
  },
}

interface Props {
  isLoading?: boolean
  treeTableProps?: TreeTableProps
  columns?: (SimpleColumn | CreatorColumn | TagListColumn | ActionsColumn)[]
}

const TreeTableProvider = forwardRef<TreeTable, Props>(
  (
    { isLoading = false, treeTableProps: treeTableProps = {}, columns = [] }: Props,
    ref: React.ForwardedRef<TreeTable>,
  ) => {
    if (isLoading) {
      treeTableProps.value = [{ key: 'loading' }]
    }
    return (
      <TreeTable ref={ref} {...treeTableProps}>
        {columns.map((column) => column.build(isLoading))}
      </TreeTable>
    )
  },
)

export default TreeTableProvider

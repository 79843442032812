import React from 'react'
import { TabPanel, TabView } from 'primereact/tabview'
import RfiTabParticipants from './RfiTabParticipants'
import RfiTabQuestionPackQuestions from './RfiTabQuestionPackQuestions'

interface Props {
  rfi: Rfi
}

const RfiTabs = ({ rfi }: Props): JSX.Element => {
  return (
    <TabView>
      <TabPanel header="Participants">
        <RfiTabParticipants rfi={rfi} />
      </TabPanel>
      <TabPanel header="Question Packs & Questions">
        <RfiTabQuestionPackQuestions rfi={rfi} />
      </TabPanel>
    </TabView>
  )
}

export default RfiTabs

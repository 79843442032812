import React from 'react'
import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'

interface Props {
  searchValue: string
  onSearchInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  clearSearchInput: () => void
  isFetching?: boolean
  disabled?: boolean
}

const SearchBar = ({
  searchValue,
  onSearchInputChange,
  clearSearchInput,
  isFetching = false,
  disabled = false,
}: Props): JSX.Element => {
  return (
    <div className="col-12 p-0">
      <div className="p-inputgroup">
        <span className="p-inputgroup-addon">
          {isFetching ? <i className="pi pi-spin pi-spinner" /> : <i className="pi pi-search" />}
        </span>
        <InputText value={searchValue} onChange={onSearchInputChange} disabled={disabled} />
        <Button
          icon="pi pi-times-circle"
          className="p-button-primary"
          onClick={clearSearchInput}
          disabled={disabled}
        />
      </div>
    </div>
  )
}

export default SearchBar

import { useNavigate, useSearchParams } from 'react-router-dom'
import {
  useAppSelector,
  useDataTableLazy,
  useDebounce,
  useInput,
  usePermission,
} from '../../common/hooks'
import { prepareSearchValueSpaceJoin } from '../../common/utils'
import QuestionPackList from '../../components/QuestionPack/QuestionPackList'
import SearchBar from '../../components/SearchBar'
import {
  PaginationParams,
  useQuestionPackListBySearchByStrQuery,
  useQuestionPackListWithPaginationQuery,
} from '../../redux/api'
import { AppError, setError } from '../../redux/app'
import { useEffect, useState } from 'react'
import { DataTablePropsPresets } from '../../components/DataTable/DataTableProvider'

const QuestionPackListPage = (): JSX.Element => {
  const navigate = useNavigate()
  const currentWorkSpace = useAppSelector((state) => state.app.workspace!.id)

  // debounced search
  const hasSearchPermission = usePermission('question_pack', 'search')
  const [searchStr] = useSearchParams()
  const [searchValue, onSearchInputChange, clearSearchInput] = useInput(
    searchStr.get('searchStr') || '',
  )
  const debouncedSearchValue = useDebounce(searchValue, 500)
  useEffect(() => {
    searchValue ? navigate(`/questionpack?searchStr=${searchValue}`) : navigate(`/questionpack`)
  }, [debouncedSearchValue])

  // lazy pagination
  const [paginationParams, setPaginationParams] = useState<PaginationParams>({
    page: 1,
    size: 10,
    sort: 'name',
  })

  const { data, error, isLoading, isFetching } = !!debouncedSearchValue
    ? useQuestionPackListBySearchByStrQuery({
        workspaceId: currentWorkSpace,
        words: prepareSearchValueSpaceJoin(debouncedSearchValue),
      })
    : useQuestionPackListWithPaginationQuery({
        workspaceId: currentWorkSpace,
        paginationParams: {
          page: paginationParams.page,
          size: paginationParams.size,
          sort: paginationParams.sort,
        },
      })

  const dataTableProps = DataTablePropsPresets.listPage(data?.content)

  // lazy pagination
  const dataTablePropsLazy = {
    ...dataTableProps,
    ...useDataTableLazy(paginationParams, setPaginationParams, data?.meta),
  }

  if (error) {
    setError(AppError.QUESTIONPACK_API_REQUEST)
  }

  return (
    <div className="px-4 py-5 md:px-6 lg:px-8">
      {hasSearchPermission ? (
        <SearchBar
          searchValue={searchValue}
          onSearchInputChange={onSearchInputChange}
          clearSearchInput={clearSearchInput}
          isFetching={isFetching}
        />
      ) : (
        ''
      )}
      <QuestionPackList
        toolbarLeft={
          <h1>
            {!!debouncedSearchValue
              ? `Question Packs with "${debouncedSearchValue}"`
              : 'Question Packs'}
          </h1>
        }
        data={data?.content}
        isLoading={isLoading}
        dataTableProps={!!debouncedSearchValue ? dataTableProps : dataTablePropsLazy}
      />
    </div>
  )
}

export default QuestionPackListPage

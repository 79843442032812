import TagList, { NORMALIZE_PRESETS } from '../TagList'
import { Divider } from 'primereact/divider'
import { Tag } from 'primereact/tag'
import { RfiStatus } from '../../enums'
import { ProgressBar } from 'primereact/progressbar'
import { DispatcherButtonPresets, DispatcherButtonProps } from '../DispatcherButton'
import {
  useArchiveModal,
  useCanSendModal,
  useExtendModal,
  useWithdrawModal,
} from '../../common/modalHooks'
import { useGroupedTagList } from '../../common/hooks'
import styles from '../../styles/details.module.scss'
import { Link } from 'react-router-dom'
import ProgressTag from '../ProgressTag'

interface Props {
  participant: Participant
}

const ParticipantDetails = ({ participant }: Props): JSX.Element => {
  const groupedTagList = useGroupedTagList(participant?.rfi.tags)

  const withdrawButtonsProps: DispatcherButtonProps = {
    buttonProps: { icon: 'pi pi-clock', label: 'Withdraw' },
    className: DispatcherButtonPresets.TERTIARY_ACTIONS,
  }

  const extendButtonsProps: DispatcherButtonProps = {
    buttonProps: { icon: 'pi pi-clock', label: 'Extend' },
    className: DispatcherButtonPresets.TERTIARY_ACTIONS,
  }

  const archiveButtonsProps: DispatcherButtonProps = {
    buttonProps: { icon: 'pi pi-inbox', label: 'Archive' },
    className: DispatcherButtonPresets.TERTIARY_ACTIONS,
  }

  const canSendButtonsProps: DispatcherButtonProps = {
    buttonProps: { icon: 'pi pi-clock', label: 'Send' },
    className: DispatcherButtonPresets.TERTIARY_ACTIONS,
  }

  const extendButton = useExtendModal('PARTICIPANT', participant)
  const withdrawButton = useWithdrawModal('PARTICIPANT', participant)
  const archiveButton = useArchiveModal('PARTICIPANT', participant)
  const canSendButton = useCanSendModal(participant)

  const progress =
    participant!.status === RfiStatus.IN_PROGRESS ? (
      <ProgressTag status={participant!.status} progress={participant!.progress} />
    ) : (
      <Tag
        className={'bg-primary-reverse border-1'}
        value={String(participant!.status).replace(/_/g, ' ')}
      ></Tag>
    )

  const headerSubline =
    participant.rfi.type === 'COMPANY' ? (
      <>
        answered by{' '}
        <Link to={`/company/${participant?.company.id}`} className={styles.details}>
          <span>{participant?.company.name} </span>
          <i className="pi pi-external-link" style={{ fontSize: '.6em' }}></i>
        </Link>{' '}
      </>
    ) : (
      <>
        answered by{' '}
        <Link to={`/company/${participant?.company.id}`} className={styles.details}>
          <span>{participant?.company.name} </span>
          <i className="pi pi-external-link" style={{ fontSize: '.6em' }}></i>
        </Link>{' '}
        for{' '}
        <Link to={`/product/${participant?.product?.id}`} className={styles.details}>
          <span>{participant?.product?.name} </span>
          <i className="pi pi-external-link" style={{ fontSize: '.6em' }}></i>
        </Link>{' '}
      </>
    )

  return (
    <>
      {canSendButton.modal}
      {extendButton.modal}
      <div className="card mt-5">
        <h3 className="mb-0">Participant</h3>
        <h1 className="mt-1">
          <Link to={`/rfi/${participant?.rfi.id}`} className={styles.details}>
            <span>{participant?.rfi.name} </span>
            <i className="pi pi-external-link" style={{ fontSize: '.5em' }}></i>
          </Link>{' '}
        </h1>
        <span className="text-xl">{headerSubline}</span>
        <p>{participant?.rfi.description}</p>
      </div>
      <Divider />
      <div className="card mb-5">
        <div className="flex card-container overflow-hidden gap-4">
          <div className="flex-auto col-6">
            <div className="flex flex-column gap-4">
              <div className="flex gap-2">
                <span className="font-medium">Status: </span>
                {progress}
                {canSendButton.dispatcherButton}
                {withdrawButton.dispatcherButton}
                {archiveButton.dispatcherButton}
              </div>
              <div>
                <span className="font-medium">Markets: </span>
                <Tag className="bg-bluegray-500" value={participant?.rfi.geo.name} />
              </div>
              <div className="flex gap-1">
                <div className="font-medium">Capabilities: </div>
                <div className="flex flex-wrap gap-1">
                  <TagList
                    items={participant?.rfi.capabilities || []}
                    normalize={NORMALIZE_PRESETS.POS}
                    emptyVal={'(none)'}
                    maxShown={10}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-wrap col-6 ">
            <div className="flex flex-wrap flex-column gap-4">
              <div>
                <span className="font-medium">Start Date: </span>
                <span>{participant?.startDate}</span>
              </div>
              <div className="flex gap-2 align-items-center">
                <span className="font-medium">End Date: </span>
                <span>{participant?.endDate}</span>
                {extendButton.dispatcherButton}
              </div>
              {groupedTagList}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ParticipantDetails

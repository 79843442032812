import { Card } from 'primereact/card'
import { Link } from 'react-router-dom'
import { Toolbar } from 'primereact/toolbar'
import styles from '../../styles/list.module.scss'
import DataTableProvider, { DataTablePropsPresets } from '../DataTable/DataTableProvider'
import { actionsColumn, simpleColumn, tagListColumn } from '../DataTable/columnProvider'
import {
  useUrlCopyToClipboard,
  useToogleFavorite,
  useNavigateToCreate,
} from '../../common/dispatcherButtonHooks'
import { useDeleteModal } from '../../common/modalHooks'
import { DataTableProps } from 'primereact/datatable'
import { useDataTableFilter } from '../../common/hooks'
import { NORMALIZE_PRESETS } from '../TagList'

interface Props {
  data?: Company[]
  toolbarLeft?: JSX.Element
  toolbarRight?: JSX.Element
  hiddenColumns?: number[]
  isLoading?: boolean
  useSorting?: boolean
  showFilter?: boolean
  dataTableProps?: DataTableProps
}

const CompanyList = ({
  data,
  toolbarLeft = <h1>Companies</h1>,
  toolbarRight,
  hiddenColumns = [],
  isLoading = true,
  useSorting = true,
  showFilter = false,
  dataTableProps = DataTablePropsPresets.listPage(data),
}: Props): JSX.Element => {
  const toggleFavorite = useToogleFavorite('COMPANY')
  const copyToClipboard = useUrlCopyToClipboard('COMPANY')
  const deleteCompanyModal = useDeleteModal('COMPANY')
  const createCompanyButton = useNavigateToCreate('COMPANY')
  const columns = [
    simpleColumn({
      columnProps: {
        header: 'Name',
        field: 'name',
        sortable: useSorting,
        className: styles['first-column'],
        body: (company: Company) => {
          return <Link to={`/company/${company.id}`}>{company.name}</Link>
        },
      },
    }),
    tagListColumn({
      columnProps: {
        header: 'Capabilities',
        style: { width: '100%' },
      },
      rowDataSelector: (company: Company) => {
        const capabilities: Capability[] = []
        company.products.forEach((product) => {
          capabilities.push(...product.capabilities)
        })
        return capabilities
      },
      normalize: NORMALIZE_PRESETS.POS,
    }),
    actionsColumn({
      columnProps: {
        align: 'right',
        alignHeader: 'center',
        className: styles['action-column'],
      },
      dispatcherButtonsProps: [
        ...toggleFavorite.dispatcherButtonProps,
        ...copyToClipboard.dispatcherButtonProps,
        ...deleteCompanyModal.dispatcherButtonProps,
      ],
    }),
  ].filter((column, index) => !hiddenColumns.includes(index))

  if (showFilter) {
    data = data?.map((company) => {
      return {
        ...company,
        ...{
          capabilitiesJoin: company.products.map((capability) => capability.name).join(' '),
        },
      }
    })
    dataTableProps = {
      ...dataTableProps,
      ...{ value: data },
      ...useDataTableFilter(['name', 'capabilitiesJoin']),
    }
  }

  return (
    <>
      <div className={styles.list}>
        <Toolbar left={toolbarLeft} right={toolbarRight || createCompanyButton.dispatcherButton} />
        <Card>
          <DataTableProvider
            dataTableProps={dataTableProps}
            columns={columns}
            isLoading={isLoading}
          />
        </Card>
      </div>
    </>
  )
}

export default CompanyList

import React from 'react'
import { useAppSelector } from '../../common/hooks'
import { useQuestionListByQuestionPackQuery } from '../../redux/api'
import QuestionList from '../Question/QuestionList'

interface Props {
  questionPackId: number
}

const QuestionPackTabQuestions = ({ questionPackId }: Props): JSX.Element => {
  const workspaceId = useAppSelector((state) => state.app.workspace!.id)
  const { data, isLoading } = useQuestionListByQuestionPackQuery({
    workspaceId,
    questionPackId,
  })

  return (
    <QuestionList
      toolbarLeft={<h1 className="text-2xl">Questions</h1>}
      data={data}
      isLoading={isLoading}
      showFilter={true}
    />
  )
}

export default QuestionPackTabQuestions

import { CreateRfi } from '../../redux/api'
import { RfiType } from '../../enums'
import RfiCreateStepFourCompany from './RfiCreateStepFourCompany'
import RfiCreateStepFourProduct from './RfiCreateStepFourProduct'

interface Props {
  rfiData: CreateRfi
  setRfiData: React.Dispatch<React.SetStateAction<CreateRfi>>
  setStep: React.Dispatch<React.SetStateAction<number>>
}

const RfiCreateStepFour = ({ rfiData, setRfiData, setStep }: Props): JSX.Element => {
  return rfiData.type === RfiType.COMPANY ? (
    <RfiCreateStepFourCompany rfiData={rfiData} setRfiData={setRfiData} setStep={setStep} />
  ) : (
    <RfiCreateStepFourProduct rfiData={rfiData} setRfiData={setRfiData} setStep={setStep} />
  )
}

export default RfiCreateStepFour

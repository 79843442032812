import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import rehypeExternalLinks from 'rehype-external-links'
import { getDimensionLabel } from '../../common/utils'

interface Props {
  question: Question
  questionConstraint: ConstrainedQuestion
}

const styles = {
  noPaddingList: {
    paddingLeft: 0,
    listStylePosition: 'outside' as const,
    listStyleType: 'none' as const,
  },
  marginBottomLi: {
    paddingLeft: '20px',
    textIndent: '-20px',
    marginBottom: '5px',
    whiteSpace: 'pre-line' as const,
  },
}

const QuestionOutputMultiLineText = ({ question, questionConstraint }: Props): JSX.Element => {
  const configFormat: 'TEXT' | 'MARKDOWN' = question.config?.format || 'TEXT'

  // Render each dimension's multiline text
  const renderDimensionText = (answerData: AnswerData) => {
    if (!answerData.data || answerData.data.value === null) return null

    const textValue = answerData.data.value

    const dimensionLabel = getDimensionLabel(
      questionConstraint.dimensions,
      questionConstraint.labels,
      answerData.dimensionIndex,
      answerData.dimensionValue,
    )

    return configFormat === 'MARKDOWN' ? (
      <div key={answerData.dimensionIndex} className="ve-markdown-container">
        <span>{dimensionLabel}</span>
        <ReactMarkdown
          children={textValue}
          remarkPlugins={[remarkGfm]}
          rehypePlugins={[[rehypeExternalLinks, { target: '_blank' }]]}
        />
      </div>
    ) : (
      <li
        style={styles.marginBottomLi}
        key={`dim-${answerData.dimensionIndex}${answerData.dimensionValue}`}
      >
        <strong>{dimensionLabel}</strong>
        {textValue}
      </li>
    )
  }

  return (
    <div>
      <ul style={styles.noPaddingList}>{question.answer?.data.map(renderDimensionText)}</ul>
    </div>
  )
}

export default QuestionOutputMultiLineText

import { forwardRef } from 'react'
import { useForm, Controller, SubmitHandler } from 'react-hook-form'
import { InputText } from 'primereact/inputtext'
import { classNames } from 'primereact/utils'
import { useLazyRfiExistsByGeoQuery, useRfiUpdateMutation } from '../../redux/api'
import { useAppDispatch, useAppSelector } from '../../common/hooks'
import { InputTextarea } from 'primereact/inputtextarea'
import { setToastMessage } from '../../redux/app'

interface FormData {
  name: string
  description: string | null
}

interface Props {
  rfi: Rfi
  toggleEdit: () => void
}

const RfiDetailsEdit = forwardRef<HTMLFormElement, Props>(
  ({ rfi, toggleEdit }: Props, ref: React.ForwardedRef<HTMLFormElement>): JSX.Element => {
    const workspaceId = useAppSelector((state) => state.app.workspace!.id)

    const [nameExists] = useLazyRfiExistsByGeoQuery()
    const [updateRfi] = useRfiUpdateMutation()

    const dispatch = useAppDispatch()

    const {
      control,
      formState: { errors },
      handleSubmit,
    } = useForm<FormData>({
      reValidateMode: 'onBlur',
      defaultValues: {
        name: rfi.name,
        description: rfi.description,
      },
    })

    const onSubmit: SubmitHandler<FormData> = async (formData) => {
      try {
        await updateRfi({
          workspaceId,
          rfiId: rfi.id,
          updateRfi: {
            name: formData.name,
            description: formData.description,
          },
        })
        toggleEdit()
        dispatch(
          setToastMessage({
            severity: 'success',
            summary: 'Changes saved successfully.',
          }),
        )
      } catch {
        dispatch(
          setToastMessage({
            severity: 'error',
            summary: 'Changes could not be saved.',
          }),
        )
      }
    }

    return (
      <>
        <form ref={ref} onSubmit={handleSubmit(onSubmit)} className="p-fluid">
          <div className="card mt-5">
            <div className="formgrid grid">
              <div className="field col-12 md:col-6">
                <label htmlFor="name" className={classNames({ 'p-error': errors.name })}>
                  Name:
                </label>
                <Controller
                  name="name"
                  control={control}
                  rules={{
                    required: { value: true, message: 'Name is a required field' },
                    maxLength: { value: 255, message: 'Name must be less than 256 characters' },
                    validate: async (value) => {
                      if (value === rfi.name) return true
                      const geoId = rfi.geo.id
                      return (await nameExists({
                        workspaceId,
                        geoId,
                        name: value,
                      }).unwrap())
                        ? 'An RFI with this name already exists within this market'
                        : true
                    },
                  }}
                  render={({ field, fieldState }) => (
                    <InputText
                      id={field.name}
                      value={field.value}
                      onChange={field.onChange}
                      onBlur={(e) => {
                        field.onChange(e.target.value.trim())
                      }}
                      placeholder="Enter name"
                      className={classNames({ 'p-invalid': fieldState.error })}
                    />
                  )}
                />
                {errors.name && <small className="p-error">{errors.name?.message}</small>}
              </div>
            </div>
            <div className="formgrid grid">
              <div className="field col-12 md:col-6">
                <label htmlFor="name" className={classNames({ 'p-error': errors.description })}>
                  Description:
                </label>
                <Controller
                  name="description"
                  control={control}
                  render={({ field }) => (
                    <InputTextarea
                      id={field.name}
                      value={field.value || ''}
                      onChange={field.onChange}
                      onBlur={(e) => {
                        field.onChange(e.target.value.trim() === '' ? null : e.target.value.trim())
                      }}
                      placeholder="Enter description (optional)"
                      rows={7}
                    />
                  )}
                />
              </div>
            </div>
          </div>
        </form>
      </>
    )
  },
)

export default RfiDetailsEdit

import { OktaAuth } from '@okta/okta-auth-js'
import { put, take } from 'redux-saga/effects'
import { logout } from '../redux/actions'
import {
  AppError,
  setAuthenticated,
  setError,
  setPermissions,
  setToken,
  setUsername,
  setWorkspace,
} from '../redux/app'
import { api, WorkspaceJoinPayload } from '../redux/api'
import { findWorkspace, getNameFromIdToken } from '../common/utils'
import store from '../redux/store'

const authClient = new OktaAuth({
  issuer: process.env.OKTA_ISSUER,
  clientId: process.env.OKTA_CLIENT_ID,
  redirectUri: process.env.OKTA_REDIRECT_URL,
  services: {
    autoRenew: true,
  },
})

export default function* authentication(): any {
  if (authClient.token.isLoginRedirect()) {
    const { tokens } = yield authClient.token.parseFromUrl()
    authClient.tokenManager.setTokens(tokens)
  }

  if (!(yield authClient.isAuthenticated())) {
    authClient.signInWithRedirect({ originalUri: process.env.OKTA_REDIRECT_URL })
    return
  }

  // Get and store our token
  const token = authClient.getAccessToken()
  yield put(setToken(token!))

  authClient.authStateManager.subscribe((authState) => {
    // console.log('authState change event triggered', authState)
    store.dispatch(setToken(authState.accessToken?.accessToken || ''))
  })

  authClient.start()

  const nameFromToken = getNameFromIdToken(authClient.getIdToken())
  const workspaceJoinPayload: WorkspaceJoinPayload = {
    displayName: nameFromToken,
  }
  const { data: joinResult, error: joinError } = yield yield put(
    // @ts-ignore // TODO
    api.endpoints.workspaceJoin.initiate({ payload: workspaceJoinPayload }),
  )
  if (joinError) return yield put(setError(AppError.WORKSPACE_JOIN_REQUEST))
  store.dispatch(setUsername(joinResult.displayName))

  const { data: workspaceData, error: workspaceError } = yield yield put(
    // @ts-ignore // TODO
    api.endpoints.workspaceList.initiate(),
  )
  if (workspaceError) return yield put(setError(AppError.WORKSPACE_REQUEST))

  // Find and set the vendor explorer workspace
  const workspace = findWorkspace(workspaceData)
  if (!workspace) return yield put(setError(AppError.WORKSPACE_DETERMINATION))

  yield put(setWorkspace(workspace))

  const { data: permissionsData, error: permissionsError } = yield yield put(
    api.endpoints.permissionListByAppRef.initiate({
      workspaceId: workspace.id,
      appRef: process.env.WORKSPACE_REF!,
    }) as any,
  )
  if (permissionsError) return yield put(setError(AppError.PERMISSIONS_REQUEST))

  yield put(setPermissions(permissionsData))

  // All good, we are authenticated and ready - login flow done
  yield put(setAuthenticated(true))

  // Wait for the user logging out
  yield take(logout)

  yield put(setAuthenticated(false))

  // Reset the tokens and repeat the stoney login procedure
  authClient.signOut({
    clearTokensBeforeRedirect: true,
  })
}

import {
  EditMode,
  useUrlCopyToClipboard,
  useEdit,
  useToogleFavorite,
} from '../../common/dispatcherButtonHooks'
import BackButton from '../BackButton'
import { useDeleteModal } from '../../common/modalHooks'

interface Props {
  product?: Product
  editMode: EditMode
}

const ProductToolbar = ({ product, editMode }: Props): JSX.Element => {
  const toggleFavorite = useToogleFavorite('PRODUCT', product)
  const copyToClipboard = useUrlCopyToClipboard('PRODUCT', product)
  const deleteProduct = useDeleteModal('PRODUCT', product, '/product/')
  const editProduct = useEdit('PRODUCT', editMode)

  const actions = editMode.enabled ? (
    <>
      {editProduct.cancelDispatchterButton}
      {editProduct.submitDispatcherButton}
    </>
  ) : (
    <>
      {toggleFavorite.dispatcherButton}
      {copyToClipboard.dispatcherButton}
      {editProduct.editDispatcherButton}
      {deleteProduct.dispatcherButton}
    </>
  )

  return (
    <>
      {editMode.enabled ? (
        <div className="flex justify-content-end">
          <div>
            <div>{actions}</div>
          </div>
        </div>
      ) : (
        <div className="flex justify-content-between">
          <div className="flex-none ">
            <BackButton />
          </div>
          <div>{actions}</div>
        </div>
      )}
    </>
  )
}

export default ProductToolbar

import { Dispatch, SetStateAction, useEffect, useMemo } from 'react'
import { validateWorkspacePermission } from '../common/utils'
import UnauthorizedPage from '../scenes/http/403UnauthorizedPage'

interface Props {
  element: JSX.Element
  resourceTypeRef: string
  operationRef: string
}

const RestrictedElement = ({ element, resourceTypeRef, operationRef }: Props): JSX.Element => {
  const isValid = useMemo(
    () => validateWorkspacePermission(resourceTypeRef, operationRef),
    [resourceTypeRef, operationRef],
  )

  return isValid ? element : <UnauthorizedPage />
}

export default RestrictedElement

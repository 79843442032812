import { Card } from 'primereact/card'
import { Skeleton } from 'primereact/skeleton'
import { classNames } from 'primereact/utils'
import styles from '../../styles/favorites.module.scss'
import DispatcherButton from '../DispatcherButton'

interface Props {
  customClasses?: string
  customStyles?: React.CSSProperties
}

const FavoriteCardSkeleton = ({ customClasses = '', customStyles }: Props): JSX.Element => {
  const header = (
    <>
      <DispatcherButton buttonProps={{ icon: 'pi pi-spin pi-spinner' }} />
    </>
  )

  return (
    <Card
      header={header}
      className={classNames(styles.favorites, customClasses)}
      title={<Skeleton width="5rem" />}
      subTitle={<Skeleton height="2rem" width="10rem" />}
      style={customStyles}
    >
      <Skeleton height="10rem" />
    </Card>
  )
}

export default FavoriteCardSkeleton

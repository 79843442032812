import { Tag } from 'primereact/tag'
import { Tooltip } from 'primereact/tooltip'
import { classNames } from 'primereact/utils'
import { useState } from 'react'
import { filterDistinct, sort } from '../common/utils'

export interface NormalizedItem {
  key: any
  value: any
  sort: any
  tooltip?: string
}

export const NORMALIZE_PRESETS = {
  NAME: (item: Record<string, any>) => ({ key: item.id, value: item.name, sort: item.name }),
  POS: (item: Record<string, any>) => ({ key: item.id, value: item.name, sort: item.pos }),
}

interface Props {
  items: Record<string, any>[] | Record<string, any>[][]
  normalize?: (item: Record<string, any>) => NormalizedItem
  emptyVal?: string
  maxShown?: number
}

const TagList = ({
  items,
  normalize = NORMALIZE_PRESETS.NAME,
  emptyVal = '',
  maxShown = 5,
}: Props): JSX.Element => {
  const [expanded, setExpanded] = useState(false)

  const create = (items: Record<string, any>[]) => {
    const normalizedItems = items.map(normalize)
    return (filterDistinct(sort(normalizedItems) as NormalizedItem[]) as NormalizedItem[]).map(
      (item) => (
        <div key={item.key} className="tooltip-wrapper" data-pr-tooltip={item.tooltip}>
          <Tag value={item.value} className={'bg-bluegray-500'} />
        </div>
      ),
    )
  }

  const results =
    items.length > 0 && Array.isArray(items[0])
      ? ([...items] as Record<string, any>[][]).flatMap((items) => create(items))
      : create([...items])

  const expand = (event: React.MouseEvent) => {
    event.stopPropagation()
    setExpanded(!expanded)
  }

  const customClasses = 'flex inline-flex flex-wrap gap-1'

  const collapsed = (results: JSX.Element[]) => {
    return (
      <>
        <div className={customClasses}>
          {results.slice(0, maxShown)}
          {expanded ? results.slice(maxShown, results.length) : null}
          <div
            key="expand"
            data-pr-tooltip={expanded ? 'Close' : 'Show all'}
            className="tooltip-wrapper"
          >
            <Tag
              icon={classNames(expanded ? 'pi pi-chevron-up mr-0' : 'pi pi-chevron-down')}
              value={expanded ? '' : `+${results.length - maxShown}`}
              className={'bg-primary cursor-pointer flex inline-flex flex-wrap '}
              onClick={(event) => expand(event)}
            />
          </div>
        </div>
      </>
    )
  }

  if (results && results.length === 0) {
    return <>{emptyVal}</>
  }

  return (
    <>
      <Tooltip target=".tooltip-wrapper" className="text-sm" />
      {!!results.length && results.length > maxShown ? (
        <>{collapsed(results)}</>
      ) : (
        <div className="flex inline-flex flex-wrap gap-1">{results}</div>
      )}
    </>
  )
}

export default TagList

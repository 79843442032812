import { Tooltip } from 'primereact/tooltip'

interface Props {
  member?: Member
  emptyVal?: string
}

const Creator = ({ member, emptyVal = '' }: Props): JSX.Element => {
  return !!member ? (
    <>
      <Tooltip target=".tooltip-wrapper" />
      <span className="tooltip-wrapper" data-pr-tooltip={member.username}>
        {member.firstName} {!!member.middleNames ? `${member.middleNames} ` : ''}
        {member.lastName}
      </span>
    </>
  ) : (
    <span>{emptyVal}</span>
  )
}

export default Creator

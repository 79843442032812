import { Toolbar } from 'primereact/toolbar'
import styles from '../../styles/favorites.module.scss'
import CompanyFavoriteCard from '../Company/CompanyFavoriteCard'
import ProductFavoriteCard from '../Product/ProductFavoriteCard'
import ParticipantFavoriteCard from '../Participant/ParticipantFavoriteCard'
import RfiFavoriteCard from '../RFI/RfiFavoriteCard'
import DataViewProvider, { DataViewPropsPresets } from '../DataView/DataViewProvider'

interface Props {
  toolbarLeft?: JSX.Element
  toolbarRight?: JSX.Element
  hiddenColumns?: number[]
  isLoading?: boolean
  isHomePage?: boolean
  data?: Favorite[]
  showFilter?: boolean
}

const FavoriteList = ({
  toolbarLeft = <h1>Companies</h1>,
  toolbarRight,
  data,
  showFilter = false,
  isLoading = true,
}: Props): JSX.Element => {
  const renderGridItem = (item: Favorite) => {
    switch (item.type) {
      case 'COMPANY':
        return (
          <CompanyFavoriteCard key={item.id} data={item} customClasses="col-12 md:col-4 card" />
        )
      case 'PRODUCT':
        return (
          <ProductFavoriteCard key={item.id} data={item} customClasses="col-12 md:col-4 card" />
        )
      case 'RFI':
        return <RfiFavoriteCard key={item.id} data={item} customClasses="col-12 md:col-4 card" />
      case 'PARTICIPANT':
        return (
          <ParticipantFavoriteCard key={item.id} data={item} customClasses="col-12 md:col-4 card" />
        )
      default:
        return <></>
    }
  }

  const itemTemplate = (data: Favorite, layout: string) => {
    if (!data) {
      return
    }

    if (layout === 'grid') return renderGridItem(data)
  }

  let dataViewProps = DataViewPropsPresets.standardGridPage(data, 'createdAt', -1)

  dataViewProps = {
    ...DataViewPropsPresets.standardGridPage(data, 'createdAt', -1),
    itemTemplate: itemTemplate,
    className: styles.dataview,
  }

  if (data?.length === 0) {
    dataViewProps = {
      ...DataViewPropsPresets.standardGridPage(data, 'createdAt', -1),
      paginator: false,
    }
  }

  return (
    <>
      <div className={styles.dataview}>
        <Toolbar left={toolbarLeft} right={toolbarRight} />
        <DataViewProvider dataViewProps={dataViewProps} isLoading={isLoading}></DataViewProvider>
      </div>
    </>
  )
}

export default FavoriteList

import { useNavigate } from 'react-router-dom'
import CompanyFavoriteCard from '../Company/CompanyFavoriteCard'
import DispatcherButton, { DispatcherButtonPresets } from '../DispatcherButton'
import ProductFavoriteCard from '../Product/ProductFavoriteCard'

import RfiFavoriteCard from '../RFI/RfiFavoriteCard'
import ParticipantFavoriteCard from '../Participant/ParticipantFavoriteCard'

import { classNames } from 'primereact/utils'

import styles from '../../styles/favorites.module.scss'

interface Props {
  topFavorites: Favorite[]
  amountFavorites?: number
  isLoading?: boolean
  isFetching?: boolean
}

const FavoriteTopFive = ({ topFavorites, amountFavorites = 0 }: Props): JSX.Element => {
  const navigate = useNavigate()
  const generateFavs = topFavorites?.map((item) => {
    switch (item.type) {
      case 'COMPANY':
        return <CompanyFavoriteCard key={item.id} data={item} customClasses="col-12 md:col-4" />
      case 'PRODUCT':
        return <ProductFavoriteCard key={item.id} data={item} customClasses="col-12 md:col-4" />
      case 'RFI':
        return <RfiFavoriteCard key={item.id} data={item} customClasses="col-12 md:col-4" />
      case 'PARTICIPANT':
        return <ParticipantFavoriteCard key={item.id} data={item} customClasses="col-12 md:col-4" />
      default:
        return <></>
    }
  })

  if (topFavorites.length === 0)
    return (
      <>
        <h1>My Favorites</h1>
        <p>
          You do not have any favorites. Click on{' '}
          <i className="pi pi-heart" style={{ fontSize: '0.875rem' }}></i> next to an item to make
          it a favorite.
        </p>
      </>
    )

  return (
    <div className={styles.topfive}>
      <h1>My Favorites</h1>
      <div className="grid grid-nogutter">{generateFavs} </div>

      <div className="flex justify-content-end mt-3">
        {amountFavorites > 3 ? (
          <DispatcherButton
            buttonProps={{
              label: 'Show all',
              icon: 'pi pi-arrow-right',
              iconPos: 'right',
            }}
            className={classNames(DispatcherButtonPresets.OUTLINED_PRIMARY, '')}
            onClick={() => navigate(`/favorites/`)}
          />
        ) : (
          <></>
        )}
      </div>
    </div>
  )
}

export default FavoriteTopFive

import { useNavigate } from 'react-router-dom'
import CompanyCreateContent from '../../components/Company/CompanyCreateContent'
import CompanyCreateToolbar from '../../components/Company/CompanyCreateToolbar'

const CompanyCreatePage = (): JSX.Element => {
  const navigate = useNavigate()
  return (
    <div className="px-4 py-5 md:px-6 lg:px-8">
      <CompanyCreateToolbar />
      <CompanyCreateContent
        onContentCreated={(company: Company) => navigate(`/company/${company.id}`)}
      />
    </div>
  )
}

export default CompanyCreatePage

import TreeNode from 'primereact/treenode'
import { TreeCheckboxSelectionKeys } from 'primereact/tree'
import React, { useEffect, useState } from 'react'
import { UseFormResetField } from 'react-hook-form'

import {
  useCapabilityListQuery,
  useCompanyListQuery,
  useContactListByCompanyQuery,
  useGeoListQuery,
  useParticipantListByRfiQuery,
  useProductListQuery,
  useQuestionListForRfiQuery,
  useQuestionPackTypeListQuery,
  useTagListQuery,
} from '../redux/api'
import { useAppSelector } from './hooks'
import { groupBy, sort } from './utils'
import { RfiType } from '../enums'

// FORM GEO DATA ...

interface FormGeoDataOptions {
  label: string
  value: string | number
  items: {
    label: string
    value: string | number
  }[]
}

export const useGeoData = (): {
  isLoading: boolean
  options: FormGeoDataOptions[] // a nested dropdown-options-list of all geos (regions and markets)
  flat: Geo[] // a flat list of all geos
} => {
  const workspaceId = useAppSelector((state) => state.app.workspace!.id)
  const { data, isLoading } = useGeoListQuery({ workspaceId })

  const [options, setOptions] = useState<FormGeoDataOptions[]>([])
  const [flat, setFlat] = useState<Geo[]>([])

  useEffect(() => {
    if (!isLoading) {
      setOptions(
        (data || [])[0].children!.map((regionGeo: Geo) => {
          const items = regionGeo.children!.map((marketGeo: Geo) => {
            return { label: marketGeo.name, value: marketGeo.id }
          })
          return { label: regionGeo.name, value: regionGeo.id, items }
        }),
      )
      setFlat([
        ...(data || [])[0].children!.flatMap((regionGeo: Geo) => {
          return regionGeo.children!.map((marketGeo: Geo) => marketGeo)
        }),
        (data || [])[0],
      ])
    }
  }, [isLoading])

  return { isLoading, options, flat }
}

// form select a single geo item (radio for global/market, single select)
export const useFormGeoDataSingle = (
  geo?: Geo,
  resetField?: UseFormResetField<any>,
): {
  isLoading: boolean
  options: FormGeoDataOptions[]
  flat: Geo[]
  marketRadio: 'global' | 'market' | null
  setMarketRadio: React.Dispatch<React.SetStateAction<'global' | 'market' | null>>
} => {
  const { isLoading, options, flat } = useGeoData()
  const [marketRadio, setMarketRadio] = useState<'global' | 'market' | null>(
    geo === undefined ? null : geo!.type === null ? 'global' : 'market',
  )

  useEffect(() => {
    if (flat.length > 0 && resetField !== undefined && marketRadio === null) {
      const defaultMarket = flat.find((geo) => geo.type === null)?.id
      if (defaultMarket !== undefined) {
        resetField('market', { defaultValue: defaultMarket })
        setMarketRadio('global')
      }
    }
  }, [flat])

  return { isLoading, options, flat, marketRadio, setMarketRadio }
}

// form select a multiple geo items (checkbox for global/market, multi select)
export const useFormGeoDataMulti = (
  item?: { geos: Geo[] },
  resetField?: UseFormResetField<any>,
): {
  isLoading: boolean
  options: FormGeoDataOptions[]
  flat: Geo[]
  defaultGeos: number[]
  defaultGlobal: boolean
  convert: (global: boolean, markets: number[]) => Geo[]
  convertSingle: (global: boolean, markets: number) => Geo
} => {
  const { isLoading, options, flat } = useGeoData()
  const [defaultGeos, setDefaultGeos] = useState<number[]>([])
  const [defaultGlobal, setDefaultGlobal] = useState(false)

  useEffect(() => {
    if (item !== undefined && options.length > 0) {
      setDefaultGeos(
        sort(
          [...(item?.geos || [])]
            .filter((geo: Geo) => geo.type !== null)
            .map((item) => ({
              key: item.id,
              sort: item.name,
            })),
        ).map((sorted) => sorted.key),
      )
      setDefaultGlobal([...(item?.geos || [])].filter((geo: Geo) => geo.type === null).length > 0)
    }
  }, [options, item])

  useEffect(() => {
    if (resetField !== undefined && defaultGlobal) {
      resetField('global', { defaultValue: defaultGlobal })
    }
  }, [defaultGlobal])

  useEffect(() => {
    if (resetField !== undefined && defaultGeos.length > 0) {
      resetField('geos', { defaultValue: defaultGeos })
    }
  }, [defaultGeos])

  const convert = (global: boolean, markets: number[]) => [
    ...flat.filter((geo) => markets.includes(geo.id)),
    ...(global ? flat.filter((geo) => geo.type === null) : []),
  ]

  const convertSingle = (global: boolean, market: number) => {
    return global
      ? flat.filter((geo) => geo.type === null)[0]
      : flat.filter((geo) => geo.id === market)[0]
  }

  return { isLoading, options, flat, defaultGeos, defaultGlobal, convert, convertSingle }
}

// ... FORM GEO DATA

// FORM TAG DATA ...

interface FormTagDataOptions {
  label: string
  items: {
    label: string
    value: number
  }[]
}

export const useFormTagData = (
  item?: { tags: Tag[] },
  resetField?: UseFormResetField<any>,
): {
  isLoading: boolean
  options: FormTagDataOptions[]
  flat: Tag[]
  defaultTags: number[]
  convert: (tags: number[]) => Tag[]
} => {
  const workspaceId = useAppSelector((state) => state.app.workspace!.id)
  const { data, isLoading } = useTagListQuery({ workspaceId })

  const [options, setOptions] = useState<FormTagDataOptions[]>([])
  const [flat, setFlat] = useState<Tag[]>([])
  const [defaultTags, setDefaultTags] = useState<number[]>([])

  useEffect(() => {
    if (!isLoading) {
      const groupedTags = groupBy(data || [], (t) => t.tagType?.name || '')
      setOptions(
        Object.entries(groupedTags)
          .sort(([keyA, tagsA], [keyB, tagsB]) => {
            const posA = tagsA[0].tagType ? tagsA[0].tagType.pos : -1
            const posB = tagsB[0].tagType ? tagsB[0].tagType.pos : -1
            return posA - posB
          })
          .map(([groupName, tags]) => {
            return {
              label: `${groupName} Tags`.trim(),
              items: tags
                .sort((a, b) => a.pos - b.pos)
                .map((tag: Tag) => {
                  return { label: tag.name, value: tag.id }
                }),
            }
          }),
      )
      setFlat(data || [])
    }
  }, [isLoading])

  useEffect(() => {
    if (item !== undefined && options.length > 0) {
      setDefaultTags(
        [...(item?.tags || [])].sort((a, b) => a.pos - b.pos).map((tag: Tag) => tag.id),
      )
    }
  }, [options])

  useEffect(() => {
    if (resetField !== undefined && defaultTags.length > 0) {
      resetField('tags', { defaultValue: defaultTags })
    }
  }, [defaultTags])

  const convert = (tags: number[]) => flat.filter((tag) => tags.includes(tag.id))

  return { isLoading, options, flat, defaultTags, convert }
}

// ... FORM TAG DATA

// FORM CAPABILITY DATA ...

interface FormCapabilityDataOptions {
  key: any
  label: string
  children?: FormCapabilityDataOptions[]
}

export const useFormCapabilityData = (
  item?: { capabilities: Capability[] },
  resetField?: UseFormResetField<any>,
): {
  isLoading: boolean
  options: FormCapabilityDataOptions[]
  flat: Capability[]
  defaultCapabilities: { [key: number]: boolean }
  convert: (capabilities: { [key: number]: boolean }) => Capability[]
} => {
  const workspaceId = useAppSelector((state) => state.app.workspace!.id)
  const { data, isLoading } = useCapabilityListQuery({ workspaceId })

  const [options, setOptions] = useState<FormCapabilityDataOptions[]>([])
  const [flat, setFlat] = useState<Capability[]>([])
  const [defaultCapabilities, setDefaultCapabilities] = useState<{ [key: number]: boolean }>({})

  useEffect(() => {
    if (!isLoading) {
      setOptions(
        sort(
          (data || []).map((categoryCapability: Capability) => {
            const children = sort(
              (categoryCapability.children || []).map((focusCapability: Capability) => {
                return {
                  key: focusCapability.id,
                  label: focusCapability.name,
                  sort: focusCapability.pos,
                }
              }),
            ) as FormCapabilityDataOptions[]
            return {
              key: categoryCapability.id,
              label: categoryCapability.name,
              sort: categoryCapability.pos,
              children,
            }
          }),
        ) as FormCapabilityDataOptions[],
      )

      setFlat([
        ...(data || []).map((categoryCapability: Capability) => categoryCapability),
        ...(data || []).flatMap((categoryCapability: Capability) => {
          return (categoryCapability.children || []).map(
            (focusCapability: Capability) => focusCapability,
          )
        }),
      ])
    }
  }, [isLoading])

  useEffect(() => {
    if (item !== undefined && options.length > 0) {
      setDefaultCapabilities(
        Object.assign(
          {},
          ...(item.capabilities.map((capability: Capability) => {
            const item: { [key: number]: boolean } = {}
            item[capability.id] = true
            return item
          }) || []),
        ),
      )
    }
  }, [options])

  useEffect(() => {
    if (resetField !== undefined && Object.keys(defaultCapabilities).length > 0) {
      resetField('capabilities', { defaultValue: defaultCapabilities })
    }
  }, [defaultCapabilities])

  const convert = (capabilities: { [key: number]: boolean }) =>
    flat.filter((capability) => capabilities[capability.id])

  return { isLoading, options, flat, defaultCapabilities, convert }
}

// ... FORM CAPABILITY DATA

// FORM PRODUCT DATA ...
export interface FormProductDataOption {
  id: number
  name: string
  company: { id: number; name: string }
  capabilities: { id: number; name: string }[]
}

// FORM PRODUCTS FOR PARTICIPANT PICKLIST (AT RFI CREATE) ...

export const useFormProductsForCreateRfiPicklist = (
  rfiData?: {
    participants: { product: Product | null }[]
  },
  resetField?: UseFormResetField<any>,
): {
  isFetching: boolean
  source: FormProductDataOption[]
  setSource: React.Dispatch<React.SetStateAction<FormProductDataOption[]>>
  target: FormProductDataOption[]
  setTarget: React.Dispatch<React.SetStateAction<FormProductDataOption[]>>
} => {
  const workspaceId = useAppSelector((state) => state.app.workspace!.id)
  const { data, isFetching } = useProductListQuery({ workspaceId })

  const [source, setSource] = useState<FormProductDataOption[]>([])
  const [target, setTarget] = useState<FormProductDataOption[]>(
    rfiData !== undefined
      ? rfiData.participants
          .map((participant) => participant.product!)
          .sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1))
      : [],
  )

  useEffect(() => {
    setSource(
      (data?.content || [])
        .map((product) => product)
        .filter(
          (sourceProduct: Product) =>
            target.findIndex((targetProduct) => sourceProduct.id === targetProduct.id) < 0,
        )
        .sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)),
    )
  }, [isFetching, target])

  useEffect(() => {
    if (resetField !== undefined && target.length > 0) {
      resetField('products', { defaultValue: target })
    }
  }, [target])

  return { isFetching, source, setSource, target, setTarget }
}

// ... FORM PRODUCTS FOR PARTICIPANT PICKLIST (AT RFI CREATE)

// FORM PRODUCTS FOR PARTICIPANT PICKLIST (AT RFI DETAILS) ...

export const useFormProductsForAddParticipantPicklist = (
  rfi: Rfi,
): {
  isLoading: boolean
  source: FormProductDataOption[]
  setSource: React.Dispatch<React.SetStateAction<FormProductDataOption[]>>
  target: FormProductDataOption[]
  setTarget: React.Dispatch<React.SetStateAction<FormProductDataOption[]>>
} => {
  const workspaceId = useAppSelector((state) => state.app.workspace!.id)

  const { data: products, isLoading: isProductsLoading } = useProductListQuery({ workspaceId })
  const { data: participants, isLoading: isParticipantsLoading } = useParticipantListByRfiQuery({
    workspaceId: workspaceId,
    rfiId: rfi.id,
  })

  const [source, setSource] = useState<FormProductDataOption[]>([])
  const [target, setTarget] = useState<FormProductDataOption[]>([])

  useEffect(() => {
    if (isProductsLoading === false && isParticipantsLoading === false) {
      setSource(
        (products?.content || [])
          .map((product: Product) => product)
          .filter(
            (sourceProduct: Product) =>
              (participants || []).findIndex(
                (participant: Participant) => sourceProduct.id === participant.product?.id,
              ) < 0,
          )
          .filter(
            (sourceProduct: Product) =>
              target.findIndex((targetProduct) => sourceProduct.id === targetProduct.id) < 0,
          )
          .sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)),
      )
    }
  }, [isProductsLoading, isParticipantsLoading, target])

  return {
    isLoading: isProductsLoading || isParticipantsLoading,
    source,
    setSource,
    target,
    setTarget,
  }
}

// ... FORM PRODUCTS FOR PARTICIPANT PICKLIST (AT RFI DETAILS)

// ... FORM PRODUCT DATA

// FORM COMPANY DATA ...

export interface FormCompanyDataOption {
  id: number
  name: string
  products: { capabilities: { id: number; name: string }[] }[]
}

export const useFormCompanyData = (
  item?: { company: { id: number } },
  resetField?: UseFormResetField<any>,
): {
  isLoading: boolean
  options: FormCompanyDataOption[]
  setOptions: React.Dispatch<React.SetStateAction<FormCompanyDataOption[]>>
  flat: Company[]
  defaultCompany: number | undefined
  convert: (companyId: number) => Company
} => {
  const workspaceId = useAppSelector((state) => state.app.workspace!.id)
  const { data, isLoading } = useCompanyListQuery({ workspaceId })

  const [options, setOptions] = useState<FormCompanyDataOption[]>([])
  const [flat, setFlat] = useState<Company[]>([])
  const [defaultCompany, setDefaultCompany] = useState<number | undefined>(undefined)

  useEffect(() => {
    if (!isLoading) {
      setOptions(
        (data?.content || [])
          .map((company) => company)
          .sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)),
      )
      setFlat(data?.content || [])
    }
  }, [isLoading])

  useEffect(() => {
    if (item !== undefined && options.length > 0) {
      setDefaultCompany(item.company.id)
    }
  }, [options])

  useEffect(() => {
    if (resetField !== undefined && defaultCompany !== undefined) {
      resetField('company', { defaultValue: defaultCompany })
    }
  }, [defaultCompany])

  const convert = (companyId: number) => flat.filter((company) => company.id === companyId)[0]

  return { isLoading, options, setOptions, flat, defaultCompany, convert }
}

// ... FORM COMPANY DATA

// FORM COMPANIES FOR PARTICIPANT PICKLIST (AT RFI CREATE) ...

export const useFormCompaniesForCreateRfiPicklist = (
  rfiData?: {
    participants: { company: Company }[]
  },
  resetField?: UseFormResetField<any>,
): {
  isFetching: boolean
  source: FormCompanyDataOption[]
  setSource: React.Dispatch<React.SetStateAction<FormCompanyDataOption[]>>
  target: FormCompanyDataOption[]
  setTarget: React.Dispatch<React.SetStateAction<FormCompanyDataOption[]>>
} => {
  const workspaceId = useAppSelector((state) => state.app.workspace!.id)
  const { data, isFetching } = useCompanyListQuery({ workspaceId })

  const [source, setSource] = useState<FormCompanyDataOption[]>([])
  const [target, setTarget] = useState<FormCompanyDataOption[]>(
    rfiData !== undefined
      ? rfiData.participants
          .map((participant) => participant.company)
          .sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1))
      : [],
  )

  useEffect(() => {
    setSource(
      (data?.content || [])
        .map((company) => company)
        .filter(
          (sourceCompany: Company) =>
            target.findIndex((targetCompany) => sourceCompany.id === targetCompany.id) < 0,
        )
        .sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)),
    )
  }, [isFetching, target])

  useEffect(() => {
    if (resetField !== undefined && target.length > 0) {
      resetField('companies', { defaultValue: target })
    }
  }, [target])

  return { isFetching, source, setSource, target, setTarget }
}

// ... FORM COMPANIES FOR PARTICIPANT PICKLIST (AT RFI CREATE)

// FORM COMPANIES FOR PARTICIPANT PICKLIST (AT RFI DETAILS) ...

export const useFormCompaniesForAddParticipantPicklist = (
  rfi: Rfi,
): {
  isLoading: boolean
  source: FormCompanyDataOption[]
  setSource: React.Dispatch<React.SetStateAction<FormCompanyDataOption[]>>
  target: FormCompanyDataOption[]
  setTarget: React.Dispatch<React.SetStateAction<FormCompanyDataOption[]>>
} => {
  const workspaceId = useAppSelector((state) => state.app.workspace!.id)

  const { data: companies, isLoading: isCompaniesLoading } = useCompanyListQuery({ workspaceId })
  const { data: participants, isLoading: isParticipantsLoading } = useParticipantListByRfiQuery({
    workspaceId: workspaceId,
    rfiId: rfi.id,
  })

  const [source, setSource] = useState<FormCompanyDataOption[]>([])
  const [target, setTarget] = useState<FormCompanyDataOption[]>([])

  useEffect(() => {
    if (isCompaniesLoading === false && isParticipantsLoading === false) {
      setSource(
        (companies?.content || [])
          .map((company) => company)
          .filter(
            (sourceCompany: Company) =>
              (participants || []).findIndex(
                (participant) => sourceCompany.id === participant.company.id,
              ) < 0,
          )
          .filter(
            (sourceCompany: Company) =>
              target.findIndex((targetCompany) => sourceCompany.id === targetCompany.id) < 0,
          )
          .sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)),
      )
    }
  }, [isCompaniesLoading, isParticipantsLoading, target])

  return {
    isLoading: isCompaniesLoading || isParticipantsLoading,
    source,
    setSource,
    target,
    setTarget,
  }
}

// ... FORM COMPANIES FOR PARTICIPANT PICKLIST (AT RFI DETAILS)

// FORM CONTACT DATA ...

export interface FormContactDataOption {
  id: number
  firstName: string
  lastName: string
  jobTitle: string | null
}

export const useFormContactData = (
  item?: Participant,
  resetField?: UseFormResetField<any>,
): {
  isLoading: boolean
  isFetching: boolean
  options: FormContactDataOption[]
  setOptions: React.Dispatch<React.SetStateAction<FormContactDataOption[]>>
  flat: Contact[]
  defaultContact: number | undefined
  convert: (contactId: number) => Contact
  source: FormContactDataOption[]
  setSource: React.Dispatch<React.SetStateAction<FormContactDataOption[]>>
  target: FormContactDataOption[]
  setTarget: React.Dispatch<React.SetStateAction<FormContactDataOption[]>>
} => {
  const workspaceId = useAppSelector((state) => state.app.workspace!.id)
  const { data, isLoading, isFetching } = useContactListByCompanyQuery({
    workspaceId: workspaceId,
    companyId: Number(item?.company.id),
  })

  const [options, setOptions] = useState<FormContactDataOption[]>([])
  const [flat, setFlat] = useState<Contact[]>([])
  const [defaultContact, setDefaultContact] = useState<number | undefined>(undefined)

  const [source, setSource] = useState<FormContactDataOption[]>([])
  const [target, setTarget] = useState<FormContactDataOption[]>([])

  useEffect(() => {
    if (!isFetching) {
      setOptions(
        (data || [])
          .map((contact: Contact) => {
            return contact
          })
          .sort((a, b) => (a.lastName.toLowerCase() > b.lastName.toLowerCase() ? 1 : -1)),
      )

      setSource(
        (data || [])
          .map((contact: Contact) => {
            return contact
          })
          .sort((a, b) => (a.lastName.toLowerCase() > b.lastName.toLowerCase() ? 1 : -1)),
      )

      setFlat(data || [])
    }
  }, [isFetching, target])

  useEffect(() => {
    if (item !== undefined && options.length > 0) {
      setDefaultContact(item?.company.id)
    }
  }, [options])

  useEffect(() => {
    if (resetField !== undefined && defaultContact !== undefined) {
      resetField('recipients', { defaultValue: defaultContact })
    }
  }, [defaultContact])

  const convert = (contactId: number) => flat.filter((contact) => contact.id === contactId)[0]

  return {
    isLoading,
    isFetching,
    options,
    setOptions,
    flat,
    defaultContact,
    convert,
    source,
    setSource,
    target,
    setTarget,
  }
}

// ... FORM CONTACT DATA

// FORM QUESTIONPACK TYPESCOPE DATA ...

interface FormQuestionPackTypeScopeDataOption {
  label: string
  value: any
}

export const useFormQuestionPackTypeScopeData = (
  item?: { questionPackType: QuestionPackType },
  resetField?: UseFormResetField<any>,
): {
  isLoading: boolean
  options: FormQuestionPackTypeScopeDataOption[]
  flat: QuestionPackType[]
  defaultQuestionPackType: number | undefined
  convert: (questionTypeScopeId: number) => QuestionPackType
} => {
  const workspaceId = useAppSelector((state) => state.app.workspace!.id)
  const { data, isLoading } = useQuestionPackTypeListQuery({ workspaceId })
  const [options, setOptions] = useState<FormQuestionPackTypeScopeDataOption[]>([])
  const [flat, setFlat] = useState<QuestionPackType[]>([])
  const [defaultQuestionPackType, setDefaultQuestionPackType] = useState<number | undefined>(
    undefined,
  )

  useEffect(() => {
    if (!isLoading) {
      setOptions(
        (data || [])
          .map((questionPackType: QuestionPackType) => {
            return {
              label: `${questionPackType.name} (${questionPackType.scope})`,
              value: questionPackType.id,
            }
          })
          .sort((a, b) => (a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1)),
      )

      setFlat(data || [])
    }
  }, [isLoading])

  useEffect(() => {
    if (item !== undefined && options.length > 0) {
      setDefaultQuestionPackType(item.questionPackType.id)
    }
  }, [options])

  useEffect(() => {
    if (resetField !== undefined && defaultQuestionPackType !== undefined) {
      resetField('questionPackTypeScope', { defaultValue: defaultQuestionPackType })
    }
  }, [defaultQuestionPackType])

  const convert = (questionTypeScopeId: number) =>
    flat.filter((questionPackType) => questionPackType.id === questionTypeScopeId)[0]

  return { isLoading, options, flat, defaultQuestionPackType, convert }
}

// ... FORM QUESTIONPACK TYPESCOPE DATA

// FORM QUESTIONPACK FOR RFI (CREATE) DATA ...

interface FormQuestionPackForCreateOption extends TreeNode {
  data: {
    id: number
    name: string
    search: string
    type: string | null
    market: Geo | null
    capabilities: Capability[] | null
    isRfiTypeCompany: boolean
    isRfiTypeProduct: boolean
    isMustHave: boolean
    isDefaultSelected: boolean
  }
}

export interface TreeCheckboxSelectionKeysExtended extends TreeCheckboxSelectionKeys {
  [key: string]: {
    checked?: boolean
    partialChecked?: boolean
    isMustHave?: boolean
  }
}

export const useFormQuestionPackForCreateData = (
  currentQuestionPacks: QuestionPack[],
  rfiType: keyof typeof RfiType,
  geoId: number,
  capabilityIds: number[],
  tagIds: number[],
  hasDefaultSelection: boolean,
  setHasDefaultSelection: React.Dispatch<React.SetStateAction<boolean>>,
  resetField?: UseFormResetField<any>,
): {
  isFetching: boolean
  options: FormQuestionPackForCreateOption[]
  flat: QuestionPack[]
  defaultQuestionPacks: TreeCheckboxSelectionKeysExtended
  convert: (selectedValues: TreeCheckboxSelectionKeysExtended) => QuestionPack[]
} => {
  const workspaceId = useAppSelector((state) => state.app.workspace!.id)
  const { data, isFetching } = useQuestionListForRfiQuery(
    {
      workspaceId,
      rfiType,
      geoId,
      capabilityIds,
      tagIds,
    },
    { refetchOnMountOrArgChange: true },
  )
  const [options, setOptions] = useState<FormQuestionPackForCreateOption[]>([])
  const [flat, setFlat] = useState<QuestionPack[]>([])
  const [defaultQuestionPacks, setDefaultQuestionPacks] =
    useState<TreeCheckboxSelectionKeysExtended>({})

  const isSelected = (questionPackId: number, questionId?: number): boolean => {
    const questionPackIndex = currentQuestionPacks.findIndex(
      (questionPack: QuestionPack) => questionPackId === questionPack.id,
    )
    if (questionPackIndex > -1) {
      return questionId === undefined
        ? true
        : currentQuestionPacks[questionPackIndex].questions.findIndex(
            (question: Question) => questionId === question.id,
          ) > -1
    }
    return false
  }

  useEffect(() => {
    if (!isFetching) {
      setOptions(
        (data || []).map((questionPack: QuestionPack) => {
          const children = (questionPack.questions || []).map((question: Question) => {
            return {
              key: `${questionPack.id}-${question.id}`,
              data: {
                id: question.id,
                name: question.phrase,
                search: `${question.phrase} ${question.description}`,
                type: question.type,
                market: null,
                capabilities: null,
                isMustHave: question.isMustHave,
                isDefaultSelected: question.isDefaultSelected,
              },
              className: question.isMustHave ? 've-treenode-musthave' : undefined,
            }
          })
          return {
            key: `${questionPack.id}`,
            data: {
              id: questionPack.id,
              name: questionPack.name,
              search: questionPack.name,
              type: null,
              market: questionPack.geo,
              capabilities: questionPack.capabilities,
              isRfiTypeCompany: questionPack.isRfiTypeCompany,
              isRfiTypeProduct: questionPack.isRfiTypeProduct,
              isMustHave: questionPack.isMustHave,
              isDefaultSelected: questionPack.isDefaultSelected,
            },
            className: questionPack.isMustHave ? 've-treenode-musthave' : undefined,
            children,
          }
        }),
      )
    }

    setFlat(data || [])
  }, [isFetching])

  useEffect(() => {
    if (options.length > 0) {
      setDefaultQuestionPacks(
        Object.assign(
          {},
          ...Object.values(options).flatMap((option) => {
            const items: TreeCheckboxSelectionKeysExtended[] = []

            const parentItem: TreeCheckboxSelectionKeysExtended = {}
            parentItem[`${option.key}`] = {
              checked:
                option.data.isMustHave ||
                isSelected(option.data.id) ||
                (hasDefaultSelection && option.data.isDefaultSelected),
              partialChecked: false,
              isMustHave: option.data.isMustHave,
            }
            items.push(parentItem)
            ;(option.children || []).forEach((childOption) => {
              const childItem: TreeCheckboxSelectionKeysExtended = {}
              childItem[`${childOption.key}`] = {
                checked:
                  childOption.data.isMustHave ||
                  isSelected(option.data.id, childOption.data.id) ||
                  (hasDefaultSelection && childOption.data.isDefaultSelected),
                partialChecked: false,
                isMustHave: childOption.data.isMustHave,
              }
              items.push(childItem)
            })

            return items
          }),
        ),
      )
      setHasDefaultSelection(false)
    }
  }, [options])

  useEffect(() => {
    if (resetField !== undefined && Object.keys(defaultQuestionPacks).length > 0) {
      resetField('questionPacks', { defaultValue: defaultQuestionPacks })
    }
  }, [defaultQuestionPacks])

  const convert = (selectedValues: TreeCheckboxSelectionKeysExtended) => {
    const questionPacks = flat.filter(
      (questionPack) =>
        selectedValues[`${questionPack.id}`].checked ||
        selectedValues[`${questionPack.id}`].partialChecked,
    )
    return questionPacks
      .map((questionPack) => {
        const cloned = { ...questionPack }
        cloned.excludedQuestions = [...cloned.questions].filter(
          (question) => !selectedValues[`${questionPack.id}-${question.id}`].checked,
        )
        cloned.questions = [...cloned.questions].filter(
          (question) => selectedValues[`${questionPack.id}-${question.id}`].checked,
        )
        return cloned
      })
      .filter((questionPack) => questionPack.questions.length > 0)
  }

  return { isFetching, options, flat, defaultQuestionPacks, convert }
}

// ... FORM QUESTIONPACK FOR RFI (CREATE) DATA

import QuestionOutputBoolean from './QuestionOutputBoolean'
import QuestionOutputDate from './QuestionOutputDate'
import QuestionOutputFile from './QuestionOutputFile'
import QuestionOutputMultiLineText from './QuestionOutputMultiLineText'
import QuestionOutputMultiSelect from './QuestionOutputMultiSelect'
import QuestionOutputNumber from './QuestionOutputNumber'
import QuestionOutputSingleLineText from './QuestionOutputSingleLineText'
import QuestionOutputSingleSelect from './QuestionOutputSingleSelect'

interface Props {
  question: Question
  questionConstraint?: ConstrainedQuestion
}

const typeMap: any = {
  BOOLEAN: QuestionOutputBoolean,
  DATE: QuestionOutputDate,
  FILE: QuestionOutputFile,
  MULTI_LINE_TEXT: QuestionOutputMultiLineText,
  MULTI_SELECT: QuestionOutputMultiSelect,
  NUMBER: QuestionOutputNumber,
  SINGLE_LINE_TEXT: QuestionOutputSingleLineText,
  SINGLE_SELECT: QuestionOutputSingleSelect,
}

const QuestionOutput = ({ question, questionConstraint }: Props): JSX.Element => {
  const Component: any = typeMap[question.type]
  return Component && <Component question={question} questionConstraint={questionConstraint} />
}

export default QuestionOutput

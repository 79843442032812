export enum RfiStatus {
  CREATED = 'CREATED',
  SENT = 'SENT',
  IN_PROGRESS = 'IN_PROGRESS',
  SUBMITTED = 'SUBMITTED',
  PUBLISHED = 'PUBLISHED',
  ARCHIVED = 'ARCHIVED',
  WITHDRAWN = 'WITHDRAWN',
  EXPIRED = 'EXPIRED',
  UNKNOWN = 'UNKNOWN',
  CUSTOM = 'CUSTOM',
}

export enum RfiType {
  COMPANY = 'COMPANY',
  PRODUCT = 'PRODUCT',
}

import TagList, { NORMALIZE_PRESETS } from '../TagList'
import { Divider } from 'primereact/divider'
import { vowelPattern } from '../../consts'
import { useGroupedTagList } from '../../common/hooks'
import styles from '../../styles/details.module.scss'
import { Link } from 'react-router-dom'

interface Props {
  product?: Product
}

const ProductDetails = ({ product }: Props): JSX.Element => {
  const groupedTagList = useGroupedTagList(product?.tags)

  return (
    <>
      <div className="card mt-5">
        <h3 className="mb-0">Product</h3>
        <h1 className="mt-1">{product?.name}</h1>
        <span className="text-xl">
          {product?.company.name.match(vowelPattern) ? 'an ' : 'a '}
          <Link to={`/company/${product?.company.id}`} className={styles.details}>
            <span>{product?.company.name} </span>
            <i className="pi pi-external-link" style={{ fontSize: '.6em' }}></i>
          </Link>{' '}
          product
        </span>
        <p>{product?.description}</p>
      </div>
      <Divider />
      <div className="card mb-5">
        <div className="flex card-container overflow-hidden gap-4">
          <div className="flex-auto col-6">
            <div className="flex flex-column gap-4">
              <div>
                <span className="font-medium">Aliases: </span>
                <span>{product?.aliases || '(none)'}</span>
              </div>
              <div className="flex gap-1">
                <div className="font-medium">Available Markets: </div>
                <div className="flex flex-wrap gap-1">
                  <TagList
                    items={[
                      (product?.geos || []).filter((geo) => geo.type === null),
                      (product?.geos || []).filter((geo) => geo.type !== null),
                    ]}
                    emptyVal={'(none)'}
                    maxShown={10}
                  />
                </div>
              </div>
              <div className="flex gap-1">
                <div className="font-medium">Capabilities: </div>
                <div className="flex flex-wrap gap-1">
                  <TagList
                    items={product?.capabilities || []}
                    normalize={NORMALIZE_PRESETS.POS}
                    emptyVal={'(none)'}
                    maxShown={10}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-wrap col-6 ">
            <div className="flex flex-wrap flex-column gap-4">{groupedTagList}</div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ProductDetails

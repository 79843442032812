import { Divider } from 'primereact/divider'
import { useGroupedTagList } from '../../common/hooks'
import TagList, { NORMALIZE_PRESETS } from '../TagList'

interface Props {
  company: Company
}

const CompanyDetails = ({ company }: Props): JSX.Element => {
  const groupedTagList = useGroupedTagList(company?.tags)

  const capabilities: Capability[] = []
  company?.products.forEach((product) => {
    capabilities.push(...product.capabilities)
  })

  return (
    <>
      <div className="card mt-5">
        <h3 className="mb-0">Company</h3>
        <h1 className="mt-1">{company?.name}</h1>
        <p>{company?.description}</p>
      </div>
      <Divider />
      <div className="card mb-5">
        <div className="flex card-container overflow-hidden gap-4">
          <div className="flex-auto col-6">
            <div className="flex flex-column gap-4">
              <div>
                <span className="font-medium">Aliases: </span>
                <span>{company?.aliases || '(none)'}</span>
              </div>
              <div className="flex gap-1">
                <div className="font-medium">Capabilities: </div>
                <div className="flex flex-wrap gap-1">
                  <TagList
                    items={capabilities}
                    normalize={NORMALIZE_PRESETS.POS}
                    emptyVal={'(none)'}
                    maxShown={10}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-wrap col-6 ">
            <div className="flex flex-wrap flex-column gap-4">{groupedTagList}</div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CompanyDetails

import React from 'react'
import ContactList from '../Contact/ContactList'
import { useAppSelector } from '../../common/hooks'
import { useContactListByCompanyQuery } from '../../redux/api'

interface Props {
  company: Company
}

const CompanyTabContacts = ({ company }: Props): JSX.Element => {
  const workspaceId = useAppSelector((state) => state.app.workspace!.id)
  const { data, isFetching } = useContactListByCompanyQuery({
    workspaceId,
    companyId: company!.id,
  })

  return (
    <ContactList
      toolbarLeft={<h1 className="text-2xl">Contacts</h1>}
      isLoading={isFetching}
      data={data}
      showFilter={true}
      company={company}
    />
  )
}

export default CompanyTabContacts

import TagList, { NORMALIZE_PRESETS } from '../TagList'
import { Divider } from 'primereact/divider'
import { Tag } from 'primereact/tag'
import { useGroupedTagList } from '../../common/hooks'
import { Checkbox } from 'primereact/checkbox'
import { Controller } from 'react-hook-form'
import { classNames } from 'primereact/utils'

interface Props {
  questionPack?: QuestionPack
}

const QuestionPackDetails = ({ questionPack }: Props): JSX.Element => {
  const groupedTagList = useGroupedTagList(questionPack?.tags, undefined, 'Required')

  const renderOptions = () => {
    if (questionPack?.isMustHave) {
      return 'Must-have, Selected by default'
    }

    if (questionPack?.isDefaultSelected) {
      return 'Selected by default'
    }

    return '(none)'
  }

  const renderRfiTypes = () => {
    let rfiTypeString: String = ''
    if (questionPack?.isRfiTypeCompany) {
      rfiTypeString += 'Company'
    }

    if (questionPack?.isRfiTypeProduct) {
      rfiTypeString += 'Product'
    }

    if (questionPack?.isRfiTypeProduct && questionPack?.isRfiTypeCompany) {
      rfiTypeString = 'Company, Product'
    }

    return rfiTypeString
  }

  return (
    <>
      <div className="card mt-5">
        <h3 className="mb-0">Question Pack</h3>
        <h1 className="mt-1">{questionPack?.name}</h1>
        <p className="text-2xl">Version {questionPack?.version}</p>
        <p>{questionPack?.description}</p>
      </div>
      <Divider />
      <div className="card mb-5">
        <div className="flex card-container overflow-hidden gap-4">
          <div className="flex-auto col-6">
            <div className="flex flex-column gap-4">
              <div>
                <span className="font-medium">Display Name: </span>
                {questionPack?.displayName}
              </div>
              <div>
                <span className="font-medium">Type (Scope): </span>
                {questionPack?.questionPackType.name} ({questionPack?.questionPackType.scope})
              </div>
              <div>
                <span className="font-medium">RFI Types: </span>
                {renderRfiTypes()}
              </div>
              <div>
                <span className="font-medium">Options: </span>
                {renderOptions()}
              </div>
              <div>
                <span className="font-medium">Origin Market: </span>
                <Tag value={questionPack?.geo.name} className={'bg-bluegray-500'} />
              </div>
              <div>
                <span className="font-medium">Exclusive Markets: </span>
                <TagList
                  items={[
                    (questionPack?.geos || []).filter((geo) => geo.type === null),
                    (questionPack?.geos || []).filter((geo) => geo.type !== null),
                  ]}
                  emptyVal={'(none; this Question Pack is available to all markets)'}
                  maxShown={10}
                />
              </div>
              <div className="flex gap-1">
                <div className="font-medium">Exclusive Capabilities: </div>
                <div className="flex flex-wrap gap-1">
                  <TagList
                    items={questionPack?.capabilities || []}
                    normalize={NORMALIZE_PRESETS.POS}
                    emptyVal={'(none; this Question Pack is available to all capabilities)'}
                    maxShown={10}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="flex-auto col-6">
            <div className="flex flex-column gap-4">{groupedTagList}</div>
          </div>
        </div>
      </div>
    </>
  )
}

export default QuestionPackDetails

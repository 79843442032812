import {
  EditMode,
  useUrlCopyToClipboard,
  useEdit,
  useToogleFavorite,
} from '../../common/dispatcherButtonHooks'
import BackButton from '../BackButton'
import { useDeleteModal } from '../../common/modalHooks'

interface Props {
  rfi: Rfi
  editMode: EditMode
}

const RfiToolbar = ({ rfi, editMode }: Props): JSX.Element => {
  const toggleFavorite = useToogleFavorite('RFI', rfi)
  const copyToClipboard = useUrlCopyToClipboard('RFI', rfi)
  const deleteRfi = useDeleteModal('RFI', rfi, '/rfi/')
  const editRfi = useEdit('RFI', editMode)

  const actions = editMode.enabled ? (
    <>
      {editRfi.cancelDispatchterButton}
      {editRfi.submitDispatcherButton}
    </>
  ) : (
    <>
      {toggleFavorite.dispatcherButton}
      {copyToClipboard.dispatcherButton}
      {editRfi.editDispatcherButton}
      {deleteRfi.dispatcherButton}
    </>
  )

  return (
    <>
      {editMode.enabled ? (
        <div className="flex justify-content-end">
          <div>
            <div>{actions}</div>
          </div>
        </div>
      ) : (
        <div className="flex justify-content-between">
          <div className="flex-none ">
            <BackButton />
          </div>
          <div>{actions}</div>
        </div>
      )}
    </>
  )
}

export default RfiToolbar

import { Button, ButtonProps } from 'primereact/button'
import { IconType } from 'primereact/utils'
import { useAppDispatch } from '../common/hooks'
import { AppDispatch } from '../redux/store'

interface ToogleProps {
  icon: IconType<ButtonProps>
  isToggled: (data: any) => boolean
}

export enum DispatcherButtonPresets {
  PRIMARY_ICON_ACTIONS = 'p-button-rounded p-button-text p-button-primary p-button-sm',
  OUTLINED_PRIMARY = 'p-button-rounded p-button-outlined p-button-primary p-button-sm',
  OUTLINED_WHITE = 'p-button-rounded p-button-outlined p-button-sm text-white',
  TERTIARY_ACTIONS = 'p-button-primary p-button-sm p-button-rounded h-2rem',
  FILLED_PRIMARY = 'p-button-primary p-button-sm',
  FILLED_ROUNDED_PRIMARY = 'p-button-primary p-button-rounded p-button-sm',
}

export interface DispatcherButtonProps {
  buttonProps: Omit<ButtonProps, 'className'>
  className?: DispatcherButtonPresets | string
  onClick?: (dispatch: AppDispatch, data: any) => void
  toggleProps?: ToogleProps
  visible?: (data: any) => boolean
}

const DispatcherButton = ({
  buttonProps,
  className = DispatcherButtonPresets.PRIMARY_ICON_ACTIONS,
  onClick,
  toggleProps,
  data,
  visible,
}: DispatcherButtonProps & { data?: any }): JSX.Element => {
  let icon = buttonProps.icon
  if (onClick !== undefined) {
    const dispatch = useAppDispatch()
    buttonProps.onClick = () => {
      onClick(dispatch, data)
    }
  }

  if (toggleProps !== undefined) {
    icon = toggleProps.isToggled(data) ? toggleProps.icon : icon
  }

  return (
    <>
      <Button
        className={className}
        tooltipOptions={{
          position: 'bottom',
          className: 'text-sm',
        }}
        {...buttonProps}
        icon={icon}
        visible={visible !== undefined ? visible(data) : true}
      />
    </>
  )
}

export default DispatcherButton

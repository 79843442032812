import { useEffect, useRef } from 'react'
import { Toast } from 'primereact/toast'
import { useAppDispatch, useAppSelector } from '../common/hooks'
import { AppError, setToastMessage } from '../redux/app'

interface Props {
  toastAppErrors: boolean
}

const ToastProvider = ({ toastAppErrors }: Props): JSX.Element => {
  const toastRef = useRef<Toast>(null)
  const toast = useAppSelector((state) => state.app.toast)
  const dispatch = useAppDispatch()

  if (toastAppErrors) {
    const applicationError = useAppSelector((state) => state.app.error)
    useEffect(() => {
      if (applicationError !== AppError.NONE) {
        dispatch(
          setToastMessage({
            severity: 'error',
            summary: 'Application Error',
            detail: `Code: ${AppError[applicationError]}`,
          }),
        )
      }
    }, [applicationError])
  }

  useEffect(() => {
    if (toast !== null) {
      toastRef.current!.show(toast)
    }
    return () => {
      dispatch(setToastMessage(null))
    }
  }, [toast])

  return <Toast ref={toastRef} position="top-center" />
}

export default ToastProvider

import React from 'react'
import { TabPanel, TabView } from 'primereact/tabview'
import ProductTabProductRfis from './ProductTabProductRfis'

interface Props {
  product: Product
}

const ProductTabs = ({ product }: Props): JSX.Element => {
  return (
    <TabView>
      <TabPanel header="Product RFIs">
        <ProductTabProductRfis productId={product.id} />
      </TabPanel>
    </TabView>
  )
}

export default ProductTabs

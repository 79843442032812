import { classNames } from 'primereact/utils'

interface Props {
  tooltip: string
  label?: React.ReactNode
  flexDirection?: 'row' | 'column'
}

const InfoTooltip = ({ tooltip, label, flexDirection = 'row' }: Props): JSX.Element => {
  return (
    <div
      className={classNames(
        `flex ${flexDirection == 'row' ? 'align-items-center' : 'align-items-start'}`,
        `flex-${flexDirection}`,
        'line-height-1',
      )}
    >
      {label}
      <div className={flexDirection == 'row' ? 'ml-2' : 'ml-0'}>
        <i
          className="infoTooltip pi pi-info-circle p-text-secondary p-overlay-badge vertical-align-middle"
          data-pr-tooltip={tooltip}
          data-pr-position="right"
          data-pr-at="right+15 center"
          data-pr-my="left center"
          style={{ cursor: 'pointer' }}
        ></i>
      </div>
    </div>
  )
}

export default InfoTooltip

import { Card } from 'primereact/card'
import { Toolbar } from 'primereact/toolbar'
import styles from '../../styles/list.module.scss'
import DataTableProvider, { DataTablePropsPresets } from '../DataTable/DataTableProvider'
import { actionsColumn, simpleColumn, tagListColumn } from '../DataTable/columnProvider'
import { useCreateModal, useDeleteModal, useEditModal } from '../../common/modalHooks'
import { useDataTableFilter } from '../../common/hooks'

interface Props {
  toolbarLeft?: JSX.Element
  hiddenColumns?: number[]
  isLoading?: boolean
  data?: Contact[]
  showFilter?: boolean
  company?: Company
}

const ContactList = ({
  toolbarLeft = <h1>Contacts</h1>,
  hiddenColumns = [],
  isLoading = true,
  data,
  showFilter = false,
  company,
}: Props): JSX.Element => {
  const createContactModal = useCreateModal('CONTACT', company!, 'Add Contact')
  const deleteContactModal = useDeleteModal('CONTACT')
  const editContactModal = useEditModal('CONTACT')
  const columns = [
    simpleColumn({
      columnProps: {
        header: 'Name',
        field: 'lastName',
        sortable: true,
        className: styles['first-column'],
        body: (contact: Contact) => {
          return (
            <>
              {contact.firstName} {!!contact.middleNames ? `${contact.middleNames} ` : ''}{' '}
              {contact.lastName}
            </>
          )
        },
      },
    }),
    simpleColumn({
      columnProps: {
        header: 'Job Title',
        field: 'jobTitle',
        sortable: true,
      },
    }),
    simpleColumn({
      columnProps: {
        header: 'Email',
        field: 'email',
        sortable: true,
        className: styles['email-column'],
        body: (contact: Contact) => {
          return <a href={`mailto:${contact.email}`}>{contact.email}</a>
        },
      },
    }),
    tagListColumn({
      columnProps: {
        header: 'Markets',
        className: styles['column'],
        style: { width: '30%' },
      },
      rowDataSelector: (contact: Contact) => [
        contact.geos.filter((geo) => geo.type === null),
        contact.geos.filter((geo) => geo.type !== null),
      ],
    }),
    actionsColumn({
      columnProps: {
        align: 'right',
        alignHeader: 'center',
        className: styles['action-column'],
      },
      dispatcherButtonsProps: [
        ...editContactModal.dispatcherButtonProps,
        ...deleteContactModal.dispatcherButtonProps,
      ],
    }),
  ].filter((column, index) => !hiddenColumns.includes(index))

  let dataTableProps = DataTablePropsPresets.listPage(data, 'lastName', 1)

  if (showFilter) {
    data = data?.map((contact) => {
      return {
        ...contact,
        ...{
          marketsJoin: contact.geos.map((geo) => geo.name).join(' '),
        },
      }
    })
    dataTableProps = {
      ...DataTablePropsPresets.listPage(data, 'lastName', 1),
      ...useDataTableFilter(['firstName', 'lastName', 'jobTitle', 'email', 'marketsJoin']),
    }
  }

  return (
    <div className={styles.list}>
      {createContactModal.modal}
      {editContactModal.modal}
      <Toolbar left={toolbarLeft} right={createContactModal.dispatcherButton} />
      <Card>
        <DataTableProvider
          dataTableProps={dataTableProps}
          columns={columns}
          isLoading={isLoading}
        />
      </Card>
    </div>
  )
}

export default ContactList

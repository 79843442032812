import { QuestionAnswerSection } from '../../common/dataHooks'
import AnchorLink from '../AnchorLink'

interface Props {
  data: QuestionAnswerSection[]
}

const QuestionAnswerToc = ({ data }: Props): JSX.Element => {
  return (
    <nav className="ve-table-of-contents" aria-label="Sections">
      <ul>
        <li>
          <h1 className="mb-5">Sections</h1>
        </li>
        {data.map((questionAnswerSection) => (
          <li key={questionAnswerSection.ref} className="mb-4">
            <h2>{questionAnswerSection.name}</h2>
            {questionAnswerSection.questionPacks.length > 0 && (
              <ul>
                {questionAnswerSection.questionPacks.map((questionPack) => (
                  <li key={questionPack.ref} className="ml-3">
                    <AnchorLink href={`#${questionPack.ref}`}>
                      <h3>{questionPack.displayName}</h3>
                    </AnchorLink>
                  </li>
                ))}
              </ul>
            )}
          </li>
        ))}
      </ul>
    </nav>
  )
}

export default QuestionAnswerToc

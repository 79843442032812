import { FilterMatchMode } from 'primereact/api'
import { Button } from 'primereact/button'
import { DataTablePFSEvent, DataTableProps } from 'primereact/datatable'
import { InputText } from 'primereact/inputtext'
import { TreeTableProps } from 'primereact/treetable'
import { ChangeEvent, useEffect, useMemo, useState } from 'react'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import TagList, { NORMALIZE_PRESETS } from '../components/TagList'
import type { RootState, AppDispatch } from '../redux/store'
import { groupBy, validateWorkspacePermission } from './utils'
import { ListDataMeta, PaginationParams } from '../redux/api'

export const useAppDispatch = () => useDispatch<AppDispatch>()

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

export const usePermission = (resourceTypeRef: string, operationRef: string): boolean => {
  const isValid = useMemo(
    () => validateWorkspacePermission(resourceTypeRef, operationRef),
    [resourceTypeRef, operationRef],
  )
  return isValid
}

export const useInput = (
  prefilledSearchValue?: string,
): [string, (e: React.ChangeEvent<HTMLInputElement>) => void, () => void] => {
  const [value, setValue] = useState(prefilledSearchValue || '')
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => setValue(e.target.value)
  const clearValue = () => setValue('')
  return [value, onChange, clearValue]
}

export const useDebounce = (value: string, delay?: number): string => {
  const [debouncedValue, setDebouncedValue] = useState(value)
  useEffect(() => {
    const timer = setTimeout(() => setDebouncedValue(value), value === '' ? 0 : delay || 1000)
    return () => clearTimeout(timer)
  }, [value])
  return debouncedValue
}

export const useDataTableLazy = (
  paginationParams: PaginationParams,
  setPaginationParams: React.Dispatch<React.SetStateAction<PaginationParams>>,
  listMeta?: ListDataMeta,
): DataTableProps => {
  const [first, setFirst] = useState(0)
  const onLazy = (event: DataTablePFSEvent) => {
    setFirst(event.first!)
    setPaginationParams((prev) => ({
      ...prev,
      page: event.first! / event.rows! + 1,
      sort: !!event.sortField
        ? event.sortOrder === -1
          ? `-${event.sortField}`
          : event.sortField
        : undefined,
    }))
  }
  return {
    lazy: true,
    sortField: !!paginationParams.sort ? paginationParams.sort.replace('-', '') : undefined,
    sortOrder: !!paginationParams.sort
      ? paginationParams.sort.startsWith('-')
        ? -1
        : 1
      : undefined,
    first: first,
    rows: paginationParams.size || 10,
    totalRecords: listMeta?.totalSize,
    onSort: onLazy,
    onPage: onLazy,
  }
}

export const useDataTableFilter = (globalFilterFields: string[]): DataTableProps => {
  const [filters, setFilters] = useState<any>(null)
  const [globalFilterValue, setGlobalFilterValue] = useState('')
  const initFilters = () => {
    setFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    })
    setGlobalFilterValue('')
  }
  const clearFilter = () => {
    initFilters()
  }
  const onGlobalFilterChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    let _filters = { ...filters }
    _filters['global'].value = value
    setFilters(_filters)
    setGlobalFilterValue(value)
  }
  const header = (
    <div className="col-4">
      <div className="p-inputgroup">
        <span className="p-inputgroup-addon">
          <i className="pi pi-filter" />
        </span>
        <InputText value={globalFilterValue} onChange={onGlobalFilterChange} />
        <Button icon="pi pi-times-circle" className="p-button-primary" onClick={clearFilter} />
      </div>
    </div>
  )

  useEffect(() => {
    initFilters()
  }, [])

  return { header, filters, globalFilterFields }
}

export const useTreeTableFilter = (): TreeTableProps => {
  const [globalFilter, setGlobalFilter] = useState<string | null>(null)
  const clearFilter = () => {
    setGlobalFilter(null)
  }
  const onGlobalFilterChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    setGlobalFilter(value)
  }
  const header = (
    <div className="col-4">
      <div className="p-inputgroup">
        <span className="p-inputgroup-addon">
          <i className="pi pi-filter" />
        </span>
        <InputText value={globalFilter || ''} onChange={onGlobalFilterChange} />
        <Button icon="pi pi-times-circle" className="p-button-primary" onClick={clearFilter} />
      </div>
    </div>
  )

  return { header, globalFilter }
}

// Grouped Tag List ...

const defaultGroupedTagListTemplate = (key: string, label: string, list: JSX.Element) => {
  return (
    <div key={key}>
      <span className="font-medium">{label}: </span>
      {list}
    </div>
  )
}

export const useGroupedTagList = (
  tags?: Tag[],
  template: (
    key: string,
    label: string,
    list: JSX.Element,
  ) => JSX.Element = defaultGroupedTagListTemplate,
  prefix: string = '',
): JSX.Element => {
  if (tags === undefined || tags.length === 0) {
    const label = `${prefix} Tags`.trim()
    return template('tags_none', label, <span>(none)</span>)
  }

  const groupedTags = groupBy(tags || [], (t) => t.tagType?.name || '')
  const sortedGroupedTags = Object.entries(groupedTags).sort(([keyA, tagsA], [keyB, tagsB]) => {
    const posA = tagsA[0].tagType ? tagsA[0].tagType.pos : -1
    const posB = tagsB[0].tagType ? tagsB[0].tagType.pos : -1
    return posA - posB
  })

  return (
    <>
      {sortedGroupedTags.map(([group, tags]) => {
        const label = `${prefix} ${group} Tags`.trim()
        return template(label, label, <TagList items={tags} normalize={NORMALIZE_PRESETS.POS} />)
      })}
    </>
  )
}

// ... Grouped Tag List

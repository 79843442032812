import { useNavigate } from 'react-router-dom'
import RfiCreateContent from '../../components/RFI/RfiCreateContent'
import RfiCreateToolbar from '../../components/RFI/RfiCreateToolbar'

const RfiCreatePage = (): JSX.Element => {
  const navigate = useNavigate()
  return (
    <div className="px-4 py-5 md:px-6 lg:px-8">
      <RfiCreateToolbar />
      <RfiCreateContent onCreated={(rfi: Rfi) => navigate(`/rfi/${rfi.id}`)} />
    </div>
  )
}

export default RfiCreatePage

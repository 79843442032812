import { getDimensionLabel } from '../../common/utils'

interface Props {
  question: Question
  questionConstraint: ConstrainedQuestion
}

const styles = {
  noPaddingList: {
    paddingLeft: 0,
    listStylePosition: 'outside' as const,
    listStyleType: 'none' as const,
  },
  marginBottomLi: {
    marginBottom: '5px',
  },
}

const QuestionOutputSingleLineText = ({ question, questionConstraint }: Props): JSX.Element => {
  const renderDimensionText = (answerData: AnswerData) => {
    if (!answerData.data || !answerData.data.value) return null

    const dimensionLabel = getDimensionLabel(
      questionConstraint.dimensions,
      questionConstraint.labels,
      answerData.dimensionIndex,
      answerData.dimensionValue,
    )

    return (
      <li
        style={styles.marginBottomLi}
        key={`dim-${answerData.dimensionIndex}${answerData.dimensionValue}`}
      >
        <strong>{dimensionLabel}</strong>
        {answerData.data.value}
      </li>
    )
  }

  return (
    <div>
      <ul style={styles.noPaddingList}>{question.answer?.data.map(renderDimensionText)}</ul>
    </div>
  )
}

export default QuestionOutputSingleLineText

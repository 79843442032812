import { useNavigate } from 'react-router-dom'
import DispatcherButton, { DispatcherButtonPresets } from './DispatcherButton'

const BackButton = (): JSX.Element => {
  const navigate = useNavigate()

  const buttonProps = {
    icon: 'pi pi-arrow-left',
    label: 'Back',
  }

  return (
    <DispatcherButton
      className={DispatcherButtonPresets.OUTLINED_PRIMARY}
      buttonProps={buttonProps}
      onClick={() => navigate(-1)}
    />
  )
}

export default BackButton

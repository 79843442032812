import { Card } from 'primereact/card'
import { Toolbar } from 'primereact/toolbar'
import styles from '../../styles/list.module.scss'
import DataTableProvider from '../DataTable/DataTableProvider'
import { actionsColumn, simpleColumn } from '../DataTable/columnProvider'
import { useDataTableFilter } from '../../common/hooks'
import { DataTableProps } from 'primereact/datatable'
import { Tag } from 'primereact/tag'
import { useEditModal } from '../../common/modalHooks'

interface Props {
  toolbarLeft?: JSX.Element
  hiddenColumns?: number[]
  isLoading?: boolean
  data?: Question[]
  showFilter?: boolean
}

const QuestionList = ({
  toolbarLeft = <h1>Contacts</h1>,
  hiddenColumns = [],
  isLoading = true,
  data,
  showFilter = false,
}: Props): JSX.Element => {
  //const deleteQuestion = useDeleteModal('QUESTION')
  const editQuestion = useEditModal('QUESTION')

  const columns = [
    simpleColumn({
      columnProps: {
        style: { width: '16px' },
        rowReorder: true,
      },
    }),
    simpleColumn({
      columnProps: {
        field: 'phrase',
        style: { width: '100%' },
        body: (question: Question) => {
          return (
            <div className="flex flex-wrap">
              <div className="flex align-items-center justify-content-center m-2">
                {question.phrase}
              </div>
              <div className="flex align-items-center justify-content-center m-2">
                <div className="tooltip-wrapper">
                  <Tag
                    value={question.type.toLowerCase().replace(/_/g, ' ') || '(none)'}
                    className={'bg-bluegray-500'}
                  />
                </div>
              </div>
            </div>
          )
        },
      },
    }),
    actionsColumn({
      columnProps: {
        align: 'right',
        className: styles['action-column'],
      },
      dispatcherButtonsProps: [...editQuestion.dispatcherButtonProps],
    }),
  ].filter((column, index) => !hiddenColumns.includes(index))

  let dataTableProps: DataTableProps = {
    value: data,
    reorderableRows: true,
    paginator: false,
    size: 'small',
  }

  if (showFilter) {
    dataTableProps = {
      ...dataTableProps,
      ...useDataTableFilter(['phrase', 'description', 'type']),
    }
  }

  return (
    <div className={styles.list}>
      {editQuestion.modal}
      <Toolbar left={toolbarLeft} />
      <Card>
        <DataTableProvider
          dataTableProps={dataTableProps}
          columns={columns}
          isLoading={isLoading}
        />
      </Card>
    </div>
  )
}

export default QuestionList

import React from 'react'
import { TabPanel, TabView } from 'primereact/tabview'
import ParticipantTabQuestionAnswers from './ParticipantTabQuestionAnswers'

interface Props {
  participant: Participant
}

const ParticipantTabs = ({ participant }: Props): JSX.Element => {
  return (
    <TabView>
      <TabPanel header="Question & Answers">
        <ParticipantTabQuestionAnswers participant={participant} />
      </TabPanel>
    </TabView>
  )
}

export default ParticipantTabs

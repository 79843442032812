import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ToastMessage } from 'primereact/toast'
import { GenericModalProps } from '../components/GenericModal'

export enum AppError {
  NONE,
  OKTA_LOGIN,
  WORKSPACE_REQUEST,
  WORKSPACE_JOIN_REQUEST,
  WORKSPACE_DETERMINATION,
  PERMISSIONS_REQUEST,
  FAVORITE_TOGGLE_REQUEST,
  FAVORITES_REQUEST,
  PRODUCT_API_REQUEST,
  GEO_API_REQUEST,
  COMPANY_API_REQUEST,
  CONTACT_API_REQUEST,
  RFI_API_REQUEST,
  QUESTIONPACK_API_REQUEST,
}

type AppState = {
  locale: string
  isAuthenticated: boolean
  error: AppError
  permissions: Permission[]
  workspace: Workspace | null
  token?: string | null
  username?: string | null
  activeTabIndex: number
  toast: ToastMessage | null
  modalContent: GenericModalProps | null
  showModal: boolean
  needsReload: boolean
}

const initialState: AppState = {
  locale: 'en-US',
  isAuthenticated: false,
  error: AppError.NONE,
  permissions: [],
  workspace: null,
  token: null,
  username: null,
  activeTabIndex: 0,
  toast: null,
  modalContent: null,
  showModal: false,
  needsReload: false,
}

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setNeedsReload: (state) => {
      state.needsReload = true
    },
    setLocale: (state, action: PayloadAction<string>) => {
      state.locale = action.payload
    },
    setAuthenticated: (state, action: PayloadAction<boolean>) => {
      state.isAuthenticated = action.payload
    },
    setToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload
    },
    setUsername: (state, action: PayloadAction<string>) => {
      state.username = action.payload
    },
    setWorkspace: (state, action: PayloadAction<Workspace>) => {
      state.workspace = action.payload
    },
    setPermissions: (state, action: PayloadAction<Permission[]>) => {
      state.permissions = action.payload
    },
    setError: (state, action: PayloadAction<AppError>) => {
      state.error = action.payload
    },
    setActiveTabIndex: (state, action: PayloadAction<number>) => {
      state.activeTabIndex = action.payload
    },
    setToastMessage: (state, action: PayloadAction<ToastMessage | null>) => {
      state.toast = action.payload
    },
    setShowModal: (state, action: PayloadAction<boolean>) => {
      state.showModal = action.payload
    },
  },
})

export const {
  setLocale,
  setAuthenticated,
  setToken,
  setUsername,
  setWorkspace,
  setPermissions,
  setError,
  setActiveTabIndex,
  setToastMessage,
  setShowModal,
  setNeedsReload,
} = appSlice.actions

export default appSlice.reducer

import React from 'react'
import ParticipantList from '../Participant/ParticipantList'
import { RfiType } from '../../enums'
import { useAppSelector } from '../../common/hooks'
import { useParticipantListByCompanyAndRfiTypeQuery } from '../../redux/api'

interface Props {
  companyId: number
}

const CompanyTabCompanyRfis = ({ companyId }: Props): JSX.Element => {
  const workspaceId = useAppSelector((state) => state.app.workspace!.id)
  const { data, isFetching } = useParticipantListByCompanyAndRfiTypeQuery({
    workspaceId,
    companyId,
    rfiType: RfiType.COMPANY,
  })

  return (
    <ParticipantList
      toolbarLeft={<h1 className="text-2xl">Company RFIs</h1>}
      isLoading={isFetching}
      data={data}
      showFilter={true}
      type="COMPANY"
    />
  )
}

export default CompanyTabCompanyRfis

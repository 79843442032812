import React from 'react'
import { useQuestionByRfiTreeTableData } from '../../common/dataHooks'
import QuestionPackTree from '../QuestionPack/QuestionPackTree'

interface Props {
  rfi: Rfi
}

const RfiTabQuestionPackQuestions = ({ rfi }: Props): JSX.Element => {
  const { data, isLoading } = useQuestionByRfiTreeTableData(rfi)

  return (
    <QuestionPackTree
      toolbarLeft={<h1 className="text-2xl">Question Packs & Questions</h1>}
      data={data}
      isLoading={isLoading}
      showFilter={true}
    />
  )
}

export default RfiTabQuestionPackQuestions

import {
  useAppSelector,
  useDataTableLazy,
  useDebounce,
  useInput,
  usePermission,
} from '../../common/hooks'
import { prepareSearchValueSpaceJoin } from '../../common/utils'
import {
  PaginationParams,
  useCompanyListBySearchByStrQuery,
  useCompanyListWithPaginationQuery,
} from '../../redux/api'
import { AppError, setError } from '../../redux/app'
import CompanyList from '../../components/Company/CompanyList'
import SearchBar from '../../components/SearchBar'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { DataTablePropsPresets } from '../../components/DataTable/DataTableProvider'

const CompanyListPage = (): JSX.Element => {
  const navigate = useNavigate()
  const currentWorkSpace = useAppSelector((state) => state.app.workspace!.id)

  // debounced search
  const hasSearchPermission = usePermission('company', 'search')
  const [searchStr] = useSearchParams()
  const [searchValue, onSearchInputChange, clearSearchInput] = useInput(
    searchStr.get('searchStr') || '',
  )
  const debouncedSearchValue = useDebounce(searchValue, 500)
  useEffect(() => {
    searchValue ? navigate(`/company?searchStr=${searchValue}`) : navigate(`/company`)
  }, [debouncedSearchValue])

  // lazy pagination
  const [paginationParams, setPaginationParams] = useState<PaginationParams>({
    page: 1,
    size: 10,
    sort: 'name',
  })

  const { data, error, isLoading, isFetching } = !!debouncedSearchValue
    ? useCompanyListBySearchByStrQuery({
        workspaceId: currentWorkSpace,
        words: prepareSearchValueSpaceJoin(debouncedSearchValue),
      })
    : useCompanyListWithPaginationQuery({
        workspaceId: currentWorkSpace,
        paginationParams: {
          page: paginationParams.page,
          size: paginationParams.size,
          sort: paginationParams.sort,
        },
      })

  const dataTableProps = DataTablePropsPresets.listPage(data?.content)

  // lazy pagination
  const dataTablePropsLazy = {
    ...dataTableProps,
    ...useDataTableLazy(paginationParams, setPaginationParams, data?.meta),
  }

  if (error) {
    setError(AppError.COMPANY_API_REQUEST)
  }

  return (
    <div className="px-4 py-5 md:px-6 lg:px-8">
      {hasSearchPermission ? (
        <SearchBar
          searchValue={searchValue}
          onSearchInputChange={onSearchInputChange}
          clearSearchInput={clearSearchInput}
          isFetching={isFetching}
        />
      ) : (
        ''
      )}
      <CompanyList
        toolbarLeft={
          <h1>
            {!!debouncedSearchValue ? `Companies with "${debouncedSearchValue}"` : 'Companies'}
          </h1>
        }
        data={data?.content}
        isLoading={isLoading || isFetching}
        dataTableProps={!!debouncedSearchValue ? dataTableProps : dataTablePropsLazy}
      />
    </div>
  )
}

export default CompanyListPage

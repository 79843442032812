import { Card } from 'primereact/card'
import { Skeleton } from 'primereact/skeleton'
import { classNames } from 'primereact/utils'
import { useNavigate } from 'react-router-dom'
import { useToogleFavorite } from '../../common/dispatcherButtonHooks'
import { useAppSelector } from '../../common/hooks'
import { useCompanyQuery } from '../../redux/api'
import styles from '../../styles/favorites.module.scss'
import DispatcherButton, { DispatcherButtonPresets } from '../DispatcherButton'
import FavoriteCardSkeleton from '../Favorite/FavoriteCardSkeleton'
import TagList, { NORMALIZE_PRESETS } from '../TagList'

interface Props {
  data: Favorite
  customClasses?: string
  customStyles?: React.CSSProperties
}

const CompanyFavoriteCard = ({ data, customClasses = '', customStyles }: Props): JSX.Element => {
  const workspaceId = useAppSelector((state) => state.app.workspace!.id)
  const {
    data: companyData,
    isLoading,
    isFetching,
  } = useCompanyQuery({
    workspaceId: workspaceId,
    companyId: data._id,
  })
  const capabilities: Capability[] = []
  companyData?.products.forEach((product) => {
    capabilities.push(...product.capabilities)
  })

  const navigate = useNavigate()
  const toggleFavorite = useToogleFavorite('COMPANY', companyData)
  const header = (
    <>
      {toggleFavorite.dispatcherButton}
      <DispatcherButton
        buttonProps={{
          icon: 'pi pi-external-link',
          tooltip: 'Open',
        }}
        onClick={() => navigate(`/company/${companyData?.id}`)}
      />
    </>
  )

  if (isLoading && isFetching) return <FavoriteCardSkeleton customClasses="col-12 md:col-4" />

  return (
    <Card
      header={header}
      className={classNames(styles.favorites, customClasses)}
      title="Company"
      subTitle={companyData?.name}
      style={customStyles}
    >
      <div className="flex flex-column lg:flex-row column-gap-4">
        <div className="font-medium col-12 md:col-3 pl-0">Capabilities:</div>
        <div className="col pl-0">
          <TagList items={capabilities} normalize={NORMALIZE_PRESETS.POS} emptyVal={'(none)'} />
        </div>
      </div>
    </Card>
  )
}

export default CompanyFavoriteCard

import { forwardRef } from 'react'
import { DataView, DataViewProps, DataViewSortOrderType } from 'primereact/dataview'

interface Presets {
  standardGridPage: (
    value?: any[],
    sortField?: string,
    sortOrder?: DataViewSortOrderType,
  ) => DataViewProps
}

export const DataViewPropsPresets: Presets = {
  standardGridPage: (value, sortField = 'name', sortOrder = 1) => {
    return {
      value: value,
      layout: 'grid',
      paginator: true,
      rows: 12,
      paginatorTemplate: 'FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink',
      currentPageReportTemplate: 'Page {currentPage} of {totalPages}',
      alwaysShowPaginator: false,
      gutter: true,
      sortField: sortField,
      sortOrder: sortOrder,
    }
  },
}

interface Props {
  isLoading?: boolean
  dataViewProps?: DataViewProps
}

const DataViewProvider = forwardRef<DataView, Props>(
  ({ isLoading = false, dataViewProps = {} }: Props, ref: React.ForwardedRef<DataView>) => {
    if (isLoading) {
      dataViewProps.value = [{}]
    }
    return <DataView ref={ref} {...dataViewProps}></DataView>
  },
)

export default DataViewProvider

import { Tag } from 'primereact/tag'
import { Card } from 'primereact/card'
import { ProgressBar } from 'primereact/progressbar'
import { classNames } from 'primereact/utils'
import { useNavigate } from 'react-router-dom'
import { useToogleFavorite } from '../../common/dispatcherButtonHooks'
import { useAppSelector } from '../../common/hooks'
import { RfiStatus } from '../../enums'
import { useParticipantQuery } from '../../redux/api'
import styles from '../../styles/favorites.module.scss'
import DispatcherButton from '../DispatcherButton'
import FavoriteCardSkeleton from '../Favorite/FavoriteCardSkeleton'
import TagList, { NORMALIZE_PRESETS } from '../TagList'
import ProgressTag from '../ProgressTag'

interface Props {
  data: Favorite
  customClasses?: string
  customStyles?: React.CSSProperties
}

const ParticipantFavoriteCard = ({
  data,
  customClasses = '',
  customStyles,
}: Props): JSX.Element => {
  const workspaceId = useAppSelector((state) => state.app.workspace!.id)
  const {
    data: participantData,
    isLoading,
    isFetching,
  } = useParticipantQuery({
    workspaceId: workspaceId,
    itemId: data._id,
  })

  const getStatus =
    participantData?.status === RfiStatus.IN_PROGRESS ? (
      <ProgressTag status={participantData?.status} progress={participantData?.progress} />
    ) : (
      <Tag
        className="bg-primary-reverse border-1"
        value={String(participantData?.status).replace(/_/g, ' ')}
      ></Tag>
    )

  const navigate = useNavigate()
  const toggleFavorite = useToogleFavorite('PARTICIPANT', participantData)
  const header = (
    <>
      {toggleFavorite.dispatcherButton}
      <DispatcherButton
        buttonProps={{
          icon: 'pi pi-external-link',
          tooltip: 'Open',
        }}
        onClick={() => navigate(`/participant/${participantData?.id}`)}
      />
    </>
  )

  const headlineSubline =
    participantData?.rfi.type === 'COMPANY' ? (
      <>
        answered by <strong>{participantData?.company.name}</strong>
      </>
    ) : (
      <>
        answered by <strong>{participantData?.company.name}</strong> for{' '}
        <strong>{participantData?.product?.name}</strong>
      </>
    )

  if (isLoading && isFetching) return <FavoriteCardSkeleton customClasses="col-12 md:col-4" />

  return (
    <Card
      header={header}
      className={classNames(styles.favorites, customClasses)}
      title="Participant"
      subTitle={participantData?.rfi.name}
      style={customStyles}
    >
      <p>{headlineSubline}</p>

      <div className="flex flex-column lg:flex-row column-gap-4">
        <div className="font-medium col-12 md:col-3 pl-0">Markets:</div>
        <div className="col pl-0">
          <div className="tooltip-wrapper">
            <Tag className="bg-bluegray-500" value={participantData?.rfi.geo.name} />
          </div>
        </div>
      </div>
      <div className="flex flex-column lg:flex-row column-gap-4">
        <div className="font-medium col-12 md:col-3 pl-0">Capabilities:</div>
        <div className="col pl-0">
          <TagList
            items={participantData?.rfi.capabilities || []}
            normalize={NORMALIZE_PRESETS.POS}
            emptyVal={'(none)'}
          />
        </div>
      </div>
      <div className="flex flex-column lg:flex-row column-gap-4">
        <div className="font-medium col-12 md:col-3 pl-0">Status:</div>
        <div className="col pl-0">{getStatus}</div>
      </div>
    </Card>
  )
}

export default ParticipantFavoriteCard

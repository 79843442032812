import store from '../redux/store'
import { jwtDecode } from 'jwt-decode'

export const findWorkspace = (
  workspaces: Workspace[],
  identifier: string = process.env.WORKSPACE_REF!,
): Workspace | null => {
  for (const workspace of workspaces) {
    if (workspace.ref === identifier) return workspace

    if (workspace.children?.length) {
      const found = findWorkspace(workspace.children, identifier)
      if (found) return found
    }
  }
  return null
}

export const getNameFromIdToken = (idToken?: string): string => {
  if (idToken === undefined) {
    return ''
  }
  const decoded = jwtDecode(idToken)
  const decodedObject = JSON.parse(JSON.stringify(decoded))
  return decodedObject.name || ''
}

export const validateWorkspacePermission = (
  resourceTypeRef: string,
  operationRef: string,
  appRef: string = process.env.WORKSPACE_REF!,
): boolean => {
  const permissions = store.getState().app.permissions
  return (
    permissions.filter(
      (permission: Permission) =>
        (permission.appExpr === '*' || permission.appExpr === appRef) &&
        (permission.resourceTypeExpr === '*' || permission.resourceTypeExpr === resourceTypeRef) &&
        (permission.resourceTypeOperationExpr === '*' ||
          permission.resourceTypeOperationExpr === operationRef),
    ).length > 0
  )
}

export const sort = (unsorted: Record<string, any>[], key: string = 'sort') => {
  return [...unsorted].sort((a, b) =>
    (typeof a[key] === 'string' ? a[key].toLowerCase() : a[key]) >
    (typeof b[key] === 'string' ? b[key].toLowerCase() : b[key])
      ? 1
      : -1,
  )
}

export const filterDistinct = (unfiltered: Record<string, any>[], key: string = 'key') => {
  return [...unfiltered].filter(
    (value, index, self) =>
      self.findIndex((v: Record<string, any>) => v[key] === value[key]) === index,
  )
}

export const groupBy = <T>(
  array: T[],
  predicate: (value: T, index: number, array: T[]) => string,
) =>
  array.reduce(
    (acc, value, index, array) => {
      ;(acc[predicate(value, index, array)] ||= []).push(value)
      return acc
    },
    {} as { [key: string]: T[] },
  )

/**
 * Strips multiple search terms of spaces, trims converts to lowercase and then joins for search
 *
 * @param inputValue
 * @returns
 */
export const prepareSearchValueSpaceJoin = (inputValue: string) => {
  let cleanInputValue = inputValue.toLowerCase().split(' ')
  cleanInputValue = cleanInputValue.filter((entry) => /\S/.test(entry))
  return cleanInputValue.join(' ')
}

export const prepareSearchValueCommaJoined = (inputValue: string) => {
  let cleanInputValue = inputValue.toLowerCase().split(' ')
  cleanInputValue = cleanInputValue.filter((entry) => /\S/.test(entry))
  return cleanInputValue.join(',')
}

export let sortByCreatedAtDesc = (a: Favorite, b: Favorite) => {
  return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
}

export const formDataReduce = (
  items: {
    id: number
  }[],
) => {
  return items.map((item) => {
    return { id: item.id }
  })
}

export const getDimensionLabel = (
  dimensions: number,
  labels?: string[] | [string, string][] | null,
  dimensionIndex?: number,
  dimensionValue?: string,
): string => {
  if (dimensions > 1) {
    if (labels && Array.isArray(labels[0])) {
      const label = labels.find((label) => label[1] === dimensionValue)
      if (label) {
        return `${label[0]}: `
      }
    } else if (labels && dimensionIndex !== undefined && labels[dimensionIndex] !== undefined) {
      return `${labels[dimensionIndex]}: `
    } else if (dimensionIndex !== undefined) {
      return `${dimensionIndex + 1}. `
    }
  }
  return ''
}

export const convertToLowerCaseAndCapitalize = (str: string) => {
  str = str.toLowerCase()
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export const extractRefs = (expr: string) => {
  // matches answer array refs and any function refs like in "answers['first_ref'].values.some(option => option.value == '3rd party') && someFunction('second_ref') === false" => ['first_ref', 'second_ref']
  const regex =
      /(answers(\.|\s*\[[\s'"]+)|[a-zA-Z_][a-zA-Z0-9\-_]+\s*\(\s*['"])([a-zA-Z_][a-zA-Z0-9\-_]+)/g,
    refs: string[] = []

  let matches
  while ((matches = regex.exec(expr))) refs.push(matches[3])

  return refs
}

// Used to evaluate expressions
export const constraintEval = (
  expr: string,
  // We need to pass the answers and questions to the eval function
  answers: { [key: string]: { dimensionIndex: number; data: any }[] },
  questions: ConstrainedQuestions,
): any => {
  const _a = (ref: string, dimensionIndex: number = 0) =>
    answers[ref]?.find((a) => a.dimensionIndex === dimensionIndex)
  const _q = (ref: string) => questions[ref]

  // SINGLE_LINE_TEXT
  const singleLineText_getDimensionValues = (ref: string) => {
    const q = _q(ref)
    let values: string[] = []
    for (let dimensionIndex = 0; dimensionIndex < (q?.dimensions || 0); dimensionIndex++) {
      values.push(_a(ref, dimensionIndex)?.data.value)
    }
    return values
  }

  // BOOLEAN
  const boolean_isTrue = (ref: string, dimensionIndex: number = 0) =>
    _a(ref, dimensionIndex)?.data.value === true
  const boolean_isFalse = (ref: string, dimensionIndex: number = 0) =>
    _a(ref, dimensionIndex)?.data.value === false

  // NUMBER
  const number_getValue = (ref: string, dimensionIndex: number = 0) =>
    _a(ref, dimensionIndex)?.data.value

  // SINGLE_SELECT
  const singleSelect_getValue = (ref: string, dimensionIndex: number = 0) =>
    _a(ref, dimensionIndex)?.data.value
  const singleSelect_hasSelection = (ref: string, dimensionIndex: number = 0) =>
    _a(ref, dimensionIndex)?.data.value !== undefined
  const singleSelect_isSelected = (ref: string, value: any, dimensionIndex: number = 0) =>
    _a(ref, dimensionIndex)?.data.value === value
  const singleSelect_isOtherSelected = (ref: string, dimensionIndex: number = 0) =>
    _a(ref, dimensionIndex)?.data.isOther === true

  // MULTI_SELECT
  const multiSelect_hasSelection = (ref: string, dimensionIndex: number = 0) => {
    const a = _a(ref, dimensionIndex)
    return Array.isArray(a?.data.values) && a?.data.values.length > 0
  }
  const multiSelect_isSelected = (ref: string, value: any, dimensionIndex: number = 0) => {
    const a = _a(ref, dimensionIndex)
    return Array.isArray(a?.data.values) && a?.data.values.some((i: any) => i.value === value)
  }
  const multiSelect_isOtherSelected = (ref: string, dimensionIndex: number = 0) => {
    const a = _a(ref, dimensionIndex)
    return Array.isArray(a?.data.values) && a?.data.values.some((i: any) => i.isOther === true)
  }
  const multiSelect_getSelectionCount = (ref: string, dimensionIndex: number = 0) => {
    const a = _a(ref, dimensionIndex)
    return Array.isArray(a?.data.values) ? a?.data.values.length : 0
  }
  const multiSelect_getSelectionLabels = (ref: string, dimensionIndex: number = 0) => {
    const a = _a(ref, dimensionIndex)
    const q = _q(ref)
    if (Array.isArray(a?.data.values) && a?.data.values.length > 0) {
      // has selection
      return a?.data.values.map((value: any) => {
        const option: any = q?.config.options
          .map((option: any) => {
            option.label ??= option.value
            option.value ??= option.label
            return option
          })
          .find((option: any) => option.value === value.value)
        if (option) {
          return [option.label, option.value]
        } else {
          return [value.value, value.isOther ? '' : undefined]
        }
      })
    } else {
      return undefined
    }
  }

  try {
    return eval(expr)
  } catch (e: any) {
    console.error(`eval of ${expr} failed`, e.message)
    return false
  }
}

export const flattenAnswers = (questionPacks: QuestionPack[]): { [name: string]: any } =>
  questionPacks?.reduce(
    (acc, questionPack) => ({
      ...acc,
      ...questionPack.questions.reduce(
        (que, question) => ({ ...que, [question.ref]: question.answer?.data }),
        {},
      ),
    }),
    {},
  )
export const computeConstrainedQuestions = (
  questionPacks: QuestionPack[],
): ConstrainedQuestions => {
  // Extract all refs from all conditions and cache them until questionPacks update
  const usedRefs: string[] = []
  const previousRefs: Set<string> = new Set()

  for (const questionPack of questionPacks) {
    for (const question of questionPack.questions) {
      previousRefs.add(question.ref)

      const extractedRefs = [
        ...extractRefs(question.constraintIsMandatoryExpr),
        ...extractRefs(question.constraintIsVisibleExpr),
      ]

      usedRefs.push(...extractedRefs)

      // Print a warning if a ref is used before it exists in the processing order
      const unknownRef = extractedRefs.find((ref) => !previousRefs.has(ref))
      if (unknownRef)
        console.error(`ref ${unknownRef} used before it is defined in constraint processing order`)
    }
  }

  // Prepare our data stores for access by the constraint expressions
  const answers = flattenAnswers(questionPacks)
  const questions: ConstrainedQuestions = flattenQuestionsForConstraints(questionPacks)

  // Add all refs to the answers object
  for (const ref in answers) {
    answers[ref] ||= []
  }

  // Evaluate all conditions and cache the result
  for (const questionPack of questionPacks) {
    for (const question of questionPack.questions) {
      const parent = questions[question.ref]
      const current = questions[question.ref].constraints

      // Eval and store result in defined order to be maybe reused in following isMandatory condition
      current.isVisible = constraintEval(question.constraintIsVisibleExpr, answers, questions)

      current.isMandatory = constraintEval(question.constraintIsMandatoryExpr, answers, questions)

      parent.dimensions = constraintEval(question.dimensionsExpr, answers, questions)

      parent.labels = question.labelsExpr
        ? constraintEval(question.labelsExpr!, answers, questions)
        : null
    }
  }

  return questions
}

export const flattenQuestionsForConstraints = (
  questionPacks: QuestionPack[],
): ConstrainedQuestions =>
  questionPacks.reduce(
    (acc, questionPack) => ({
      ...acc,
      ...questionPack.questions.reduce(
        (acc, question) => ({
          ...acc,
          [question.ref]: {
            constraints: {
              isVisible: undefined,
              isMandatory: undefined,
            },
            isMustHave: question.isMustHave,
            type: question.type,
            config: question.config,
          },
        }),
        {},
      ),
    }),
    {},
  )

import { useRef, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../common/hooks'
import styles from '../../styles/edit_modal.module.scss'
import DispatcherButton, { DispatcherButtonPresets } from '../../components/DispatcherButton'
import { InputText } from 'primereact/inputtext'
import { Steps } from 'primereact/steps'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { classNames } from 'primereact/utils'
import {
  CreateContact,
  useContactCreateMutation,
  useLazyContactExistsByEmailQuery,
} from '../../redux/api'
import { useFormGeoDataMulti } from '../../common/formHooks'
import { Checkbox } from 'primereact/checkbox'
import { MultiSelect } from 'primereact/multiselect'
import { Toolbar } from 'primereact/toolbar'
import TagList from '../../components/TagList'
import { setToastMessage } from '../../redux/app'
import { emailPattern } from '../../consts'

interface FormData {
  firstName: string
  middleNames: string | null
  lastName: string
  jobTitle: string | null
  email: string
  global: boolean
  geos: number[]
}

interface Props {
  company: { id: number; name: string }
  onContentCreated?: (createdContent: Contact) => void
}

const ContactCreatePage = ({
  company,
  onContentCreated: onContentCreated = () => {},
}: Props): JSX.Element => {
  const workspaceId = useAppSelector((state) => state.app.workspace!.id)

  const contactForm = useRef<HTMLFormElement>(null)

  const [emailExists] = useLazyContactExistsByEmailQuery()

  const [currentStep, setCurrentStep] = useState<number>(1)

  const [createContact] = useContactCreateMutation()

  const itemsSteps = [{ label: 'General Information' }, { label: 'Summary' }]

  const [geoCheckbox, setGeosCheckbox] = useState<boolean>(false)

  const dispatch = useAppDispatch()

  const [contactData, setContactData] = useState<CreateContact>({
    company: { id: company.id, name: company.name },
    firstName: '',
    middleNames: null,
    lastName: '',
    jobTitle: null,
    email: '',
    geos: [],
  })

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<FormData>({
    reValidateMode: 'onBlur',
    defaultValues: {
      firstName: contactData.firstName,
      middleNames: contactData.middleNames,
      lastName: contactData.lastName,
      jobTitle: contactData.jobTitle,
      email: contactData.email,
    },
  })

  const formGeoData = useFormGeoDataMulti()

  const confirmContactData: SubmitHandler<FormData> = async (formData) => {
    setContactData({
      company: { id: company.id, name: company.name },
      firstName: formData.firstName,
      middleNames: formData.middleNames,
      lastName: formData.lastName,
      jobTitle: formData.jobTitle,
      email: formData.email,
      geos: formGeoData.convert(formData.global, formData.geos),
    })
    setCurrentStep(2)
  }

  const onSubmit = async () => {
    try {
      const response = await createContact({ workspaceId, createContact: contactData }).unwrap()
      onContentCreated(response)
      dispatch(
        setToastMessage({
          severity: 'success',
          summary: 'Contact created successfully.',
        }),
      )
    } catch {
      dispatch(
        setToastMessage({
          severity: 'error',
          summary: 'Contact could not be created.',
        }),
      )
    }
  }

  const nextButton = (
    <DispatcherButton
      buttonProps={{
        label: 'Next',
        icon: 'pi pi-arrow-right',
        iconPos: 'right',
        disabled: formGeoData.isLoading,
      }}
      className={DispatcherButtonPresets.FILLED_ROUNDED_PRIMARY}
      onClick={() => {
        if (contactForm.current) {
          contactForm.current.dispatchEvent(
            new Event('submit', { cancelable: true, bubbles: true }),
          )
        }
      }}
    />
  )

  const backIcon = (
    <DispatcherButton
      buttonProps={{ icon: 'pi pi-pencil', onClick: () => setCurrentStep(1) }}
      className={DispatcherButtonPresets.PRIMARY_ICON_ACTIONS}
    />
  )

  const backButton = (
    <DispatcherButton
      buttonProps={{
        label: 'Back',
        icon: 'pi pi-arrow-left',
        disabled: formGeoData.isLoading,
      }}
      className={DispatcherButtonPresets.OUTLINED_PRIMARY}
      onClick={() => setCurrentStep(1)}
    />
  )

  const finishButton = (
    <DispatcherButton
      buttonProps={{
        label: 'Finish',
        icon: 'pi pi-check',
        iconPos: 'right',
        disabled: formGeoData.isLoading,
      }}
      className={DispatcherButtonPresets.FILLED_ROUNDED_PRIMARY}
      onClick={() => onSubmit()}
    />
  )

  return currentStep === 1 ? (
    <div className={styles.edit_modal}>
      <form ref={contactForm} className="p-fluid" onSubmit={handleSubmit(confirmContactData)}>
        <Steps model={itemsSteps} className="mb-4" activeIndex={currentStep - 1} />
        <h3>General Information</h3>

        <div className="card mb-3">
          <div className="grid card-container">
            <div className="col-12 mb-2 md:col-2 md:mb-0 font-medium align-self-center">
              <label htmlFor="firstName" className={classNames({ 'p-error': errors.firstName })}>
                First Name:
              </label>
            </div>
            <div className="col-12 md:col-10">
              <Controller
                name="firstName"
                control={control}
                rules={{
                  required: { value: true, message: 'First name is a required field' },
                  maxLength: { value: 255, message: 'First name must be less than 256 characters' },
                }}
                render={({ field, fieldState }) => (
                  <InputText
                    id={field.name}
                    value={field.value}
                    onChange={field.onChange}
                    onBlur={(e) => {
                      field.onChange(e.target.value.trim())
                    }}
                    placeholder="Enter first name"
                    className={classNames({ 'p-invalid': fieldState.error })}
                  />
                )}
              />
              {errors.firstName && <small className="p-error">{errors.firstName?.message}</small>}
            </div>
          </div>
        </div>

        <div className="card mb-3">
          <div className="grid card-container">
            <div className="col-12 mb-2 md:col-2 md:mb-0 font-medium align-self-center">
              <label
                htmlFor="middleNames"
                className={classNames({ 'p-error': errors.middleNames })}
              >
                Middle Names:
              </label>
            </div>
            <div className="col-12 md:col-10">
              <Controller
                name="middleNames"
                control={control}
                rules={{
                  maxLength: {
                    value: 255,
                    message: 'Middle names must be less than 256 characters',
                  },
                }}
                render={({ field, fieldState }) => (
                  <InputText
                    id={field.name}
                    value={field.value || ''}
                    onChange={field.onChange}
                    onBlur={(e) => {
                      field.onChange(e.target.value.trim() === '' ? null : e.target.value.trim())
                    }}
                    placeholder="Enter middle names (optional)"
                    className={classNames({ 'p-invalid': fieldState.error })}
                  />
                )}
              />
              {errors.middleNames && (
                <small className="p-error">{errors.middleNames?.message}</small>
              )}
            </div>
          </div>
        </div>

        <div className="card mb-3">
          <div className="grid card-container">
            <div className="col-12 mb-2 md:col-2 md:mb-0 font-medium align-self-center">
              <label htmlFor="lastName" className={classNames({ 'p-error': errors.lastName })}>
                Last Name:
              </label>
            </div>
            <div className="col-12 md:col-10">
              <Controller
                name="lastName"
                control={control}
                rules={{
                  required: { value: true, message: 'Last name is a required field' },
                  maxLength: { value: 255, message: 'Last name must be less than 256 characters' },
                }}
                render={({ field, fieldState }) => (
                  <InputText
                    id={field.name}
                    value={field.value}
                    onChange={field.onChange}
                    onBlur={(e) => {
                      field.onChange(e.target.value.trim())
                    }}
                    placeholder="Enter last name"
                    className={classNames({ 'p-invalid': fieldState.error })}
                  />
                )}
              />
              {errors.lastName && <small className="p-error">{errors.lastName?.message}</small>}
            </div>
          </div>
        </div>

        <div className="card mb-3">
          <div className="grid card-container">
            <div className="col-12 mb-2 md:col-2 md:mb-0 font-medium align-self-center">
              <label htmlFor="jobTitle" className={classNames({ 'p-error': errors.jobTitle })}>
                Job Title:
              </label>
            </div>
            <div className="col-12 md:col-10">
              <Controller
                name="jobTitle"
                control={control}
                rules={{
                  maxLength: {
                    value: 255,
                    message: 'Job title must be less than 256 characters',
                  },
                }}
                render={({ field, fieldState }) => (
                  <InputText
                    id={field.name}
                    value={field.value || ''}
                    onChange={field.onChange}
                    onBlur={(e) => {
                      field.onChange(e.target.value.trim() === '' ? null : e.target.value.trim())
                    }}
                    placeholder="Enter job title (optional)"
                    className={classNames({ 'p-invalid': fieldState.error })}
                  />
                )}
              />
              {errors.jobTitle && <small className="p-error">{errors.jobTitle?.message}</small>}
            </div>
          </div>
        </div>

        <div className="card mb-3">
          <div className="grid card-container">
            <div className="col-12 mb-2 md:col-2 md:mb-0 font-medium align-self-center">
              <label htmlFor="email" className={classNames({ 'p-error': errors.email })}>
                Email:
              </label>
            </div>
            <div className="col-12 md:col-10">
              <Controller
                name="email"
                control={control}
                rules={{
                  required: { value: true, message: 'Email is a required field' },
                  maxLength: { value: 255, message: 'Email must be less than 256 characters' },
                  pattern: { value: emailPattern, message: 'Email is not valid' },
                  validate: async (value) => {
                    return (await emailExists({
                      workspaceId,
                      email: value,
                    }).unwrap())
                      ? 'A contact with this email already exists'
                      : true
                  },
                }}
                render={({ field, fieldState }) => (
                  <InputText
                    id={field.name}
                    value={field.value}
                    onChange={field.onChange}
                    onBlur={(e) => {
                      field.onChange(e.target.value.trim())
                    }}
                    placeholder="Enter email"
                    className={classNames({ 'p-invalid': fieldState.error })}
                  />
                )}
              />
              {errors.email && <small className="p-error">{errors.email?.message}</small>}
            </div>
          </div>
        </div>

        <div className="field grid">
          <label
            htmlFor="markets"
            className={classNames(
              { 'p-error': errors.geos },
              'col-12 mb-2 xl:col-2 xl:mb-0 font-medium',
            )}
          >
            Markets:
          </label>

          <div className="flex col-12 xl:col-10 align-self-center pl-0">
            <div className="col-12 xl:col-10">
              <Controller
                name="global"
                control={control}
                defaultValue={false}
                render={({ field, fieldState }) => (
                  <Checkbox
                    inputId={field.name}
                    onChange={(e) => field.onChange(e.checked)}
                    checked={field.value}
                    className={classNames({ 'p-invalid': fieldState.error })}
                  />
                )}
              />
              {errors.global && <small className="p-error">{errors.global?.message}</small>}

              <label className="ml-2 mb-2 xl:mb-0 font-medium">
                <label className={classNames({ 'p-error': errors.geos }, 'ml-2')}>Global</label>
              </label>
            </div>
          </div>
        </div>

        <div className="field grid">
          <div className="flex col-12 xl:col-10 xl:col-offset-2 align-self-center pl-0">
            <div className="flex col-3 xl:col-3 align-self-center">
              <Checkbox checked={geoCheckbox} onChange={() => setGeosCheckbox(!geoCheckbox)} />
              <div className="ml-2 mb-2 xl:mb-0 font-medium">
                <label className={classNames({ 'p-error': errors.geos }, 'ml-2')}>Markets</label>
              </div>
            </div>

            <div className="col-9 xl:col-9 pr-0">
              <Controller
                name="geos"
                control={control}
                defaultValue={[]}
                render={({ field, fieldState }) => (
                  <MultiSelect
                    id={field.name}
                    value={field.value}
                    options={formGeoData.options}
                    onChange={(e) => field.onChange(e.value)}
                    optionLabel="label"
                    optionGroupLabel="label"
                    optionGroupChildren="items"
                    display="chip"
                    filter={true}
                    resetFilterOnHide={true}
                    placeholder="Select markets"
                    showSelectAll={true}
                    scrollHeight={'400px'}
                    className={classNames({ 'p-invalid': fieldState.error })}
                    disabled={!geoCheckbox}
                  />
                )}
              />
              {errors.geos && <small className="p-error">{errors.geos?.message}</small>}
            </div>
          </div>
        </div>
      </form>
      <Toolbar right={nextButton} />
    </div>
  ) : (
    <div className={styles.edit_modal}>
      <Steps model={itemsSteps} className="mb-4" activeIndex={currentStep - 1} />
      <h3>Summary</h3>

      <div className="flex align-items-center flex-wrap">
        <div className="mr-2">
          <h4>General Information</h4>
        </div>
        <div>{backIcon}</div>
      </div>

      <div className="card mb-3">
        <div className="grid card-container">
          <div className="col-12 mb-2 md:col-2 md:mb-0 font-medium align-self-center">Company:</div>
          <div className="col-12 md:col-10">{company.name}</div>
        </div>
      </div>

      <div className="card mb-3">
        <div className="grid card-container">
          <div className="col-12 mb-2 md:col-2 md:mb-0 font-medium align-self-center">
            First Name:
          </div>
          <div className="col-12 md:col-10">{contactData.firstName}</div>
        </div>
      </div>

      <div className="card mb-3">
        <div className="grid card-container">
          <div className="col-12 mb-2 md:col-2 md:mb-0 font-medium align-self-center">
            Middle Names:
          </div>
          <div className="col-12 md:col-10">{contactData.middleNames || '(none)'}</div>
        </div>
      </div>

      <div className="card mb-3">
        <div className="grid card-container">
          <div className="col-12 mb-2 md:col-2 md:mb-0 font-medium align-self-center">
            Last Name:
          </div>
          <div className="col-12 md:col-10">{contactData.lastName}</div>
        </div>
      </div>

      <div className="card mb-3">
        <div className="grid card-container">
          <div className="col-12 mb-2 md:col-2 md:mb-0 font-medium align-self-center">
            Job Title:
          </div>
          <div className="col-12 md:col-10">{contactData.jobTitle || '(none)'}</div>
        </div>
      </div>

      <div className="card mb-3">
        <div className="grid card-container">
          <div className="col-12 mb-2 md:col-2 md:mb-0 font-medium align-self-center">Email:</div>
          <div className="col-12 md:col-10">{contactData.email}</div>
        </div>
      </div>

      <div className="card mb-3">
        <div className="grid card-container">
          <div className="col-12 mb-2 md:col-2 md:mb-0 font-medium align-self-center">Markets:</div>
          <div className="col-12 md:col-10">
            <TagList
              items={[
                contactData.geos.filter((geo) => geo.type === null),
                contactData.geos.filter((geo) => geo.type !== null),
              ]}
              emptyVal={'(none)'}
            />
          </div>
        </div>
      </div>

      <Toolbar left={backButton} right={finishButton} />
    </div>
  )
}

export default ContactCreatePage

import { Tooltip } from 'primereact/tooltip'
import { Toolbar } from 'primereact/toolbar'
import DispatcherButton, { DispatcherButtonPresets } from '../DispatcherButton'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { RadioButton } from 'primereact/radiobutton'
import { classNames } from 'primereact/utils'
import { useState } from 'react'
import InfoTooltip from '../InfoTooltip'
import { InputTextarea } from 'primereact/inputtextarea'
import editStyles from '../../styles/edit_modal.module.scss'
import { UpdateQuestion } from '../../redux/api'

interface FormData {
  constraintIsMandatoryExpr: string
  constraintIsVisibleExpr: string
}

interface Props {
  questionData: UpdateQuestion
  setQuestionData: React.Dispatch<React.SetStateAction<UpdateQuestion>>
  setStep: React.Dispatch<React.SetStateAction<number>>
}

const QuestionEditStepTwo = ({ questionData, setStep, setQuestionData }: Props): JSX.Element => {
  const [next, setNext] = useState(1)

  const {
    control,
    formState: { errors },
    handleSubmit,
    resetField,
    watch,
  } = useForm<FormData>({
    reValidateMode: 'onBlur',
    defaultValues: {
      constraintIsVisibleExpr: questionData.constraintIsVisibleExpr,
      constraintIsMandatoryExpr: questionData.constraintIsMandatoryExpr,
    },
  })

  const visibleRadio = watch('constraintIsVisibleExpr')
  const mandatoryRadio = watch('constraintIsMandatoryExpr')

  const submitFormData: SubmitHandler<FormData> = async (formData, e) => {
    setQuestionData({
      ...questionData,
      constraintIsMandatoryExpr: formData.constraintIsMandatoryExpr,
      constraintIsVisibleExpr: formData.constraintIsVisibleExpr,
    })
    setStep((step) => step + next)
  }

  return (
    <form className="p-fluid" onSubmit={handleSubmit(submitFormData)}>
      <Tooltip target=".infoTooltip" />
      <div className={'flex align-items-center flex-row'}>
        <h3>Constraints</h3>
      </div>
      <div className="card">
        <div className="field grid">
          <label
            htmlFor="constraintIsVisibleExpr"
            className={classNames(
              { 'p-error': errors.constraintIsVisibleExpr },
              'col-12 md:col-2 lg:col-2 align-items-start mt-3',
            )}
          >
            Visible:
          </label>
          <div className="col-12 md:col-10 lg:col-10">
            <Controller
              name="constraintIsVisibleExpr"
              control={control}
              rules={{
                validate: (value) =>
                  visibleRadio === 'true' || visibleRadio === 'false' || value !== ''
                    ? true
                    : "Expression is required if neither 'True' nor 'False' is selected",
              }}
              render={({ field, fieldState, formState }) => (
                <>
                  <div className="field grid mt-3">
                    <div className="col-12 align-self-center">
                      <RadioButton
                        inputId="visibleTrue"
                        name="visibleRadio"
                        onChange={(e) => {
                          if (e.checked) {
                            resetField('constraintIsVisibleExpr', { defaultValue: 'true' })
                          }
                        }}
                        value={true}
                        checked={formState.defaultValues?.constraintIsVisibleExpr === 'true'}
                        className={classNames({ 'p-invalid': fieldState.error })}
                      />
                      <label htmlFor="visibleTrue" className={'ml-2'}>
                        True (default)
                      </label>
                    </div>
                  </div>
                  <div className="field grid">
                    <div className="col-12 align-self-center">
                      <RadioButton
                        inputId="visibleFalse"
                        name="visibleRadio"
                        onChange={(e) => {
                          if (e.checked) {
                            resetField('constraintIsVisibleExpr', { defaultValue: 'false' })
                          }
                        }}
                        value={false}
                        checked={formState.defaultValues?.constraintIsVisibleExpr === 'false'}
                        className={classNames({ 'p-invalid': fieldState.error })}
                      />
                      <label htmlFor="visibleFalse" className={'ml-2'}>
                        False
                      </label>
                    </div>
                  </div>
                  <div className="field grid">
                    <div className="col-12 md:col-4 lg:col-3 align-self-start">
                      <RadioButton
                        inputId="visibleOnlyIf"
                        name="visibleRadio"
                        onChange={(e) => {
                          if (e.checked) {
                            resetField('constraintIsVisibleExpr', {
                              defaultValue:
                                formState.defaultValues?.constraintIsVisibleExpr == 'true' ||
                                formState.defaultValues?.constraintIsVisibleExpr == 'false'
                                  ? ''
                                  : formState.defaultValues?.constraintIsVisibleExpr,
                            })
                          }
                        }}
                        value={formState.defaultValues?.constraintIsVisibleExpr}
                        checked={
                          formState.defaultValues?.constraintIsVisibleExpr !== 'true' &&
                          formState.defaultValues?.constraintIsVisibleExpr !== 'false'
                        }
                        className={classNames({ 'p-invalid': fieldState.error })}
                      />
                      <label htmlFor="visibleOnlyIf" className={'ml-2'}>
                        Only if...
                      </label>
                    </div>

                    <div className="col-12 mt-3 md:col-8 md:mt-0 lg:col-9">
                      <InputTextarea
                        id={field.name}
                        autoResize={true}
                        value={field.value === 'true' || field.value === 'false' ? '' : field.value}
                        disabled={field.value === 'true' || field.value === 'false'}
                        onChange={(e) => {
                          if (e.target.value.trim() === 'true') {
                            resetField('constraintIsVisibleExpr', { defaultValue: 'true' })
                            e.target.value = '' // clear the textarea
                          } else if (e.target.value.trim() === 'false') {
                            resetField('constraintIsVisibleExpr', { defaultValue: 'false' })
                            e.target.value = '' // clear the textarea
                          } else {
                            field.onChange(e.target.value)
                          }
                        }}
                        onBlur={(e) => {
                          field.onChange(e.target.value.trim())
                        }}
                        placeholder="Enter expression"
                        className={classNames(
                          { 'p-invalid': fieldState.error },
                          editStyles.monospace,
                        )}
                      />
                    </div>
                  </div>
                </>
              )}
            />
            {errors.constraintIsVisibleExpr && (
              <small className="p-error">{errors.constraintIsVisibleExpr?.message}</small>
            )}
          </div>
        </div>
        <div className="field grid">
          <label
            htmlFor="constraintIsMandatoryExpr"
            className={classNames(
              { 'p-error': errors.constraintIsMandatoryExpr },
              'col-12 md:col-2 lg:col-2 align-items-start mt-3',
            )}
          >
            Mandatory:
          </label>
          <div className="col-12 md:col-10 lg:col-10">
            <Controller
              name="constraintIsMandatoryExpr"
              control={control}
              rules={{
                validate: (value) =>
                  mandatoryRadio === 'true' || mandatoryRadio === 'false' || value !== ''
                    ? true
                    : "Expression is required if neither 'True' nor 'False' is selected",
              }}
              render={({ field, fieldState, formState }) => (
                <>
                  <div className="field grid mt-3">
                    <div className="col-12 align-self-center">
                      <RadioButton
                        inputId="mandatoryTrue"
                        name="mandatoryRadio"
                        onChange={(e) => {
                          // field.onChange(e.checked)
                          if (e.checked) {
                            resetField('constraintIsMandatoryExpr', { defaultValue: 'true' })
                          }
                        }}
                        value={true}
                        checked={formState.defaultValues?.constraintIsMandatoryExpr === 'true'}
                        className={classNames({ 'p-invalid': fieldState.error })}
                      />
                      <label htmlFor="mandatoryTrue" className={'ml-2'}>
                        True (default)
                      </label>
                    </div>
                  </div>
                  <div className="field grid">
                    <div className="col-12 align-self-center">
                      <RadioButton
                        inputId="mandatoryFalse"
                        name="mandatoryRadio"
                        onChange={(e) => {
                          // field.onChange(e.checked)
                          if (e.checked) {
                            resetField('constraintIsMandatoryExpr', { defaultValue: 'false' })
                          }
                        }}
                        value={false}
                        checked={formState.defaultValues?.constraintIsMandatoryExpr === 'false'}
                        className={classNames({ 'p-invalid': fieldState.error })}
                      />
                      <label htmlFor="mandatoryFalse" className={'ml-2'}>
                        False
                      </label>
                    </div>
                  </div>
                  <div className="field grid">
                    <div className="col-12 md:col-4 lg:col-3 align-self-start">
                      <RadioButton
                        inputId="mandatoryOnlyIf"
                        name="mandatoryRadio"
                        onChange={(e) => {
                          if (e.checked) {
                            resetField('constraintIsMandatoryExpr', {
                              defaultValue:
                                formState.defaultValues?.constraintIsMandatoryExpr == 'true' ||
                                formState.defaultValues?.constraintIsMandatoryExpr == 'false'
                                  ? ''
                                  : formState.defaultValues?.constraintIsMandatoryExpr,
                            })
                          }
                        }}
                        value={formState.defaultValues?.constraintIsMandatoryExpr}
                        checked={
                          formState.defaultValues?.constraintIsMandatoryExpr !== 'true' &&
                          formState.defaultValues?.constraintIsMandatoryExpr !== 'false'
                        }
                        className={classNames({ 'p-invalid': fieldState.error })}
                      />
                      <label htmlFor="mandatoryOnlyIf" className={'ml-2'}>
                        Only if...
                      </label>
                    </div>

                    <div className="col-12 mt-3 md:col-8 md:mt-0 lg:col-9">
                      <InputTextarea
                        id={field.name}
                        autoResize={true}
                        value={field.value === 'true' || field.value === 'false' ? '' : field.value}
                        disabled={field.value === 'true' || field.value === 'false'}
                        onChange={(e) => {
                          if (e.target.value.trim() === 'true') {
                            resetField('constraintIsMandatoryExpr', { defaultValue: 'true' })
                            e.target.value = '' // clear the textarea
                          } else if (e.target.value.trim() === 'false') {
                            resetField('constraintIsMandatoryExpr', { defaultValue: 'false' })
                            e.target.value = '' // clear the textarea
                          } else {
                            field.onChange(e.target.value)
                          }
                        }}
                        onBlur={(e) => {
                          field.onChange(e.target.value.trim())
                        }}
                        placeholder="Enter expression"
                        className={classNames(
                          { 'p-invalid': fieldState.error },
                          editStyles.monospace,
                        )}
                      />
                    </div>
                  </div>
                </>
              )}
            />
            {errors.constraintIsMandatoryExpr && (
              <small className="p-error">{errors.constraintIsMandatoryExpr?.message}</small>
            )}
          </div>
        </div>
      </div>
      <Toolbar
        left={
          <DispatcherButton
            buttonProps={{
              label: 'Back',
              icon: 'pi pi-arrow-left',
              type: 'submit',
              onClick: () => setNext(-1),
            }}
            className={DispatcherButtonPresets.OUTLINED_PRIMARY}
          />
        }
        right={
          <DispatcherButton
            buttonProps={{
              label: 'Next',
              icon: 'pi pi-arrow-right',
              iconPos: 'right',
              type: 'submit',
            }}
            className={DispatcherButtonPresets.FILLED_ROUNDED_PRIMARY}
          />
        }
      />
    </form>
  )
}

export default QuestionEditStepTwo

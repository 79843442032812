import { Card } from 'primereact/card'
import { Toolbar } from 'primereact/toolbar'
import styles from '../../styles/list.module.scss'
import TreeTableProvider, { TreeTablePropsPresets } from '../DataTable/TreeTableProvider'
import { simpleColumn } from '../DataTable/columnProvider'
import { useAppSelector, useTreeTableFilter } from '../../common/hooks'
import TreeNode from 'primereact/treenode'
import { QuestionPackTreeNodeData } from '../../common/dataHooks'
import { Tag } from 'primereact/tag'
import TagList, { NORMALIZE_PRESETS } from '../TagList'

interface Props {
  toolbarLeft?: JSX.Element | null
  hiddenColumns?: number[]
  isLoading?: boolean
  data?: TreeNode[]
  showFilter?: boolean
}

const QuestionPackTree = ({
  toolbarLeft = <h1>Question Packs</h1>,
  hiddenColumns = [],
  isLoading = true,
  data,
  showFilter = false,
}: Props): JSX.Element => {
  const workspaceId = useAppSelector((state) => state.app.workspace!.id)

  const columns = [
    simpleColumn({
      columnProps: {
        headerStyle: { width: '48px' },
        style: { width: '48px' },
        expander: true,
      },
    }),
    simpleColumn({
      columnProps: {
        field: 'search',
        headerStyle: {},
        style: { width: '100%' },
        body: (node: any, column: any) => {
          const data = node.data as QuestionPackTreeNodeData
          return data.type === null ? (
            <div key={node.key} className={'flex gap-3'} style={{ lineHeight: '24px' }}>
              <div className={'flex flex-none font-bold'}>{data.name}</div>
              <div className={'flex flex-grow-1'}></div>
              <div className="flex gap-1">
                <div>Market: </div>
                <div className="flex flex-wrap gap-1">
                  <div className="tooltip-wrapper">
                    <Tag value={data.market?.name || '(none)'} className={'bg-bluegray-500'} />
                  </div>
                </div>
              </div>
              <div className="flex gap-1">
                <div>Capabilities: </div>
                <div className="flex flex-wrap gap-1">
                  <TagList
                    items={data.capabilities || []}
                    normalize={NORMALIZE_PRESETS.POS}
                    emptyVal={'(none)'}
                  />
                </div>
              </div>
            </div>
          ) : (
            <div key={node.key} className={'flex gap-3'} style={{ lineHeight: '24px' }}>
              <div className="flex gap-3">
                <div>{data.name}</div>
                <div className="flex flex-wrap gap-1">
                  <div className="tooltip-wrapper">
                    <Tag
                      value={data.type.toLowerCase().replace(/_/g, ' ') || '(none)'}
                      className={'bg-bluegray-500 white-space-nowrap'}
                    />
                  </div>
                </div>
              </div>
            </div>
          )
        },
      },
    }),
  ].filter((column, index) => !hiddenColumns.includes(index))

  data = data?.map((treeNode) => {
    return {
      ...treeNode,
      ...{
        style:
          (treeNode.data as QuestionPackTreeNodeData).type === null
            ? { backgroundColor: '#f8f9fa' }
            : undefined,
      },
    }
  })

  let treeTableProps = TreeTablePropsPresets.listPage(data)

  if (showFilter) {
    treeTableProps = {
      ...treeTableProps,
      ...useTreeTableFilter(),
    }
  }

  return toolbarLeft === null ? (
    <TreeTableProvider treeTableProps={treeTableProps} columns={columns} isLoading={isLoading} />
  ) : (
    <>
      <div className={styles.list}>
        <Toolbar left={toolbarLeft} right={<></>} />
        <Card>
          <TreeTableProvider
            treeTableProps={treeTableProps}
            columns={columns}
            isLoading={isLoading}
          />
        </Card>
      </div>
    </>
  )
}

export default QuestionPackTree

import { useQuestionAnswerData } from '../../common/dataHooks'
import QuestionOutput from '../Question/QuestionOutput'
import QuestionAnswerToc from './QuestionAnswerToc'
import { useQuestionAnswerExportButton } from '../../common/dispatcherButtonHooks'
import { computeConstrainedQuestions, constraintEval } from '../../common/utils'
import React from 'react'

interface Props {
  data: QuestionPack[]
  participant: Participant
}

const QuestionAnswerList = ({ data, participant }: Props): JSX.Element => {
  const constrainedQuestions: ConstrainedQuestions = computeConstrainedQuestions(data)
  const questionAnswerData = useQuestionAnswerData(data)
  const questionAnswerExportButton = useQuestionAnswerExportButton(data, participant)

  const renderOutputIfVisible = (questionPack: QuestionPack, answers: any): JSX.Element => {
    return (
      <>
        {questionPack.questions.map((question) =>
          constraintEval(question.constraintIsVisibleExpr, answers, constrainedQuestions) ? (
            <div key={question.ref} id={question.ref} className="grid py-2">
              <div className="col-6 px-3 bg-gray-200">
                <p style={{ lineHeight: '1.5rem' }}>{question.phrase}</p>
              </div>
              <div className="col-6 p-2 bg-gray-200 mt-0" style={{ wordBreak: 'break-word' }}>
                <QuestionOutput
                  question={question}
                  questionConstraint={constrainedQuestions[question.ref]}
                />
              </div>
            </div>
          ) : (
            <React.Fragment key={question.ref}></React.Fragment>
          ),
        )}
      </>
    )
  }

  const renderQuestionPack = (questionPack: QuestionPack): JSX.Element => {
    const answers = questionPack.questions.reduce((que: Record<string, any>, question) => {
      if (question.answer && question.answer.data) {
        // Loop over the dimensions in the answer data
        question.answer.data.forEach((answerData: AnswerData) => {
          if (answerData.data && answerData.data.value) {
            const dimensionKey = `${question.ref}_${answerData.dimensionIndex}`
            que[dimensionKey] = answerData.data.value
          }
        })
      }
      return que
    }, {})

    return (
      <div key={questionPack.ref} className="card">
        <div className="grid">
          <div id={questionPack.ref} className="col-12">
            <h2 style={{ fontSize: 'medium', fontWeight: '800' }}>{questionPack.displayName}</h2>
          </div>
        </div>
        {!!questionPack.description ? (
          <div className="grid mb-2">
            <div className="col-12">{questionPack.description}</div>
          </div>
        ) : (
          <></>
        )}
        {renderOutputIfVisible(questionPack, answers)} {/* Renders Questions */}
      </div>
    )
  }

  return (
    <div className="card">
      <div className="flex justify-content-end">{questionAnswerExportButton}</div>
      <div className="grid">
        <div className="col-12 md:col-2">
          <QuestionAnswerToc data={questionAnswerData} />
        </div>
        <div className="col-12 md:col-10">
          {questionAnswerData.flatMap((questionPackType) =>
            questionPackType.questionPacks.map((questionPack) => renderQuestionPack(questionPack)),
          )}
        </div>
      </div>
    </div>
  )
}

export default QuestionAnswerList

import DispatcherButton, { DispatcherButtonPresets } from '../DispatcherButton'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { CreateRfi } from '../../redux/api'
import { Toolbar } from 'primereact/toolbar'
import {
  TreeCheckboxSelectionKeysExtended,
  useFormQuestionPackForCreateData,
} from '../../common/formHooks'
import { Tree } from 'primereact/tree'
import TreeNode from 'primereact/treenode'
import { Tag } from 'primereact/tag'
import TagList, { NORMALIZE_PRESETS } from '../TagList'
import { useState } from 'react'
import InfoTooltip from '../InfoTooltip'
import { Tooltip } from 'primereact/tooltip'
import checkBoxGray from '../../assets/checkbox_icon_gray.png'
import checkBoxEmpty from '../../assets/checkbox_icon_empty.png'
import checkBoxPrimary from '../../assets/checkbox_icon_primary.png'

interface FormData {
  questionPacks: TreeCheckboxSelectionKeysExtended
}

interface Props {
  rfiData: CreateRfi
  setRfiData: React.Dispatch<React.SetStateAction<CreateRfi>>
  setStep: React.Dispatch<React.SetStateAction<number>>
  hasDefaultSelection: boolean
  setHasDefaultSelection: React.Dispatch<React.SetStateAction<boolean>>
}

const nodeTemplate = (node: TreeNode) => {
  const data = node.data as any
  return data.type === null ? (
    <div key={node.key} className={'flex gap-3'} style={{ lineHeight: '24px' }}>
      <div className={'flex flex-none font-bold'}>{data.name}</div>
      <div className={'flex flex-grow-1'}></div>
      <div className="flex gap-1">
        <div>Market: </div>
        <div className="flex flex-wrap gap-1">
          <div className="tooltip-wrapper">
            <Tag value={data.market?.name || '(none)'} className={'bg-bluegray-500'} />
          </div>
        </div>
      </div>
      <div className="flex gap-1">
        <div>Capabilities: </div>
        <div className="flex flex-wrap gap-1">
          <TagList
            items={data.capabilities || []}
            normalize={NORMALIZE_PRESETS.POS}
            emptyVal={'(none)'}
          />
        </div>
      </div>
    </div>
  ) : (
    <div key={node.key} className={'flex gap-3'} style={{ lineHeight: '24px' }}>
      <div className="flex gap-3">
        <div>{data.name}</div>
        <div className="flex flex-wrap gap-1">
          <div className="tooltip-wrapper">
            <Tag value={data.type.toLowerCase() || '(none)'} className={'bg-bluegray-500'} />
          </div>
        </div>
      </div>
    </div>
  )
}

const RfiCreateStepThree = ({
  rfiData,
  setRfiData,
  setStep,
  hasDefaultSelection,
  setHasDefaultSelection,
}: Props): JSX.Element => {
  const [next, setNext] = useState(1)

  const {
    control,
    formState: { errors },
    handleSubmit,
    resetField,
  } = useForm<FormData>({
    reValidateMode: 'onBlur',
    defaultValues: {
      questionPacks: {},
    },
  })

  const formQuestionPackForCreateData = useFormQuestionPackForCreateData(
    rfiData.questionPacks,
    rfiData.type!,
    rfiData.geo!.id,
    rfiData.capabilities.map((item) => item.id),
    rfiData.tags.map((item) => item.id),
    hasDefaultSelection,
    setHasDefaultSelection,
    resetField,
  )

  const submitFormData: SubmitHandler<FormData> = async (formData) => {
    setRfiData({
      ...rfiData,
      questionPacks: formQuestionPackForCreateData.convert(formData.questionPacks),
    })
    setStep((step) => step + next)
  }

  return (
    <form className="p-fluid" onSubmit={handleSubmit(submitFormData)}>
      <Tooltip target=".infoTooltip" />
      <InfoTooltip
        tooltip={`You can click on the arrow on the left side to expand any QP and preview the questions it contains.\nDepending on each QP setup, you will be be able to select/unselect QPs and Questions to include them into your RFI.`}
        label={<h3>Question Packs & Questions</h3>}
      />
      <p>
        Select the Question Packs (QPs) and Questions you wish to include in your RFI.
        <br />
        <br />
        The QPs suggested here vary according to the Capability(ies)/Tag(s) you have chosen at the
        previous stage.
        <br />
        If you don't see a QP that you wanted to include in your RFI, please go back and adjust your
        Capability(ies)/Tag(s) selection accordingly.
        <br />
        Note: the full list of available QPs are in the Question Packs section in the Menu header.
        <br />
        <br />
        Key:
        <br />
        <br />
        <img src={checkBoxGray} width={22} height={22} style={{ verticalAlign: 'bottom' }} />: this
        QP/Question is selected by default and must be asked in your RFI, it cannot be unselected or
        removed from your RFI
        <br />
        <br />
        <img src={checkBoxPrimary} width={22} height={22} style={{ verticalAlign: 'bottom' }} />:
        this QP/Question is selected by default, but you are free to unselect it if you don't wish
        to include it in your RFI
        <br />
        <br />
        <img src={checkBoxEmpty} width={22} height={22} style={{ verticalAlign: 'bottom' }} />: this
        QP/Question isn't selected by default, make sure to tick the box if you do want to include
        it in your RFI
      </p>
      <div key="questionPacks" className="field grid">
        <div className="col-12">
          <Controller
            name="questionPacks"
            control={control}
            rules={{
              validate: (value) => {
                return formQuestionPackForCreateData.convert(value).length > 0
                  ? true
                  : 'At least one Question Pack must be selected'
              },
            }}
            render={({ field, formState }) => (
              <Tree
                loading={formQuestionPackForCreateData.isFetching}
                value={formQuestionPackForCreateData.options}
                nodeTemplate={nodeTemplate}
                filter={true}
                filterBy={'data.search'}
                selectionMode="checkbox"
                selectionKeys={field.value}
                onSelect={(e) => {
                  if (e.node.data.type === null) {
                    // parent clicked (node-key like '1') -> set parent: checked true, partial-checked false, set children: checked true, partial-checked false
                    Object.entries(field.value).forEach(([key, value]) => {
                      if (key === e.node.key || key.startsWith(`${e.node.key}-`)) {
                        if (field.value[`${key}`].isMustHave === false) {
                          field.value[`${key}`].checked = true
                          field.value[`${key}`].partialChecked = false
                        }
                      }
                    })
                  } else {
                    // child clicked (node-key like '1-2') -> set child: checked true, partial-checked false, set parent: checked true, partial-checked false
                    if (field.value[`${e.node.key}`].isMustHave === false) {
                      field.value[`${e.node.key}`].checked = true
                      field.value[`${e.node.key}`].partialChecked = false
                    }
                    const parentKey = `${e.node.key}`.split('-')[0]
                    if (field.value[`${parentKey}`].isMustHave === false) {
                      field.value[`${parentKey}`].checked = true
                      field.value[`${e.node.key}`].partialChecked = false
                    }
                  }
                  field.onChange(field.value)
                }}
                onUnselect={(e) => {
                  const defaults = formState.defaultValues?.questionPacks || {}
                  if (e.node.data.type === null) {
                    // parent clicked (node-key like '1') -> set parent: checked false, partial-checked false, set children: checked false, partial-checked false
                    Object.entries(field.value).forEach(([key, value]) => {
                      if (key === e.node.key || key.startsWith(`${e.node.key}-`)) {
                        if (field.value[`${key}`].isMustHave === false) {
                          field.value[`${key}`].checked = false
                          field.value[`${key}`].partialChecked = false
                        }
                      }
                    })
                  } else {
                    // child clicked (node-key like '1-2') -> set child: checked false, partial-checked false
                    if (field.value[`${e.node.key}`].isMustHave === false) {
                      field.value[`${e.node.key}`].checked = false
                      field.value[`${e.node.key}`].partialChecked = false
                    }
                    const parentKey = `${e.node.key}`.split('-')[0]
                    if (field.value[`${parentKey}`].isMustHave === false) {
                      let totalChildCount = 0
                      let uncheckedChildCount = 0
                      Object.entries(field.value).forEach(([key, value]) => {
                        if (key.startsWith(`${parentKey}-`)) {
                          totalChildCount++
                          if (field.value[`${key}`].checked === false) {
                            uncheckedChildCount++
                          }
                        }
                      })
                      if (totalChildCount === uncheckedChildCount) {
                        field.value[`${parentKey}`].checked = false
                        field.value[`${parentKey}`].partialChecked = false
                      }
                    }
                  }
                  field.onChange(field.value)
                }}
              />
            )}
          />
          {errors.questionPacks && (
            <small className="p-error">{errors.questionPacks?.message?.toString()}</small>
          )}
        </div>
      </div>
      <Toolbar
        left={
          <DispatcherButton
            buttonProps={{
              label: 'Back',
              icon: 'pi pi-arrow-left',
              type: 'submit',
              onClick: () => setNext(-1),
            }}
            className={DispatcherButtonPresets.OUTLINED_PRIMARY}
          />
        }
        right={
          <DispatcherButton
            buttonProps={{
              label: 'Next',
              icon: 'pi pi-arrow-right',
              iconPos: 'right',
              type: 'submit',
            }}
            className={DispatcherButtonPresets.FILLED_ROUNDED_PRIMARY}
          />
        }
      />
    </form>
  )
}

export default RfiCreateStepThree

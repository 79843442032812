import { Divider } from 'primereact/divider'
import { Skeleton } from 'primereact/skeleton'
import { Steps } from 'primereact/steps'

const ContactEditSkeleton = (): JSX.Element => {
  const itemsSteps = [{ label: 'General Information' }, { label: 'Summary' }]

  return (
    <>
      <Steps model={itemsSteps} className="mb-4" activeIndex={0} />
      <h3>General Information</h3>
      <div className="flex align-items-center flex-wrap">
        <div className="mr-2">
          <h4>General Information</h4>
        </div>
      </div>

      <div className="card mb-3">
        <div className="grid card-container">
          <div className="col-12 mb-2 md:col-2 md:mb-0 font-medium align-self-center">Company:</div>
          <Skeleton width="400px" height="40px"></Skeleton>
        </div>
      </div>

      <div className="card mb-3">
        <div className="grid card-container">
          <div className="col-12 mb-2 md:col-2 md:mb-0 font-medium align-self-center">
            First Name:
          </div>
          <Skeleton width="400px" height="40px"></Skeleton>
        </div>
      </div>

      <div className="card mb-3">
        <div className="grid card-container">
          <div className="col-12 mb-2 md:col-2 md:mb-0 font-medium align-self-center">
            Middle Names:
          </div>
          <Skeleton width="400px" height="40px"></Skeleton>
        </div>
      </div>

      <div className="card mb-3">
        <div className="grid card-container">
          <div className="col-12 mb-2 md:col-2 md:mb-0 font-medium align-self-center">
            Last Name:
          </div>
          <Skeleton width="400px" height="40px"></Skeleton>
        </div>
      </div>

      <div className="card mb-3">
        <div className="grid card-container">
          <div className="col-12 mb-2 md:col-2 md:mb-0 font-medium align-self-center">
            Job Title:
          </div>
          <Skeleton width="400px" height="40px"></Skeleton>
        </div>
      </div>

      <div className="card mb-3">
        <div className="grid card-container">
          <div className="col-12 mb-2 md:col-2 md:mb-0 font-medium align-self-center">Email:</div>
          <Skeleton width="400px" height="40px"></Skeleton>
        </div>
      </div>

      <div className="card mb-3">
        <div className="grid card-container">
          <div className="col-12 md:col-2  font-medium align-self-center">Markets:</div>
          <div className="col-12 md:col-10 p-0">
            <Skeleton width="400px" height="40px"></Skeleton>
          </div>
        </div>
      </div>
    </>
  )
}

export default ContactEditSkeleton

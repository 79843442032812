import { configureStore } from '@reduxjs/toolkit'
import createSagaMiddleware from '@redux-saga/core'

import app from './app'
import { api, rtkQueryErrorLogger } from './api'

import sagas from '../sagas'

const sagaMiddleware = createSagaMiddleware()
const store = configureStore({
  reducer: {
    app: app,
    [api.reducerPath]: api.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(api.middleware, rtkQueryErrorLogger, sagaMiddleware),
})
sagaMiddleware.run(sagas)

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export default store

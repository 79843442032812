import React from 'react'
import { useAnswerListByParticipantQuery } from '../../redux/api'
import { Skeleton } from 'primereact/skeleton'
import QuestionAnswerList from '../QuestionAnswer/QuestionAnswerList'
import NotFoundPage from '../../scenes/http/404NotFoundPage'
import { useAppSelector } from '../../common/hooks'

interface Props {
  participant: Participant
}

const ParticipantQuestionAnswersContentPublished = ({ participant }: Props): JSX.Element => {
  const workspaceId = useAppSelector((state) => state.app.workspace!.id)
  const { data, isLoading } = useAnswerListByParticipantQuery({
    workspaceId: workspaceId,
    participantId: participant.id,
  })

  return isLoading ? (
    <Skeleton />
  ) : data === undefined ? (
    <NotFoundPage />
  ) : (
    <QuestionAnswerList data={data} participant={participant} />
  )
}

export default ParticipantQuestionAnswersContentPublished

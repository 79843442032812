import React from 'react'
import { getDimensionLabel } from '../../common/utils'

interface FileData {
  id: number
  fileName: string
  url: string
}

interface AnswerDataFiles extends AnswerData {
  data: FileData[]
}

interface Props {
  question: {
    answer?: {
      data: AnswerDataFiles[]
    } | null
  }
  questionConstraint: ConstrainedQuestion
}

const styles = {
  noPaddingList: {
    paddingLeft: 0,
    listStylePosition: 'outside' as const,
    listStyleType: 'none' as const,
  },
  listTypeLevel1: {
    marginBottom: '5px',
  },
  listTypeLevel2: {
    listStyleType: 'disc' as const,
    marginBottom: '5px',
  },
  ulBasic: {
    listStylePosition: 'outside' as const,
  },
}

const QuestionOutputFile = ({ question, questionConstraint }: Props): JSX.Element => {
  const renderFiles = (answerData: AnswerData) => {
    const { data } = answerData
    if (!data || data.length === 0) return null

    const dimensionLabel = getDimensionLabel(
      questionConstraint.dimensions,
      questionConstraint.labels,
      answerData.dimensionIndex,
      answerData.dimensionValue,
    )
    return (
      <React.Fragment key={`dim-${answerData.dimensionIndex}${answerData.dimensionValue}`}>
        {dimensionLabel ? (
          <li style={styles.listTypeLevel1}>
            <strong>{dimensionLabel}</strong>
            <ul>
              {data.map((file: FileData, index: number) => (
                <li style={styles.listTypeLevel2} key={file.id}>
                  <a href={file.url} target={'_blank'} rel="noopener noreferrer">
                    {file.fileName}
                  </a>
                </li>
              ))}
            </ul>
          </li>
        ) : (
          <ul style={styles.ulBasic}>
            {data.map((file: FileData, index: number) => (
              <li style={styles.listTypeLevel1} key={file.id}>
                <a href={file.url} target={'_blank'} rel="noopener noreferrer">
                  {file.fileName}
                </a>
              </li>
            ))}
          </ul>
        )}
      </React.Fragment>
    )
  }

  return question.answer?.data?.length ? (
    <div className="ve-file-list">
      <ul style={styles.noPaddingList}>{question.answer.data.map(renderFiles)}</ul>
    </div>
  ) : (
    <></>
  )
}

export default QuestionOutputFile

import { useState } from 'react'
import { Steps } from 'primereact/steps'
import { CreateRfi } from '../../redux/api'
import RfiCreateStepOne from './RfiCreateStepOne'
import RfiCreateStepTwo from './RfiCreateStepTwo'
import RfiCreateStepThree from './RfiCreateStepThree'
import RfiCreateStepFour from './RfiCreateStepFour'
import RfiCreateStepFive from './RfiCreateStepFive'
import createStyles from '../../styles/create.module.scss'
import { Message } from 'primereact/message'
interface Props {
  onCreated?: (rfi: Rfi) => void
}

const steps = [
  { label: 'General Information' },
  { label: 'Capabilities & Tags' },
  { label: 'Question Packs & Questions' },
  { label: 'Participants' },
  { label: 'Summary' },
]

const RfiCreateContent = ({ onCreated = () => {} }: Props): JSX.Element => {
  const [step, setStep] = useState<number>(1)
  const [hasQuestionPacksDefaultSelection, setHasQuestionPacksDefaultSelection] =
    useState<boolean>(true)

  const [rfiData, setRfiData] = useState<CreateRfi>({
    name: '',
    description: null,
    geo: undefined,
    startDate: new Date().toISOString().slice(0, 10),
    endDate: '',
    capabilities: [],
    tags: [],
    type: undefined,
    questionPacks: [],
    participants: [],
  })

  const renderSwitch = (stepToRender: number) => {
    switch (stepToRender) {
      case 1:
        return (
          <div className={createStyles.main}>
            <Message
              className="mb-5"
              severity="warn"
              text="Please make sure to check if the company(ies) you're about to interrogate do(es)n't already have an on-going or completed RFI."
            />
            <Steps model={steps} activeIndex={0} className={'mb-4'} />

            <RfiCreateStepOne rfiData={rfiData} setRfiData={setRfiData} setStep={setStep} />
          </div>
        )

      case 2:
        return (
          <div className={createStyles.main}>
            <Message
              className="mb-5"
              severity="warn"
              text="Please make sure to check if the company(ies) you're about to interrogate do(es)n't already have an on-going or completed RFI."
            />
            <Steps model={steps} activeIndex={1} className={'mb-4'} />

            <RfiCreateStepTwo rfiData={rfiData} setRfiData={setRfiData} setStep={setStep} />
          </div>
        )

      case 3:
        return (
          <div className={createStyles.main}>
            <Message
              className="mb-5"
              severity="warn"
              text="Please make sure to check if the company(ies) you're about to interrogate do(es)n't already have an on-going or completed RFI."
            />
            <Steps model={steps} activeIndex={2} className={'mb-4'} />

            <RfiCreateStepThree
              rfiData={rfiData}
              setRfiData={setRfiData}
              setStep={setStep}
              hasDefaultSelection={hasQuestionPacksDefaultSelection}
              setHasDefaultSelection={setHasQuestionPacksDefaultSelection}
            />
          </div>
        )

      case 4:
        return (
          <div className={createStyles.main}>
            <Message
              className="mb-5"
              severity="warn"
              text="Please make sure to check if the company(ies) you're about to interrogate do(es)n't already have an on-going or completed RFI."
            />
            <Steps model={steps} activeIndex={3} className={'mb-4'} />
            <RfiCreateStepFour rfiData={rfiData} setRfiData={setRfiData} setStep={setStep} />
          </div>
        )

      case 5:
        return (
          <div className={createStyles.main}>
            <Steps model={steps} activeIndex={4} className={'mb-4'} />
            <RfiCreateStepFive rfiData={rfiData} setStep={setStep} onCreated={onCreated} />
          </div>
        )

      default:
        return <></>
    }
  }

  return renderSwitch(step)
}

export default RfiCreateContent

import { Divider } from 'primereact/divider'
import { Skeleton } from 'primereact/skeleton'

const QuestionDetailsSkeleton = (): JSX.Element => {
  return (
    <>
      <div className="flex justify-content-start">
        <Skeleton width="200px" height="20px" className="mb-2"></Skeleton>
        <Skeleton width="650px" height="100px" className="ml-3 mb-4"></Skeleton>
      </div>
      <div className="flex justify-content-start">
        <Skeleton width="200px" height="20px" className="mb-2"></Skeleton>
        <Skeleton width="650px" height="100px" className="ml-3 mb-2"></Skeleton>
      </div>
      <div className="card mt-5">
        <div className="flex justify-content-start">
          <Skeleton width="200px" height="20px" className="mb-2"></Skeleton>
          <Skeleton height="40px" className="mb-2 ml-3 w-4"></Skeleton>
        </div>
        <div className="flex justify-content-start">
          <Skeleton width="200px" height="20px" className="mb-2"></Skeleton>
          <Skeleton height="40px" className="mb-2 ml-3 w-4"></Skeleton>
        </div>
        <div className="flex justify-content-start">
          <Skeleton width="200px" height="20px" className="mb-2"></Skeleton>
          <Skeleton height="40px" className="mb-2 ml-3 w-4"></Skeleton>
        </div>
        <div className="flex justify-content-start">
          <Skeleton width="200px" height="20px" className="mb-2"></Skeleton>
          <Skeleton height="40px" className="mb-2 ml-3 w-4"></Skeleton>
        </div>
        <div className="flex justify-content-start">
          <Skeleton width="200px" height="20px" className="mb-2"></Skeleton>
          <Skeleton width="650px" height="40px" className="mb-2 ml-3"></Skeleton>
        </div>
        <div className="flex justify-content-start">
          <Skeleton width="200px" height="20px" className="mb-2"></Skeleton>
          <Skeleton width="650px" height="40px" className="mb-2  ml-3"></Skeleton>
        </div>
        <div className="flex justify-content-start">
          <Skeleton width="200px" height="20px" className="mb-2"></Skeleton>
          <Skeleton width="650px" height="40px" className="mb-2  ml-3"></Skeleton>
        </div>
      </div>
    </>
  )
}

export default QuestionDetailsSkeleton

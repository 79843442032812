import {
  EditMode,
  useUrlCopyToClipboard,
  useEdit,
  useToogleFavorite,
} from '../../common/dispatcherButtonHooks'
import BackButton from '../BackButton'
import { useDeleteModal } from '../../common/modalHooks'

interface Props {
  company: Company
  editMode: EditMode
}

const CompanyToolbar = ({ company, editMode }: Props): JSX.Element => {
  const toggleFavorite = useToogleFavorite('COMPANY', company)
  const copyToClipboard = useUrlCopyToClipboard('COMPANY', company)
  const deleteCompany = useDeleteModal('COMPANY', company, '/company/')
  const editCompany = useEdit('COMPANY', editMode)

  const actions = editMode.enabled ? (
    <>
      {editCompany.cancelDispatchterButton}
      {editCompany.submitDispatcherButton}
    </>
  ) : (
    <>
      {toggleFavorite.dispatcherButton}
      {copyToClipboard.dispatcherButton}
      {editCompany.editDispatcherButton}
      {deleteCompany.dispatcherButton}
    </>
  )

  return (
    <>
      {editMode.enabled ? (
        <div className="flex justify-content-end">
          <div>
            <div>{actions}</div>
          </div>
        </div>
      ) : (
        <div className="flex justify-content-between">
          <div className="flex-none ">
            <BackButton />
          </div>
          <div>{actions}</div>
        </div>
      )}
    </>
  )
}

export default CompanyToolbar

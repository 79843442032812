import { EditMode, useEdit, useQuestionPackExportButton } from '../../common/dispatcherButtonHooks'
import BackButton from '../BackButton'
import { useDeleteModal } from '../../common/modalHooks'

interface Props {
  questionPack?: QuestionPack
  editMode: EditMode
}

const QuestionPackToolbar = ({ questionPack, editMode }: Props): JSX.Element => {
  const deleteQuestionPack = useDeleteModal('QUESTIONPACK', questionPack, '/questionpack/')
  const editQuestionPack = useEdit('QUESTIONPACK', editMode)
  const exportQuestionPackButton = useQuestionPackExportButton(questionPack!)

  const actions = editMode.enabled ? (
    <>
      {editQuestionPack.cancelDispatchterButton}
      {editQuestionPack.submitDispatcherButton}
    </>
  ) : (
    <>
      {exportQuestionPackButton}
      {editQuestionPack.editDispatcherButton}
      {deleteQuestionPack.dispatcherButton}
    </>
  )

  return (
    <>
      {editMode.enabled ? (
        <div className="flex justify-content-end">
          <div>
            <div>{actions}</div>
          </div>
        </div>
      ) : (
        <div className="flex justify-content-between">
          <div className="flex-none ">
            <BackButton />
          </div>
          <div>{actions}</div>
        </div>
      )}
    </>
  )
}

export default QuestionPackToolbar

import { useEffect, useMemo, useState } from 'react'
import { useAppSelector } from '../../common/hooks'
import { useLazyOptionsPresetQuery } from '../../redux/api'
import { getDimensionLabel } from '../../common/utils'

interface Props {
  question: Question
  questionConstraint: ConstrainedQuestion
}

const styles = {
  noPaddingList: {
    paddingLeft: 0,
    listStylePosition: 'outside' as const,
    listStyleType: 'none' as const,
  },
  marginBottomLi: {
    marginBottom: '5px',
  },
}

const fillMissing = (option: any): { value: string; label: string } =>
  option.label === undefined
    ? { value: option.value, label: option.value }
    : option.value === undefined
      ? { value: option.label, label: option.label }
      : option

const QuestionOutputSingleSelect = ({ question, questionConstraint }: Props): JSX.Element => {
  const [queryOptionsPreset] = useLazyOptionsPresetQuery()
  const configOptionsPresetRef: string | undefined = question.config?.optionsPresetRef || undefined
  const configOptions: any[] = question.config?.options || []
  const [presetOptions, setPresetoptions] = useState<any[]>([])
  const workspaceId = useAppSelector((state) => state.app.workspace!.id)

  useEffect(() => {
    if (configOptionsPresetRef !== undefined) {
      queryOptionsPreset({
        type: 'SINGLE_SELECT',
        ref: configOptionsPresetRef,
        workspaceId: workspaceId,
      })
        .unwrap()
        .then((result: any[]) => setPresetoptions(result))
    }
  }, [])

  const options = useMemo(() => {
    let filtered = [
      ...presetOptions.map((option) => fillMissing(option)),
      ...configOptions.map((option) => fillMissing(option)),
    ].reduce((acc: any, obj) => {
      acc[obj.label] = obj
      return acc
    }, {})
    return Object.values(filtered) as { value: string; label: string }[]
  }, [presetOptions.length])

  // Render each dimension's single select value
  const renderDimensionValue = (answerData: AnswerData) => {
    const { dimensionValue, data } = answerData
    if (!data || data.value === null) return null

    const selectedOption =
      data.isOther === true
        ? data.value
        : options.find((option) => option.value === data.value)?.label

    const dimensionLabel = getDimensionLabel(
      questionConstraint.dimensions,
      questionConstraint.labels,
      answerData.dimensionIndex,
      answerData.dimensionValue,
    )

    return (
      <li
        style={styles.marginBottomLi}
        key={`dim-${answerData.dimensionIndex}${answerData.dimensionValue}`}
      >
        <strong>{dimensionLabel}</strong>
        {selectedOption}
      </li>
    )
  }

  return (
    <div>
      <ul style={styles.noPaddingList}>{question.answer?.data.map(renderDimensionValue)}</ul>
    </div>
  )
}

export default QuestionOutputSingleSelect

import { useEffect, useState } from 'react'
import { Steps } from 'primereact/steps'
import QuestionEditStepOne from './QuestionEditStepOne'
import editStyles from '../../styles/edit_modal.module.scss'
import QuestionEditStepTwo from './QuestionEditStepTwo'
import QuestionEditStepThree from './QuestionEditThree'
import { UpdateQuestion, useQuestionQuery } from '../../redux/api'
import { useAppSelector } from '../../common/hooks'
import QuestionDetailsSkeleton from './QuestionDetailsSkeleton'

interface Props {
  question: Question
  onFinished?: (question: Question) => void
}

const steps = [{ label: 'General' }, { label: 'Constraints' }, { label: 'Summary' }]

const QuestionEditContent = ({ question, onFinished = () => {} }: Props): JSX.Element => {
  const workspaceId = useAppSelector((state) => state.app.workspace!.id)
  const [step, setStep] = useState<number>(1)
  const [questionData, setQuestionData] = useState<UpdateQuestion>({
    id: question.id,
    type: question.type,
    phrase: question.phrase,
    description: question.description,
    geos: question.geos,
    capabilities: question.capabilities,
    tags: question.tags,
    constraintIsVisibleExpr: question.constraintIsVisibleExpr,
    constraintIsMandatoryExpr: question.constraintIsMandatoryExpr,
    isRfiTypeCompany: question.isRfiTypeCompany,
    isRfiTypeProduct: question.isRfiTypeProduct,
    isMustHave: question.isMustHave,
    isDefaultSelected: question.isDefaultSelected,
    labelsExpr: question.labelsExpr,
    dimensionsExpr: question.dimensionsExpr,
  })
  // need to reload question because missing geos, capabilities, tags
  const { data } = useQuestionQuery({
    workspaceId: workspaceId,
    questionId: questionData.id,
  })

  useEffect(() => {
    if (data !== undefined) {
      setQuestionData({
        id: data.id,
        type: data.type,
        phrase: data.phrase,
        description: data.description,
        geos: data.geos,
        capabilities: data.capabilities,
        tags: data.tags,
        constraintIsVisibleExpr: data.constraintIsVisibleExpr,
        constraintIsMandatoryExpr: data.constraintIsMandatoryExpr,
        isRfiTypeCompany: data.isRfiTypeCompany,
        isRfiTypeProduct: data.isRfiTypeProduct,
        isMustHave: data.isMustHave,
        isDefaultSelected: data.isDefaultSelected,
        labelsExpr: data.labelsExpr,
        dimensionsExpr: data.dimensionsExpr,
      })
    }
  }, [data])

  const renderSwitch = (stepToRender: number) => {
    switch (stepToRender) {
      case 1:
        return (
          <div className={editStyles.main}>
            <Steps model={steps} activeIndex={0} className={'mb-4'} />

            <QuestionEditStepOne
              questionData={questionData}
              setQuestionData={setQuestionData!}
              setStep={setStep}
            />
          </div>
        )
      case 2:
        return (
          <div className={editStyles.main}>
            <Steps model={steps} activeIndex={1} className={'mb-4'} />

            <QuestionEditStepTwo
              questionData={questionData}
              setQuestionData={setQuestionData!}
              setStep={setStep}
            />
          </div>
        )

      case 3:
        return (
          <div className={editStyles.main}>
            <Steps model={steps} activeIndex={2} className={'mb-4'} />

            <QuestionEditStepThree
              questionData={questionData}
              setStep={setStep}
              onFinished={onFinished}
            />
          </div>
        )

      default:
        return <></>
    }
  }

  return data === undefined ? <QuestionDetailsSkeleton /> : renderSwitch(step)
}

export default QuestionEditContent

import { Tooltip } from 'primereact/tooltip'
import { Toolbar } from 'primereact/toolbar'
import DispatcherButton, { DispatcherButtonPresets } from '../DispatcherButton'
import { useForm } from 'react-hook-form'
import { useState } from 'react'
import { setToastMessage } from '../../redux/app'
import { AppDispatch } from '../../redux/store'
import { useAppSelector, useGroupedTagList } from '../../common/hooks'
import { ReducedUpdateQuestion, UpdateQuestion, useQuestionUpdateMutation } from '../../redux/api'
import TagList, { NORMALIZE_PRESETS } from '../TagList'
import { Tag } from 'primereact/tag'
import editStyles from '../../styles/edit_modal.module.scss'
import { classNames } from 'primereact/utils'

interface Props {
  questionData: UpdateQuestion
  setStep: React.Dispatch<React.SetStateAction<number>>
  onFinished?: (question: Question) => void
}

const groupedTagListTemplate = (key: string, label: string, list: JSX.Element) => {
  return (
    <div className="card mb-3" key={key}>
      <div className="grid card-container">
        <div className="col-12 mb-2 md:col-2 md:mb-0 font-medium align-self-center">{label}:</div>
        <div className="col-12 md:col-10">{list}</div>
      </div>
    </div>
  )
}

const reduceSubmitData = (updateQuestion: UpdateQuestion): ReducedUpdateQuestion => {
  return {
    phrase: updateQuestion.phrase,
    description: updateQuestion.description,
    geos: updateQuestion.geos.map((geo) => ({ id: geo.id })),
    capabilities: updateQuestion.capabilities.map((capability) => ({ id: capability.id })),
    tags: updateQuestion.tags.map((tag) => ({ id: tag.id })),
    constraintIsVisibleExpr: updateQuestion.constraintIsVisibleExpr,
    constraintIsMandatoryExpr: updateQuestion.constraintIsMandatoryExpr,
    isRfiTypeCompany: updateQuestion.isRfiTypeCompany,
    isRfiTypeProduct: updateQuestion.isRfiTypeProduct,
    isMustHave: updateQuestion.isMustHave,
    isDefaultSelected: updateQuestion.isDefaultSelected,
  }
}

const QuestionEditStepThree = ({
  questionData,
  setStep,
  onFinished = () => {},
}: Props): JSX.Element => {
  const workspaceId = useAppSelector((state) => state.app.workspace!.id)
  const [submitted, setSubmitted] = useState(false)
  const [updateQuestion] = useQuestionUpdateMutation()
  const groupedTagList = useGroupedTagList(questionData.tags, groupedTagListTemplate)

  const editStepIcon = (step: number) => (
    <DispatcherButton
      buttonProps={{ icon: 'pi pi-pencil', onClick: () => setStep(step) }}
      className={DispatcherButtonPresets.PRIMARY_ICON_ACTIONS}
    />
  )

  const finishButton = (
    <DispatcherButton
      buttonProps={{ label: 'Finish', icon: 'pi pi-check', iconPos: 'right', disabled: submitted }}
      className={DispatcherButtonPresets.FILLED_ROUNDED_PRIMARY}
      onClick={async (dispatch: AppDispatch) => {
        setSubmitted(true)
        try {
          const response = await updateQuestion({
            workspaceId,
            questionId: questionData.id,
            data: reduceSubmitData(questionData),
          }).unwrap()
          onFinished(response)
          dispatch(
            setToastMessage({
              severity: 'success',
              summary: 'Question edited successfully.',
            }),
          )
        } catch {
          dispatch(
            setToastMessage({
              severity: 'error',
              summary: 'Question could not be edited.',
            }),
          )
          setSubmitted(false)
        }
      }}
    />
  )

  const renderOptions = () => {
    if (questionData?.isMustHave) {
      return 'Must-have, Selected by default'
    }

    if (questionData?.isDefaultSelected) {
      return 'Selected by default'
    }

    return '(none)'
  }

  const renderRfiTypes = () => {
    let rfiTypeString: String = ''
    if (questionData?.isRfiTypeCompany) {
      rfiTypeString += 'Company'
    }

    if (questionData?.isRfiTypeProduct) {
      rfiTypeString += 'Product'
    }

    if (questionData?.isRfiTypeProduct && questionData?.isRfiTypeCompany) {
      rfiTypeString = 'Company, Product'
    }

    return rfiTypeString
  }

  return (
    <>
      <div className={'flex align-items-center flex-row'}>
        <h3>Summary</h3>
      </div>
      <div className="flex align-items-center flex-wrap">
        <div className="mr-2">
          <h4>General Information</h4>
        </div>
        <div>{editStepIcon(1)}</div>
      </div>

      <div className="card mb-3">
        <div className="grid card-container">
          <div className="col-12 mb-2 md:col-2 md:mb-0 font-medium align-self-center">Phrase:</div>
          <div className="col-12 md:col-10">{questionData!.phrase}</div>
        </div>
      </div>
      <div className="card mb-3">
        <div className="grid card-container">
          <div className="col-12 mb-2 md:col-2 md:mb-0 font-medium align-self-center">
            Description:
          </div>
          <div className="col-12 md:col-10">{questionData!.description}</div>
        </div>
      </div>
      <div className="card mb-3">
        <div className="grid card-container">
          <div className="col-12 mb-2 md:col-2 md:mb-0 font-medium align-self-center">
            Dimensions Expression:
          </div>
          <div className="col-12 md:col-10">{questionData!.dimensionsExpr}</div>
        </div>
      </div>
      <div className="card mb-3">
        <div className="grid card-container">
          <div className="col-12 mb-2 md:col-2 md:mb-0 font-medium align-self-center">
            Labels Expression:
          </div>
          <div className="col-12 md:col-10">{questionData!.labelsExpr}</div>
        </div>
      </div>
      <div className="card mb-3">
        <div className="grid card-container">
          <div className="col-12 mb-2 md:col-2 md:mb-0 font-medium align-self-center">Type:</div>
          <div className="col-12 md:col-10">
            <Tag
              value={questionData.type.toLowerCase().replace(/_/g, ' ') || '(none)'}
              className={'bg-bluegray-500'}
            />
          </div>
        </div>
      </div>
      <div className="card mb-3">
        <div className="grid card-container">
          <div className="col-12 mb-2 md:col-2 md:mb-0 font-medium align-self-center">
            RFI Types:
          </div>
          <div className="col-12 md:col-10">{renderRfiTypes()}</div>
        </div>
      </div>
      <div className="card mb-3">
        <div className="grid card-container">
          <div className="col-12 mb-2 md:col-2 md:mb-0 font-medium align-self-center">Options:</div>
          <div className="col-12 md:col-10">{renderOptions()}</div>
        </div>
      </div>

      <div className="card mb-3">
        <div className="grid card-container">
          <div className="col-12 mb-2 md:col-2 md:mb-0 font-medium align-self-center">
            Exclusive Markets:
          </div>
          <div className="col-12 md:col-10">
            <TagList
              items={[
                questionData!.geos.filter((geo) => geo.type === null),
                questionData!.geos.filter((geo) => geo.type !== null),
              ]}
              emptyVal={'(none)'}
            />
          </div>
        </div>
      </div>

      <div className="card mb-3">
        <div className="grid card-container">
          <div className="col-12 mb-2 md:col-2 md:mb-0 font-medium align-self-center">
            Exclusive Capabilities:
          </div>
          <div className="col-12 md:col-10">
            <TagList
              items={questionData!.capabilities || []}
              normalize={NORMALIZE_PRESETS.POS}
              emptyVal={'(none)'}
              maxShown={10}
            />
          </div>
        </div>
      </div>

      {groupedTagList}

      <div className="flex align-items-center flex-wrap">
        <div className="mr-2">
          <h4>Constraints</h4>
        </div>
        <div>{editStepIcon(2)}</div>
      </div>

      <div className="card mb-3">
        <div className="grid card-container">
          <div className="col-12 mb-2 md:col-2 md:mb-0 font-medium">Visible:</div>
          <div className={classNames(editStyles.monospace, 'col-12 md:col-10 pr-5')}>
            {questionData!.constraintIsVisibleExpr}
          </div>
        </div>
      </div>

      <div className="card mb-3">
        <div className="grid card-container">
          <div className="col-12 mb-2 md:col-2 md:mb-0 font-medium">Mandatory:</div>
          <div className={classNames(editStyles.monospace, 'col-12 md:col-10 pr-5')}>
            {questionData!.constraintIsMandatoryExpr}
          </div>
        </div>
      </div>

      <Toolbar
        left={
          <DispatcherButton
            buttonProps={{
              icon: 'pi pi-arrow-left',
              label: 'Back',
              type: 'button',
            }}
            className={DispatcherButtonPresets.OUTLINED_PRIMARY}
            onClick={() => setStep((step) => step - 1)}
          />
        }
        right={finishButton}
      />
    </>
  )
}

export default QuestionEditStepThree

import PrimeReact from 'primereact/api'

PrimeReact.ripple = true

const Footer = (): JSX.Element => {
  const footerText = `Copyright ${new Date().getFullYear()} GroupM, Global Innovation Group.`

  return (
    <footer
      className={`w-full shadow-2 flex align-items-center justify-content-end relative lg:static`}
      style={{ height: '70px', backgroundColor: 'rgb(24, 24, 27)' }}
    >
      <div className="mr-5 text-white">{footerText}</div>
    </footer>
  )
}

export default Footer

import { forwardRef } from 'react'
import { DataTable, DataTableProps, DataTableSortOrderType } from 'primereact/datatable'
import { ActionsColumn, CreatorColumn, SimpleColumn, TagListColumn } from './columnProvider'
import { PaginatorCurrentPageReportOptions } from 'primereact/paginator'
import { Link } from 'react-router-dom'
import { ListData } from '../../redux/api'

interface Presets {
  listPage: (
    value?: any[],
    sortField?: string,
    sortOrder?: DataTableSortOrderType,
  ) => DataTableProps
  homePage: (
    listPageLink: string,
    rows: number,
    listData?: ListData<any>,
    sortField?: string,
    sortOrder?: DataTableSortOrderType,
  ) => DataTableProps
  rfiWizard: (value?: any[]) => DataTableProps
}

export const DataTablePropsPresets: Presets = {
  listPage: (value, sortField = 'name', sortOrder = 1) => {
    return {
      value: value,
      sortField: sortField,
      sortOrder: sortOrder,
      paginator: (value || []).length > 0,
      rows: 10,
      rowHover: true,
      alwaysShowPaginator: false,
      paginatorTemplate: 'FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink',
      currentPageReportTemplate: 'Page {currentPage} of {totalPages}',
    }
  },
  rfiWizard: (value) => {
    return { ...DataTablePropsPresets.listPage(value), ...{ rows: 5 } }
  },
  homePage: (listPageLink, rows, listData, sortField = 'createdAt', sortOrder = -1) => {
    return {
      lazy: listData?.meta !== undefined,
      value: listData?.content,
      sortField: sortField,
      sortOrder: sortOrder,
      paginator: (listData?.content || []).length > 0,
      rows: rows,
      totalRecords: listData?.meta?.totalSize,
      rowHover: true,
      alwaysShowPaginator: false,
      paginatorTemplate: {
        layout: 'CurrentPageReport',
        CurrentPageReport: (options: PaginatorCurrentPageReportOptions) => {
          return (
            <Link
              to={listPageLink}
              style={{
                color: 'var(--text-primary)',
                userSelect: 'none',
                textDecoration: 'none',
                textAlign: 'right',
                width: '100%',
              }}
            >
              <strong style={{ color: 'var(--text-primary) !important' }}>Show all</strong>
              <i className="pi pi-arrow-right ml-2" />
            </Link>
          )
        },
      },
    }
  },
}

interface Props {
  isLoading?: boolean
  dataTableProps?: DataTableProps
  columns?: (SimpleColumn | CreatorColumn | TagListColumn | ActionsColumn)[]
}

const DataTableProvider = forwardRef<DataTable, Props>(
  (
    { isLoading = false, dataTableProps = {}, columns = [] }: Props,
    ref: React.ForwardedRef<DataTable>,
  ) => {
    if (isLoading) {
      dataTableProps.value = [{}]
    }

    return (
      <DataTable ref={ref} {...dataTableProps}>
        {columns.map((column) => column.build(isLoading))}
      </DataTable>
    )
  },
)

export default DataTableProvider

import { useAppSelector } from '../../common/hooks'
import BackButton from '../../components/BackButton'
import FavoriteList from '../../components/Favorite/FavoriteList'
import { useFavoriteListQuery } from '../../redux/api'
import { AppError, setError } from '../../redux/app'

const FavoriteListPage = (): JSX.Element => {
  const currentWorkSpace = useAppSelector((state) => state.app.workspace!.id)

  const { data, error, isLoading, isFetching } = useFavoriteListQuery({
    workspaceId: currentWorkSpace,
  })

  if (error) {
    setError(AppError.COMPANY_API_REQUEST)
  }

  return (
    <div className="px-4 py-5 md:px-6 lg:px-8">
      <div className="flex-none ">
        <BackButton />
      </div>
      <FavoriteList
        toolbarLeft={<h1>{'My Favorites'}</h1>}
        data={data}
        isLoading={isLoading || isFetching}
      />
    </div>
  )
}

export default FavoriteListPage

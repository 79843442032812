import { useNavigate } from 'react-router-dom'
import ProductCreateContent from '../../components/Product/ProductCreateContent'
import ProductCreateToolbar from '../../components/Product/ProductCreateToolbar'

interface Props {
  company?: Company
}

const ProductCreatePage = ({ company }: Props): JSX.Element => {
  const navigate = useNavigate()
  return (
    <div className="px-4 py-5 md:px-6 lg:px-8">
      <ProductCreateToolbar />
      <ProductCreateContent
        company={company}
        onContentCreated={(product: Product) => navigate(`/product/${product.id}`)}
      />
    </div>
  )
}

export default ProductCreatePage

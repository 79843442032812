import { getDimensionLabel } from '../../common/utils'

interface Props {
  question: Question
  questionConstraint: ConstrainedQuestion
}

const styles = {
  noPaddingList: {
    paddingLeft: 0,
    listStylePosition: 'outside' as const,
    listStyleType: 'none' as const,
  },
  marginBottomLi: {
    marginBottom: '5px',
  },
}

const QuestionOutputNumber = ({ question, questionConstraint }: Props): JSX.Element => {
  const precision = question.config?.precision || 0

  // Render each dimension's number
  const renderDimensionNumber = (answerData: AnswerData) => {
    if (!answerData.data || answerData.data.value === null) return null

    const numberValue = answerData.data.value.toLocaleString('en-US', {
      minimumFractionDigits: precision,
      maximumFractionDigits: precision,
    })
    const unit = answerData.data.unit ? ` ${answerData.data.unit}` : ''

    const dimensionLabel = getDimensionLabel(
      questionConstraint.dimensions,
      questionConstraint.labels,
      answerData.dimensionIndex,
      answerData.dimensionValue,
    )

    return (
      <li
        style={styles.marginBottomLi}
        key={`dim-${answerData.dimensionIndex}${answerData.dimensionValue}`}
      >
        <strong>{dimensionLabel}</strong>
        {numberValue}
        {unit}
      </li>
    )
  }

  return (
    <div>
      <ul style={styles.noPaddingList}>{question.answer?.data.map(renderDimensionNumber)}</ul>
    </div>
  )
}

export default QuestionOutputNumber

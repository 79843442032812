import { Tag } from 'primereact/tag'
import React from 'react'

interface Props {
  status: string
  progress: number
}

const ProgressTag = ({ status, progress }: Props): JSX.Element => {
  return (
    <Tag className={'lg:mb-2 bg-primary-reverse p-0 '}>
      <div className="p-inputgroup ">
        <div className="w-auto align-self-center p-2 border-1 border-round-left">
          <span style={{ whiteSpace: 'nowrap' }}>{String(status).replace(/_/g, ' ')}</span>
        </div>
        <div className="p-inputgroup-addon bg-primary p-0 border-transparent">{`${progress}%`}</div>
      </div>
    </Tag>
  )
}

export default ProgressTag

import { Card } from 'primereact/card'
import { Link } from 'react-router-dom'
import { Toolbar } from 'primereact/toolbar'
import styles from '../../styles/list.module.scss'
import DataTableProvider, { DataTablePropsPresets } from '../DataTable/DataTableProvider'
import { actionsColumn, simpleColumn, tagListColumn } from '../DataTable/columnProvider'
import { useDeleteModal } from '../../common/modalHooks'
import { NORMALIZE_PRESETS } from '../TagList'
import { DataTableProps } from 'primereact/datatable'
import { useDataTableFilter } from '../../common/hooks'

interface Props {
  data?: QuestionPack[]
  toolbarLeft?: JSX.Element
  hiddenColumns?: number[]
  isLoading?: boolean
  useSorting?: boolean
  showFilter?: boolean
  dataTableProps?: DataTableProps
}

const QuestionPackList = ({
  data,
  toolbarLeft = <h1>Question Packs</h1>,
  hiddenColumns = [],
  isLoading = true,
  useSorting = true,
  showFilter = false,
  dataTableProps = DataTablePropsPresets.listPage(data),
}: Props): JSX.Element => {
  const deleteModal = useDeleteModal('QUESTIONPACK')

  const columns = [
    simpleColumn({
      columnProps: {
        header: 'Name',
        field: 'name',
        sortable: useSorting,
        className: styles['first-column'],
        body: (questionPack: QuestionPack) => {
          return <Link to={`/questionpack/${questionPack.id}`}>{questionPack.name}</Link>
        },
      },
    }),
    simpleColumn({
      columnProps: {
        header: 'Type (Scope)',
        field: 'typeScope',
        sortable: useSorting,
        className: styles['column'],
        style: { width: '20%' },
      },
    }),
    tagListColumn({
      columnProps: {
        header: 'Market',
        field: 'market',
        sortable: useSorting,
        className: styles['column'],
        style: { width: '30%' },
      },
      rowDataSelector: (questionPack: QuestionPack) => [questionPack.geo],
    }),
    tagListColumn({
      columnProps: {
        header: 'Capabilities',
        className: styles['column'],
        style: { width: '30%' },
      },
      rowDataSelector: (questionPack: QuestionPack) => questionPack.capabilities,
      normalize: NORMALIZE_PRESETS.POS,
    }),
    actionsColumn({
      columnProps: {
        align: 'right',
        alignHeader: 'center',
        className: styles['action-column'],
      },
      dispatcherButtonsProps: [...deleteModal.dispatcherButtonProps],
    }),
  ].filter((column, index) => !hiddenColumns.includes(index))

  data = data?.map((questionPack) => {
    return {
      ...questionPack,
      ...{
        typeScope: `${questionPack.questionPackType.name} (${questionPack.questionPackType.scope})`,
        market: questionPack.geo.name,
      },
    }
  })
  dataTableProps = { ...dataTableProps, ...{ value: data } }

  if (showFilter) {
    data = data?.map((questionPack) => {
      return {
        ...questionPack,
        ...{
          capabilitiesJoin: questionPack.capabilities
            .map((capability) => capability.name)
            .join(' '),
        },
      }
    })
    dataTableProps = {
      ...dataTableProps,
      ...{ value: data },
      ...useDataTableFilter(['name', 'typeScope', 'market', 'capabilitiesJoin']),
    }
  }

  return (
    <>
      <div className={styles.list}>
        <Toolbar left={toolbarLeft} right={<></>} />
        <Card>
          <DataTableProvider
            dataTableProps={dataTableProps}
            columns={columns}
            isLoading={isLoading}
          />
        </Card>
      </div>
    </>
  )
}

export default QuestionPackList

import { useAppDispatch, useAppSelector } from '../../common/hooks'
import { Calendar } from 'primereact/calendar'
import { Toolbar } from 'primereact/toolbar'
import { useParticipantExtendMutation } from '../../redux/api'
import { setToastMessage } from '../../redux/app'
import DispatcherButton, { DispatcherButtonPresets } from '../DispatcherButton'
import { Message } from 'primereact/message'

import styles from '../../styles/edit_modal.module.scss'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'

interface FormData {
  endDate: string
}

interface Props {
  participant: Participant
  onCreated: () => void
}

const ParticipantExtend = ({ participant, onCreated }: Props): JSX.Element => {
  const workspaceId = useAppSelector((state) => state.app.workspace!.id)
  const dispatch = useAppDispatch()
  const [extendParticipant] = useParticipantExtendMutation()

  const { control, handleSubmit } = useForm<FormData>({
    defaultValues: {
      endDate: participant.endDate,
    },
  })

  const onSubmit: SubmitHandler<FormData> = async (formData) => {
    try {
      if (formData.endDate !== participant.endDate) {
        await extendParticipant({
          workspaceId: workspaceId,
          itemId: participant.id,
          updateItem: { endDate: formData.endDate },
        })
        dispatch(
          setToastMessage({
            severity: 'success',
            summary: `Participant "${participant.company.name}" extended successfully.`,
          }),
        )
      }
      onCreated()
    } catch (error) {
      dispatch(
        setToastMessage({
          severity: 'error',
          summary: `Participant "${participant.company.name}" could not be extended.`,
        }),
      )
    }
  }

  return (
    <div className={` ${styles.edit_modal}`}>
      <Message
        severity="warn"
        text={`This will extend the end date for participant "${participant.company.name}" only.`}
      />

      <form className="mt-4" onSubmit={handleSubmit(onSubmit)}>
        <div className="grid align-items-center">
          <label htmlFor="startDate" className="col-2">
            Start Date:
          </label>
          <div className="col-9 bg-surface-500 pl-0">
            <span>{participant.startDate}</span>
          </div>
        </div>
        <div className="grid align-items-center mt-2">
          <label htmlFor="basic" className="col-2">
            End Date:
          </label>
          <div className="col-9 bg-surface-500 pl-0">
            <Controller
              name="endDate"
              control={control}
              render={({ field }) => (
                <Calendar
                  id={field.name}
                  showIcon
                  showButtonBar
                  dateFormat="yy-mm-dd"
                  minDate={new Date(Date.parse(participant.endDate))}
                  value={new Date(Date.parse(field.value))}
                  onChange={(e) => {
                    if (null !== e.target.value) {
                      const date = e.target.value as Date
                      const normalized = new Date(
                        Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()),
                      )
                      field.onChange(normalized.toISOString().slice(0, 10))
                    }
                  }}
                />
              )}
            />
          </div>
        </div>
        <Toolbar
          right={
            <DispatcherButton
              buttonProps={{ label: 'Extend Participant', type: 'submit' }}
              className={DispatcherButtonPresets.OUTLINED_PRIMARY}
            />
          }
        />
      </form>
    </div>
  )
}

export default ParticipantExtend

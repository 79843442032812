import React from 'react'
import { RfiStatus } from '../../enums'
import ParticipantQuestionAnswersContentPublished from './ParticipantQuestionAnswersContentPublished'

const contentIsComingSoon = (participant: Participant): JSX.Element => (
  <div className="flex flex-column gap-3 col-8 col-offset-2 align-items-center">
    <i className={`pi pi-comments`} style={{ fontSize: '10rem', color: 'var(--surface-400)' }}></i>
    <h3 className="text-center">
      <strong>{participant.company.name}</strong> is in the process of answering the{' '}
      <strong>"{participant.rfi.name}"</strong> RFI.
    </h3>
    <p>The answers will be published soon.</p>
  </div>
)

const contentForOtherStatus = (participant: Participant): JSX.Element => {
  const configByStatus = (status: string) => {
    switch (status) {
      case RfiStatus.ARCHIVED:
        return {
          icon: 'pi pi-inbox',
          mainText: 'The answers were archived.',
          subText: `Check more recent RFIs answered by <strong>${participant.company.name}</strong> or create a new RFI.`,
        }
      case RfiStatus.WITHDRAWN:
        return {
          icon: 'pi pi-ban',
          mainText: 'The answers were withdrawn.',
          subText: `Check other RFIs answered by <strong>${participant.company.name}</strong> or create a new RFI.`,
        }
      case RfiStatus.EXPIRED:
        return {
          icon: 'pi pi-times-circle',
          mainText: `The runtime for <strong>${participant.company.name}</strong> answering the <strong>"${participant.rfi.name}"</strong> RFI has expired.`,
          subText: `Check other RFIs answered by <strong>${participant.company.name}</strong> or create a new RFI.`,
        }
      default:
        return {
          icon: '',
          mainText: '',
          subText: '',
        }
    }
  }
  return (
    <div className="flex flex-column gap-3 col-8 col-offset-2 align-items-center ">
      <i
        className={`pi ${configByStatus(participant.status || '')?.icon}`}
        style={{ fontSize: '10rem', color: 'var(--surface-400)' }}
      ></i>
      <h3
        className="text-center"
        dangerouslySetInnerHTML={{ __html: configByStatus(participant.status || '').mainText }}
      ></h3>
      <p dangerouslySetInnerHTML={{ __html: configByStatus(participant.status || '').subText }}></p>
    </div>
  )
}

interface Props {
  participant: Participant
}

const ParticipantTabQuestionAnswers = ({ participant }: Props): JSX.Element => {
  const renderContent = (): JSX.Element => {
    if (participant.status === RfiStatus.PUBLISHED) {
      return <ParticipantQuestionAnswersContentPublished participant={participant} />
    }
    if (participant.isComingSoon) {
      return contentIsComingSoon(participant)
    } else {
      switch (participant.status) {
        case RfiStatus.ARCHIVED:
        case RfiStatus.WITHDRAWN:
        case RfiStatus.EXPIRED:
          return contentForOtherStatus(participant)
        default:
          return <></>
      }
    }
  }

  return renderContent()
}

export default ParticipantTabQuestionAnswers

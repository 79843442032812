import { Card } from 'primereact/card'
import { ProgressBar } from 'primereact/progressbar'
import { Tag } from 'primereact/tag'
import { classNames } from 'primereact/utils'
import { useNavigate } from 'react-router-dom'
import { useToogleFavorite } from '../../common/dispatcherButtonHooks'
import { useAppSelector } from '../../common/hooks'
import { RfiStatus } from '../../enums'
import { useRfiQuery } from '../../redux/api'
import styles from '../../styles/favorites.module.scss'
import DispatcherButton from '../DispatcherButton'
import FavoriteCardSkeleton from '../Favorite/FavoriteCardSkeleton'
import TagList, { NORMALIZE_PRESETS } from '../TagList'
import ProgressTag from '../ProgressTag'

interface Props {
  data: Favorite
  customClasses?: string
  customStyles?: React.CSSProperties
}

const RfiFavoriteCard = ({ data, customClasses = '', customStyles }: Props): JSX.Element => {
  const workspaceId = useAppSelector((state) => state.app.workspace!.id)
  const {
    data: rfiData,
    isLoading,
    isFetching,
  } = useRfiQuery({
    workspaceId: workspaceId,
    itemId: data._id,
  })

  const getStatus =
    rfiData?.status === RfiStatus.IN_PROGRESS ? (
      <ProgressTag status={rfiData?.status} progress={rfiData?.progress} />
    ) : (
      <Tag
        className="bg-primary-reverse border-1"
        value={String(rfiData?.status).replace(/_/g, ' ')}
      ></Tag>
    )

  const navigate = useNavigate()
  const toggleFavorite = useToogleFavorite('RFI', rfiData)
  const header = (
    <>
      {toggleFavorite.dispatcherButton}
      <DispatcherButton
        buttonProps={{
          icon: 'pi pi-external-link',
          tooltip: 'Open',
        }}
        onClick={() => navigate(`/rfi/${rfiData?.id}`)}
      />
    </>
  )

  if (isLoading && isFetching) return <FavoriteCardSkeleton customClasses="col-12 md:col-4" />

  return (
    <Card
      header={header}
      className={classNames(styles.favorites, customClasses)}
      title="RFI"
      subTitle={rfiData?.name}
      style={customStyles}
    >
      <div className="flex flex-column lg:flex-row column-gap-4">
        <div className="font-medium col-12 md:col-3 pl-0">Type:</div>
        <div className="col pl-0">
          <div className="tooltip-wrapper">{rfiData?.type}</div>
        </div>
      </div>
      <div className="flex flex-column lg:flex-row column-gap-4">
        <div className="font-medium col-12 md:col-3 pl-0">Market:</div>
        <div className="col pl-0">
          <div className="tooltip-wrapper">
            <Tag className="bg-bluegray-500" value={rfiData?.geo.name} />
          </div>
        </div>
      </div>
      <div className="flex flex-column lg:flex-row column-gap-4">
        <div className="font-medium col-12 md:col-3 pl-0">Capabilities:</div>
        <div className="col pl-0">
          <TagList
            items={rfiData?.capabilities || []}
            normalize={NORMALIZE_PRESETS.POS}
            emptyVal={'(none)'}
          />
        </div>
      </div>
      <div className="flex flex-column lg:flex-row column-gap-4">
        <div className="font-medium col-12 md:col-3 pl-0">Status:</div>
        <div className="col pl-0">{getStatus}</div>
      </div>
    </Card>
  )
}

export default RfiFavoriteCard

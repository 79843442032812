import { getDimensionLabel } from '../../common/utils'

interface Props {
  question: Question
  questionConstraint: ConstrainedQuestion
}

const styles = {
  noPaddingList: {
    paddingLeft: 0,
    listStylePosition: 'outside' as const,
    listStyleType: 'none' as const,
  },

  marginBottomLi: {
    marginBottom: '5px',
  },
}

const QuestionOutputBoolean = ({ question, questionConstraint }: Props): JSX.Element => {
  const trueLabel = question.config?.trueLabel || 'Yes'
  const falseLabel = question.config?.falseLabel || 'No'
  // Render each dimension's answer
  const renderDimensionAnswer = (answerData: AnswerData) => {
    if (!answerData.data) return null

    // Use the labels when rendering the answer
    const valueLabel = answerData.data.value ? trueLabel : falseLabel
    const reason = answerData.data.reason?.value ? ` (${answerData.data.reason?.value})` : ''

    const dimensionLabel = getDimensionLabel(
      questionConstraint.dimensions,
      questionConstraint.labels,
      answerData.dimensionIndex,
      answerData.dimensionValue,
    )

    return (
      <li
        style={styles.marginBottomLi}
        key={`dim-${answerData.dimensionIndex}${answerData.dimensionValue}`}
      >
        <strong>{dimensionLabel}</strong>
        {valueLabel}
        {reason}
      </li>
    )
  }
  return <ul style={styles.noPaddingList}>{question.answer?.data.map(renderDimensionAnswer)}</ul>
}

export default QuestionOutputBoolean

import React, { useRef, useState } from 'react'
import DispatcherButton, {
  DispatcherButtonPresets,
  DispatcherButtonProps,
} from '../components/DispatcherButton'
import { confirmDialog } from 'primereact/confirmdialog'
import {
  useCompanyDeleteMutation,
  useContactDeleteMutation,
  useParticipantArchiveMutation,
  useParticipantDeleteMutation,
  useParticipantWithdrawMutation,
  useProductDeleteMutation,
  useQuestionPackDeleteMutation,
  useRfiDeleteMutation,
  useRfiWithdrawMutation,
} from '../redux/api'
import { setToastMessage } from '../redux/app'
import ContactCreatePage from '../scenes/contact/ContactCreatePage'
import { useAppDispatch, useAppSelector, usePermission } from './hooks'
import { Dialog } from 'primereact/dialog'
import { AppDispatch } from '../redux/store'
import { useNavigate } from 'react-router-dom'
import ContactEditPage from '../scenes/contact/ContactEditPage'
import ProductCreateContent from '../components/Product/ProductCreateContent'
import RfiExtend from '../components/RFI/RfiExtend'
import ParticipantExtend from '../components/Participant/ParticipantExtend'
import ParticipantCanSend from '../components/Participant/ParticipantCanSend'
import CompanyCreateContent from '../components/Company/CompanyCreateContent'
import QuestionEditContent from '../components/Question/QuestionEditContent'
import ParticipantAddCompany from '../components/Participant/ParticipantAddCompany'
import ParticipantAddProduct from '../components/Participant/ParticipantAddProduct'
import { RfiType } from '../enums'

// DELETE MODAL ...

export const useDeleteModal = (
  type: 'PRODUCT' | 'COMPANY' | 'CONTACT' | 'QUESTIONPACK' | 'PARTICIPANT' | 'RFI',
  item?: Product | Company | Contact | QuestionPack | Participant | Rfi,
  navigateTo?: string,
): {
  dispatcherButtonProps: DispatcherButtonProps[]
  dispatcherButton: JSX.Element
} => {
  const createConfig = () => {
    if (type === 'PRODUCT') {
      return {
        hasPermission: usePermission('product', 'delete'),
        mutation: useProductDeleteMutation,
        confirmHeader: (item: Product) => `Delete product "${item.name}"?`,
        confirmMessage: (item: Product) =>
          `Are you sure you want to delete the product "${item.name}"?`,
        acceptMessage: (item: Product) => `Product "${item.name}" deleted successfully.`,
        rejectMessage: (item: Product) => `Product "${item.name}" could not be deleted.`,
      }
    }
    if (type === 'COMPANY') {
      return {
        hasPermission: usePermission('company', 'delete'),
        mutation: useCompanyDeleteMutation,
        confirmHeader: (item: Company) => `Delete company "${item.name}"?`,
        confirmMessage: (item: Company) =>
          `Are you sure you want to delete the company "${item.name}", including all its products and contacts?`,
        acceptMessage: (item: Company) => `Company "${item.name}" deleted successfully.`,
        rejectMessage: (item: Company) => `Company "${item.name}" could not be deleted.`,
      }
    }
    if (type === 'CONTACT') {
      return {
        hasPermission: usePermission('contact', 'delete'),
        mutation: useContactDeleteMutation,
        confirmHeader: (item: Contact) =>
          `Delete contact "${item.firstName} ${item.middleNames ? item.middleNames : ''} ${
            item.lastName
          }"?`,
        confirmMessage: (item: Contact) =>
          `Are you sure you want to delete the contact "${item.firstName} ${
            item.middleNames ? item.middleNames : ''
          } ${item.lastName}"?`,
        acceptMessage: (item: Contact) =>
          `Contact "${item.firstName} ${item.middleNames ? item.middleNames : ''} ${
            item.lastName
          }" deleted successfully.`,
        rejectMessage: (item: Contact) =>
          `Contact "${item.firstName} ${item.middleNames ? item.middleNames : ''} ${
            item.lastName
          }" could not be deleted.`,
      }
    }
    if (type === 'QUESTIONPACK') {
      return {
        hasPermission: usePermission('question_pack', 'delete'),
        mutation: useQuestionPackDeleteMutation,
        confirmHeader: (item: QuestionPack) => `Delete question pack "${item.name}"?`,
        confirmMessage: (item: QuestionPack) =>
          `Are you sure you want to delete the question pack "${item.name}"?`,
        acceptMessage: (item: QuestionPack) => `Question pack "${item.name}" deleted successfully.`,
        rejectMessage: (item: QuestionPack) => `Question pack "${item.name}" could not be deleted.`,
      }
    }
    if (type === 'PARTICIPANT') {
      return {
        hasPermission: usePermission('participant', 'delete'),
        mutation: useParticipantDeleteMutation,
        confirmHeader: (item: Participant) => `Delete participant "${item.company.name}"?`,
        confirmMessage: (item: Participant) =>
          `Are you sure you want to delete the participant "${item.company.name}"?`,
        acceptMessage: (item: Participant) =>
          `Participant "${item.company.name}" deleted successfully.`,
        rejectMessage: (item: Participant) =>
          `Participant "${item.company.name}" could not be deleted.`,
      }
    }
    if (type === 'RFI') {
      return {
        hasPermission: usePermission('rfi', 'delete'),
        mutation: useRfiDeleteMutation,
        confirmHeader: (item: Rfi) => `Delete RFI "${item.name}"?`,
        confirmMessage: (item: Rfi) => `Are you sure you want to delete the RFI "${item.name}"?`,
        acceptMessage: (item: Rfi) => `RFI "${item.name}" deleted successfully.`,
        rejectMessage: (item: Rfi) => `RFI "${item.name}" could not be deleted.`,
      }
    }
  }

  const config = createConfig()

  const [deleteMutation] = config!.mutation()

  const workspaceId = useAppSelector((state) => state.app.workspace!.id)

  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const openConfirmDeleteDialog = (item: any) => {
    const accept = async () => {
      try {
        if (navigateTo !== undefined) navigate(navigateTo)
        await deleteMutation({
          workspaceId: workspaceId,
          itemId: item.id,
        }).unwrap()
        dispatch(
          setToastMessage({
            severity: 'success',
            summary: config!.acceptMessage(item),
          }),
        )
      } catch (error) {
        dispatch(
          setToastMessage({
            severity: 'error',
            summary: config!.rejectMessage(item),
          }),
        )
      }
    }

    confirmDialog({
      message: config!.confirmMessage(item),
      header: config!.confirmHeader(item),
      acceptClassName: 'p-button-danger',
      accept,
    })
  }

  const buttonProps = {
    icon: 'pi pi-trash',
    tooltip: 'Delete',
  }

  const onClick = (dispatch: AppDispatch, data: Product | Company | Contact | Rfi | QuestionPack) =>
    openConfirmDeleteDialog(data)

  const dispatcherButtonProps = config!.hasPermission
    ? [
        {
          buttonProps,
          className: DispatcherButtonPresets.PRIMARY_ICON_ACTIONS,
          onClick,
        },
      ]
    : []

  const dispatcherButton = config!.hasPermission ? (
    <DispatcherButton buttonProps={buttonProps} onClick={onClick} data={item} />
  ) : (
    <></>
  )

  return { dispatcherButtonProps, dispatcherButton }
}

// ... DELETE MODAL

// CREATE MODAL ...

export const useCreateModal = (
  type: 'PRODUCT' | 'COMPANY' | 'CONTACT',
  company?: Company,
  header?: string,
  onCreated?: (createdContent: Product | Company | Contact) => void,
): {
  dispatcherButtonProps: DispatcherButtonProps[]
  dispatcherButton: JSX.Element
  modal: JSX.Element
  setVisible: React.Dispatch<React.SetStateAction<boolean>>
} => {
  const [visible, setVisible] = useState(false)

  const config = {
    PRODUCT: {
      header: header || 'Create Product',
      content: (
        <ProductCreateContent
          company={company}
          onContentCreated={(createdProduct) => {
            setVisible(false)
            if (onCreated !== undefined) {
              onCreated(createdProduct)
            }
          }}
        />
      ),
      hasPermission: usePermission('product', 'create'),
    },
    COMPANY: {
      header: header || 'Create Company',
      content: (
        <CompanyCreateContent
          onContentCreated={(createdCompany) => {
            setVisible(false)
            if (onCreated !== undefined) {
              onCreated(createdCompany)
            }
          }}
        />
      ),
      hasPermission: usePermission('company', 'create'),
    },
    CONTACT: {
      header: header || 'Create Contact',
      content: (
        <ContactCreatePage
          company={company!}
          onContentCreated={(createdContact) => {
            setVisible(false)
            if (onCreated !== undefined) {
              onCreated(createdContact)
            }
          }}
        />
      ),
      hasPermission: usePermission('contact', 'create'),
    },
  }

  const onHide = () => {
    const confirmHideDialog = confirmDialog({
      message: 'Are you sure you want to cancel?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => setVisible(false),
      reject: () => confirmHideDialog.hide(),
    })
  }

  const modal = (
    <Dialog
      onHide={onHide}
      visible={visible}
      closable={true}
      blockScroll={true}
      breakpoints={{ '960px': '75vw', '640px': '100vw' }}
      style={{ width: '50vw' }}
      header={config[type].header}
      draggable={false}
      className={'createModal'}
    >
      {config[type].content}
    </Dialog>
  )

  const buttonProps = { icon: 'pi pi-plus', label: config[type].header }

  const dispatcherButtonProps = config[type].hasPermission
    ? [
        {
          buttonProps,
          className: DispatcherButtonPresets.PRIMARY_ICON_ACTIONS,
          onClick: () => setVisible(true),
        },
      ]
    : []

  const dispatcherButton = config[type].hasPermission ? (
    <DispatcherButton
      buttonProps={buttonProps}
      className={DispatcherButtonPresets.FILLED_ROUNDED_PRIMARY}
      onClick={() => setVisible(true)}
    />
  ) : (
    <></>
  )

  return { dispatcherButtonProps, dispatcherButton, modal, setVisible }
}

// ... CREATE MODAL

// EDIT MODAL ...

export const useEditModal = (
  type: 'CONTACT' | 'QUESTION',
  item?: Contact | Question,
): {
  dispatcherButtonProps: DispatcherButtonProps[]
  dispatcherButton: JSX.Element
  modal: JSX.Element
  setVisible: React.Dispatch<React.SetStateAction<boolean>>
} => {
  const [visible, setVisible] = useState(false)
  const [modalContent, setModalContent] = useState(<></>)

  const config = {
    CONTACT: {
      header: 'Edit Contact',
      hasPermission: usePermission('contact', 'update'),
      onClick: (dispatch: AppDispatch, data: Contact) => {
        setModalContent(<ContactEditPage contact={data} onFinish={() => setVisible(false)} />)
        setVisible(true)
      },
    },
    QUESTION: {
      header: 'Edit Question',
      hasPermission: usePermission('question', 'update'),
      onClick: (dispatch: AppDispatch, data: Question) => {
        setModalContent(
          <QuestionEditContent question={data} onFinished={() => setVisible(false)} />,
        )
        setVisible(true)
      },
    },
  }

  const onHide = () => {
    const confirmHideDialog = confirmDialog({
      message: 'Are you sure you want to cancel?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => setVisible(false),
      reject: () => confirmHideDialog.hide(),
    })
  }

  const modal = (
    <Dialog
      onHide={onHide}
      visible={visible}
      closable={true}
      blockScroll={true}
      breakpoints={{ '960px': '75vw', '640px': '100vw' }}
      style={{ width: '50vw' }}
      header={config[type].header}
      draggable={false}
      className={'editModal'}
    >
      {modalContent}
    </Dialog>
  )

  const buttonProps = {
    icon: 'pi pi-pencil',
    tooltip: 'Edit',
  }

  const dispatcherButtonProps = config[type].hasPermission
    ? [
        {
          buttonProps,
          className: DispatcherButtonPresets.PRIMARY_ICON_ACTIONS,
          onClick: config[type].onClick,
        },
      ]
    : []

  const dispatcherButton = config[type].hasPermission ? (
    <DispatcherButton
      buttonProps={buttonProps}
      className={DispatcherButtonPresets.OUTLINED_PRIMARY}
      onClick={config[type].onClick}
      data={item}
    />
  ) : (
    <></>
  )

  return { dispatcherButtonProps, dispatcherButton, modal, setVisible }
}

// ... EDIT MODAL

// EXTEND MODAL ...
export const useExtendModal = (
  type: 'RFI' | 'PARTICIPANT',
  item?: Rfi | Participant,
): {
  dispatcherButtonProps: DispatcherButtonProps[]
  dispatcherButton: JSX.Element
  modal: JSX.Element
} => {
  const [visible, setVisible] = useState(false)
  const [itemData, setItemData] = useState<Rfi | Participant>()

  const config = {
    RFI: {
      header: 'Extend RFI End Date',
      content: <RfiExtend rfi={itemData as Rfi} onCreated={() => setVisible(false)} />,
      hasPermission: usePermission('rfi', 'extend'),
    },
    PARTICIPANT: {
      header: 'Extend Participant End Date',
      content: (
        <ParticipantExtend
          participant={itemData as Participant}
          onCreated={() => setVisible(false)}
        />
      ),
      hasPermission: usePermission('participant', 'extend'),
    },
  }

  const onClick = async (dispatch: AppDispatch, item: Rfi) => {
    setItemData(item)
    setVisible(true)
  }

  const modal = (
    <Dialog
      onHide={() => setVisible(false)}
      visible={visible}
      closable={true}
      blockScroll={true}
      breakpoints={{ '960px': '75vw', '640px': '100vw' }}
      style={{ width: '50vw' }}
      header={config[type].header}
      draggable={false}
      className={''}
    >
      {config[type].content}
    </Dialog>
  )

  const dispatcherButtonProps = config[type].hasPermission
    ? [
        {
          buttonProps: {
            icon: 'pi pi-clock',
            tooltip: 'Extend',
          },
          className: DispatcherButtonPresets.PRIMARY_ICON_ACTIONS,
          onClick: onClick,
          visible: (rowData: Rfi | Participant): boolean => {
            return rowData.canExtend
          },
        },
      ]
    : []

  const dispatcherButton =
    config[type].hasPermission && item !== undefined && item.canExtend ? (
      <>
        <DispatcherButton
          buttonProps={{ icon: 'pi pi-clock', label: 'Extend' }}
          className={DispatcherButtonPresets.TERTIARY_ACTIONS}
          onClick={onClick}
          data={item}
        />
      </>
    ) : (
      <></>
    )
  return {
    dispatcherButtonProps,
    dispatcherButton,
    modal,
  }
}
// ... EXTEND MODAL

// WITHDRAW MODAL ...
export const useWithdrawModal = (
  type: 'RFI' | 'PARTICIPANT',
  item?: Rfi | Participant,
): {
  dispatcherButtonProps: DispatcherButtonProps[]
  dispatcherButton: JSX.Element
} => {
  const configByType = (type: 'RFI' | 'PARTICIPANT') => {
    switch (type) {
      case 'RFI':
        return {
          hasPermission: usePermission('rfi', 'withdraw'),
          mutation: useRfiWithdrawMutation,
        }
      case 'PARTICIPANT':
        return {
          hasPermission: usePermission('participant', 'withdraw'),
          mutation: useParticipantWithdrawMutation,
        }
    }
  }
  const config = configByType(type)
  const workspaceId = useAppSelector((state) => state.app.workspace!.id)
  const dispatch = useAppDispatch()
  const [withdrawMutation] = config.mutation()

  const openConfirmDeleteDialog = (item: Rfi | Participant) => {
    const configByType = (type: 'RFI' | 'PARTICIPANT') => {
      switch (type) {
        case 'RFI':
          return {
            confirmHeader: `Withdraw RFI "${(item as Rfi).name}"?`,
            confirmMessage: `Are you sure you want to withdraw the RFI "${(item as Rfi).name}"?`,
            acceptMessage: `RFI "${(item as Rfi).name}" withdrawn successfully.`,
            rejectMessage: `RFI "${(item as Rfi).name}" could not be withdrawn.`,
          }
        case 'PARTICIPANT':
          return {
            confirmHeader: `Withdraw participant "${(item as Participant).company.name}"?`,
            confirmMessage: `Are you sure you want to withdraw the participant "${
              (item as Participant).company.name
            }"?`,
            acceptMessage: `Participant "${
              (item as Participant).company.name
            }" withdrawn successfully.`,
            rejectMessage: `Participant "${
              (item as Participant).company.name
            }" could not be withdrawn.`,
          }
      }
    }
    const config = configByType(type)

    const accept = async () => {
      try {
        await withdrawMutation({
          workspaceId: workspaceId,
          itemId: item.id,
        }).unwrap()
        dispatch(
          setToastMessage({
            severity: 'success',
            summary: config.acceptMessage,
          }),
        )
      } catch (error) {
        dispatch(
          setToastMessage({
            severity: 'error',
            summary: config.rejectMessage,
          }),
        )
      }
    }

    confirmDialog({
      message: config.confirmMessage,
      header: config.confirmHeader,
      acceptClassName: 'p-button-danger',
      accept,
    })
  }

  const onClick = (dispatch: AppDispatch, data: Rfi | Participant) => openConfirmDeleteDialog(data)

  const dispatcherButtonProps = config.hasPermission
    ? [
        {
          buttonProps: {
            icon: 'pi pi-ban',
            tooltip: 'Withdraw',
          },
          className: DispatcherButtonPresets.PRIMARY_ICON_ACTIONS,
          onClick: onClick,
          visible: (rowData: Rfi | Participant): boolean => {
            return rowData.canWithdraw
          },
        },
      ]
    : []

  const dispatcherButton =
    config.hasPermission && item !== undefined && item.canWithdraw ? (
      <>
        <DispatcherButton
          buttonProps={{ icon: 'pi pi-ban', label: 'Withdraw' }}
          className={DispatcherButtonPresets.TERTIARY_ACTIONS}
          onClick={onClick}
          data={item}
        />
      </>
    ) : (
      <></>
    )

  return { dispatcherButtonProps, dispatcherButton }
}
// ... WITHDRAW MODAL

// ARCHIVE MODAL ...
export const useArchiveModal = (
  type: 'PARTICIPANT',
  item?: Participant,
): {
  dispatcherButtonProps: DispatcherButtonProps[]
  dispatcherButton: JSX.Element
} => {
  const configByType = (type: 'PARTICIPANT') => {
    switch (type) {
      case 'PARTICIPANT':
        return {
          hasPermission: usePermission('participant', 'archive'),
          mutation: useParticipantArchiveMutation,
        }
    }
  }
  const config = configByType(type)
  const workspaceId = useAppSelector((state) => state.app.workspace!.id)
  const dispatch = useAppDispatch()
  const [archiveMutation] = config.mutation()

  const openConfirmDeleteDialog = (item: Participant) => {
    const configByType = (type: 'PARTICIPANT') => {
      switch (type) {
        case 'PARTICIPANT':
          return {
            confirmHeader: `Archive participant "${item.company.name}"?`,
            confirmMessage: `Are you sure you want to archive the participant "${item.company.name}"?`,
            acceptMessage: `Participant "${item.company.name}" archived successfully.`,
            rejectMessage: `Participant "${item.company.name}" could not be archived.`,
          }
      }
    }
    const config = configByType(type)

    const accept = async () => {
      try {
        await archiveMutation({
          workspaceId: workspaceId,
          itemId: item.id,
        }).unwrap()
        dispatch(
          setToastMessage({
            severity: 'success',
            summary: config.acceptMessage,
          }),
        )
      } catch (error) {
        dispatch(
          setToastMessage({
            severity: 'error',
            summary: config.rejectMessage,
          }),
        )
      }
    }

    confirmDialog({
      message: config.confirmMessage,
      header: config.confirmHeader,
      acceptClassName: 'p-button-danger',
      accept,
    })
  }

  const onClick = (dispatch: AppDispatch, data: Participant) => openConfirmDeleteDialog(data)

  const dispatcherButtonProps = config.hasPermission
    ? [
        {
          buttonProps: {
            icon: 'pi pi-inbox',
            tooltip: 'Archive',
          },
          className: DispatcherButtonPresets.PRIMARY_ICON_ACTIONS,
          onClick: onClick,
          visible: (rowData: Participant): boolean => {
            return rowData.canArchive
          },
        },
      ]
    : []

  const dispatcherButton =
    config.hasPermission && item !== undefined && item.canArchive ? (
      <>
        <DispatcherButton
          buttonProps={{ icon: 'pi pi-inbox', label: 'Archive' }}
          className={DispatcherButtonPresets.TERTIARY_ACTIONS}
          onClick={onClick}
          data={item}
        />
      </>
    ) : (
      <></>
    )

  return { dispatcherButtonProps, dispatcherButton }
}
// ... ARCHIVE MODAL

// CAN SEND MODAL ...
export const useCanSendModal = (
  item?: Participant,
): {
  dispatcherButtonProps: DispatcherButtonProps[]
  dispatcherButton: JSX.Element
  modal: JSX.Element
} => {
  const [visible, setVisible] = useState<boolean>(false)
  const [itemData, setItemData] = useState<Participant>()
  const hasPermission = usePermission('participant', 'send')
  const [header, setHeader] = useState<string>('Send to Participant')

  const onClick = async (dispatch: AppDispatch, item: Participant) => {
    setItemData(item)
    setVisible(true)
  }

  const dialogRef = useRef<Dialog>(null)

  const modal = (
    <Dialog
      ref={dialogRef}
      onHide={() => setVisible(false)}
      visible={visible}
      closable={true}
      blockScroll={true}
      breakpoints={{ '960px': '75vw', '640px': '100vw' }}
      style={{ width: '50vw' }}
      header={header}
      draggable={false}
      className={''}
    >
      <ParticipantCanSend
        data={itemData}
        onCreated={() => setVisible(false)}
        setDialogTitle={setHeader}
      />
    </Dialog>
  )

  const buttonProps = {
    icon: 'pi pi-envelope',
    tooltip: 'Send invite to participant',
  }
  const dispatcherButtonProps = hasPermission
    ? [
        {
          buttonProps,
          className: DispatcherButtonPresets.PRIMARY_ICON_ACTIONS,
          onClick: onClick,
          visible: (rowData: Participant): boolean => {
            return rowData.canSend
          },
        },
      ]
    : []

  const dispatcherButton =
    hasPermission && item !== undefined && item.canSend ? (
      <>
        <DispatcherButton
          buttonProps={{
            icon: 'pi pi-envelope',
            label: 'Send invite to participant',
          }}
          className={DispatcherButtonPresets.TERTIARY_ACTIONS}
          onClick={onClick}
          data={item}
        />
      </>
    ) : (
      <></>
    )

  return {
    dispatcherButtonProps,
    dispatcherButton,
    modal,
  }
}
// ... CAN SEND MODAL

// ADD PARTICIPANT TO RFI MODAL ...

export const useAddParticipantModal = (
  rfi: Rfi,
  header?: string,
): {
  dispatcherButtonProps: DispatcherButtonProps[]
  dispatcherButton: JSX.Element
  modal: JSX.Element
  setVisible: React.Dispatch<React.SetStateAction<boolean>>
} => {
  const [visible, setVisible] = useState(false)

  const hasPermission = usePermission('participant', 'create')

  const onHide = () => {
    const confirmHideDialog = confirmDialog({
      message: 'Are you sure you want to cancel?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => setVisible(false),
      reject: () => confirmHideDialog.hide(),
    })
  }

  const modal = (
    <Dialog
      onHide={onHide}
      visible={visible}
      closable={true}
      blockScroll={true}
      breakpoints={{ '960px': '75vw', '640px': '100vw' }}
      style={{ width: '50vw' }}
      header={header || 'Create Participants'}
      draggable={false}
      className={'addModal'}
    >
      {rfi.type === RfiType.COMPANY ? (
        <ParticipantAddCompany rfi={rfi} onAdded={() => setVisible(false)} />
      ) : (
        <ParticipantAddProduct rfi={rfi} onAdded={() => setVisible(false)} />
      )}
    </Dialog>
  )

  const buttonProps = { icon: 'pi pi-plus', label: header || 'Create Participants' }

  const dispatcherButtonProps = hasPermission
    ? [
        {
          buttonProps,
          className: DispatcherButtonPresets.PRIMARY_ICON_ACTIONS,
          onClick: () => setVisible(true),
        },
      ]
    : []

  const dispatcherButton = hasPermission ? (
    <DispatcherButton
      buttonProps={buttonProps}
      className={DispatcherButtonPresets.FILLED_ROUNDED_PRIMARY}
      onClick={() => setVisible(true)}
    />
  ) : (
    <></>
  )

  return { dispatcherButtonProps, dispatcherButton, modal, setVisible }
}

// ADD PARTICIPANT TO RFI MODAL ...

import DispatcherButton, { DispatcherButtonPresets } from '../DispatcherButton'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { CreateRfi } from '../../redux/api'
import { Toolbar } from 'primereact/toolbar'
import { FormCompanyDataOption, useFormCompaniesForCreateRfiPicklist } from '../../common/formHooks'
import TagList, { NORMALIZE_PRESETS } from '../TagList'
import { PickList } from 'primereact/picklist'
import { useState } from 'react'
import { useCreateModal } from '../../common/modalHooks'
import InfoTooltip from '../InfoTooltip'
import { Tooltip } from 'primereact/tooltip'
import { Skeleton } from 'primereact/skeleton'

interface FormData {
  companies: FormCompanyDataOption[]
}

interface Props {
  rfiData: CreateRfi
  setRfiData: React.Dispatch<React.SetStateAction<CreateRfi>>
  setStep: React.Dispatch<React.SetStateAction<number>>
}

const itemTemplate = (item: FormCompanyDataOption) => {
  const capabilities: { id: number; name: string }[] = []
  item.products.forEach((product) => {
    capabilities.push(...product.capabilities)
  })
  return (
    <div key={item.name} className={'ve-picklist-item'}>
      <div className={'inline-flex flex-wrap column-gap-3'}>
        <div className={'inline-flex flex-wrap font-bold line-height-3'}>{item.name}</div>
        <div className={'inline-flex gap-1'}>
          <div className={'line-height-3'}>Capabilities:</div>
          <TagList
            items={capabilities}
            emptyVal={'(none)'}
            normalize={NORMALIZE_PRESETS.POS}
            maxShown={5}
          />
        </div>
      </div>
    </div>
  )
}

const RfiCreateStepFourCompany = ({ rfiData, setRfiData, setStep }: Props): JSX.Element => {
  const [next, setNext] = useState(1)

  const { control, resetField, handleSubmit } = useForm<FormData>({
    defaultValues: {
      companies: [],
    },
  })

  const formCompaniesData = useFormCompaniesForCreateRfiPicklist(rfiData, resetField)

  const onContentCreated = (contentCreated: any): void => {
    formCompaniesData.setTarget([...formCompaniesData.target, contentCreated as Company])
  }

  const createCompanyModal = useCreateModal('COMPANY', undefined, undefined, onContentCreated)

  const submitFormData: SubmitHandler<FormData> = async (formData) => {
    setRfiData({
      ...rfiData,
      participants: formData.companies.map((option: FormCompanyDataOption) => {
        return { company: option }
      }) as Participant[],
    })
    setStep((step) => step + next)
  }

  return (
    <>
      {createCompanyModal.modal}
      <InfoTooltip
        tooltip={`Participants are the Companies that will participate in answering your RFI.`}
        label={<h3>Participants (optional)</h3>}
      />
      <p>Please select which companies you would like to issue your RFI to.</p>
      <Toolbar right={createCompanyModal.dispatcherButton} />
      <form className="p-fluid" onSubmit={handleSubmit(submitFormData)}>
        <div key="participants" className="field grid pt-3">
          <Tooltip target=".infoTooltip" />

          <div className="col-12">
            <Controller
              name="companies"
              control={control}
              render={({ field }) =>
                formCompaniesData.isFetching ? (
                  <Skeleton height="342px" />
                ) : (
                  <PickList
                    source={formCompaniesData.source}
                    target={formCompaniesData.target}
                    itemTemplate={itemTemplate}
                    sourceHeader="Available Companies"
                    targetHeader="Selected Companies"
                    showSourceControls={false}
                    showTargetControls={false}
                    sourceStyle={{ height: '342px' }}
                    targetStyle={{ height: '342px' }}
                    onChange={(event) => {
                      field.onChange(event.target)
                      formCompaniesData.setSource(event.source)
                      formCompaniesData.setTarget(event.target)
                    }}
                    filterBy="name"
                    sourceFilterPlaceholder="Filter by name"
                    targetFilterPlaceholder="Filter by name"
                  />
                )
              }
            />
          </div>
        </div>
        <Toolbar
          left={
            <DispatcherButton
              buttonProps={{
                icon: 'pi pi-arrow-left',
                label: 'Back',
                type: 'submit',
                onClick: () => setNext(-1),
              }}
              className={DispatcherButtonPresets.OUTLINED_PRIMARY}
            />
          }
          right={
            <DispatcherButton
              buttonProps={{
                label: 'Next',
                icon: 'pi pi-arrow-right',
                iconPos: 'right',
                type: 'submit',
              }}
              className={DispatcherButtonPresets.FILLED_ROUNDED_PRIMARY}
            />
          }
        />
      </form>
    </>
  )
}

export default RfiCreateStepFourCompany

import React from 'react'
import { useAppSelector } from '../../common/hooks'
import { useParticipantListByRfiQuery } from '../../redux/api'
import ParticipantListRfiDetailsPage from '../Participant/ParticipantListRfiDetailsPage'

interface Props {
  rfi: Rfi
}

const RfiTabParticipants = ({ rfi }: Props): JSX.Element => {
  const workspaceId = useAppSelector((state) => state.app.workspace!.id)
  const { data, isLoading } = useParticipantListByRfiQuery({
    workspaceId,
    rfiId: rfi.id,
  })

  return (
    <ParticipantListRfiDetailsPage
      toolbarLeft={<h1 className="text-2xl">Participants</h1>}
      data={data}
      isLoading={isLoading}
      showFilter={true}
      rfi={rfi}
    />
  )
}

export default RfiTabParticipants

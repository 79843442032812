import { useAppDispatch, useAppSelector } from '../../common/hooks'
import { useEffect, useRef, useState } from 'react'
import {
  UpdateParticipantCanSend,
  useParticipantCanSendMutation,
  MailToParticipant,
} from '../../redux/api'
import { setToastMessage } from '../../redux/app'
import DispatcherButton, { DispatcherButtonPresets } from '../DispatcherButton'

import styles from '../../styles/edit_modal.module.scss'
import { InputTextarea } from 'primereact/inputtextarea'
import { PickList } from 'primereact/picklist'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { Steps } from 'primereact/steps'
import { Toolbar } from 'primereact/toolbar'
import { FormContactDataOption, useFormContactData } from '../../common/formHooks'
import { classNames } from 'primereact/utils'
import { InputText } from 'primereact/inputtext'
import { useCopyToClipboard } from '../../common/dispatcherButtonHooks'
import { Checkbox } from 'primereact/checkbox'
import { useCreateModal } from '../../common/modalHooks'

interface FormData {
  recipients: FormContactDataOption[]
  customMessage: string
}

interface FormDataTwo {
  mailContent: string
}

interface Props {
  data?: Participant
  onCreated: () => void
  setDialogTitle: (title: string) => void
}

const ParticipantCanSend = ({ data, onCreated, setDialogTitle }: Props): JSX.Element => {
  const steps = [{ label: 'Recipients & Message' }, { label: 'Link & Email' }]

  const [sendParticipants] = useParticipantCanSendMutation()
  const dispatch = useAppDispatch()
  const workspaceId = useAppSelector((state) => state.app.workspace!.id)
  const [currentStep, setCurrentStep] = useState<number>(0)
  const formRefOne = useRef<HTMLFormElement>(null)
  const [confirmDisclaimer, setConfirmDisclaimer] = useState(false)

  const copyToClipboardButton = (value: string) => useCopyToClipboard(value)

  const onContentCreated = (contentCreated: any): void => {
    formContactData.setTarget([...formContactData.target, contentCreated as Contact])
  }

  const createContactModal = useCreateModal(
    'CONTACT',
    data?.company!,
    'Create Contact',
    onContentCreated,
  )

  const [emailContent, setEmailContent] = useState<MailToParticipant>({
    participantUrl: '',
    participantPassword: '',
    mailToUrl: '',
  })
  const formContactData = useFormContactData(data)

  const {
    control: control,
    formState: { errors: errors },
    handleSubmit: handleSubmit,
  } = useForm<FormData>({
    defaultValues: {
      recipients: [],
      customMessage: '',
    },
  })

  useEffect(() => {
    switch (currentStep) {
      case 0:
        setDialogTitle('Disclaimer')
        break
      case 1:
      case 2:
        setDialogTitle('Send Invite to Participant')
        break
    }
  }, [currentStep])

  const submitFormData: SubmitHandler<FormData> = async (formData) => {
    const config = {
      RECIPIENT: {
        successMessage: 'Email generated successfully.',
        rejectMessage: 'Email could not be generated.',
      },
    }

    const updateCanSend: UpdateParticipantCanSend = {
      contacts: formData.recipients,
      customMessage: formData.customMessage.trim(),
    }
    try {
      const response = await sendParticipants({
        workspaceId: workspaceId,
        itemId: data!.id,
        contactData: updateCanSend,
      }).unwrap()
      setEmailContent(response)
      dispatch(
        setToastMessage({
          severity: 'success',
          summary: config['RECIPIENT'].successMessage,
        }),
      )
    } catch (error) {
      dispatch(
        setToastMessage({
          severity: 'error',
          summary: config['RECIPIENT'].rejectMessage,
        }),
      )
    }

    setCurrentStep(2)
  }

  const submitAndOpenEmailClient = () => {
    window.open(emailContent!.mailToUrl)
  }

  const bottomToolbarRightContentsStep0 = (
    <>
      <DispatcherButton
        buttonProps={{
          label: 'Continue',
          icon: 'pi pi-check',
          iconPos: 'right',
          disabled: !confirmDisclaimer,
        }}
        className={DispatcherButtonPresets.FILLED_ROUNDED_PRIMARY}
        onClick={() => setCurrentStep(1)}
      />
    </>
  )

  const bottomToolbarRightContentsStep1 = (
    <DispatcherButton
      buttonProps={{
        label: 'Generate Link & Email',
        icon: 'pi pi-arrow-right',
        iconPos: 'right',
      }}
      className={DispatcherButtonPresets.FILLED_ROUNDED_PRIMARY}
      onClick={() => {
        if (formRefOne.current) {
          formRefOne.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))
        }
      }}
    />
  )

  const bottomToolbarRightContentsStep2 = (
    <>
      <DispatcherButton
        buttonProps={{ label: 'Done', icon: 'pi pi-check', iconPos: 'right' }}
        className={DispatcherButtonPresets.FILLED_ROUNDED_PRIMARY}
        onClick={onCreated}
      />
    </>
  )

  const customItemTemplate = (item: any) => {
    return (
      <div className="p-d-flex p-ai-center">
        <div className="p-mr-2">
          {item.firstName} {item.lastName}
          <br />
          <span className="text-sm text-color-secondary">
            <i>{item.jobTitle}</i>
          </span>
        </div>
      </div>
    )
  }

  const renderSwitch = (currentStep: number) => {
    switch (currentStep) {
      case 0:
        return (
          <div className={`${styles.edit_modal}`}>
            <p>RFIs will often contain vendor's confidential information.</p>
            <p>
              We may also need to share our own confidential information with the vendor during or
              after the RFI process. Please reach out to your local legal contact to check a mutual{' '}
              <a
                href="https://insidemedia.sharepoint.com/:w:/r/sites/GRM-Global-Legal-Intranet/_layouts/15/Doc.aspx?sourcedoc=%7B9335ac16-c95f-4056-9e28-905d5f1b45ea%7D"
                target="_blank"
              >
                Non-Disclosure Agreement (NDA)
              </a>{' '}
              is in place before an RFI is sent to the vendor.
            </p>
            <div className="field-checkbox">
              <Checkbox
                inputId="disclaimer"
                id="disclaimer"
                checked={confirmDisclaimer}
                onChange={() => setConfirmDisclaimer(!confirmDisclaimer)}
              />
              <label htmlFor="city1">
                Yes, a Non-Disclosure Agreement (NDA) with <strong>{data?.company.name}</strong> is
                in place.
              </label>
            </div>
            <Toolbar right={bottomToolbarRightContentsStep0} />
          </div>
        )
      case 1:
        return (
          <div className={`${styles.edit_modal}`}>
            {createContactModal.modal}
            <Steps model={steps} activeIndex={0} className={'mb-4'} />
            <h3>Recipients (optional)</h3>
            <Toolbar right={createContactModal.dispatcherButton} />
            <form ref={formRefOne} className="p-fluid" onSubmit={handleSubmit(submitFormData)}>
              {/* <Steps model={steps} activeIndex={3} className={'mb-4'} /> */}
              <div key="recipients" className="field grid pt-3">
                <div className="col-12">
                  <Controller
                    name="recipients"
                    control={control}
                    render={({ field, fieldState, formState }) => (
                      <PickList
                        onChange={(event) => {
                          field.onChange(event.target)
                          formContactData.setSource(event.source)
                          formContactData.setTarget(event.target)
                        }}
                        source={formContactData.source}
                        target={formContactData.target}
                        itemTemplate={customItemTemplate}
                        sourceHeader="Available Contacts"
                        targetHeader="Selected Contacts"
                        showSourceControls={false}
                        showTargetControls={false}
                        sourceStyle={{ height: '342px' }}
                        targetStyle={{ height: '342px' }}
                        filterBy="firstName,lastName,jobTitle,email"
                        sourceFilterPlaceholder="Filter by name"
                        targetFilterPlaceholder="Filter by name"
                        className={classNames({ 'p-invalid': fieldState.error })}
                      />
                    )}
                  />
                  {errors.recipients && (
                    <small className="p-error">{errors.recipients?.message}</small>
                  )}
                </div>
              </div>
              <h3>Custom Message</h3>
              <div key="customMessage" className="field grid">
                <div className="col-12">
                  <Controller
                    name="customMessage"
                    control={control}
                    render={({ field }) => (
                      <InputTextarea
                        rows={5}
                        cols={20}
                        value={field.value}
                        placeholder="Enter custom message (optional)"
                        onChange={field.onChange}
                        onBlur={(e) => {
                          field.onChange(e.target.value.trim())
                        }}
                      />
                    )}
                  />
                </div>
              </div>
            </form>
            <Toolbar right={bottomToolbarRightContentsStep1} />
          </div>
        )
      case 2:
        return (
          <div className={`${styles.edit_modal}`}>
            <Steps model={steps} activeIndex={1} className={'mb-4'} />
            <div>
              <div className="field grid col-10">
                <h3>Link</h3>
                <div className="p-inputgroup align-items-center">
                  <>
                    <label htmlFor="emailContent" className={'col-2 pl-0'}>
                      URL:
                    </label>
                    <InputText
                      name="emailContent"
                      placeholder="URL"
                      value={emailContent.participantUrl}
                    />
                    {copyToClipboardButton(emailContent.participantUrl).dispatcherButton}
                  </>
                </div>
              </div>
              <div className="field grid col-10">
                <div className="p-inputgroup align-items-center">
                  <>
                    <label htmlFor="emailContent" className={'col-2 pl-0'}>
                      Password:
                    </label>
                    <InputText placeholder="Password" value={emailContent.participantPassword} />
                    {copyToClipboardButton(emailContent.participantPassword).dispatcherButton}
                  </>
                </div>
              </div>
            </div>
            <h3>Email</h3>
            <div className="field grid col-10">
              <DispatcherButton
                buttonProps={{ label: 'Open email in local email client', icon: 'pi pi-envelope' }}
                className={classNames(DispatcherButtonPresets.TERTIARY_ACTIONS, 'col-offset-2')}
                onClick={submitAndOpenEmailClient}
              />
            </div>
            <Toolbar right={bottomToolbarRightContentsStep2} />
          </div>
        )
      default:
        return <></>
    }
  }
  return renderSwitch(currentStep)
}

export default ParticipantCanSend

import styles from '../../styles/list.module.scss'
import DataTableProvider, { DataTablePropsPresets } from '../DataTable/DataTableProvider'
import { Toolbar } from 'primereact/toolbar'
import { Card } from 'primereact/card'
import { useDataTableFilter } from '../../common/hooks'
import { actionsColumn, simpleColumn, tagListColumn } from '../DataTable/columnProvider'
import {
  useUrlCopyToClipboard,
  useNavigateToCreate,
  useToogleFavorite,
} from '../../common/dispatcherButtonHooks'
import { useDeleteModal, useExtendModal, useWithdrawModal } from '../../common/modalHooks'
import { Link } from 'react-router-dom'
import { ProgressBar } from 'primereact/progressbar'
import { RfiStatus } from '../../enums'
import { Tag } from 'primereact/tag'
import { DataTableProps } from 'primereact/datatable'
import { NORMALIZE_PRESETS } from '../TagList'
import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'
import ProgressTag from '../ProgressTag'

interface Props {
  data?: Rfi[]
  toolbarLeft?: JSX.Element
  toolbarRight?: JSX.Element
  hiddenColumns?: number[]
  isLoading?: boolean
  useSorting?: boolean
  showFilter?: boolean
  dataTableProps?: DataTableProps
}

const RfiList = ({
  data,
  toolbarLeft = <h1>RFIs</h1>,
  toolbarRight,
  hiddenColumns = [],
  isLoading = true,
  useSorting = true,
  showFilter = false,
  dataTableProps = DataTablePropsPresets.listPage(data),
}: Props): JSX.Element => {
  const toggleFavorite = useToogleFavorite('RFI')
  const copyToClipboard = useUrlCopyToClipboard('RFI')
  const deleteModal = useDeleteModal('RFI')
  const createRfiButton = useNavigateToCreate('RFI')
  const extendButton = useExtendModal('RFI')
  const withdrawButton = useWithdrawModal('RFI')
  const columns = [
    simpleColumn({
      columnProps: {
        header: 'Name',
        field: 'name',
        sortable: useSorting,
        className: styles['first-column'],
        body: (rfi: Rfi) => {
          return <Link to={`/rfi/${rfi.id}`}>{rfi.name}</Link>
        },
      },
    }),
    simpleColumn({
      columnProps: {
        header: 'Type',
        field: 'type',
        sortable: useSorting,
        className: styles['column'],
        style: { width: '10%' },
      },
    }),
    simpleColumn({
      columnProps: {
        header: 'Market',
        field: 'market',
        sortable: useSorting,
        className: styles['column'],
        style: { width: '10%' },
        body: (rfi: Rfi) => {
          return (
            <div className="tooltip-wrapper">
              <Tag value={rfi.geo.name} className={'bg-bluegray-500'} />
            </div>
          )
        },
      },
    }),
    tagListColumn({
      columnProps: {
        header: 'Capabilities',
        className: styles['column'],
        style: { width: 'auto' },
      },
      rowDataSelector: (rfi: Rfi) => rfi.capabilities,
      normalize: NORMALIZE_PRESETS.POS,
    }),
    simpleColumn({
      columnProps: {
        header: 'Status',
        field: 'status',
        sortable: useSorting,
        className: styles['column'],
        style: { width: 'auto' },
        body: (rfi: Rfi) => {
          return rfi.status === RfiStatus.IN_PROGRESS ? (
            <ProgressTag status={rfi.status} progress={rfi.progress} />
          ) : (
            <Tag
              className="bg-primary-reverse border-1"
              value={String(rfi.status).replace(/_/g, ' ')}
            ></Tag>
          )
        },
      },
    }),
    simpleColumn({
      columnProps: {
        header: 'End Date',
        field: 'endDate',
        sortable: useSorting,
        className: styles['column'],
        style: { width: '12%' },
        body: (rfi: Rfi) => {
          return <span>{rfi.endDate}</span>
        },
      },
    }),
    actionsColumn({
      columnProps: {
        align: 'right',
        alignHeader: 'center',
        className: styles['action-column'],
        style: { width: 'auto' },
      },
      dispatcherButtonsProps: [
        ...toggleFavorite.dispatcherButtonProps,
        ...copyToClipboard.dispatcherButtonProps,
        ...extendButton.dispatcherButtonProps,
        ...withdrawButton.dispatcherButtonProps,
        ...deleteModal.dispatcherButtonProps,
      ],
    }),
  ].filter((column, index) => !hiddenColumns.includes(index))

  data = data?.map((rfi) => {
    return {
      ...rfi,
      ...{
        market: rfi.geo.name,
      },
    }
  })
  dataTableProps = { ...dataTableProps, ...{ value: data } }

  if (showFilter) {
    data = data?.map((rfi) => {
      return {
        ...rfi,
        ...{
          capabilitiesJoin: rfi.capabilities.map((capability) => capability.name).join(' '),
        },
      }
    })
    dataTableProps = {
      ...dataTableProps,
      ...{ value: data },
      ...useDataTableFilter(['name', 'market', 'capabilitiesJoin', 'endDate']),
    }
  }

  return (
    <>
      {extendButton.modal}
      <div className={styles.list}>
        <Toolbar left={toolbarLeft} right={toolbarRight || createRfiButton.dispatcherButton} />
        <Card>
          <DataTableProvider
            dataTableProps={dataTableProps}
            columns={columns}
            isLoading={isLoading}
          />
        </Card>
      </div>
    </>
  )
}

export default RfiList

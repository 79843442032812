import React from 'react'
import { TabPanel, TabView } from 'primereact/tabview'
import CompanyTabCompanyRfis from './CompanyTabCompanyRfis'
import CompanyTabProductRfis from './CompanyTabProductRfis'
import CompanyTabProducts from './CompanyTabProducts'
import CompanyTabContacts from './CompanyTabContacts'

interface Props {
  company: Company
}

const CompanyTabs = ({ company }: Props): JSX.Element => {
  return (
    <TabView>
      <TabPanel header="Company RFIs">
        <CompanyTabCompanyRfis companyId={company.id} />
      </TabPanel>
      <TabPanel header="Product RFIs">
        <CompanyTabProductRfis companyId={company.id} />
      </TabPanel>
      <TabPanel header="Products">
        <CompanyTabProducts company={company} />
      </TabPanel>
      <TabPanel header="Contacts">
        <CompanyTabContacts company={company} />
      </TabPanel>
    </TabView>
  )
}

export default CompanyTabs

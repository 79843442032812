import Header from '../components/Header'
import HomePage from './HomePage'
import SecondaryMenu from '../components/SecondaryMenu'
import { Route, Routes, useLocation } from 'react-router-dom'
import RfiListPage from './rfi/RfiListPage'
import RfiCreatePage from './rfi/RfiCreatePage'
import RfiDetailsPage from './rfi/RfiDetailsPage'
import CompanyListPage from './company/CompanyListPage'
import CompanyDetailsPage from './company/CompanyDetailsPage'
import ProductListPage from './product/ProductListPage'
import ProductDetailsPage from './product/ProductDetailsPage'
import { useAppSelector } from '../common/hooks'
import { ProgressBar } from 'primereact/progressbar'
import NotFoundPage from './http/404NotFoundPage'
import ToastProvider from '../components/ToastProvider'
import RestrictedElement from '../components/RestrictedElement'
import NeedsReloadDialog from '../components/NeedsReloadDialog'
import { ConfirmDialog } from 'primereact/confirmdialog'
import ProductCreatePage from './product/ProductCreatePage'
import QuestionPackListPage from './questionPack/QuestionPackListPage'
import QuestionPackDetailsPage from './questionPack/QuestionPackDetailsPage'
import FavoriteListPage from './favorite/FavoriteListPage'
import ParticipantDetailsPage from './participant/ParticipantDetailsPage'
import Footer from '../components/Footer'
import CompanyCreatePage from './company/CompanyCreatePage'
import { useEffect, useState } from 'react'
import PreHomePage from './PreHomePage'
import { classNames } from 'primereact/utils'
import gsap from 'gsap'
import ScrollToPlugin from 'gsap/ScrollToPlugin'

gsap.registerPlugin(ScrollToPlugin)

const App = (): JSX.Element => {
  const isAuthenticated = useAppSelector((state) => state.app.isAuthenticated)
  const [isPreHomepage, setIsPreHomepage] = useState(true)
  const location = useLocation()

  const scrollToTop = () => {
    gsap.to(window, { duration: 0.2, scrollTo: 0, ease: 'sine.out' })
  }

  useEffect(() => {
    scrollToTop()
    if (isPreHomepage) setIsPreHomepage(false)
  }, [location.pathname])

  return (
    <>
      <ToastProvider toastAppErrors={true} />
      {isAuthenticated ? (
        <div
          className={classNames(
            'min-h-screen flex flex-column card-container',
            isPreHomepage ? 'bg-black' : 'surface-200',
          )}
        >
          <header className="flex-none">
            <Header />
            {!isPreHomepage ? (
              <>
                <SecondaryMenu />
                <ConfirmDialog
                  breakpoints={{ '960px': '10vw', '428px': '100vw' }}
                  style={{ width: '10vw' }}
                />
              </>
            ) : null}
          </header>
          <section className="flex-1 ">
            <div className="mainContent m-auto">
              <Routes>
                <Route path="*" element={<NotFoundPage />} />
                <Route path="/" element={<PreHomePage setPreHomepage={setIsPreHomepage} />} />
                <Route path="/home" element={<HomePage />} />
                <Route
                  path="/rfi"
                  element={
                    <RestrictedElement
                      element={<RfiListPage />}
                      resourceTypeRef="rfi"
                      operationRef="list"
                    />
                  }
                />
                <Route
                  path="/rfi/create"
                  element={
                    <RestrictedElement
                      element={<RfiCreatePage />}
                      resourceTypeRef="rfi"
                      operationRef="create"
                    />
                  }
                />
                <Route
                  path="/rfi/:id"
                  element={
                    <RestrictedElement
                      element={<RfiDetailsPage />}
                      resourceTypeRef="rfi"
                      operationRef="read"
                    />
                  }
                />
                <Route
                  path="/company"
                  element={
                    <RestrictedElement
                      element={<CompanyListPage />}
                      resourceTypeRef="company"
                      operationRef="list"
                    />
                  }
                />
                <Route
                  path="/company/create"
                  element={
                    <RestrictedElement
                      element={<CompanyCreatePage />}
                      resourceTypeRef="company"
                      operationRef="create"
                    />
                  }
                />
                <Route
                  path="/company/:id"
                  element={
                    <RestrictedElement
                      element={<CompanyDetailsPage />}
                      resourceTypeRef="company"
                      operationRef="read"
                    />
                  }
                />
                <Route
                  path="/product"
                  element={
                    <RestrictedElement
                      element={<ProductListPage />}
                      resourceTypeRef="product"
                      operationRef="list"
                    />
                  }
                />
                <Route
                  path="/product/create"
                  element={
                    <RestrictedElement
                      element={<ProductCreatePage />}
                      resourceTypeRef="product"
                      operationRef="create"
                    />
                  }
                />
                <Route
                  path="/product/:id"
                  element={
                    <RestrictedElement
                      element={<ProductDetailsPage />}
                      resourceTypeRef="product"
                      operationRef="read"
                    />
                  }
                />
                <Route
                  path="/questionpack"
                  element={
                    <RestrictedElement
                      element={<QuestionPackListPage />}
                      resourceTypeRef="question_pack"
                      operationRef="list"
                    />
                  }
                />
                <Route
                  path="/questionpack/:id"
                  element={
                    <RestrictedElement
                      element={<QuestionPackDetailsPage />}
                      resourceTypeRef="question_pack"
                      operationRef="read"
                    />
                  }
                />
                <Route
                  path="/participant/:id"
                  element={
                    <RestrictedElement
                      element={<ParticipantDetailsPage />}
                      resourceTypeRef="participant"
                      operationRef="read"
                    />
                  }
                />
                <Route
                  path="/favorites"
                  element={
                    <RestrictedElement
                      element={<FavoriteListPage />}
                      resourceTypeRef="favorite"
                      operationRef="list"
                    />
                  }
                />
              </Routes>
            </div>
          </section>
          {!isPreHomepage ? (
            <footer className="flex-none">
              <Footer />
            </footer>
          ) : null}
        </div>
      ) : (
        <div className="flex justify-content-start min-h-screen min-w-screen flex-wrap surface-ground">
          <div className=" m-auto">
            <h5>Loading Vendor Explorer...</h5>
            <ProgressBar mode="indeterminate" style={{ height: '6px' }}></ProgressBar>
          </div>
        </div>
      )}
      <NeedsReloadDialog />
    </>
  )
}

export default App

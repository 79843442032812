import DispatcherButton, { DispatcherButtonPresets } from '../DispatcherButton'
import { CreateRfi, ReducedCreateRfi, useRfiCreateMutation } from '../../redux/api'
import { Toolbar } from 'primereact/toolbar'
import TagList, { NORMALIZE_PRESETS } from '../TagList'
import { simpleColumn, tagListColumn } from '../DataTable/columnProvider'
import { useAppSelector, useGroupedTagList } from '../../common/hooks'
import { useState } from 'react'
import { setToastMessage } from '../../redux/app'
import { AppDispatch } from '../../redux/store'
import QuestionPackTree from '../QuestionPack/QuestionPackTree'
import { questionPacksToTreeNodes } from '../../common/dataHooks'
import { Tag } from 'primereact/tag'
import { RfiType } from '../../enums'
import DataTableProvider, { DataTablePropsPresets } from '../DataTable/DataTableProvider'
import styles from '../../styles/list.module.scss'

interface Props {
  rfiData: CreateRfi
  setStep: React.Dispatch<React.SetStateAction<number>>
  onCreated?: (rfi: Rfi) => void
}

const groupedTagListTemplate = (key: string, label: string, list: JSX.Element) => {
  return (
    <div key={key} className="grid card-container line-height-3">
      <div className="col-4 md:col-3 lg:col-2 mb-2 md:mb-0 font-medium align-self-center">
        {label}:
      </div>
      <div className="col-8 md:col-9 lg:col-10">{list}</div>
    </div>
  )
}

const participantsDataTableRows = (rfiData: CreateRfi) => {
  return rfiData.type === RfiType.COMPANY
    ? rfiData.participants.map((participant) => participant.company)
    : rfiData.participants.map((participant) => participant.product!)
}

const participantsDataTableColumns = (rfiData: CreateRfi) => {
  return rfiData.type === RfiType.COMPANY
    ? [
        simpleColumn({
          columnProps: {
            header: 'Name',
            field: 'name',
            sortable: true,
            style: { width: '25%' },
          },
        }),
        tagListColumn({
          columnProps: {
            header: 'Capabilities',
            style: { width: '100%' },
          },
          rowDataSelector: (company: Company) => {
            const capabilities: Capability[] = []
            company.products.forEach((product) => {
              capabilities.push(...product.capabilities)
            })
            return capabilities
          },
          normalize: NORMALIZE_PRESETS.POS,
        }),
      ]
    : [
        simpleColumn({
          columnProps: {
            header: 'Name',
            field: 'name',
            sortable: true,
            style: { width: '25%' },
          },
        }),
        simpleColumn({
          columnProps: {
            header: 'Company',
            field: 'company.name',
            sortable: true,
            className: styles['column'],
            style: { width: '25%' },
          },
        }),
        tagListColumn({
          columnProps: {
            header: 'Markets',
            className: styles['column'],
            style: { width: '25%' },
          },
          rowDataSelector: (product: Product) => [
            product.geos.filter((geo) => geo.type === null),
            product.geos.filter((geo) => geo.type !== null),
          ],
        }),
        tagListColumn({
          columnProps: {
            header: 'Capabilities',

            style: { width: '25%' },
          },
          rowDataSelector: (product: Product) => product.capabilities,
          normalize: NORMALIZE_PRESETS.POS,
        }),
      ]
}

const reduceSubmitData = (rfiData: CreateRfi): ReducedCreateRfi => {
  return {
    name: rfiData.name,
    description: rfiData.description,
    geo: { id: rfiData.geo!.id },
    startDate: rfiData.startDate,
    endDate: rfiData.endDate,
    capabilities: rfiData.capabilities.map((capability) => ({ id: capability.id })),
    tags: rfiData.tags.map((tag) => ({ id: tag.id })),
    type: rfiData.type!,
    questionPacks: rfiData.questionPacks.map((questionPack) =>
      questionPack.excludedQuestions!.length > 0
        ? {
            id: questionPack.id,
            excludedQuestions: questionPack.excludedQuestions!.map((question) => ({
              id: question.id,
            })),
          }
        : { id: questionPack.id },
    ),
    participants: rfiData.participants.map((participant) => ({
      company: { id: participant.company.id },
      product: !!participant.product ? { id: participant.product.id } : null,
    })),
  }
}

const RfiCreateStepFive = ({ rfiData, setStep, onCreated = () => {} }: Props): JSX.Element => {
  const workspaceId = useAppSelector((state) => state.app.workspace!.id)
  const [submitted, setSubmitted] = useState(false)
  const [createRfi] = useRfiCreateMutation()
  const groupedTagList = useGroupedTagList(rfiData.tags, groupedTagListTemplate)

  const editStepIcon = (step: number) => (
    <DispatcherButton
      buttonProps={{ icon: 'pi pi-pencil', onClick: () => setStep(step) }}
      className={DispatcherButtonPresets.PRIMARY_ICON_ACTIONS}
    />
  )

  const finishButton = (
    <DispatcherButton
      buttonProps={{ label: 'Finish', icon: 'pi pi-check', iconPos: 'right', disabled: submitted }}
      className={DispatcherButtonPresets.FILLED_ROUNDED_PRIMARY}
      onClick={async (dispatch: AppDispatch) => {
        setSubmitted(true)
        try {
          const response = await createRfi({
            workspaceId,
            data: reduceSubmitData(rfiData),
          }).unwrap()
          onCreated(response)
          dispatch(
            setToastMessage({
              severity: 'success',
              summary: 'RFI created successfully.',
            }),
          )
        } catch {
          dispatch(
            setToastMessage({
              severity: 'error',
              summary: 'RFI could not be created.',
            }),
          )
          setSubmitted(false)
        }
      }}
    />
  )

  return (
    <>
      <div className={'flex align-items-center flex-row'}>
        <h3>Summary</h3>
      </div>
      <p>
        Please review the criteria you have input and selected for your RFI.
        <br />
        You can edit any of them by clicking on the <i className="pi pi-pencil text-primary" />{' '}
        button.
        <br />
        Once you're happy with the result, click on 'Finish' to create your RFI.
      </p>
      <div className="flex align-items-center flex-wrap">
        <div className="mr-2">
          <h4>General Information</h4>
        </div>
        <div>{editStepIcon(1)}</div>
      </div>

      <div className="card mb-3">
        <div className="grid card-container line-height-3">
          <div className="col-4 md:col-3 lg:col-2 mb-2 md:mb-0 font-medium align-self-center">
            Name:
          </div>
          <div className="col-8 md:col-9 lg:col-10">{rfiData!.name}</div>
        </div>
        <div className="grid card-container line-height-3">
          <div className="col-4 md:col-3 lg:col-2 mb-2 md:mb-0 font-medium align-self-center">
            Description:
          </div>
          <div className="col-8 md:col-9 lg:col-10">{rfiData!.description || '(none)'}</div>
        </div>
        <div className="grid card-container line-height-3">
          <div className="col-4 md:col-3 lg:col-2 mb-2 md:mb-0 font-medium align-self-center">
            Type:
          </div>
          <div className="col-8 md:col-9 lg:col-10">
            {rfiData!.type === RfiType.COMPANY ? 'Company' : 'Product'}
          </div>
        </div>
        <div className="grid card-container line-height-3">
          <div className="col-4 md:col-3 lg:col-2 mb-2 md:mb-0 font-medium align-self-center">
            Market:
          </div>
          <div className="col-8 md:col-9 lg:col-10">
            <div className="tooltip-wrapper">
              <Tag value={rfiData!.geo!.name} className={'bg-bluegray-500'} />
            </div>
          </div>
        </div>
        <div className="grid card-container line-height-3">
          <div className="col-4 md:col-3 lg:col-2 mb-2 md:mb-0 font-medium align-self-center">
            Start Date:
          </div>
          <div className="col-8 md:col-9 lg:col-10">{rfiData!.startDate}</div>
        </div>
        <div className="grid card-container line-height-3">
          <div className="col-4 md:col-3 lg:col-2 mb-2 md:mb-0 font-medium align-self-center">
            End Date:
          </div>
          <div className="col-8 md:col-9 lg:col-10">{rfiData!.endDate}</div>
        </div>
      </div>

      <div className="flex align-items-center flex-wrap">
        <div className="mr-2">
          <h4>Capabilities & Tags</h4>
        </div>
        <div>{editStepIcon(2)}</div>
      </div>

      <div className="card mb-3">
        <div className="grid card-container line-height-3">
          <div className="col-4 md:col-3 lg:col-2 mb-2 md:mb-0 font-medium align-self-center">
            Capabilities:
          </div>
          <div className="col-8 md:col-9 lg:col-10">
            <TagList
              items={rfiData!.capabilities || []}
              normalize={NORMALIZE_PRESETS.POS}
              emptyVal={'(none)'}
              maxShown={10}
            />
          </div>
        </div>
        {groupedTagList}
      </div>

      <div className="flex align-items-center flex-wrap mb-3">
        <div className="mr-2">
          <h4>Question Packs & Questions</h4>
        </div>
        <div>{editStepIcon(3)}</div>
        <QuestionPackTree
          toolbarLeft={null}
          data={questionPacksToTreeNodes(rfiData!.questionPacks)}
          isLoading={false}
          showFilter={false}
        />
      </div>

      <div className="flex align-items-center flex-wrap">
        <div className="mr-2">
          <h4>Participants</h4>
        </div>
        <div>{editStepIcon(4)}</div>
      </div>
      <div className="mb-3">
        <DataTableProvider
          dataTableProps={DataTablePropsPresets.rfiWizard(participantsDataTableRows(rfiData))}
          columns={participantsDataTableColumns(rfiData)}
          isLoading={false}
        />
      </div>

      <Toolbar
        left={
          <DispatcherButton
            buttonProps={{
              icon: 'pi pi-arrow-left',
              label: 'Back',
              type: 'button',
            }}
            className={DispatcherButtonPresets.OUTLINED_PRIMARY}
            onClick={() => setStep((step) => step - 1)}
          />
        }
        right={finishButton}
      />
    </>
  )
}

export default RfiCreateStepFive

import { TabMenu } from 'primereact/tabmenu'
import { useAppDispatch, useAppSelector } from '../common/hooks'
import { validateWorkspacePermission } from '../common/utils'
import { useLocation, useNavigate } from 'react-router-dom'
import { useEffect } from 'react'
import { setActiveTabIndex } from '../redux/app'
import { MenuItem } from 'primereact/menuitem'

const SecondaryMenu = (): JSX.Element => {
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const activeTabIndex = useAppSelector((state) => state.app.activeTabIndex)
  const menuItems: (MenuItem & { regex: string; tabIndex: number })[] = [
    {
      label: 'Home',
      command: (event) => {
        navigate('/home')
      },
      regex: '^/home$|/favorites',
      tabIndex: 0,
    },
    {
      label: 'RFIs',
      command: () => {
        navigate('/rfi')
      },
      visible: validateWorkspacePermission('rfi', 'list'),
      regex: '^/rfi.*',
      tabIndex: 1,
    },
    {
      label: 'Companies',
      command: () => {
        navigate('/company')
      },
      visible: validateWorkspacePermission('company', 'list'),
      regex: '^/company.*',
      tabIndex: 2,
    },
    {
      label: 'Products',
      command: () => {
        navigate('/product')
      },
      visible: validateWorkspacePermission('product', 'list'),
      regex: '^/product.*',
      tabIndex: 3,
    },
    {
      label: 'Question Packs',
      command: () => {
        navigate('/questionpack')
      },
      visible: validateWorkspacePermission('question_pack', 'list'),
      regex: '^/questionpack.*',
      tabIndex: 4,
    },
  ]

  useEffect(() => {
    const result = menuItems.filter((item) => location.pathname.match(item.regex))
    !!result.length
      ? dispatch(setActiveTabIndex(result[0].tabIndex))
      : dispatch(setActiveTabIndex(-1))
  }, [location])

  return <TabMenu model={menuItems} activeIndex={activeTabIndex} onTabChange={() => {}} />
}

export default SecondaryMenu

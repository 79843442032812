import React from 'react'
import { useAppSelector } from '../common/hooks'
import { Dialog } from 'primereact/dialog'
import { Button } from 'primereact/button'

const NeedsReloadDialog = (): JSX.Element => {
  const needsReload = useAppSelector((state) => state.app.needsReload),
    doReload = () => window.location.reload(),
    footerContent = (
      <div>
        <Button label="Reload" icon="pi pi-refresh" onClick={doReload} autoFocus />
      </div>
    )

  return (
    <Dialog
      header="Reload required"
      visible={needsReload}
      closable={false}
      onHide={doReload}
      footer={footerContent}
    >
      <p className="m-0">
        Due to a new software release of Vendor Explorer just now,
        <br />
        you must reload the app to ensure using the latest versions
        <br />
        of all components.
        <br />
        <br />
        We are sorry for the inconvenience.
      </p>
    </Dialog>
  )
}

export default NeedsReloadDialog

import { useCallback } from 'react'

interface Props extends React.PropsWithChildren {
  href: string
}

const AnchorLink = ({ href, children }: Props): JSX.Element => {
  const handleClick = useCallback((event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault()
    const anchor = document.querySelector(href)
    if (anchor) {
      anchor.scrollIntoView({ behavior: 'smooth' })
    }
  }, [])

  return (
    <a href={href} onClick={handleClick}>
      {children}
    </a>
  )
}

export default AnchorLink

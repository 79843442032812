import { useCallback, useEffect, useRef, useState } from 'react'
import { useAppSelector, usePermission } from './hooks'
import {
  useFavoriteListQuery,
  useFavoriteToggleMutation,
  useQuestionListByQuestionPackQuery,
} from '../redux/api'
import DispatcherButton, {
  DispatcherButtonPresets,
  DispatcherButtonProps,
} from '../components/DispatcherButton'
import { AppDispatch } from '../redux/store'
import { setToastMessage } from '../redux/app'
import { confirmDialog } from 'primereact/confirmdialog'
import { useNavigate } from 'react-router-dom'
import { Button } from 'primereact/button'
import { Skeleton } from 'primereact/skeleton'
import { nonAlphanumericPattern } from '../consts'
import { utils, writeFile } from 'xlsx-js-style'
import { RfiStatus } from '../enums'

// TOGGLE FAVORITE ...
export const useToogleFavorite = (
  type: 'PRODUCT' | 'COMPANY' | 'RFI' | 'PARTICIPANT',
  data?: Product | Company | Rfi | Participant,
): {
  dispatcherButtonProps: DispatcherButtonProps[]
  dispatcherButton: JSX.Element
} => {
  const workspaceId = useAppSelector((state) => state.app.workspace!.id)

  const hasPermission = usePermission('favorite', 'delete')

  const { data: favorites } = useFavoriteListQuery({ workspaceId: workspaceId })

  const [toggleFavorite] = useFavoriteToggleMutation()

  const buttonProps = {
    icon: 'pi pi-heart',
    tooltip: 'Toogle favorite',
  }

  const onClick = (dispatch: AppDispatch, data: Product | Company | Rfi | Participant) =>
    toggleFavorite({ favorite: { type: type, _id: data.id }, workspaceId })

  const toggleProps = {
    icon: 'pi pi-heart-fill',
    isToggled: (data: Product | Company | Rfi | Participant) => {
      return (
        (favorites || []).findIndex((favorite) => {
          return favorite.type === type && favorite._id === data.id
        }) > -1
      )
    },
  }

  const dispatcherButtonProps = hasPermission
    ? [
        {
          buttonProps,
          className: DispatcherButtonPresets.PRIMARY_ICON_ACTIONS,
          onClick,
          toggleProps,
        },
      ]
    : []

  const dispatcherButton = hasPermission ? (
    <DispatcherButton
      buttonProps={buttonProps}
      onClick={onClick}
      toggleProps={toggleProps}
      data={data}
    />
  ) : (
    <></>
  )

  return { dispatcherButton, dispatcherButtonProps }
}

// ... TOGGLE FAVORITE

// CREATE BUTTON ...

export const useNavigateToCreate = (
  type: 'PRODUCT' | 'COMPANY' | 'PARTICIPANT' | 'RFI',
): {
  dispatcherButton: JSX.Element
} => {
  const navigate = useNavigate()

  const config = {
    PRODUCT: {
      title: 'Create Product',
      hasPermission: usePermission('product', 'create'),
      navigateTo: '/product/create/',
    },
    COMPANY: {
      title: 'Create Company',
      hasPermission: usePermission('company', 'create'),
      navigateTo: '/company/create/',
    },
    RFI: {
      title: 'Create RFI',
      hasPermission: usePermission('rfi', 'create'),
      navigateTo: '/rfi/create/',
    },
    PARTICIPANT: {
      title: 'Add Participant',
      hasPermission: usePermission('participant', 'create'),
      navigateTo: '/participant/create/',
    },
  }

  const buttonProps = { icon: 'pi pi-plus' }

  const dispatcherButton = config[type].hasPermission ? (
    <Button
      label={config[type].title}
      className={DispatcherButtonPresets.FILLED_ROUNDED_PRIMARY}
      {...buttonProps}
      onClick={() => navigate(config[type].navigateTo)}
    />
  ) : (
    <></>
  )

  return { dispatcherButton }
}

// ... CREATE BUTTON

// COPY TO CLIPBOARD ...
export const useCopyToClipboard = (
  value?: string,
): {
  dispatcherButtonProps: DispatcherButtonProps[]
  dispatcherButton: JSX.Element
} => {
  const buttonProps = { icon: 'pi pi-copy', className: 'p-button-primary' }

  const onClick = async (dispatch: AppDispatch, data: any) => {
    await navigator.clipboard.writeText(`${data}`)
    dispatch(
      setToastMessage({
        severity: 'info',
        summary: `Copied to clipboard.`,
      }),
    )
  }

  const dispatcherButtonProps = [
    {
      buttonProps,
      className: DispatcherButtonPresets.PRIMARY_ICON_ACTIONS,
      onClick,
    },
  ]

  const dispatcherButton = (
    <DispatcherButton
      buttonProps={buttonProps}
      onClick={onClick}
      data={value}
      className={DispatcherButtonPresets.FILLED_PRIMARY}
    />
  )

  return { dispatcherButton, dispatcherButtonProps }
}
// ... COPY TO CLIPBOARD

// COPY TYPE URL TO CLIPBOARD ...
export const useUrlCopyToClipboard = (
  type: 'PRODUCT' | 'COMPANY' | 'PARTICIPANT' | 'RFI',
  data?: Product | Company | Participant | Rfi,
): {
  dispatcherButtonProps: DispatcherButtonProps[]
  dispatcherButton: JSX.Element
} => {
  const config = {
    PRODUCT: {
      title: 'product',
      hasPermission: usePermission('product', 'read'),
      name: (data: Product) => data.name,
    },
    COMPANY: {
      title: 'company',
      hasPermission: usePermission('company', 'read'),
      name: (data: Company) => data.name,
    },
    RFI: {
      title: 'RFI',
      hasPermission: usePermission('rfi', 'read'),
      name: (data: Rfi) => data.name,
    },
    PARTICIPANT: {
      title: 'participant',
      hasPermission: usePermission('participant', 'read'),
      name: (data: Participant) => data.company.name,
    },
  }

  const buttonProps = {
    icon: 'pi pi-link',
    tooltip: 'Copy link to clipboard',
  }

  const onClick = async (dispatch: AppDispatch, data: any) => {
    await navigator.clipboard.writeText(
      `${window.location.origin}/${config[type].title.toLowerCase()}/${data.id}`,
    )
    dispatch(
      setToastMessage({
        severity: 'info',
        summary: `Link to ${config[type].title} "${config[type].name(data)}" copied to clipboard.`,
      }),
    )
  }

  const dispatcherButtonProps = config[type].hasPermission
    ? [
        {
          buttonProps,
          className: DispatcherButtonPresets.PRIMARY_ICON_ACTIONS,
          onClick,
        },
      ]
    : []

  const dispatcherButton = config[type].hasPermission ? (
    <DispatcherButton buttonProps={buttonProps} onClick={onClick} data={data} />
  ) : (
    <></>
  )

  return { dispatcherButton, dispatcherButtonProps }
}
// ... COPY TYPE TO CLIPBOARD

// EDIT MODE ...
export interface EditMode {
  enabled: boolean
  formRef: React.RefObject<HTMLFormElement>
  toggle: () => void
}

export const useEditMode = (): EditMode => {
  const [enabled, setEnabled] = useState(false)
  const formRef = useRef<HTMLFormElement>(null)
  const toggle = () => setEnabled(!enabled)
  return { enabled, formRef, toggle }
}

export const useEdit = (
  type: 'PRODUCT' | 'COMPANY' | 'RFI' | 'QUESTIONPACK',
  editMode: EditMode,
): {
  editDispatcherButton: JSX.Element
  cancelDispatchterButton: JSX.Element
  submitDispatcherButton: JSX.Element
} => {
  const config = {
    PRODUCT: {
      hasPermission: usePermission('product', 'update'),
    },
    COMPANY: {
      hasPermission: usePermission('company', 'update'),
    },
    RFI: {
      hasPermission: usePermission('rfi', 'update'),
    },
    QUESTIONPACK: {
      hasPermission: usePermission('question_pack', 'update'),
    },
  }

  const editDispatcherButton = config[type].hasPermission ? (
    <DispatcherButton
      buttonProps={{
        icon: 'pi pi-pencil',
        tooltip: 'Edit',
        onClick: editMode.toggle,
      }}
    />
  ) : (
    <></>
  )

  const cancelDispatchterButton = config[type].hasPermission ? (
    <DispatcherButton
      buttonProps={{
        key: 'times',
        icon: 'pi pi-times',
        tooltip: 'Cancel',
        onClick: editMode.toggle,
      }}
    />
  ) : (
    <></>
  )

  const submitDispatcherButton = config[type].hasPermission ? (
    <DispatcherButton
      buttonProps={{
        key: 'check',
        icon: 'pi pi-check',
        tooltip: 'Save',
        onClick: () => {
          if (editMode.formRef.current) {
            editMode.formRef.current.dispatchEvent(
              new Event('submit', { cancelable: true, bubbles: true }),
            )
          }
        },
      }}
    />
  ) : (
    <></>
  )

  return { editDispatcherButton, cancelDispatchterButton, submitDispatcherButton }
}

// ... EDIT MODE

// CANCEL CREATE ...

export const useCancelCreate = (): {
  dispatcherButton: JSX.Element
} => {
  const navigate = useNavigate()

  const onClick = () => {
    confirmDialog({
      message: 'Are you sure you want to cancel?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => navigate(-1),
    })
  }

  const dispatcherButton = (
    <>
      <DispatcherButton
        buttonProps={{
          key: 'times',
          icon: 'pi pi-times',
          tooltip: 'Cancel',
          onClick,
        }}
      />
    </>
  )

  return { dispatcherButton }
}

// ... CANCEL CREATE

// EXPORT QUESTIONPACK ...

export const useQuestionPackExportButton = (questionPack: QuestionPack): JSX.Element => {
  // only return button if user has permission to export
  if (!usePermission('question', 'export')) {
    return <></>
  }

  const [fileName, setFileName] = useState('')
  const [settings, setSettings] = useState<any[]>([])
  const [questions, setQuestions] = useState<any[]>([])

  const workspaceId = useAppSelector((state) => state.app.workspace!.id)
  const { data: questionList, isLoading: isQuestionListLoading } =
    useQuestionListByQuestionPackQuery({
      workspaceId: workspaceId,
      questionPackId: questionPack.id,
    })

  const headerStyle = {
    fill: { patternType: 'solid', fgColor: { rgb: '000000' } },
    font: { bold: true, color: { rgb: 'FFFFFF' } },
    alignment: { vertical: 'top' },
  }

  useEffect(() => {
    if (questionList !== undefined) {
      setFileName(
        (
          'VendorExplorer_' +
          questionPack.displayName +
          '_QuestionPack_' +
          new Date().toISOString().split('T')[0]
        ).replace(nonAlphanumericPattern, '-'),
      )

      setSettings([
        [
          {
            v: 'ID',
            s: headerStyle,
          },
          {
            v: questionPack.id,
            s: { alignment: { horizontal: 'left' } },
          },
        ],
        [
          {
            v: 'Ref',
            s: headerStyle,
          },
          questionPack.ref,
        ],
        [
          {
            v: 'Created At',
            s: headerStyle,
          },
          questionPack.createdAt,
        ],
        [
          {
            v: 'Created By (Member): ID',
            s: headerStyle,
          },
          {
            v: questionPack.createdBy.id,
            s: { alignment: { horizontal: 'left' } },
          },
        ],
        [
          {
            v: 'Created By (Member): Username',
            s: headerStyle,
          },
          questionPack.createdBy.username,
        ],
        [
          {
            v: 'Created By (Mamber): Display Name',
            s: headerStyle,
          },
          questionPack.createdBy.displayName,
        ],
        [
          {
            v: 'Name',
            s: headerStyle,
          },
          questionPack.name,
        ],
        [
          {
            v: 'Description',
            s: headerStyle,
          },
          questionPack.description,
        ],
        [
          {
            v: 'Display Name',
            s: headerStyle,
          },
          questionPack.displayName,
        ],
        [
          {
            v: 'Question Pack Type: ID',
            s: headerStyle,
          },
          {
            v: questionPack.questionPackType.id,
            s: { alignment: { horizontal: 'left' } },
          },
        ],
        [
          {
            v: 'Question Pack Type: Ref',
            s: headerStyle,
          },
          questionPack.questionPackType.ref,
        ],
        [
          {
            v: 'Question Pack Type: Name',
            s: headerStyle,
          },
          questionPack.questionPackType.name,
        ],
        [
          {
            v: 'Question Pack Type: Scope',
            s: headerStyle,
          },
          questionPack.questionPackType.scope,
        ],
        [
          {
            v: 'Question Pack Type: Pos',
            s: headerStyle,
          },
          {
            v: questionPack.questionPackType.pos,
            s: { alignment: { horizontal: 'left' } },
          },
        ],
        [
          {
            v: 'RFI Types: Company',
            s: headerStyle,
          },
          {
            v: questionPack.isRfiTypeCompany,
            s: { alignment: { horizontal: 'left' } },
          },
        ],
        [
          {
            v: 'RFI Types: Product',
            s: headerStyle,
          },
          {
            v: questionPack.isRfiTypeProduct,
            s: { alignment: { horizontal: 'left' } },
          },
        ],
        [
          {
            v: 'Options: Must-have',
            s: headerStyle,
          },
          {
            v: questionPack.isMustHave,
            s: { alignment: { horizontal: 'left' } },
          },
        ],
        [
          {
            v: 'Options: Selected by default',
            s: headerStyle,
          },
          {
            v: questionPack.isDefaultSelected,
            s: { alignment: { horizontal: 'left' } },
          },
        ],
        [
          {
            v: 'Origin Market',
            s: headerStyle,
          },
          questionPack.geo.name,
        ],
        [
          {
            v: 'Exclusive Markets',
            s: headerStyle,
          },
          {
            v: questionPack.geos.map((geo) => geo.name).join('\n'),
            s: { alignment: { vertical: 'top', wrapText: true } },
          },
        ],
        [
          {
            v: 'Exclusive Capabilities',
            s: headerStyle,
          },
          {
            v: questionPack.capabilities.map((capability) => capability.name).join('\n'),
            s: { alignment: { vertical: 'top', wrapText: true } },
          },
        ],
        [
          {
            v: 'Required Tags',
            s: headerStyle,
          },
          {
            v: questionPack.tags
              .map((tag) => {
                let tagTypeExtra = ''
                if (tag.tagType !== null) {
                  tagTypeExtra = ` (${tag.tagType.name})`
                }
                return `${tag.name}${tagTypeExtra}`
              })
              .join('\n'),
            s: { alignment: { vertical: 'top', wrapText: true } },
          },
        ],
        [
          {
            v: 'Weight',
            s: headerStyle,
          },
          {
            v: questionPack.weight,
            s: { alignment: { horizontal: 'left' } },
          },
        ],
        [
          {
            v: 'Config',
            s: headerStyle,
          },
          {
            v: JSON.stringify(questionPack.config),
            s: { alignment: { vertical: 'top', wrapText: true } },
          },
        ],
        [
          {
            v: 'Version',
            s: headerStyle,
          },
          {
            v: questionPack.version,
            s: { alignment: { horizontal: 'left' } },
          },
        ],
      ])

      setQuestions(
        questionList.map((question) => {
          return [
            {
              v: question.id,
              s: { alignment: { vertical: 'top' } },
            },
            {
              v: question.ref,
              s: { alignment: { vertical: 'top' } },
            },
            {
              v: question.phrase,
              s: { alignment: { vertical: 'top', wrapText: true } },
            },
            {
              v: question.description || '',
              s: { alignment: { vertical: 'top' } },
            },
            {
              v: question.type,
              s: { alignment: { vertical: 'top' } },
            },
            {
              v: question.isRfiTypeCompany,
              s: { alignment: { vertical: 'top' } },
            },
            {
              v: question.isRfiTypeProduct,
              s: { alignment: { vertical: 'top' } },
            },
            {
              v: question.isMustHave,
              s: { alignment: { vertical: 'top' } },
            },
            {
              v: question.isDefaultSelected,
              s: { alignment: { vertical: 'top' } },
            },
            {
              v: question.geos.map((geo) => geo.name).join('\n'),
              s: { alignment: { vertical: 'top', wrapText: true } },
            },
            {
              v: question.capabilities.map((capability) => capability.name).join('\n'),
              s: { alignment: { vertical: 'top', wrapText: true } },
            },
            {
              v: question.tags
                .map((tag) => {
                  let tagTypeExtra = ''
                  if (tag.tagType !== null) {
                    tagTypeExtra = ` (${tag.tagType.name})`
                  }
                  return `${tag.name}${tagTypeExtra}`
                })
                .join('\n'),
              s: { alignment: { vertical: 'top', wrapText: true } },
            },
            {
              v: question.constraintIsVisibleExpr,
              s: { alignment: { vertical: 'top' } },
            },
            {
              v: question.constraintIsMandatoryExpr,
              s: { alignment: { vertical: 'top' } },
            },
            {
              v: JSON.stringify(question.config),
              s: { alignment: { vertical: 'top', wrapText: true } },
            },
          ]
        }),
      )
    }
  }, [isQuestionListLoading])

  const exportFile = useCallback(() => {
    // worksheet from settings
    const wss = utils.json_to_sheet([])
    wss['!cols'] = [{ width: 40 }, { width: 80 }]
    utils.sheet_add_aoa(wss, settings, { origin: 'A1' })

    // worksheet from questions
    const wsq = utils.json_to_sheet([])
    wsq['!cols'] = [
      { width: 10 },
      { width: 40 },
      { width: 80 },
      { width: 40 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 30 },
      { width: 40 },
      { width: 40 },
      { width: 40 },
      { width: 40 },
      { width: 40 },
      { width: 80 },
    ]
    utils.sheet_add_aoa(
      wsq,
      [
        [
          { v: 'ID', s: headerStyle },
          { v: 'Ref', s: headerStyle },
          { v: 'Phrase', s: headerStyle },
          { v: 'Description', s: headerStyle },
          { v: 'Type', s: headerStyle },
          { v: 'RFI Types: Company', s: headerStyle },
          { v: 'RFI Types: Product', s: headerStyle },
          { v: 'Options: Must-have', s: headerStyle },
          { v: 'Options: Selected by default', s: headerStyle },
          { v: 'Exclusive Markets', s: headerStyle },
          { v: 'Exclusive Capabilities', s: headerStyle },
          { v: 'Required Tags', s: headerStyle },
          { v: 'Constraints: Visible', s: headerStyle },
          { v: 'Constraints: Mandatory', s: headerStyle },
          { v: 'Config', s: headerStyle },
        ],
        ...questions,
      ],
      { origin: 'A1' },
    )

    // create workbook and append worksheets
    const wb = utils.book_new()
    utils.book_append_sheet(wb, wss, 'Question Pack Settings')
    utils.book_append_sheet(wb, wsq, 'Questions')

    // trigger download workbook as xlsx
    writeFile(wb, fileName + '.xlsx', { cellStyles: true })
  }, [settings])

  return isQuestionListLoading ? (
    <Skeleton />
  ) : (
    <DispatcherButton
      buttonProps={{
        icon: 'pi pi-file-excel',
        tooltip: 'Export',
        onClick: exportFile,
      }}
    />
  )
}

// ... EXPORT QUESTIONPACK

// EXPORT QUESTION & ANSWERS ...

export const useQuestionAnswerExportButton = (
  data: QuestionPack[],
  participant: Participant,
): JSX.Element => {
  // only return button if user has permission to export answers and rfi is published
  if (!usePermission('answer', 'export') || participant.status !== RfiStatus.PUBLISHED) {
    return <></>
  }

  const headerStyle = {
    fill: { patternType: 'solid', fgColor: { rgb: '000000' } },
    font: { bold: true, color: { rgb: 'FFFFFF' } },
    alignment: { vertical: 'top' },
  }

  const fileName = (
    'VendorExplorer_' +
    participant.rfi.name +
    '_' +
    (participant.product !== null ? participant.product.name : participant.company.name) +
    '_Answers_' +
    new Date().toISOString().split('T')[0]
  ).replace(nonAlphanumericPattern, '-')

  const participantProductSettings =
    participant.product !== null
      ? [
          [
            {
              v: 'Participant (Product): ID',
              s: headerStyle,
            },
            {
              v: participant.product.id,
              s: { alignment: { horizontal: 'left' } },
            },
          ],
          [
            {
              v: 'Participant (Product): Ref',
              s: headerStyle,
            },
            participant.product.ref,
          ],
          [
            {
              v: 'Participant (Product): Name',
              s: headerStyle,
            },
            participant.product.name,
          ],
          [
            {
              v: 'Participant (Product): Description',
              s: headerStyle,
            },
            participant.product.description,
          ],
          [
            {
              v: 'Participant (Product): Aliases',
              s: headerStyle,
            },
            participant.product.aliases,
          ],
          [
            {
              v: 'Participant (Product): Capabilities',
              s: headerStyle,
            },
            {
              v: participant.product.capabilities.map((capability) => capability.name).join('\n'),
              s: { alignment: { vertical: 'top', wrapText: true } },
            },
          ],
        ]
      : []

  const settings = [
    [
      {
        v: 'Participant: ID',
        s: headerStyle,
      },
      {
        v: participant.id,
        s: { alignment: { horizontal: 'left' } },
      },
    ],
    [
      {
        v: 'Participant: Created At',
        s: headerStyle,
      },
      participant.createdAt,
    ],
    [
      {
        v: 'Participant: Created By (Member): ID',
        s: headerStyle,
      },
      {
        v: participant.createdBy.id,
        s: { alignment: { horizontal: 'left' } },
      },
    ],
    [
      {
        v: 'Participant: Created By (Member): Username',
        s: headerStyle,
      },
      participant.createdBy.username,
    ],
    [
      {
        v: 'Participant: Created By (Member): Display Name',
        s: headerStyle,
      },
      participant.createdBy.displayName,
    ],
    [
      {
        v: 'Participant (Company): ID',
        s: headerStyle,
      },
      {
        v: participant.company.id,
        s: { alignment: { horizontal: 'left' } },
      },
    ],
    [
      {
        v: 'Participant (Company): Ref',
        s: headerStyle,
      },
      participant.company.ref,
    ],
    [
      {
        v: 'Participant (Company): Name',
        s: headerStyle,
      },
      participant.company.name,
    ],
    [
      {
        v: 'Participant (Company): Description',
        s: headerStyle,
      },
      participant.company.description,
    ],
    [
      {
        v: 'Participant (Company): Aliases',
        s: headerStyle,
      },
      participant.company.aliases,
    ],
    ...participantProductSettings,
    [
      {
        v: 'Participant: Status',
        s: headerStyle,
      },
      participant.status,
    ],
    [
      {
        v: 'Participant: Progress',
        s: headerStyle,
      },
      {
        v: participant.progress,
        s: { alignment: { horizontal: 'left' } },
      },
    ],
    [
      {
        v: 'Participant: Start Date',
        s: headerStyle,
      },
      participant.startDate,
    ],
    [
      {
        v: 'Participant: End Date',
        s: headerStyle,
      },
      participant.endDate,
    ],
    [
      {
        v: 'RFI: ID',
        s: headerStyle,
      },
      {
        v: participant.rfi.id,
        s: { alignment: { horizontal: 'left' } },
      },
    ],
    [
      {
        v: 'RFI: Name',
        s: headerStyle,
      },
      participant.rfi.name,
    ],
    [
      {
        v: 'RFI: Description',
        s: headerStyle,
      },
      participant.rfi.description,
    ],
    [
      {
        v: 'RFI: Type',
        s: headerStyle,
      },
      participant.rfi.type,
    ],
    [
      {
        v: 'RFI: Geo',
        s: headerStyle,
      },
      participant.rfi.geo,
    ],
    [
      {
        v: 'RFI: Capabilities',
        s: headerStyle,
      },
      {
        v: participant.rfi.capabilities.map((capability) => capability.name).join('\n'),
        s: { alignment: { vertical: 'top', wrapText: true } },
      },
    ],
    [
      {
        v: 'RFI: Tags',
        s: headerStyle,
      },
      {
        v: participant.rfi.tags
          .map((tag) => {
            let tagTypeExtra = ''
            if (tag.tagType !== null) {
              tagTypeExtra = ` (${tag.tagType.name})`
            }
            return `${tag.name}${tagTypeExtra}`
          })
          .join('\n'),
        s: { alignment: { vertical: 'top', wrapText: true } },
      },
    ],
  ]

  const questions = data.flatMap((questionPack) => {
    return questionPack.questions.map((question) => {
      return [
        {
          v: questionPack.id,
          s: { alignment: { vertical: 'top' } },
        },
        {
          v: questionPack.ref,
          s: { alignment: { vertical: 'top' } },
        },
        {
          v: questionPack.name,
          s: { alignment: { vertical: 'top' } },
        },
        {
          v: questionPack.displayName,
          s: { alignment: { vertical: 'top' } },
        },
        {
          v: question.id,
          s: { alignment: { vertical: 'top' } },
        },
        {
          v: question.ref,
          s: { alignment: { vertical: 'top' } },
        },
        {
          v: question.type,
          s: { alignment: { vertical: 'top' } },
        },
        {
          v: question.phrase,
          s: { alignment: { vertical: 'top', wrapText: true } },
        },
        {
          v: JSON.stringify(question.config),
          s: { alignment: { vertical: 'top', wrapText: true } },
        },
        {
          v: question.answer?.id,
          s: { alignment: { vertical: 'top' } },
        },
        {
          v: JSON.stringify(question.answer?.data),
          s: { alignment: { vertical: 'top', wrapText: true } },
        },
      ]
    })
  })

  const exportFile = () => {
    // worksheet from settings
    const wss = utils.json_to_sheet([])
    wss['!cols'] = [{ width: 50 }, { width: 80 }]
    utils.sheet_add_aoa(wss, settings, { origin: 'A1' })

    // worksheet from questions
    const wsq = utils.json_to_sheet([])
    wsq['!cols'] = [
      { width: 20 },
      { width: 30 },
      { width: 30 },
      { width: 30 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 80 },
      { width: 80 },
      { width: 20 },
      { width: 80 },
    ]
    utils.sheet_add_aoa(
      wsq,
      [
        [
          { v: 'Question Pack: ID', s: headerStyle },
          { v: 'Question Pack: Ref', s: headerStyle },
          { v: 'Question Pack: Name', s: headerStyle },
          { v: 'Question Pack: Display Name', s: headerStyle },
          { v: 'Question: ID', s: headerStyle },
          { v: 'Question: Ref', s: headerStyle },
          { v: 'Question: Type', s: headerStyle },
          { v: 'Question: Phrase', s: headerStyle },
          { v: 'Question: Config', s: headerStyle },
          { v: 'Answer: ID', s: headerStyle },
          { v: 'Answer: Data', s: headerStyle },
        ],
        ...questions,
      ],
      { origin: 'A1' },
    )

    // create workbook and append worksheets
    const wb = utils.book_new()
    utils.book_append_sheet(wb, wss, 'Participant and RFI Settings')
    utils.book_append_sheet(wb, wsq, 'Questions and Answers')

    // trigger download workbook as xlsx
    writeFile(wb, fileName + '.xlsx', { cellStyles: true })
  }

  return (
    <DispatcherButton
      buttonProps={{
        icon: 'pi pi-file-excel',
        label: 'Export',
        onClick: exportFile,
      }}
      className={DispatcherButtonPresets.TERTIARY_ACTIONS}
    />
  )
}

// ... EXPORT QUESTION & ANSWERS

import { Divider } from 'primereact/divider'
import { Skeleton } from 'primereact/skeleton'

const ParticipantDetailsSkeleton = (): JSX.Element => {
  return (
    <>
      <div className="flex justify-content-between">
        <Skeleton width="100px" height="45px" className="mb-4"></Skeleton>
        <Skeleton width="200px" height="30px" className="mb-4"></Skeleton>
      </div>
      <div className="card mt-5">
        <Skeleton width="200px" height="30px" className="mb-4"></Skeleton>
        <Skeleton width="200px" height="20px" className="mb-4"></Skeleton>
      </div>
      <Divider />
      <div className="card mb-5">
        <div className="flex card-container overflow-hidden gap-4">
          <div className="flex-auto col-6">
            <div className="flex flex-column gap-3">
              <Skeleton width="600px" height="130px" className="mb-4"></Skeleton>
            </div>
          </div>
          <div className="flex flex-wrap col-6 ">
            <div className="flex flex-wrap flex-column gap-3">
              <Skeleton width="600px" height="130px" className="mb-4"></Skeleton>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ParticipantDetailsSkeleton

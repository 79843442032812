import { useRef } from 'react'
import { Avatar } from 'primereact/avatar'
import { Menu } from 'primereact/menu'
import PrimeReact from 'primereact/api'
import { useAppDispatch, useAppSelector } from '../common/hooks'
import { logout } from '../redux/actions'

import { Link } from 'react-router-dom'
import { useVersionRamServiceQuery, useVersionVendorExplorerServiceQuery } from '../redux/api'

PrimeReact.ripple = true

const Header = (): JSX.Element => {
  const username = useAppSelector((state) => state.app.username) || ''
  const permissions = useAppSelector((state) => state.app.permissions) || []
  const roles = permissions
    .filter(
      (value, index, self) => self.findIndex((item) => item.role.ref === value.role.ref) === index,
    )
    .map((permission) => permission.role.name)
    .sort() // sort alphabetically
    .join(', ')

  const { data: vendorVersionData, isLoading: vendorVersionLoading } =
    useVersionVendorExplorerServiceQuery({})
  const { data: ramVersionData, isLoading: ramVersionLoading } = useVersionRamServiceQuery({})

  const version = process.env.VERSION ? process.env.VERSION : ''

  const userMenu = useRef<Menu>(null),
    dispatch = useAppDispatch()

  const versionItem = (item: any) => {
    return (
      <div className="pl-3 ml-1 pr-3 mr-1">
        <span>{item.label} </span>
        <i className={item.icon}></i>
      </div>
    )
  }

  const items = [
    {
      label: username,
      items: [
        {
          label: roles,
          className: 'text-xs pb-2',
          visible: true,
          disabled: true,
          template: versionItem,
        },
        {
          label: `ENV: ${process.env.ENV}`,
          className: 'text-xs',
          visible: process.env.ENV !== 'prod',
          disabled: true,
          template: versionItem,
        },
        {
          label: `UI: ${version}`,
          className: 'text-xs',
          visible: true,
          disabled: true,
          template: versionItem,
        },
        {
          label: `API: ${vendorVersionData?.version}`,
          className: 'text-xs',
          visible: true,
          disabled: true,
          template: versionItem,
          icon: vendorVersionLoading ? 'pi pi-spin pi-spinner' : '',
        },
        {
          label: `RAM: ${ramVersionData?.version}`,
          className: 'text-xs pb-2',
          visible: true,
          disabled: true,
          template: versionItem,
          icon: ramVersionLoading ? 'pi pi-spin pi-spinner' : '',
        },
        {
          label: 'Logout',
          icon: 'pi pi-power-off',
          command: () => dispatch(logout()),
        },
      ],
    },
  ]

  return (
    <div
      className={`w-full shadow-2 flex align-items-center justify-content-between relative lg:static border-bottom-1 border-gray-800`}
      style={{ height: '70px', backgroundColor: 'rgb(24, 24, 27)' }}
    >
      <div className="ml-4 text-white flex align-items-center flex-shrink-0 justify-content-between">
        <h1 className="mr-4">Global Innovation Group</h1>
        <div style={{ height: '30px', width: '2px', backgroundColor: 'white' }}></div>
        <Link to={'/'} style={{ textDecoration: 'none' }} className={'text-white ml-4'}>
          <h2>Vendor Explorer</h2>
        </Link>
        {process.env.ENV !== 'prod' ? (
          <h2 className="ml-4 uppercase text-orange-500">{process.env.ENV}</h2>
        ) : null}
      </div>

      <div className="mr-4 left-0 top-100 z-1 shadow-2 flex-shrink-0 lg:shadow-none">
        <ul className="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row">
          <li>
            <Avatar
              icon="pi pi-user"
              className="mr-2"
              style={{ backgroundColor: '#8B5CF6', color: '#ffffff' }}
              shape="circle"
              onClick={(event) => userMenu.current?.toggle(event)}
            />
            <Menu model={items} ref={userMenu} popup id="userMenu" />
          </li>
        </ul>
      </div>
    </div>
  )
}

export default Header

import { useAppSelector } from '../../common/hooks'
import { useCompanyQuery } from '../../redux/api'
import { useParams } from 'react-router-dom'
import CompanyDetails from '../../components/Company/CompanyDetails'
import { useEditMode } from '../../common/dispatcherButtonHooks'
import CompanyTabs from '../../components/Company/CompanyTabs'
import CompanyDetailsSkeleton from '../../components/Company/CompanyDetailsSkeleton'
import CompanyToolbar from '../../components/Company/CompanyToolbar'
import CompanyDetailsEdit from '../../components/Company/CompanyDetailsEdit'
import NotFoundPage from '../http/404NotFoundPage'

const CompanyDetailsPage = (): JSX.Element => {
  const urlParams = useParams()
  const workspaceId = useAppSelector((state) => state.app.workspace!.id)
  const editMode = useEditMode()

  // load company by company-id
  const { data: company, isLoading: isCompanyLoading } = useCompanyQuery({
    workspaceId: workspaceId,
    companyId: urlParams.id!,
  })

  return (
    <div className="px-4 py-5 md:px-6 lg:px-8">
      {isCompanyLoading ? (
        <CompanyDetailsSkeleton />
      ) : company === undefined ? (
        <NotFoundPage />
      ) : (
        <>
          <CompanyToolbar company={company} editMode={editMode} />
          {editMode.enabled ? (
            <CompanyDetailsEdit
              company={company}
              toggleEdit={editMode.toggle}
              ref={editMode.formRef}
            />
          ) : (
            <>
              <CompanyDetails company={company} />
              <CompanyTabs company={company} />
            </>
          )}
        </>
      )}
    </div>
  )
}

export default CompanyDetailsPage

import { EventChannel, eventChannel } from 'redux-saga'
import { race, call, delay, take, put } from 'redux-saga/effects'
import { setNeedsReload } from '../redux/app'

const RECHECK_INTERVAL = process.env.VERSION_CHECK_INTERVAL_MS || 5 * 60 * 1000,
  VERSION_URL = '/VERSION'

const getVersion = async (): Promise<string> => {
  const request = await fetch(VERSION_URL + '?' + Date.now())
  return await request.text()
}

export default function* versionSaga(): Generator {
  const version = yield call(getVersion),
    channel = yield call(watchEvents)

  while (true) {
    // @ts-ignore
    const [, wasTimeout] = yield race([take(channel), delay(RECHECK_INTERVAL)])

    if (wasTimeout) {
      const currentVersion = yield call(getVersion)
      if (currentVersion !== version) yield put(setNeedsReload())
    }
  }
}

function watchEvents(): EventChannel<{ type: string }> {
  return eventChannel((emitter) => {
    const body = document.querySelector('body'),
      onMouseDown = body?.addEventListener('mousedown', () => emitter({ type: 'mousedown' })),
      onKeyUp = body?.addEventListener('keyup', () => emitter({ type: 'keyup' }))

    return () => {
      body?.removeEventListener('mousedown', onMouseDown!)
      body?.removeEventListener('keyup', onKeyUp!)
    }
  })
}

import { Toolbar } from 'primereact/toolbar'
import { useAppSelector } from '../../common/hooks'
import {
  EditMode,
  useUrlCopyToClipboard,
  useToogleFavorite,
} from '../../common/dispatcherButtonHooks'
import BackButton from '../BackButton'
import { useDeleteModal } from '../../common/modalHooks'

interface Props {
  participant: Participant
  editMode: EditMode
}

const ParticipantToolbar = ({ participant: data, editMode }: Props): JSX.Element => {
  const workspaceId = useAppSelector((state) => state.app.workspace!.id)

  const toggleFavorite = useToogleFavorite('PARTICIPANT', data)
  const copyToClipboard = useUrlCopyToClipboard('PARTICIPANT', data)
  const deleteParticipant = useDeleteModal('PARTICIPANT', data, '/rfi/')

  // TODO refactor to hooks and add permissions for edit and delete
  const toolbarRight = editMode.enabled ? (
    <></>
  ) : (
    <>
      {toggleFavorite.dispatcherButton}
      {copyToClipboard.dispatcherButton}
      {deleteParticipant.dispatcherButton}
    </>
  )

  return (
    <>
      <div className="flex justify-content-between">
        <div className="flex-none ">
          <BackButton />
        </div>
        <Toolbar
          right={toolbarRight}
          className="p-0 m-0"
          style={{ border: 0, background: 'none' }}
        />
      </div>
    </>
  )
}

export default ParticipantToolbar

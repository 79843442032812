import { useAppSelector } from '../../common/hooks'
import { useRfiQuery } from '../../redux/api'
import { useParams } from 'react-router-dom'
import { useEditMode } from '../../common/dispatcherButtonHooks'
import RfiDetailsSkeleton from '../../components/RFI/RfiDetailsSkeleton'
import RfiToolbar from '../../components/RFI/RfiToolbar'
import RfiTabs from '../../components/RFI/RfiTabs'
import RfiDetails from '../../components/RFI/RfiDetails'
import RfiDetailsEdit from '../../components/RFI/RfiDetailsEdit'
import NotFoundPage from '../http/404NotFoundPage'

const RfiDetailsPage = (): JSX.Element => {
  const urlParams = useParams()
  const workspaceId = useAppSelector((state) => state.app.workspace!.id)
  const editMode = useEditMode()

  const { data: rfi, isLoading: isRfiLoading } = useRfiQuery({
    workspaceId: workspaceId,
    itemId: urlParams.id!,
  })

  return (
    <div className="px-4 py-5 md:px-6 lg:px-8">
      {isRfiLoading ? (
        <RfiDetailsSkeleton />
      ) : rfi === undefined ? (
        <NotFoundPage />
      ) : (
        <>
          <RfiToolbar rfi={rfi} editMode={editMode} />
          {editMode.enabled ? (
            <RfiDetailsEdit rfi={rfi} toggleEdit={editMode.toggle} ref={editMode.formRef} />
          ) : (
            <>
              <RfiDetails rfi={rfi} />
              <RfiTabs rfi={rfi} />
            </>
          )}
        </>
      )}
    </div>
  )
}

export default RfiDetailsPage

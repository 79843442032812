import TagList, { NORMALIZE_PRESETS } from '../TagList'
import { Divider } from 'primereact/divider'
import { Tag } from 'primereact/tag'
import { RfiStatus } from '../../enums'
import { ProgressBar } from 'primereact/progressbar'
import { useExtendModal, useWithdrawModal } from '../../common/modalHooks'
import { useGroupedTagList } from '../../common/hooks'
import ProgressTag from '../ProgressTag'

interface Props {
  rfi?: Rfi
}

const RfiDetails = ({ rfi }: Props): JSX.Element => {
  const groupedTagList = useGroupedTagList(rfi?.tags)

  const extendButton = useExtendModal('RFI', rfi)
  const withdrawButton = useWithdrawModal('RFI', rfi)

  const progress =
    rfi!.status === RfiStatus.IN_PROGRESS ? (
      <div className="w-3">
        <ProgressTag status={rfi!.status} progress={rfi!.progress} />
      </div>
    ) : (
      <Tag
        className={'bg-primary-reverse border-1'}
        value={String(rfi!.status).replace(/_/g, ' ')}
      ></Tag>
    )

  return (
    <>
      {extendButton.modal}
      <div className="card mt-5">
        <h3 className="mb-0">RFI</h3>
        <h1 className="mt-1">{rfi?.name}</h1>
        <p>{rfi?.description}</p>
      </div>
      <Divider />
      <div className="card mb-5">
        <div className="flex card-container overflow-hidden gap-4">
          <div className="flex-auto col-6">
            <div className="flex flex-column gap-4">
              <div className="flex gap-2 align-items-start">
                <span className="font-medium mt-1">Status: </span>
                {progress}
                {withdrawButton.dispatcherButton}
              </div>
              <div>
                <span className="font-medium">Type: </span>
                <span>{rfi?.type}</span>
              </div>
              <div>
                <span className="font-medium">Market: </span>
                <Tag className="bg-bluegray-500" value={rfi?.geo.name} />
              </div>
              <div className="flex gap-1">
                <div className="font-medium">Capabilities: </div>
                <div className="flex flex-wrap gap-1">
                  <TagList
                    items={rfi?.capabilities || []}
                    normalize={NORMALIZE_PRESETS.POS}
                    emptyVal={'(none)'}
                    maxShown={10}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-wrap col-6 ">
            <div className="flex flex-wrap flex-column gap-4">
              <div>
                <span className="font-medium">Start Date: </span>
                <span>{rfi?.startDate}</span>
              </div>
              <div className="flex gap-2 align-items-center">
                <span className="font-medium">End Date: </span>
                <span>{rfi?.endDate}</span>
                {extendButton.dispatcherButton}
              </div>
              {groupedTagList}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default RfiDetails

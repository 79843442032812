import { useEffect } from 'react'
import RfiList from '../components/RFI/RfiList'
import ProductList from '../components/Product/ProductList'
import CompanyList from '../components/Company/CompanyList'
import { AppError, setError } from '../redux/app'
import {
  useAppDispatch,
  useAppSelector,
  useDebounce,
  useInput,
  usePermission,
} from '../common/hooks'
import {
  useCompanyListBySearchByStrQuery,
  useCompanyListWithPaginationQuery,
  useFavoriteListQuery,
  useProductListBySearchByStrQuery,
  useProductListWithPaginationQuery,
  useQuestionPackListBySearchByStrQuery,
  useQuestionPackListWithPaginationQuery,
  useRfiListBySearchByStrQuery,
  useRfiListWithPaginationQuery,
} from '../redux/api'
import { prepareSearchValueSpaceJoin, sortByCreatedAtDesc } from '../common/utils'
import SearchBar from '../components/SearchBar'
import FavoriteTopFive from '../components/Favorite/FavoriteTopFive'
import QuestionPackList from '../components/QuestionPack/QuestionPackList'
import { useSearchParams, useNavigate } from 'react-router-dom'
import { DataTablePropsPresets } from '../components/DataTable/DataTableProvider'

interface Props {
  rows?: number
}

const HomePage = ({ rows = 5 }: Props): JSX.Element => {
  const currentWorkSpace = useAppSelector((state) => state.app.workspace!.id)
  const dispatch = useAppDispatch()

  const navigate = useNavigate()
  const hasCompanyListPermission = usePermission('company', 'list')
  const hasProductListPermission = usePermission('product', 'list')
  const hasRfiListPermission = usePermission('rfi', 'list')
  const hasQuestionPackListPermission = usePermission('question_pack', 'list')
  const hasCompanySearchPermission = usePermission('company', 'search')
  const hasProductSearchPermission = usePermission('product', 'search')
  const hasRfiSearchPermission = usePermission('rfi', 'search')
  const hasQuestionPackSearchPermission = usePermission('question_pack', 'search')

  const [searchStr] = useSearchParams()
  const [searchValue, onSearchInputChange, clearSearchInput] = useInput(
    searchStr.get('searchStr') || '',
  )
  const debouncedSearchValue = useDebounce(searchValue, 500)
  useEffect(() => {
    searchValue ? navigate(`/home?searchStr=${searchValue}`) : navigate(`/home`)
  }, [debouncedSearchValue])

  const {
    data: companyListData,
    error: companyError,
    isLoading: companyIsLoading,
    isFetching: companyIsFetching,
  } = !!debouncedSearchValue
    ? useCompanyListBySearchByStrQuery({
        workspaceId: currentWorkSpace,
        words: prepareSearchValueSpaceJoin(debouncedSearchValue),
      })
    : useCompanyListWithPaginationQuery({
        workspaceId: currentWorkSpace,
        paginationParams: { page: 1, size: rows, sort: '-createdAt' },
      })

  const {
    data: productListData,
    error: productError,
    isLoading: productIsLoading,
    isFetching: productIsFetching,
  } = !!debouncedSearchValue
    ? useProductListBySearchByStrQuery({
        workspaceId: currentWorkSpace,
        words: prepareSearchValueSpaceJoin(debouncedSearchValue),
      })
    : useProductListWithPaginationQuery({
        workspaceId: currentWorkSpace,
        paginationParams: { page: 1, size: rows, sort: '-createdAt' },
      })

  const {
    data: rfiListData,
    error: rfiError,
    isLoading: rfiIsLoading,
    isFetching: rfiIsFetching,
  } = !!debouncedSearchValue
    ? useRfiListBySearchByStrQuery({
        workspaceId: currentWorkSpace,
        words: prepareSearchValueSpaceJoin(debouncedSearchValue),
      })
    : useRfiListWithPaginationQuery({
        workspaceId: currentWorkSpace,
        paginationParams: { page: 1, size: rows, sort: '-createdAt' },
      })

  const {
    data: questionPackListData,
    error: questionPackError,
    isLoading: questionPackIsLoading,
    isFetching: questionPackIsFetching,
  } = !!debouncedSearchValue
    ? useQuestionPackListBySearchByStrQuery({
        workspaceId: currentWorkSpace,
        words: prepareSearchValueSpaceJoin(debouncedSearchValue),
      })
    : useQuestionPackListWithPaginationQuery({
        workspaceId: currentWorkSpace,
        paginationParams: { page: 1, size: rows, sort: '-createdAt' },
      })

  const {
    data: favoriteData,
    error: favoriteError,
    isLoading: favoriteIsLoading,
    isFetching: favoriteIsFetching,
  } = useFavoriteListQuery({ workspaceId: currentWorkSpace })

  const isAllFetching = rfiIsFetching || productIsFetching || companyIsFetching

  useEffect(() => {
    if (productError) {
      dispatch(setError(AppError.PRODUCT_API_REQUEST))
    }
    if (companyError) {
      dispatch(setError(AppError.COMPANY_API_REQUEST))
    }
    if (rfiError) {
      dispatch(setError(AppError.RFI_API_REQUEST))
    }
    if (questionPackError) {
      dispatch(setError(AppError.QUESTIONPACK_API_REQUEST))
    }
  })

  return (
    <div className="px-4 py-5 md:px-6 lg:px-8">
      {/* SEARCH BAR */}
      {hasCompanySearchPermission || hasProductSearchPermission || hasRfiSearchPermission ? (
        <SearchBar
          searchValue={searchValue}
          onSearchInputChange={onSearchInputChange}
          clearSearchInput={clearSearchInput}
          isFetching={isAllFetching}
        />
      ) : (
        ''
      )}

      {!searchValue ? (
        <FavoriteTopFive
          topFavorites={[...(favoriteData || [])].sort(sortByCreatedAtDesc).slice(0, 3)}
          amountFavorites={favoriteData?.length}
          isLoading={favoriteIsLoading}
          isFetching={favoriteIsFetching}
        />
      ) : (
        <></>
      )}

      {/* RFI LIST */}
      {hasRfiListPermission || (searchValue && hasRfiSearchPermission) ? (
        <RfiList
          data={rfiListData?.content}
          toolbarLeft={<h1>Most Recent RFIs</h1>}
          isLoading={rfiIsLoading || rfiIsFetching}
          useSorting={false}
          dataTableProps={DataTablePropsPresets.homePage(
            debouncedSearchValue ? `/rfi?searchStr=${debouncedSearchValue}` : '/rfi',
            rows,
            rfiListData,
          )}
        />
      ) : (
        <></>
      )}

      {/* COMPANY LIST */}
      {hasCompanyListPermission || (searchValue && hasCompanySearchPermission) ? (
        <CompanyList
          data={companyListData?.content}
          toolbarLeft={<h1>Most Recent Companies</h1>}
          isLoading={companyIsLoading || companyIsFetching}
          useSorting={false}
          dataTableProps={DataTablePropsPresets.homePage(
            debouncedSearchValue ? `/company?searchStr=${debouncedSearchValue}` : '/company',
            rows,
            companyListData,
          )}
        />
      ) : (
        <></>
      )}

      {/* PRODUCT LIST */}
      {hasProductListPermission || (searchValue && hasProductSearchPermission) ? (
        <ProductList
          data={productListData?.content}
          toolbarLeft={<h1>Most Recent Products</h1>}
          isLoading={productIsLoading || productIsFetching}
          useSorting={false}
          dataTableProps={DataTablePropsPresets.homePage(
            debouncedSearchValue ? `/product?searchStr=${debouncedSearchValue}` : '/product',
            rows,
            productListData,
          )}
        />
      ) : (
        <></>
      )}

      {/* QUESTION PACK LIST */}
      {hasQuestionPackListPermission || (searchValue && hasQuestionPackSearchPermission) ? (
        <QuestionPackList
          data={questionPackListData?.content}
          toolbarLeft={<h1>Most Recent Question Packs</h1>}
          isLoading={questionPackIsLoading || questionPackIsFetching}
          useSorting={false}
          dataTableProps={DataTablePropsPresets.homePage(
            debouncedSearchValue
              ? `/questionpack?searchStr=${debouncedSearchValue}`
              : '/questionpack',
            rows,
            questionPackListData,
          )}
        />
      ) : (
        <></>
      )}
    </div>
  )
}

export default HomePage

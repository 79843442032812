import { isRejectedWithValue } from '@reduxjs/toolkit'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { Middleware, MiddlewareAPI } from 'redux'
import { FormContactDataOption } from '../common/formHooks'
import { setToastMessage } from './app'
import { RootState } from './store'
import { RfiType } from '../enums'

interface ToggleFavorite {
  favorite: Omit<Favorite, 'id' | 'createdAt'>
  workspaceId: number
}

export interface UpdateRfi {
  name: string
  description: string | null
}

export interface CreateRfi extends UpdateRfi {
  geo: Geo | undefined
  startDate: string
  endDate: string
  capabilities: Capability[]
  tags: Tag[]
  type: keyof typeof RfiType | undefined
  questionPacks: QuestionPack[]
  participants: Participant[]
}

export interface ReducedCreateRfi {
  name: string
  description: string | null
  geo: { id: number }
  startDate: string
  endDate: string
  capabilities: { id: number }[]
  tags: { id: number }[]
  type: keyof typeof RfiType
  questionPacks: { id: number; excludedQuestions?: { id: number }[] }[]
  participants: { company: { id: number }; product: { id: number } | null }[]
}

export interface UpdateExtend {
  endDate: string
}

export interface UpdateParticipantCanSend {
  contacts: FormContactDataOption[]
  customMessage: string
}

export interface MailToParticipant {
  participantUrl: string
  participantPassword: string
  mailToUrl: string
}

export interface CreateParticipant {
  rfi: {
    id: number
  }
  company: {
    id: number
  }
  product?: {
    id: number
  }
}

export interface UpdateCompany {
  name: string
  description: string | null
  aliases: string | null
  tags: Tag[]
}

export interface CreateCompany extends UpdateCompany {}

export interface UpdateProduct {
  name: string
  description: string | null
  aliases: string | null
  geos: Geo[]
  capabilities: Capability[]
  tags: Tag[]
}

export interface CreateProduct extends UpdateProduct {
  company: { id: number; name: string }
}

export interface UpdateContact {
  company: { id: number; name: string }
  firstName: string
  middleNames: string | null
  lastName: string
  jobTitle: string | null
  email: string
  geos: Geo[]
}

export interface UpdateQuestionPack {
  name: string
  displayName: string
  description: string | null
  questionPackType: QuestionPackType
  isRfiTypeCompany: boolean
  isRfiTypeProduct: boolean
  isMustHave: boolean
  isDefaultSelected: boolean
  geo: Geo
  geos: Geo[]
  capabilities: Capability[]
  tags: Tag[]
}

export interface UpdateQuestion {
  id: number
  type:
    | 'BOOLEAN'
    | 'DATE'
    | 'FILE'
    | 'MULTI_LINE_TEXT'
    | 'MULTI_SELECT'
    | 'NUMBER'
    | 'SINGLE_LINE_TEXT'
    | 'SINGLE_SELECT'
  phrase: string
  description: string | null
  dimensionsExpr: string
  labelsExpr: string | null
  geos: Geo[]
  capabilities: Capability[]
  tags: Tag[]
  constraintIsVisibleExpr: string
  constraintIsMandatoryExpr: string
  isRfiTypeCompany: boolean
  isRfiTypeProduct: boolean
  isMustHave: boolean
  isDefaultSelected: boolean
}

export interface ReducedUpdateQuestion {
  phrase: string
  description: string | null
  geos: { id: number }[]
  capabilities: { id: number }[]
  tags: { id: number }[]
  constraintIsVisibleExpr: string
  constraintIsMandatoryExpr: string
  isRfiTypeCompany: boolean
  isRfiTypeProduct: boolean
  isMustHave: boolean
  isDefaultSelected: boolean
}

export interface CreateContact extends UpdateContact {}

export interface WorkspaceJoinPayload {
  displayName: string
}

export interface PaginationParams {
  page: number
  size?: number
  sort?: string
}

export interface ListDataMeta {
  page: number
  totalPages: number
  totalSize: number
  sort: string
}

export interface ListData<T> {
  content: T[]
  meta?: ListDataMeta
}

const urlParams = (paginationParams: PaginationParams) => {
  const urlParams = new URLSearchParams()
  urlParams.append('page', paginationParams.page.toString())
  if (paginationParams.size && paginationParams.size > 0) {
    urlParams.append('size', paginationParams.size.toString())
  }
  if (paginationParams.sort && paginationParams.sort.trim() !== '') {
    urlParams.append('sort', paginationParams.sort.trim())
  }
  return urlParams.toString()
}

export const rtkQueryErrorLogger: Middleware = (api: MiddlewareAPI) => (next) => (action) => {
  if (isRejectedWithValue(action)) {
    api.dispatch(
      setToastMessage({
        severity: 'error',
        summary: 'API Error',
        detail: action.payload.data.error || 'Unknown error',
      }),
    )
  }
  return next(action)
}

const optimisticUpdateDeleteFavorites = {
  onQueryStarted: async (
    { workspaceId }: { workspaceId: number },
    { dispatch, queryFulfilled }: any,
  ) => {
    const patchResult = dispatch(
      api.util.updateQueryData('favoriteList', { workspaceId }, (draft) => {
        while (draft.length) draft.pop()
      }),
    )
    try {
      await queryFulfilled
    } catch {
      patchResult.undo()
    }
  },
}

export const api = createApi({
  baseQuery: fetchBaseQuery({
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).app.token
      headers.set('Authorization', `Bearer ${token}`)
      return headers
    },
  }),

  tagTypes: [
    'Capability',
    'Company',
    'Contact',
    'Favorite',
    'Geo',
    'Participant',
    'Permission',
    'Product',
    'Question',
    'QuestionPack',
    'QuestionPackType',
    'Rfi',
    'Tag',
    'Workspace',
    'Version',
  ],

  endpoints: (build) => ({
    // Answer/QuestionPack -> retrieves QuestionPacks with the answered questions array
    answerListByParticipant: build.query<
      QuestionPack[],
      { workspaceId: number; participantId: number }
    >({
      query: (queryArg) => ({
        url: `${process.env.VENDOR_EXPLORER_SERVICE_URL}/${queryArg.workspaceId}/participant/${queryArg.participantId}/answer`,
      }),
      transformResponse: (data: any) => data.content,
      providesTags: (result, error, queryArg) => [
        { type: 'QuestionPack', id: `ANSWER_LIST_BY_PARTICIPANT_${queryArg.participantId}` },
      ],
    }),
    // Capability
    capabilityList: build.query<Capability[], { workspaceId: number }>({
      query: (queryArg) => ({
        url: `${process.env.VENDOR_EXPLORER_SERVICE_URL}/${queryArg.workspaceId}/capability`,
      }),
      transformResponse: (data: any) => data.content,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: 'Capability' as const, id })),
              { type: 'Capability', id: 'LIST' },
            ]
          : [{ type: 'Capability', id: 'LIST' }],
    }),

    // Company
    companyList: build.query<ListData<Company>, { workspaceId: number }>({
      query: (queryArg) =>
        `${process.env.VENDOR_EXPLORER_SERVICE_URL}/${queryArg.workspaceId}/company`,
      providesTags: (result) =>
        result
          ? [
              ...result.content.map(({ id }) => ({ type: 'Company' as const, id })),
              { type: 'Company', id: 'LIST' },
            ]
          : [{ type: 'Company', id: 'LIST' }],
    }),
    companyListWithPagination: build.query<
      ListData<Company>,
      { workspaceId: number; paginationParams: PaginationParams }
    >({
      query: (queryArg) =>
        `${process.env.VENDOR_EXPLORER_SERVICE_URL}/${queryArg.workspaceId}/company?${urlParams(
          queryArg.paginationParams,
        )}`,
      providesTags: (result) =>
        result
          ? [
              ...result.content.map(({ id }) => ({ type: 'Company' as const, id })),
              { type: 'Company', id: 'LIST' },
            ]
          : [{ type: 'Company', id: 'LIST' }],
    }),
    companyListBySearchByStr: build.query<
      ListData<Company>,
      { workspaceId: number; words: string }
    >({
      query: (queryArg) =>
        `${process.env.VENDOR_EXPLORER_SERVICE_URL}/${
          queryArg.workspaceId
        }/search/company?str=${encodeURIComponent(queryArg.words)}`,
      providesTags: (result, error, queryArg) =>
        result
          ? [
              ...result.content.map(({ id }) => ({ type: 'Company' as const, id })),
              { type: 'Company', id: 'LIST' },
            ]
          : [{ type: 'Company', id: 'LIST' }],
    }),
    companyExistsByName: build.query<boolean, { workspaceId: number; name: string }>({
      query: (queryArg) => ({
        url: `${process.env.VENDOR_EXPLORER_SERVICE_URL}/${
          queryArg.workspaceId
        }/company/exists?name=${encodeURIComponent(queryArg.name)}`,
      }),
      transformResponse: (data: any) => data.content,
    }),
    company: build.query<Company, { workspaceId: number; companyId: string | number }>({
      query: (queryArg) => ({
        url: `${process.env.VENDOR_EXPLORER_SERVICE_URL}/${queryArg.workspaceId}/company/${queryArg.companyId}`,
      }),
      transformResponse: (data: any) => data.content,
      providesTags: (result) => [{ type: 'Company', id: result?.id }],
    }),
    companyDelete: build.mutation<Company, { workspaceId: number; itemId: number }>({
      query: (queryArg) => ({
        url: `${process.env.VENDOR_EXPLORER_SERVICE_URL}/${queryArg.workspaceId}/company/${queryArg.itemId}`,
        method: 'DELETE',
      }),
      transformResponse: (data: any) => data.content,
      invalidatesTags: () => ['Company', 'Product', 'Rfi', 'Participant', 'Contact', 'Favorite'],
      ...optimisticUpdateDeleteFavorites,
    }),
    companyUpdate: build.mutation<
      Company,
      { workspaceId: number; companyId: number; updateCompany: UpdateCompany }
    >({
      query: (queryArg) => ({
        url: `${process.env.VENDOR_EXPLORER_SERVICE_URL}/${queryArg.workspaceId}/company/${queryArg.companyId}`,
        method: 'PUT',
        body: queryArg.updateCompany,
      }),
      transformResponse: (data: any) => data.content,
      invalidatesTags: () => ['Company', 'Product', 'Rfi', 'Participant', 'Contact', 'Favorite'],
    }),
    companyCreate: build.mutation<Company, { workspaceId: number; createCompany: CreateCompany }>({
      query: (queryArg) => ({
        url: `${process.env.VENDOR_EXPLORER_SERVICE_URL}/${queryArg.workspaceId}/company/`,
        method: 'POST',
        body: queryArg.createCompany,
      }),
      transformResponse: (data: any) => data.content,
      invalidatesTags: () => ['Company', 'Product', 'Rfi', 'Participant', 'Contact', 'Favorite'],
    }),

    // Contacts
    contactListByCompany: build.query<
      Contact[],
      { workspaceId: number; companyId: string | number }
    >({
      query: (queryArg) => ({
        url: `${process.env.VENDOR_EXPLORER_SERVICE_URL}/${queryArg.workspaceId}/company/${queryArg.companyId}/contact`,
      }),
      transformResponse: (data: any) => data.content,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: 'Contact' as const, id })),
              { type: 'Contact', id: 'LIST_BY_COMPANY' },
            ]
          : [{ type: 'Contact', id: 'LIST_BY_COMPANY' }],
    }),
    contactExistsByEmail: build.query<boolean, { workspaceId: number; email: string }>({
      query: (queryArg) => ({
        url: `${process.env.VENDOR_EXPLORER_SERVICE_URL}/${
          queryArg.workspaceId
        }/contact/exists?email=${encodeURIComponent(queryArg.email)}`,
      }),
      transformResponse: (data: any) => data.content,
    }),
    contactDelete: build.mutation<Contact, { workspaceId: number; itemId: number }>({
      query: (queryArg) => ({
        url: `${process.env.VENDOR_EXPLORER_SERVICE_URL}/${queryArg.workspaceId}/contact/${queryArg.itemId}`,
        method: 'DELETE',
      }),
      transformResponse: (data: any) => data.content,
      invalidatesTags: () => ['Contact'],
    }),
    contactUpdate: build.mutation<
      Contact,
      { workspaceId: number; contactId: number; updateContact: UpdateContact }
    >({
      query: (queryArg) => ({
        url: `${process.env.VENDOR_EXPLORER_SERVICE_URL}/${queryArg.workspaceId}/contact/${queryArg.contactId}`,
        method: 'PUT',
        body: queryArg.updateContact,
      }),
      transformResponse: (data: any) => data.content,
      invalidatesTags: () => ['Contact'],
    }),
    contactCreate: build.mutation<Contact, { workspaceId: number; createContact: CreateContact }>({
      query: (queryArg) => ({
        url: `${process.env.VENDOR_EXPLORER_SERVICE_URL}/${queryArg.workspaceId}/contact/`,
        method: 'POST',
        body: queryArg.createContact,
      }),
      transformResponse: (data: any) => data.content,
      invalidatesTags: () => ['Contact', { type: 'Contact', id: 'LIST_BY_COMPANY' }],
    }),
    contact: build.query<Contact, { workspaceId: number; contactId: string | number }>({
      query: (queryArg) => ({
        url: `${process.env.VENDOR_EXPLORER_SERVICE_URL}/${queryArg.workspaceId}/contact/${queryArg.contactId}`,
      }),
      transformResponse: (data: any) => data.content,
      providesTags: (result) => [{ type: 'Contact', id: result?.id }],
    }),

    // Favorite
    favoriteList: build.query<Favorite[], { workspaceId: number }>({
      query: (queryArg) => ({
        url: `${process.env.VENDOR_EXPLORER_SERVICE_URL}/${queryArg.workspaceId}/favorite`,
      }),
      transformResponse: (data: any) => data.content,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: 'Favorite' as const, id })),
              { type: 'Favorite', id: 'LIST' },
            ]
          : [{ type: 'Favorite', id: 'LIST' }],
    }),
    favoriteToggle: build.mutation<Favorite, ToggleFavorite>({
      query: (queryArg) => ({
        url: `${process.env.VENDOR_EXPLORER_SERVICE_URL}/${queryArg.workspaceId}/favorite`,
        method: 'PUT',
        body: queryArg.favorite,
      }),

      // type Favorite = {
      //   id: number
      //   createdAt: Date
      //   type: 'COMPANY' | 'PRODUCT' | 'RFI' | 'PARTICIPANT'
      //   _id: number
      // }

      // interface ToggleFavorite {
      //   favorite: Omit<Favorite, 'id' | 'createdAt'>
      //   workspaceId: number
      // }
      transformResponse: (data: any) => data.content,
      invalidatesTags: [{ type: 'Favorite', id: 'LIST' }],
    }),

    // Geo
    geoList: build.query<Geo[], { workspaceId: number }>({
      query: (queryArg) => ({
        url: `${process.env.VENDOR_EXPLORER_SERVICE_URL}/${queryArg.workspaceId}/geo`,
      }),
      transformResponse: (data: any) => data.content,
      providesTags: (result) =>
        result
          ? [...result.map(({ id }) => ({ type: 'Geo' as const, id })), { type: 'Geo', id: 'LIST' }]
          : [{ type: 'Geo', id: 'LIST' }],
    }),

    // Permission
    permissionList: build.query<Permission[], { workspaceId: number }>({
      query: (queryArg) => ({
        url: `${process.env.RAM_SERVICE_URL}/${queryArg.workspaceId}/permission`,
      }),
      transformResponse: (data: any) => data.content,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: 'Permission' as const, id })),
              { type: 'Permission', id: 'LIST' },
            ]
          : [{ type: 'Permission', id: 'LIST' }],
    }),
    permissionListByAppRef: build.query<Permission[], { workspaceId: number; appRef: string }>({
      query: (queryArg) => ({
        url: `${process.env.RAM_SERVICE_URL}/${queryArg.workspaceId}/permission/${queryArg.appRef}`,
      }),
      transformResponse: (data: any) => data.content,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: 'Permission' as const, id })),
              { type: 'Permission', id: 'LIST' },
            ]
          : [{ type: 'Permission', id: 'LIST' }],
    }),

    // Product
    productList: build.query<ListData<Product>, { workspaceId: number }>({
      query: (queryArg) =>
        `${process.env.VENDOR_EXPLORER_SERVICE_URL}/${queryArg.workspaceId}/product`,
      providesTags: (result) =>
        result
          ? [
              ...result.content.map(({ id }) => ({ type: 'Product' as const, id })),
              { type: 'Product', id: 'LIST' },
            ]
          : [{ type: 'Product', id: 'LIST' }],
    }),
    productListByCompany: build.query<
      ListData<Product>,
      { workspaceId: number; companyId: string | number }
    >({
      query: (queryArg) =>
        `${process.env.VENDOR_EXPLORER_SERVICE_URL}/${queryArg.workspaceId}/company/${queryArg.companyId}/product`,
      providesTags: (result, error, queryArg) =>
        result
          ? [
              ...result.content.map(({ id }) => ({ type: 'Product' as const, id })),
              { type: 'Product', id: 'LIST' },
            ]
          : [{ type: 'Product', id: 'LIST' }],
    }),
    productListWithPagination: build.query<
      ListData<Product>,
      { workspaceId: number; paginationParams: PaginationParams }
    >({
      query: (queryArg) =>
        `${process.env.VENDOR_EXPLORER_SERVICE_URL}/${queryArg.workspaceId}/product?${urlParams(
          queryArg.paginationParams,
        )}`,
      providesTags: (result) =>
        result
          ? [
              ...result.content.map(({ id }) => ({ type: 'Product' as const, id })),
              { type: 'Product', id: 'LIST' },
            ]
          : [{ type: 'Product', id: 'LIST' }],
    }),
    productListBySearchByStr: build.query<
      ListData<Product>,
      { workspaceId: number; words: string }
    >({
      query: (queryArg) =>
        `${process.env.VENDOR_EXPLORER_SERVICE_URL}/${
          queryArg.workspaceId
        }/search/product?str=${encodeURIComponent(queryArg.words)}`,
      providesTags: (result, error, queryArg) =>
        result
          ? [
              ...result.content.map(({ id }) => ({ type: 'Product' as const, id })),
              { type: 'Product', id: 'LIST' },
            ]
          : [{ type: 'Product', id: 'LIST' }],
    }),
    productExistsByCompany: build.query<
      boolean,
      { workspaceId: number; companyId: number; name: string }
    >({
      query: (queryArg) => ({
        url: `${process.env.VENDOR_EXPLORER_SERVICE_URL}/${queryArg.workspaceId}/company/${
          queryArg.companyId
        }/product/exists?name=${encodeURIComponent(queryArg.name)}`,
      }),
      transformResponse: (data: any) => data.content,
    }),
    product: build.query<Product, { workspaceId: number; productId: string | number }>({
      query: (queryArg) => ({
        url: `${process.env.VENDOR_EXPLORER_SERVICE_URL}/${queryArg.workspaceId}/product/${queryArg.productId}`,
      }),
      transformResponse: (data: any) => data.content,
      providesTags: (result) => [{ type: 'Product', id: result?.id }],
    }),
    productDelete: build.mutation<Product, { workspaceId: number; itemId: number }>({
      query: (queryArg) => ({
        url: `${process.env.VENDOR_EXPLORER_SERVICE_URL}/${queryArg.workspaceId}/product/${queryArg.itemId}`,
        method: 'DELETE',
      }),
      transformResponse: (data: any) => data.content,
      invalidatesTags: () => ['Company', 'Product', 'Rfi', 'Participant', 'Contact', 'Favorite'],
      ...optimisticUpdateDeleteFavorites,
    }),
    productUpdate: build.mutation<
      Product,
      { workspaceId: number; productId: number; updateProduct: UpdateProduct }
    >({
      query: (queryArg) => ({
        url: `${process.env.VENDOR_EXPLORER_SERVICE_URL}/${queryArg.workspaceId}/product/${queryArg.productId}`,
        method: 'PUT',
        body: queryArg.updateProduct,
      }),
      transformResponse: (data: any) => data.content,
      invalidatesTags: () => ['Company', 'Product', 'Rfi', 'Participant', 'Contact', 'Favorite'],
    }),
    productCreate: build.mutation<Product, { workspaceId: number; createProduct: CreateProduct }>({
      query: (queryArg) => ({
        url: `${process.env.VENDOR_EXPLORER_SERVICE_URL}/${queryArg.workspaceId}/product`,
        method: 'POST',
        body: queryArg.createProduct,
      }),
      transformResponse: (data: any) => data.content,
      invalidatesTags: () => ['Company', 'Product', 'Rfi', 'Participant', 'Contact', 'Favorite'],
    }),

    // Question
    question: build.query<Question, { workspaceId: number; questionId: number }>({
      query: (queryArg) => ({
        url: `${process.env.VENDOR_EXPLORER_SERVICE_URL}/${queryArg.workspaceId}/question/${queryArg.questionId}`,
      }),
      transformResponse: (data: any) => data.content,
      providesTags: (result) => [{ type: 'Question', id: result?.id }],
    }),

    questionListByQuestionPack: build.query<
      Question[],
      { workspaceId: number; questionPackId: number }
    >({
      query: (queryArg) => ({
        url: `${process.env.VENDOR_EXPLORER_SERVICE_URL}/${queryArg.workspaceId}/questionPack/${queryArg.questionPackId}/question`,
      }),
      transformResponse: (data: any) => data.content,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: 'Question' as const, id })),
              { type: 'Question', id: 'LIST_QUESTIONPACK' },
            ]
          : [{ type: 'Question', id: 'LIST_QUESTIONPACK' }],
    }),

    // Question/QuestionPack -> retrieves QuestionPacks with the questions array filled! (compare: questionPackListByRfi -> no questions in the response!!!)
    questionListByParticipant: build.query<
      QuestionPack[],
      { workspaceId: number; participantId: number }
    >({
      query: (queryArg) => ({
        url: `${process.env.VENDOR_EXPLORER_SERVICE_URL}/${queryArg.workspaceId}/participant/${queryArg.participantId}/question`,
      }),
      transformResponse: (data: any) => data.content,
      providesTags: (result, error, queryArg) => [
        { type: 'QuestionPack', id: `QUESTION_LIST_BY_PARTICIPANT_${queryArg.participantId}` },
      ],
    }),
    questionListByRfi: build.query<QuestionPack[], { workspaceId: number; rfiId: number }>({
      query: (queryArg) => ({
        url: `${process.env.VENDOR_EXPLORER_SERVICE_URL}/${queryArg.workspaceId}/rfi/${queryArg.rfiId}/question`,
      }),
      transformResponse: (data: any) => data.content,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: 'QuestionPack' as const, id })),
              { type: 'QuestionPack', id: 'LIST_QUESTIONS' },
            ]
          : [{ type: 'QuestionPack', id: 'LIST_RFI_WITH_QUESTIONS' }],
    }),
    questionListForRfi: build.query<
      QuestionPack[],
      {
        workspaceId: number
        rfiType: keyof typeof RfiType
        geoId: number
        capabilityIds: number[]
        tagIds: number[]
      }
    >({
      query: (queryArg) => ({
        url: `${process.env.VENDOR_EXPLORER_SERVICE_URL}/${
          queryArg.workspaceId
        }/question/rfi/?type=${queryArg.rfiType}&geoId=${
          queryArg.geoId
        }&capabilityIds=${queryArg.capabilityIds.join(',')}&tagIds=${queryArg.tagIds.join(',')}`,
      }),
      transformResponse: (data: any) => data.content,
      providesTags: (result, error, queryArg) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: 'QuestionPack' as const, id })),
              {
                type: 'QuestionPack',
                id: `LIST_FOR_RFI_WITH_QUESTIONS_${queryArg.geoId}_${queryArg.capabilityIds.join(
                  ',',
                )}_${queryArg.tagIds.join(',')}`,
              },
            ]
          : [
              {
                type: 'QuestionPack',
                id: `LIST_FOR_RFI_WITH_QUESTIONS_${queryArg.geoId}_${queryArg.capabilityIds.join(
                  ',',
                )}_${queryArg.tagIds.join(',')}`,
              },
            ],
    }),
    questionUpdate: build.mutation<
      Question,
      { workspaceId: number; questionId: number; data: ReducedUpdateQuestion }
    >({
      query: (queryArg) => ({
        url: `${process.env.VENDOR_EXPLORER_SERVICE_URL}/${queryArg.workspaceId}/question/${queryArg.questionId}`,
        method: 'PUT',
        body: queryArg.data,
      }),
      transformResponse: (data: any) => data.content,
      invalidatesTags: () => ['Question', 'QuestionPack'],
    }),

    // QuestionPack
    questionPackList: build.query<ListData<QuestionPack>, { workspaceId: number }>({
      query: (queryArg) =>
        `${process.env.VENDOR_EXPLORER_SERVICE_URL}/${queryArg.workspaceId}/questionPack`,
      providesTags: (result) =>
        result
          ? [
              ...result.content.map(({ id }) => ({ type: 'QuestionPack' as const, id })),
              { type: 'QuestionPack', id: 'LIST' },
            ]
          : [{ type: 'QuestionPack', id: 'LIST' }],
    }),
    questionPackListByRfi: build.query<
      ListData<QuestionPack>,
      { workspaceId: number; rfiId: number }
    >({
      query: (queryArg) =>
        `${process.env.VENDOR_EXPLORER_SERVICE_URL}/${queryArg.workspaceId}/rfi/${queryArg.rfiId}/questionPack`,
      providesTags: (result) =>
        result
          ? [
              ...result.content.map(({ id }) => ({ type: 'QuestionPack' as const, id })),
              { type: 'QuestionPack', id: 'LIST' },
            ]
          : [{ type: 'QuestionPack', id: 'LIST' }],
    }),
    questionPackListWithPagination: build.query<
      ListData<QuestionPack>,
      { workspaceId: number; paginationParams: PaginationParams }
    >({
      query: (queryArg) =>
        `${process.env.VENDOR_EXPLORER_SERVICE_URL}/${
          queryArg.workspaceId
        }/questionPack?${urlParams(queryArg.paginationParams)}`,
      providesTags: (result) =>
        result
          ? [
              ...result.content.map(({ id }) => ({ type: 'QuestionPack' as const, id })),
              { type: 'QuestionPack', id: 'LIST' },
            ]
          : [{ type: 'QuestionPack', id: 'LIST' }],
    }),
    questionPackListBySearchByStr: build.query<
      ListData<QuestionPack>,
      { workspaceId: number; words: string }
    >({
      query: (queryArg) => ({
        url: `${process.env.VENDOR_EXPLORER_SERVICE_URL}/${queryArg.workspaceId}/search/questionPack?str=${queryArg.words}`,
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.content.map(({ id }) => ({ type: 'QuestionPack' as const, id })),
              { type: 'QuestionPack', id: 'LIST' },
            ]
          : [{ type: 'QuestionPack', id: 'LIST' }],
    }),
    questionPack: build.query<QuestionPack, { workspaceId: number; itemId: string | number }>({
      query: (queryArg) => ({
        url: `${process.env.VENDOR_EXPLORER_SERVICE_URL}/${queryArg.workspaceId}/questionPack/${queryArg.itemId}`,
      }),
      transformResponse: (data: any) => data.content,
      providesTags: (result) => [{ type: 'QuestionPack', id: result?.id }],
    }),
    questionPackDelete: build.mutation<QuestionPack, { workspaceId: number; itemId: number }>({
      query: (queryArg) => ({
        url: `${process.env.VENDOR_EXPLORER_SERVICE_URL}/${queryArg.workspaceId}/questionPack/${queryArg.itemId}`,
        method: 'DELETE',
      }),
      transformResponse: (data: any) => data.content,
      invalidatesTags: () => ['QuestionPack'],
    }),
    questionPackUpdate: build.mutation<
      QuestionPack,
      { workspaceId: number; questionPackId: number; updateQuestionPack: UpdateQuestionPack }
    >({
      query: (queryArg) => ({
        url: `${process.env.VENDOR_EXPLORER_SERVICE_URL}/${queryArg.workspaceId}/questionPack/${queryArg.questionPackId}`,
        method: 'PUT',
        body: queryArg.updateQuestionPack,
      }),
      transformResponse: (data: any) => data.content,
      invalidatesTags: () => ['QuestionPack'],
    }),

    // QuestionPackType
    questionPackTypeList: build.query<QuestionPackType[], { workspaceId: number }>({
      query: (queryArg) => ({
        url: `${process.env.VENDOR_EXPLORER_SERVICE_URL}/${queryArg.workspaceId}/questionPackType`,
      }),
      transformResponse: (data: any) => data.content,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: 'QuestionPackType' as const, id })),
              { type: 'QuestionPackType', id: 'LIST' },
            ]
          : [{ type: 'QuestionPackType', id: 'LIST' }],
    }),

    // Rfi
    rfiList: build.query<ListData<Rfi>, { workspaceId: number }>({
      query: (queryArg) => `${process.env.VENDOR_EXPLORER_SERVICE_URL}/${queryArg.workspaceId}/rfi`,
      providesTags: (result) =>
        result
          ? [
              ...result.content.map(({ id }) => ({ type: 'Rfi' as const, id })),
              { type: 'Rfi', id: 'LIST' },
            ]
          : [{ type: 'Rfi', id: 'LIST' }],
    }),
    rfiListByCompany: build.query<ListData<Rfi>, { workspaceId: number; companyId: number }>({
      query: (queryArg) =>
        `${process.env.VENDOR_EXPLORER_SERVICE_URL}/${queryArg.workspaceId}/company/${queryArg.companyId}/rfi`,
      providesTags: (result) =>
        result
          ? [
              ...result.content.map(({ id }) => ({ type: 'Rfi' as const, id })),
              { type: 'Rfi', id: 'LIST' },
            ]
          : [{ type: 'Rfi', id: 'LIST' }],
    }),
    rfiListWithPagination: build.query<
      ListData<Rfi>,
      { workspaceId: number; paginationParams: PaginationParams }
    >({
      query: (queryArg) =>
        `${process.env.VENDOR_EXPLORER_SERVICE_URL}/${queryArg.workspaceId}/rfi?${urlParams(
          queryArg.paginationParams,
        )}`,
      providesTags: (result) =>
        result
          ? [
              ...result.content.map(({ id }) => ({ type: 'Rfi' as const, id })),
              { type: 'Rfi', id: 'LIST' },
            ]
          : [{ type: 'Rfi', id: 'LIST' }],
    }),
    rfiListBySearchByStr: build.query<ListData<Rfi>, { workspaceId: number; words: string }>({
      query: (queryArg) =>
        `${process.env.VENDOR_EXPLORER_SERVICE_URL}/${
          queryArg.workspaceId
        }/search/rfi?str=${encodeURIComponent(queryArg.words)}`,
      providesTags: (result, error, queryArg) =>
        result
          ? [
              ...result.content.map(({ id }) => ({ type: 'Rfi' as const, id })),
              { type: 'Rfi', id: 'LIST' },
            ]
          : [{ type: 'Rfi', id: 'LIST' }],
    }),
    rfi: build.query<Rfi, { workspaceId: number; itemId: string | number }>({
      query: (queryArg) => ({
        url: `${process.env.VENDOR_EXPLORER_SERVICE_URL}/${queryArg.workspaceId}/rfi/${queryArg.itemId}`,
      }),
      transformResponse: (data: any) => data.content,
      providesTags: (result) => [{ type: 'Rfi', id: result?.id }],
    }),
    rfiDelete: build.mutation<Rfi, { workspaceId: number; itemId: number }>({
      query: (queryArg) => ({
        url: `${process.env.VENDOR_EXPLORER_SERVICE_URL}/${queryArg.workspaceId}/rfi/${queryArg.itemId}`,
        method: 'DELETE',
      }),
      transformResponse: (data: any) => data.content,
      invalidatesTags: () => ['Company', 'Product', 'Rfi', 'Participant', 'Contact', 'Favorite'],
      ...optimisticUpdateDeleteFavorites,
    }),
    rfiExtend: build.mutation<
      Rfi,
      { workspaceId: number; itemId: number; updateItem: UpdateExtend }
    >({
      query: (queryArg) => ({
        url: `${process.env.VENDOR_EXPLORER_SERVICE_URL}/${queryArg.workspaceId}/rfi/${queryArg.itemId}/extend`,
        method: 'PUT',
        body: queryArg.updateItem,
      }),
      transformResponse: (data: any) => data.content,
      invalidatesTags: () => ['Company', 'Product', 'Rfi', 'Participant', 'Contact', 'Favorite'],
    }),
    rfiWithdraw: build.mutation<Rfi, { workspaceId: number; itemId: number }>({
      query: (queryArg) => ({
        url: `${process.env.VENDOR_EXPLORER_SERVICE_URL}/${queryArg.workspaceId}/rfi/${queryArg.itemId}/withdraw`,
        method: 'PUT',
      }),
      transformResponse: (data: any) => data.content,
      invalidatesTags: () => ['Company', 'Product', 'Rfi', 'Participant', 'Contact', 'Favorite'],
    }),
    rfiUpdate: build.mutation<Rfi, { workspaceId: number; rfiId: number; updateRfi: UpdateRfi }>({
      query: (queryArg) => ({
        url: `${process.env.VENDOR_EXPLORER_SERVICE_URL}/${queryArg.workspaceId}/rfi/${queryArg.rfiId}`,
        method: 'PUT',
        body: queryArg.updateRfi,
      }),
      transformResponse: (data: any) => data.content,
      invalidatesTags: () => ['Company', 'Product', 'Rfi', 'Participant', 'Contact', 'Favorite'],
    }),
    rfiCreate: build.mutation<Rfi, { workspaceId: number; data: ReducedCreateRfi }>({
      query: (queryArg) => ({
        url: `${process.env.VENDOR_EXPLORER_SERVICE_URL}/${queryArg.workspaceId}/rfi`,
        method: 'POST',
        body: queryArg.data,
      }),
      transformResponse: (data: any) => data.content,
      invalidatesTags: () => ['Company', 'Product', 'Rfi', 'Participant', 'Contact', 'Favorite'],
    }),
    rfiExistsByGeo: build.query<boolean, { workspaceId: number; geoId: number; name: string }>({
      query: (queryArg) => ({
        url: `${process.env.VENDOR_EXPLORER_SERVICE_URL}/${queryArg.workspaceId}/geo/${
          queryArg.geoId
        }/rfi/exists?name=${encodeURIComponent(queryArg.name)}`,
      }),
      transformResponse: (data: any) => data.content,
    }),

    // Participant
    participantDelete: build.mutation<Participant, { workspaceId: number; itemId: number }>({
      query: (queryArg) => ({
        url: `${process.env.VENDOR_EXPLORER_SERVICE_URL}/${queryArg.workspaceId}/participant/${queryArg.itemId}`,
        method: 'DELETE',
      }),
      transformResponse: (data: any) => data.content,
      invalidatesTags: () => ['Company', 'Product', 'Rfi', 'Participant', 'Contact', 'Favorite'],
    }),
    participantListByRfi: build.query<Participant[], { workspaceId: number; rfiId: number }>({
      query: (queryArg) => ({
        url: `${process.env.VENDOR_EXPLORER_SERVICE_URL}/${queryArg.workspaceId}/rfi/${queryArg.rfiId}/participant`,
      }),
      transformResponse: (data: any) => data.content,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: 'Participant' as const, id })),
              { type: 'Participant', id: 'LIST_BY_RFI' },
            ]
          : [{ type: 'Participant', id: 'LIST_BY_RFI' }],
    }),
    participantListByCompany: build.query<
      Participant[],
      { workspaceId: number; companyId: number }
    >({
      query: (queryArg) => ({
        url: `${process.env.VENDOR_EXPLORER_SERVICE_URL}/${queryArg.workspaceId}/company/${queryArg.companyId}/participant`,
      }),
      transformResponse: (data: any) => data.content,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: 'Participant' as const, id })),
              { type: 'Participant', id: 'LIST_BY_COMPANY' },
            ]
          : [{ type: 'Participant', id: 'LIST_BY_COMPANY' }],
    }),
    participantListByCompanyAndRfiType: build.query<
      Participant[],
      { workspaceId: number; companyId: number; rfiType: keyof typeof RfiType }
    >({
      query: (queryArg) => ({
        url: `${process.env.VENDOR_EXPLORER_SERVICE_URL}/${queryArg.workspaceId}/company/${queryArg.companyId}/participant?rfiType=${queryArg.rfiType}`,
      }),
      transformResponse: (data: any) => data.content,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: 'Participant' as const, id })),
              { type: 'Participant', id: 'LIST_BY_COMPANY' },
            ]
          : [{ type: 'Participant', id: 'LIST_BY_COMPANY' }],
    }),
    participantListByProduct: build.query<
      Participant[],
      { workspaceId: number; productId: number }
    >({
      query: (queryArg) => ({
        url: `${process.env.VENDOR_EXPLORER_SERVICE_URL}/${queryArg.workspaceId}/product/${queryArg.productId}/participant`,
      }),
      transformResponse: (data: any) => data.content,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: 'Participant' as const, id })),
              { type: 'Participant', id: 'LIST_BY_PRODUCT' },
            ]
          : [{ type: 'Participant', id: 'LIST_BY_PRODUCT' }],
    }),
    participantExtend: build.mutation<
      Participant,
      { workspaceId: number; itemId: number; updateItem: UpdateExtend }
    >({
      query: (queryArg) => ({
        url: `${process.env.VENDOR_EXPLORER_SERVICE_URL}/${queryArg.workspaceId}/participant/${queryArg.itemId}/extend`,
        method: 'PUT',
        body: queryArg.updateItem,
      }),
      transformResponse: (data: any) => data.content,
      invalidatesTags: () => ['Company', 'Product', 'Rfi', 'Participant', 'Contact', 'Favorite'],
    }),
    participantWithdraw: build.mutation<Participant, { workspaceId: number; itemId: number }>({
      query: (queryArg) => ({
        url: `${process.env.VENDOR_EXPLORER_SERVICE_URL}/${queryArg.workspaceId}/participant/${queryArg.itemId}/withdraw`,
        method: 'PUT',
      }),
      transformResponse: (data: any) => data.content,
      invalidatesTags: () => ['Company', 'Product', 'Rfi', 'Participant', 'Contact', 'Favorite'],
    }),
    participantArchive: build.mutation<Participant, { workspaceId: number; itemId: number }>({
      query: (queryArg) => ({
        url: `${process.env.VENDOR_EXPLORER_SERVICE_URL}/${queryArg.workspaceId}/participant/${queryArg.itemId}/archive`,
        method: 'PUT',
      }),
      transformResponse: (data: any) => data.content,
      invalidatesTags: () => ['Company', 'Product', 'Rfi', 'Participant', 'Contact', 'Favorite'],
    }),
    participant: build.query<Participant, { workspaceId: number; itemId: string | number }>({
      query: (queryArg) => ({
        url: `${process.env.VENDOR_EXPLORER_SERVICE_URL}/${queryArg.workspaceId}/participant/${queryArg.itemId}`,
      }),
      transformResponse: (data: any) => data.content,
      providesTags: (result) => [{ type: 'Participant', id: result?.id }],
    }),
    participantCanSend: build.mutation<
      MailToParticipant,
      { workspaceId: number; itemId: number; contactData: UpdateParticipantCanSend }
    >({
      query: (queryArg) => ({
        url: `${process.env.VENDOR_EXPLORER_SERVICE_URL}/${queryArg.workspaceId}/participant/${queryArg.itemId}/send`,
        method: 'PUT',
        body: queryArg.contactData,
      }),
      transformResponse: (data: any) => data.content,
      invalidatesTags: () => ['Rfi', 'Participant'],
    }),
    participantsCreate: build.mutation<Rfi[], { workspaceId: number; data: CreateParticipant[] }>({
      query: (queryArg) => ({
        url: `${process.env.VENDOR_EXPLORER_SERVICE_URL}/${queryArg.workspaceId}/participants`,
        method: 'POST',
        body: queryArg.data,
      }),
      transformResponse: (data: any) => data.content,
      invalidatesTags: () => ['Rfi', 'Participant'],
    }),
    // Tag
    tagList: build.query<Tag[], { workspaceId: number }>({
      query: (queryArg) => ({
        url: `${process.env.VENDOR_EXPLORER_SERVICE_URL}/${queryArg.workspaceId}/tag`,
      }),
      transformResponse: (data: any) => data.content,
      providesTags: (result) =>
        result
          ? [...result.map(({ id }) => ({ type: 'Tag' as const, id })), { type: 'Tag', id: 'LIST' }]
          : [{ type: 'Tag', id: 'LIST' }],
    }),

    // RAM Workspace
    workspaceJoin: build.query<Member, { payload: WorkspaceJoinPayload }>({
      query: (queryArg) => ({
        url: `${process.env.RAM_SERVICE_URL}/join?appRefs=${process.env.WORKSPACE_REF}`,
        method: 'POST',
        body: queryArg.payload,
      }),
      transformResponse: (data: any) => data.content,
      providesTags: () => [{ type: 'Workspace', id: 'JOIN' }],
    }),

    workspaceList: build.query<Workspace[], void>({
      query: () => ({ url: `${process.env.RAM_SERVICE_URL}/workspace` }),
      transformResponse: (data: any) => data.content,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: 'Workspace' as const, id })),
              { type: 'Workspace', id: 'LIST' },
            ]
          : [{ type: 'Workspace', id: 'LIST' }],
    }),

    // Options Presets
    optionsPreset: build.query<
      any[],
      { type: 'MULTI_SELECT' | 'SINGLE_SELECT'; ref: string; workspaceId: number }
    >({
      query: (queryArg) => ({
        url: `${process.env.VENDOR_EXPLORER_SERVICE_URL}/${queryArg.workspaceId}/${queryArg.type}/optionsPreset?ref=${queryArg.ref}`,
      }),
      transformResponse: (data: any) => data.content,
    }),

    // Versions
    versionVendorExplorerService: build.query({
      query: (queryArg) => ({
        url: `${process.env.VENDOR_EXPLORER_SERVICE_URL}/`,
      }),
      transformResponse: (data: any) => data.content,
      providesTags: (result) => [{ type: 'Version', id: result?.id }],
    }),
    versionRamService: build.query({
      query: (queryArg) => ({
        url: `${process.env.RAM_SERVICE_URL}/`,
      }),
      transformResponse: (data: any) => data.content,
      providesTags: (result) => [{ type: 'Version', id: result?.id }],
    }),
  }),
})

export const {
  useAnswerListByParticipantQuery,
  useCapabilityListQuery,
  useCompanyListQuery,
  useCompanyListWithPaginationQuery,
  useCompanyListBySearchByStrQuery,
  useLazyCompanyExistsByNameQuery,
  useCompanyDeleteMutation,
  useCompanyUpdateMutation,
  useCompanyCreateMutation,
  useCompanyQuery,
  useContactListByCompanyQuery,
  useContactQuery,
  useLazyContactExistsByEmailQuery,
  useContactDeleteMutation,
  useContactUpdateMutation,
  useContactCreateMutation,
  useFavoriteListQuery,
  useFavoriteToggleMutation,
  useGeoListQuery,
  usePermissionListQuery,
  usePermissionListByAppRefQuery,
  useProductListQuery,
  useProductListByCompanyQuery,
  useProductListWithPaginationQuery,
  useProductListBySearchByStrQuery,
  useProductCreateMutation,
  useLazyProductExistsByCompanyQuery,
  useProductQuery,
  useProductDeleteMutation,
  useProductUpdateMutation,
  useQuestionQuery,
  useQuestionListByQuestionPackQuery,
  useQuestionListByParticipantQuery,
  useQuestionListByRfiQuery,
  useQuestionListForRfiQuery,
  useQuestionUpdateMutation,
  useQuestionPackListQuery,
  useQuestionPackListByRfiQuery,
  useQuestionPackListWithPaginationQuery,
  useQuestionPackListBySearchByStrQuery,
  useQuestionPackQuery,
  useQuestionPackDeleteMutation,
  useQuestionPackUpdateMutation,
  useQuestionPackTypeListQuery,
  useRfiQuery,
  useRfiListQuery,
  useRfiListByCompanyQuery,
  useRfiListWithPaginationQuery,
  useRfiListBySearchByStrQuery,
  useRfiDeleteMutation,
  useRfiExtendMutation,
  useRfiWithdrawMutation,
  useRfiUpdateMutation,
  useRfiCreateMutation,
  useLazyRfiExistsByGeoQuery,
  useParticipantQuery,
  useParticipantListByRfiQuery,
  useParticipantListByCompanyQuery,
  useParticipantListByCompanyAndRfiTypeQuery,
  useParticipantListByProductQuery,
  useParticipantDeleteMutation,
  useParticipantExtendMutation,
  useParticipantWithdrawMutation,
  useParticipantArchiveMutation,
  useParticipantCanSendMutation,
  useParticipantsCreateMutation,
  useTagListQuery,
  useWorkspaceJoinQuery,
  useWorkspaceListQuery,
  useLazyOptionsPresetQuery,
  useVersionVendorExplorerServiceQuery,
  useVersionRamServiceQuery,
} = api

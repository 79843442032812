import { getDimensionLabel } from '../../common/utils'

interface Props {
  question: Question
  questionConstraint: ConstrainedQuestion
}

const styles = {
  noPaddingList: {
    paddingLeft: 0,
    listStylePosition: 'outside' as const,
    listStyleType: 'none' as const,
  },
  marginBottomLi: {
    marginBottom: '5px',
  },
}

const QuestionOutputDate = ({ question, questionConstraint }: Props): JSX.Element => {
  const truncate = question.config?.truncate || 'DAY'

  const getOrdinalSuffix = (day: number): string => {
    if (day > 3 && day < 21) return 'th'
    switch (day % 10) {
      case 1:
        return 'st'
      case 2:
        return 'nd'
      case 3:
        return 'rd'
      default:
        return 'th'
    }
  }

  // Render each dimension's date
  const renderDimensionDate = (answerData: AnswerData) => {
    if (!answerData.data || !answerData.data.value) return null

    const date = new Date(answerData.data.value)
    let outputDate = ''

    if (truncate === 'DAY') {
      const day = date.toLocaleDateString('en-US', { day: '2-digit' as const })
      const month = date.toLocaleDateString('en-US', { month: 'long' as const })
      outputDate = `${day}${getOrdinalSuffix(date.getDate())} ${month} ${date.getFullYear()}`
    } else if (truncate === 'MONTH') {
      const month = date.toLocaleDateString('en-US', { month: 'long' as const })
      outputDate = `${month} ${date.getFullYear()}`
    } else if (truncate === 'YEAR') {
      outputDate = `${date.getFullYear()}`
    }

    const dimensionLabel = getDimensionLabel(
      questionConstraint.dimensions,
      questionConstraint.labels,
      answerData.dimensionIndex,
      answerData.dimensionValue,
    )

    return (
      <li
        style={styles.marginBottomLi}
        key={`dim-${answerData.dimensionIndex}${answerData.dimensionValue}`}
      >
        <strong>{dimensionLabel}</strong>
        {outputDate}
      </li>
    )
  }

  return <ul style={styles.noPaddingList}>{question.answer?.data.map(renderDimensionDate)}</ul>
}

export default QuestionOutputDate

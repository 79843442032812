import { useRef, useState } from 'react'
import { useAppSelector, useGroupedTagList } from '../../common/hooks'
import DispatcherButton, { DispatcherButtonPresets } from '../../components/DispatcherButton'
import { InputText } from 'primereact/inputtext'
import { Steps } from 'primereact/steps'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { classNames } from 'primereact/utils'
import {
  CreateCompany,
  useCompanyCreateMutation,
  useLazyCompanyExistsByNameQuery,
} from '../../redux/api'
import { MultiSelect } from 'primereact/multiselect'
import { Toolbar } from 'primereact/toolbar'
import { useFormTagData } from '../../common/formHooks'
import { setToastMessage } from '../../redux/app'
import { AppDispatch } from '../../redux/store'
import createStyles from '../../styles/create.module.scss'
import { InputTextarea } from 'primereact/inputtextarea'

interface FormInput {
  name: string
  description: string | null
  aliases: string | null
  tags: number[]
}

interface Props {
  onContentCreated?: (company: Company) => void
}

const CompanyCreateContent = ({ onContentCreated = () => {} }: Props): JSX.Element => {
  const workspaceId = useAppSelector((state) => state.app.workspace!.id)
  const companyForm = useRef<HTMLFormElement>(null)

  const [companyData, setCompanyData] = useState<CreateCompany>({
    name: '',
    description: null,
    aliases: null,
    tags: [],
  })
  const [currentStep, setCurrentStep] = useState<number>(1)
  const [createCompany] = useCompanyCreateMutation()
  const [nameExists] = useLazyCompanyExistsByNameQuery()

  const itemsSteps = [{ label: 'General Information' }, { label: 'Summary' }]

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<FormInput>({
    reValidateMode: 'onBlur',
    defaultValues: {
      name: companyData.name,
      description: companyData.description,
      aliases: companyData.aliases,
    },
  })

  const formTagData = useFormTagData()

  const nextButton = (
    <DispatcherButton
      buttonProps={{
        label: 'Next',
        icon: 'pi pi-arrow-right',
        iconPos: 'right',
        disabled: formTagData.isLoading,
      }}
      className={DispatcherButtonPresets.FILLED_ROUNDED_PRIMARY}
      onClick={() => {
        if (companyForm.current) {
          companyForm.current.dispatchEvent(
            new Event('submit', { cancelable: true, bubbles: true }),
          )
        }
      }}
    />
  )

  const backIcon = (
    <DispatcherButton
      buttonProps={{ icon: 'pi pi-pencil', onClick: () => setCurrentStep(1) }}
      className={DispatcherButtonPresets.PRIMARY_ICON_ACTIONS}
    />
  )

  const backButton = (
    <DispatcherButton
      buttonProps={{ label: 'Back', icon: 'pi pi-arrow-left' }}
      className={DispatcherButtonPresets.OUTLINED_PRIMARY}
      onClick={() => setCurrentStep(1)}
    />
  )

  const finishButton = (
    <DispatcherButton
      buttonProps={{ label: 'Finish', icon: 'pi pi-check', iconPos: 'right' }}
      className={DispatcherButtonPresets.FILLED_ROUNDED_PRIMARY}
      onClick={async (dispatch: AppDispatch) => {
        try {
          const response = await createCompany({
            workspaceId,
            createCompany: companyData,
          }).unwrap()
          onContentCreated(response)
          dispatch(
            setToastMessage({
              severity: 'success',
              summary: 'Company created successfully.',
            }),
          )
        } catch {
          dispatch(
            setToastMessage({
              severity: 'error',
              summary: 'Company could not be created.',
            }),
          )
        }
      }}
    />
  )

  const confirmCompanyData: SubmitHandler<FormInput> = async (formData) => {
    setCompanyData({
      name: formData.name,
      description: formData.description,
      aliases: formData.aliases,
      tags: formTagData.convert(formData.tags),
    })
    setCurrentStep(2)
  }

  const renderSwitch = (currentStep: number) => {
    switch (currentStep) {
      case 1:
        return (
          <div className={createStyles.main}>
            <form ref={companyForm} className="p-fluid" onSubmit={handleSubmit(confirmCompanyData)}>
              <Steps model={itemsSteps} className="mb-4" activeIndex={currentStep - 1} />
              <h3>General Information</h3>

              <div className="card mb-3">
                <div className="grid card-container">
                  <div className="col-12 mb-2 md:col-2 md:mb-0 font-medium align-self-center">
                    <label htmlFor="name" className={classNames({ 'p-error': errors.name })}>
                      Name:
                    </label>
                  </div>
                  <div className="col-12 md:col-10">
                    <Controller
                      name="name"
                      control={control}
                      rules={{
                        required: { value: true, message: 'Name is a required field' },
                        maxLength: { value: 255, message: 'Name must be less than 256 characters' },
                        validate: async (value) => {
                          return (await nameExists({
                            workspaceId,
                            name: value,
                          }).unwrap())
                            ? 'A company with this name already exists'
                            : true
                        },
                      }}
                      render={({ field, fieldState }) => (
                        <InputText
                          id={field.name}
                          value={field.value}
                          onChange={field.onChange}
                          onBlur={(e) => {
                            field.onChange(e.target.value.trim())
                          }}
                          placeholder="Enter name"
                          className={classNames({ 'p-invalid': fieldState.error })}
                        />
                      )}
                    />
                    {errors.name && <small className="p-error">{errors.name?.message}</small>}
                  </div>
                </div>
              </div>

              <div className="card mb-3">
                <div className="grid card-container">
                  <div className="col-12 mb-2 md:col-2 md:mb-0 md:mt-1 font-medium align-self-start">
                    <label htmlFor="name" className={classNames({ 'p-error': errors.description })}>
                      Description:
                    </label>
                  </div>
                  <div className="col-12 md:col-10">
                    <Controller
                      name="description"
                      control={control}
                      render={({ field, fieldState }) => (
                        <InputTextarea
                          id={field.name}
                          value={field.value || ''}
                          onChange={field.onChange}
                          onBlur={(e) => {
                            field.onChange(
                              e.target.value.trim() === '' ? null : e.target.value.trim(),
                            )
                          }}
                          placeholder="Enter description (optional)"
                          className={classNames({ 'p-invalid': fieldState.error })}
                          rows={7}
                        />
                      )}
                    />
                    {errors.description && (
                      <small className="p-error">{errors.description?.message}</small>
                    )}
                  </div>
                </div>
              </div>
              <div className="card mb-3">
                <div className="grid card-container">
                  <div className="col-12 mb-2 md:col-2 md:mb-0 font-medium align-self-center">
                    <label htmlFor="aliases" className={classNames({ 'p-error': errors.aliases })}>
                      Aliases:
                    </label>
                  </div>
                  <div className="col-12 md:col-10">
                    <Controller
                      name="aliases"
                      control={control}
                      render={({ field, fieldState }) => (
                        <InputText
                          id={field.name}
                          value={field.value || ''}
                          onChange={field.onChange}
                          onBlur={(e) => {
                            field.onChange(
                              e.target.value.trim() === '' ? null : e.target.value.trim(),
                            )
                          }}
                          placeholder="Enter aliases (optional)"
                          className={classNames({ 'p-invalid': fieldState.error })}
                        />
                      )}
                    />
                    {errors.aliases && <small className="p-error">{errors.aliases?.message}</small>}
                  </div>
                </div>
              </div>
              <div className="field grid">
                <label
                  htmlFor="tags"
                  className={classNames(
                    { 'p-error': errors.tags },
                    'col-12 mb-2 md:col-2 md:mb-0 font-medium',
                  )}
                >
                  Tags:
                </label>
                <div className="col-12 md:col-10">
                  <Controller
                    name="tags"
                    control={control}
                    defaultValue={formTagData.defaultTags}
                    render={({ field, fieldState }) => (
                      <MultiSelect
                        id={field.name}
                        value={field.value}
                        options={formTagData.options}
                        onChange={(e) => field.onChange(e.value)}
                        optionLabel="label"
                        optionGroupLabel="label"
                        optionGroupChildren="items"
                        display="chip"
                        filter={true}
                        resetFilterOnHide={true}
                        placeholder="Select tags (optional)"
                        showSelectAll={true}
                        scrollHeight={'400px'}
                        className={classNames({ 'p-invalid': fieldState.error })}
                      />
                    )}
                  />
                  {errors.tags && <small className="p-error">{errors.tags?.message}</small>}
                </div>
              </div>
            </form>
            <Toolbar right={nextButton} />
          </div>
        )
      case 2:
        const groupedTagListTemplate = (key: string, label: string, list: JSX.Element) => {
          return (
            <div className="card mb-3" key={key}>
              <div className="grid card-container">
                <div className="col-12 mb-2 md:col-2 md:mb-0 font-medium align-self-center">
                  {label}:
                </div>
                <div className="col-12 md:col-10">{list}</div>
              </div>
            </div>
          )
        }
        const groupedTagList = useGroupedTagList(companyData.tags, groupedTagListTemplate)

        return (
          <div className={createStyles.main}>
            <Steps model={itemsSteps} className="mb-4" activeIndex={currentStep - 1} />
            <h3>Summary</h3>

            <div className="flex align-items-center flex-wrap">
              <div className="mr-2">
                <h4>General Information</h4>
              </div>
              <div>{backIcon}</div>
            </div>

            <div className="card mb-3">
              <div className="grid card-container">
                <div className="col-12 mb-2 md:col-2 md:mb-0 font-medium align-self-center">
                  Name:
                </div>
                <div className="col-12 md:col-10">{companyData.name}</div>
              </div>
            </div>

            <div className="card mb-3">
              <div className="grid card-container">
                <div className="col-12 mb-2 md:col-2 md:mb-0 font-medium align-self-center">
                  Description:
                </div>
                <div className="col-12 md:col-10">{companyData.description}</div>
              </div>
            </div>

            <div className="card mb-3">
              <div className="grid card-container">
                <div className="col-12 mb-2 md:col-2 md:mb-0 font-medium align-self-center">
                  Aliases:
                </div>
                <div className="col-12 md:col-10">{companyData.aliases || '(none)'}</div>
              </div>
            </div>

            {groupedTagList}

            <Toolbar left={backButton} right={finishButton} />
          </div>
        )
      default:
        return <></>
    }
  }

  return renderSwitch(currentStep)
}

export default CompanyCreateContent

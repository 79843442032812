import { Toolbar } from 'primereact/toolbar'
import { useCancelCreate } from '../../common/dispatcherButtonHooks'

const CompanyCreateToolbar = (): JSX.Element => {
  const cancelCreate = useCancelCreate()
  return (
    <>
      <Toolbar
        right={cancelCreate.dispatcherButton}
        className="p-0 m-0"
        style={{ border: 0, background: 'none' }}
      />
      <div className="card mt-5">
        <h1>Create Company</h1>
      </div>
    </>
  )
}

export default CompanyCreateToolbar

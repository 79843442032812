import { useAppSelector } from '../../common/hooks'
import { useParticipantQuery } from '../../redux/api'
import { useParams } from 'react-router-dom'
import { useEditMode } from '../../common/dispatcherButtonHooks'
import ParticipantTabs from '../../components/Participant/ParticipantTabs'
import ParticipantDetails from '../../components/Participant/ParticipantDetails'
import ParticipantDetailsSkeleton from '../../components/Participant/ParticipantDetailsSkeleton'
import ParticipantToolbar from '../../components/Participant/ParticipantToolbar'
import NotFoundPage from '../http/404NotFoundPage'

const ParticipantDetailsPage = (): JSX.Element => {
  const urlParams = useParams()
  const workspaceId = useAppSelector((state) => state.app.workspace!.id)
  const editMode = useEditMode()

  // load company by company-id
  const { data: participant, isLoading: isParticipantLoading } = useParticipantQuery({
    workspaceId: workspaceId,
    itemId: urlParams.id!,
  })

  return (
    <div className="px-4 py-5 md:px-6 lg:px-8">
      {isParticipantLoading ? (
        <ParticipantDetailsSkeleton />
      ) : participant === undefined ? (
        <NotFoundPage />
      ) : (
        <>
          <ParticipantToolbar participant={participant!} editMode={editMode} />
          <ParticipantDetails participant={participant!} />
          <ParticipantTabs participant={participant!} />
        </>
      )}
    </div>
  )
}

export default ParticipantDetailsPage

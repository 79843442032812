import { ReactElement, useRef, useState } from 'react'
import { useAppSelector, useGroupedTagList } from '../../common/hooks'
import DispatcherButton, { DispatcherButtonPresets } from '../DispatcherButton'
import { InputText } from 'primereact/inputtext'
import { Steps } from 'primereact/steps'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { classNames } from 'primereact/utils'
import {
  CreateProduct,
  useLazyProductExistsByCompanyQuery,
  useProductCreateMutation,
} from '../../redux/api'
import { MultiSelect } from 'primereact/multiselect'
import { Toolbar } from 'primereact/toolbar'
import {
  useFormCapabilityData,
  useFormCompanyData,
  useFormGeoDataMulti,
  useFormTagData,
} from '../../common/formHooks'
import TagList, { NORMALIZE_PRESETS } from '../TagList'
import { Checkbox } from 'primereact/checkbox'
import { Tree } from 'primereact/tree'
import { Dropdown } from 'primereact/dropdown'
import { AppDispatch } from '../../redux/store'
import { setToastMessage } from '../../redux/app'
import createStyles from '../../styles/create.module.scss'
import { InputTextarea } from 'primereact/inputtextarea'

interface FormData {
  company: number | undefined
  name: string
  description: string | null
  aliases: string | null
  global: boolean
  geos: number[]
  tags: number[]
}

interface FormDataTwo {
  capabilities: { [key: number]: boolean }
}

interface Props {
  company?: Company
  onContentCreated?: (product: Product) => void
}

const ProductCreateContent = ({ company, onContentCreated = () => {} }: Props): JSX.Element => {
  const workspaceId = useAppSelector((state) => state.app.workspace!.id)

  const formRefOne = useRef<HTMLFormElement>(null)
  const formRefTwo = useRef<HTMLFormElement>(null)

  const [productData, setProductData] = useState<CreateProduct | undefined>(undefined)

  const [currentStep, setCurrentStep] = useState<number>(1)

  const [geoCheckbox, setGeosCheckbox] = useState<boolean>(false)

  const [createProduct] = useProductCreateMutation()

  const itemsSteps = [
    { label: 'General Information' },
    { label: 'Capabilities' },
    { label: 'Summary' },
  ]

  const [nameExists] = useLazyProductExistsByCompanyQuery()

  const {
    control,
    formState: { errors },
    handleSubmit,
    getValues,
  } = useForm<FormData>({
    reValidateMode: 'onBlur',
    defaultValues: {
      company: company?.id,
      name: '',
      description: null,
      aliases: null,
      global: false,
      geos: [],
      tags: [],
    },
  })

  const {
    control: controlTwo,
    formState: { errors: errorsTwo },
    handleSubmit: handleSubmitTwo,
  } = useForm<FormDataTwo>({
    defaultValues: {
      capabilities: [],
    },
  })

  const formCompanyData = useFormCompanyData()

  const formGeoData = useFormGeoDataMulti()

  const formTagData = useFormTagData()

  const formCapabilityData = useFormCapabilityData()

  const stepOneNextButton = (
    <DispatcherButton
      buttonProps={{ label: 'Next', icon: 'pi pi-arrow-right', iconPos: 'right' }}
      className={DispatcherButtonPresets.FILLED_ROUNDED_PRIMARY}
      onClick={() => {
        if (formRefOne.current) {
          formRefOne.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))
        }
      }}
    />
  )

  const stepTwoBackButton = (
    <DispatcherButton
      buttonProps={{ label: 'Back', icon: 'pi pi-arrow-left' }}
      className={DispatcherButtonPresets.OUTLINED_PRIMARY}
      onClick={() => setCurrentStep(1)}
    />
  )

  const stepTwoNextButton = (
    <DispatcherButton
      buttonProps={{ label: 'Next', icon: 'pi pi-arrow-right', iconPos: 'right' }}
      className={DispatcherButtonPresets.FILLED_ROUNDED_PRIMARY}
      onClick={() => {
        if (formRefTwo.current) {
          formRefTwo.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))
        }
      }}
    />
  )

  const stepThreeBackToOneIcon = (
    <DispatcherButton
      buttonProps={{ icon: 'pi pi-pencil', onClick: () => setCurrentStep(1) }}
      className={DispatcherButtonPresets.PRIMARY_ICON_ACTIONS}
    />
  )

  const stepThreeBackToTwoIcon = (
    <DispatcherButton
      buttonProps={{ icon: 'pi pi-pencil', onClick: () => setCurrentStep(2) }}
      className={DispatcherButtonPresets.PRIMARY_ICON_ACTIONS}
    />
  )

  const stepThreeBackButton = (
    <DispatcherButton
      buttonProps={{ label: 'Back', icon: 'pi pi-arrow-left' }}
      className={DispatcherButtonPresets.OUTLINED_PRIMARY}
      onClick={() => setCurrentStep(2)}
    />
  )

  const stepThreeFinishButton = (
    <DispatcherButton
      buttonProps={{ label: 'Finish', icon: 'pi pi-check', iconPos: 'right' }}
      className={DispatcherButtonPresets.FILLED_ROUNDED_PRIMARY}
      onClick={async (dispatch: AppDispatch) => {
        try {
          // TODO disable the submit button
          const response = await createProduct({
            workspaceId,
            createProduct: productData!,
          }).unwrap()
          onContentCreated(response)
          dispatch(
            setToastMessage({
              severity: 'success',
              summary: 'Product created successfully.',
            }),
          )
        } catch {
          dispatch(
            setToastMessage({
              severity: 'error',
              summary: 'Product could not be created.',
            }),
          )
        }
      }}
    />
  )

  const submitFormData: SubmitHandler<FormData> = async (formData) => {
    setProductData({
      ...(productData as CreateProduct),
      company: formCompanyData.convert(formData.company!),
      name: formData.name,
      description: formData.description,
      aliases: formData.aliases,
      geos: formGeoData.convert(formData.global, formData.geos),
      tags: formTagData.convert(formData.tags),
    })
    setCurrentStep(2)
  }

  const submitFormDataTwo: SubmitHandler<FormDataTwo> = async (formData) => {
    setProductData({
      ...(productData as CreateProduct),
      capabilities: formCapabilityData.convert(formData.capabilities),
    })
    setCurrentStep(3)
  }

  const renderSwitch = (currentStep: number): ReactElement => {
    let stepCode: ReactElement = <></>
    switch (currentStep) {
      case 1:
        stepCode = (
          <div className={createStyles.main}>
            <form ref={formRefOne} className="p-fluid" onSubmit={handleSubmit(submitFormData)}>
              <Steps model={itemsSteps} activeIndex={0} className={'mb-4'} />
              <h3>General Information</h3>

              <div className="card mb-3">
                <div className="grid card-container">
                  <div className="col-12 mb-2 md:col-2 md:mb-0 font-medium align-self-center">
                    <label htmlFor="name" className={classNames({ 'p-error': errors.company })}>
                      Company:
                    </label>
                  </div>
                  <div className="col-12 md:col-10">
                    <Controller
                      name="company"
                      control={control}
                      rules={{
                        required: { value: true, message: 'Company is a required field' },
                      }}
                      render={({ field, fieldState }) => (
                        <Dropdown
                          disabled={company !== undefined}
                          id={field.name}
                          value={field.value}
                          onChange={(e) => field.onChange(e.value)}
                          placeholder={'Select company'}
                          options={formCompanyData.options}
                          optionLabel="name"
                          optionValue="id"
                          filter={true}
                          resetFilterOnHide={true}
                          scrollHeight={'400px'}
                          className={classNames({ 'p-invalid': fieldState.error })}
                        />
                      )}
                    />
                    {errors.company && <small className="p-error">{errors.company?.message}</small>}
                  </div>
                </div>
              </div>

              <div className="card mb-3">
                <div className="grid card-container">
                  <div className="col-12 mb-2 md:col-2 md:mb-0 font-medium align-self-center">
                    <label htmlFor="name" className={classNames({ 'p-error': errors.name })}>
                      Name:
                    </label>
                  </div>
                  <div className="col-12 md:col-10">
                    <Controller
                      name="name"
                      control={control}
                      rules={{
                        required: { value: true, message: 'Name is a required field' },
                        maxLength: { value: 255, message: 'Name must be less than 256 characters' },
                        validate: async (value) => {
                          const companyId = getValues('company')
                          if (companyId === undefined) return true
                          return (await nameExists({
                            workspaceId,
                            companyId,
                            name: value,
                          }).unwrap())
                            ? 'A product with this name already exists within this company'
                            : true
                        },
                      }}
                      render={({ field, fieldState }) => (
                        <InputText
                          id={field.name}
                          value={field.value}
                          onChange={field.onChange}
                          onBlur={(e) => {
                            field.onChange(e.target.value.trim())
                          }}
                          placeholder="Enter name"
                          className={classNames({ 'p-invalid': fieldState.error })}
                        />
                      )}
                    />
                    {errors.name && <small className="p-error">{errors.name?.message}</small>}
                  </div>
                </div>
              </div>

              <div className="card mb-3">
                <div className="grid card-container">
                  <div className="col-12 mb-2 md:col-2 md:mb-0 md:mt-1 font-medium align-self-start">
                    <label htmlFor="name">Description:</label>
                  </div>
                  <div className="col-12 md:col-10">
                    <Controller
                      name="description"
                      control={control}
                      render={({ field }) => (
                        <InputTextarea
                          id={field.name}
                          value={field.value || ''}
                          onChange={field.onChange}
                          onBlur={(e) => {
                            field.onChange(
                              e.target.value.trim() === '' ? null : e.target.value.trim(),
                            )
                          }}
                          placeholder="Enter description (optional)"
                          rows={7}
                        />
                      )}
                    />
                  </div>
                </div>
              </div>

              <div className="card mb-3">
                <div className="grid card-container">
                  <div className="col-12 mb-2 md:col-2 md:mb-0 font-medium align-self-center">
                    <label htmlFor="aliases">Aliases:</label>
                  </div>
                  <div className="col-12 md:col-10">
                    <Controller
                      name="aliases"
                      control={control}
                      render={({ field }) => (
                        <InputText
                          id={field.name}
                          value={field.value || ''}
                          onChange={field.onChange}
                          onBlur={(e) => {
                            field.onChange(
                              e.target.value.trim() === '' ? null : e.target.value.trim(),
                            )
                          }}
                          placeholder="Enter aliases (optional)"
                        />
                      )}
                    />
                  </div>
                </div>
              </div>

              {/* Markets - Checkbox Variant ...*/}
              <div className="field grid">
                <label
                  htmlFor="markets"
                  className={classNames(
                    { 'p-error': errors.geos },
                    'col-12 mb-2 xl:col-2 xl:mb-0 font-medium',
                  )}
                >
                  Available Markets:
                </label>

                <div className="flex col-12 xl:col-10 align-self-center pl-0">
                  <div className="col-12 xl:col-10">
                    <Controller
                      name="global"
                      control={control}
                      defaultValue={formGeoData.defaultGlobal}
                      render={({ field, fieldState }) => (
                        <Checkbox
                          inputId={field.name}
                          onChange={(e) => field.onChange(e.checked)}
                          checked={field.value}
                          className={classNames({ 'p-invalid': fieldState.error })}
                        />
                      )}
                    />
                    {errors.global && <small className="p-error">{errors.global?.message}</small>}

                    <label
                      className={classNames(
                        { 'p-error': errors.global },
                        'ml-2 mb-2 xl:mb-0 font-medium',
                      )}
                    >
                      Global
                    </label>
                  </div>
                </div>
              </div>

              <div className="field grid">
                <div className="flex col-12 xl:col-10 xl:col-offset-2 align-self-center pl-0">
                  <div className="flex col-2 xl:col-2 align-self-center">
                    <Checkbox
                      checked={geoCheckbox}
                      onChange={() => setGeosCheckbox(!geoCheckbox)}
                    />
                    <div className="ml-2 mb-2 xl:mb-0 font-medium">
                      <label className={classNames({ 'p-error': errors.geos })}>Markets</label>
                    </div>
                  </div>

                  <div className="col-10 xl:col-10 pr-0">
                    <Controller
                      name="geos"
                      control={control}
                      defaultValue={formGeoData.defaultGeos}
                      render={({ field, fieldState, formState }) => (
                        <MultiSelect
                          id={field.name}
                          value={field.value}
                          options={formGeoData.options}
                          onChange={(e) => field.onChange(e.value)}
                          optionLabel="label"
                          optionGroupLabel="label"
                          optionGroupChildren="items"
                          placeholder="Select markets"
                          showSelectAll={true}
                          display="chip"
                          filter={true}
                          resetFilterOnHide={true}
                          scrollHeight={'400px'}
                          className={classNames({ 'p-invalid': fieldState.error })}
                          disabled={!geoCheckbox}
                        />
                      )}
                    />
                    {errors.geos && <small className="p-error">{errors.geos?.message}</small>}
                  </div>
                </div>
              </div>
              {/* ... Markets - Checkbox Variant */}

              <div className="field grid">
                <label
                  htmlFor="tags"
                  className={classNames(
                    { 'p-error': errors.tags },
                    'col-12 mb-2 md:col-2 md:mb-0 font-medium',
                  )}
                >
                  Tags:
                </label>
                <div className="col-12 md:col-10">
                  <Controller
                    name="tags"
                    control={control}
                    render={({ field, fieldState }) => (
                      <MultiSelect
                        id={field.name}
                        value={field.value}
                        onChange={(e) => field.onChange(e.value)}
                        placeholder="Select tags (optional)"
                        options={formTagData.options}
                        optionLabel="label"
                        optionGroupLabel="label"
                        optionGroupChildren="items"
                        display="chip"
                        filter={true}
                        resetFilterOnHide={true}
                        showSelectAll={true}
                        scrollHeight={'400px'}
                        className={classNames({ 'p-invalid': fieldState.error })}
                      />
                    )}
                  />
                  {errors.tags && <small className="p-error">{errors.tags?.message}</small>}
                </div>
              </div>
            </form>
            <Toolbar right={stepOneNextButton} />
          </div>
        )
        break
      case 2:
        stepCode = (
          <div className={createStyles.main}>
            <form
              ref={formRefTwo}
              className="p-fluid"
              onSubmit={handleSubmitTwo(submitFormDataTwo)}
            >
              <Steps model={itemsSteps} activeIndex={1} className={'mb-4'} />
              <h3>Capabilities</h3>

              <div className="field grid">
                <div className="col-12">
                  <Controller
                    name="capabilities"
                    control={controlTwo}
                    render={({ field, fieldState }) => (
                      <Tree
                        id={field.name}
                        value={formCapabilityData.options}
                        selectionMode="multiple"
                        metaKeySelection={false}
                        selectionKeys={field.value}
                        onSelectionChange={(e) =>
                          field.onChange(e.value as { [key: number]: boolean })
                        }
                        filter={true}
                        className={classNames({ 'p-invalid': fieldState.error })}
                      />
                    )}
                  />
                  {errorsTwo.capabilities && (
                    <small className="p-error">{errorsTwo.capabilities?.message}</small>
                  )}
                </div>
              </div>
            </form>
            <Toolbar left={stepTwoBackButton} right={stepTwoNextButton} />
          </div>
        )
        break
      case 3:
        const groupedTagListTemplate = (key: string, label: string, list: JSX.Element) => {
          return (
            <div className="card mb-3" key={key}>
              <div className="grid card-container">
                <div className="col-12 mb-2 md:col-2 md:mb-0 font-medium align-self-center">
                  {label}:
                </div>
                <div className="col-12 md:col-10">{list}</div>
              </div>
            </div>
          )
        }
        const groupedTagList = useGroupedTagList(productData!.tags, groupedTagListTemplate)

        stepCode = (
          <div className={createStyles.main}>
            <Steps model={itemsSteps} activeIndex={2} className={'mb-4'} />
            <h3>Summary</h3>
            <div className="flex align-items-center flex-wrap">
              <div className="mr-2">
                <h4>General Information</h4>
              </div>
              <div>{stepThreeBackToOneIcon}</div>
            </div>
            <div className="card mb-3">
              <div className="grid card-container">
                <div className="col-12 mb-2 md:col-2 md:mb-0 font-medium align-self-center">
                  Company:
                </div>
                <div className="col-12 md:col-10">{productData!.company.name}</div>
              </div>
            </div>
            <div className="card mb-3">
              <div className="grid card-container">
                <div className="col-12 mb-2 md:col-2 md:mb-0 font-medium align-self-center">
                  Name:
                </div>
                <div className="col-12 md:col-10">{productData!.name}</div>
              </div>
            </div>
            <div className="card mb-3">
              <div className="grid card-container">
                <div className="col-12 mb-2 md:col-2 md:mb-0 font-medium align-self-center">
                  Description:
                </div>
                <div className="col-12 md:col-10">{productData!.description}</div>
              </div>
            </div>
            <div className="card mb-3">
              <div className="grid card-container">
                <div className="col-12 mb-2 md:col-2 md:mb-0 font-medium align-self-center">
                  Aliases:
                </div>
                <div className="col-12 md:col-10">{productData!.aliases || '(none)'}</div>
              </div>
            </div>
            <div className="card mb-3">
              <div className="grid card-container">
                <div className="col-12 mb-2 md:col-2 md:mb-0 font-medium align-self-center">
                  Available Markets:
                </div>
                <div className="col-12 md:col-10">
                  <TagList
                    items={[
                      productData!.geos.filter((geo) => geo.type === null),
                      productData!.geos.filter((geo) => geo.type !== null),
                    ]}
                    emptyVal={'(none)'}
                  />
                </div>
              </div>
            </div>
            {groupedTagList}

            <div className="flex align-items-center flex-wrap">
              <div className="mr-2">
                <h4>Capabilities</h4>
              </div>
              <div>{stepThreeBackToTwoIcon}</div>
            </div>

            <div className="card mb-3">
              <div className="grid card-container">
                <div className="col-12 mb-2 md:col-2 md:mb-0 font-medium align-self-center">
                  Capabilities:
                </div>
                <div className="col-12 md:col-10">
                  <TagList
                    items={productData!.capabilities}
                    normalize={NORMALIZE_PRESETS.POS}
                    emptyVal={'(none)'}
                  />
                </div>
              </div>
            </div>
            <Toolbar left={stepThreeBackButton} right={stepThreeFinishButton} />
          </div>
        )
        break
    }
    return stepCode
  }

  return renderSwitch(currentStep)
}

export default ProductCreateContent

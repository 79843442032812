import { Dispatch, SetStateAction, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import DispatcherButton, { DispatcherButtonPresets } from '../components/DispatcherButton'

interface Props {
  setPreHomepage: Dispatch<SetStateAction<boolean>>
}

const PreHomePage = ({ setPreHomepage }: Props): JSX.Element => {
  const navigate = useNavigate()

  const gotoHomePage = () => {
    navigate('/home')
  }
  useEffect(() => {
    setPreHomepage(true)
  }, [])

  const sharePointProps = {
    label: 'Go to our SharePoint',
    className: DispatcherButtonPresets.OUTLINED_WHITE,
    onClick: () => {
      window.open(
        'https://insidemedia.sharepoint.com/sites/GlobalInnovation/SitePages/Vendor-Explorer.aspx',
        '_blank',
      )
    },
  }

  const geStartedProps = {
    label: 'Get started',
    className: DispatcherButtonPresets.FILLED_ROUNDED_PRIMARY,
    onClick: () => gotoHomePage(),
  }

  return (
    <>
      <div className="px-4 md:px-6 lg:px-8" style={{ paddingTop: '100px' }}>
        <div className="col-12 lg:col-6">
          <h1 className="text-primary text-6xl">Vendor Explorer</h1>
          <h2 className="text-white text-2xl">
            An application that helps us democratize industry intelligence across our ecosystem
          </h2>
          <div className="text-white">
            <p>
              Vendor information database directly provided by Vendors themselves.
              <br />
              Issue RFIs and have Vendors provide answers directly on separate instances of the same
              online platform.
            </p>
          </div>
          <div className="flex gap-3 mt-6">
            <DispatcherButton buttonProps={sharePointProps} />
            <DispatcherButton buttonProps={geStartedProps} />
          </div>
          <div className="text-xs text-white mt-6">
            <p>
              All the information contained in Vendor Explorer comes from RFI answers provided by
              vendors themselves.
              <br />
              RFIs will often contain vendor confidential information, which will have been provided
              under a Non-Disclosure Agreement (NDA).
              <br />
              Please only use this information for our internal purposes to analyze and compare
              vendor's products and services. We can also share this information on a confidential
              basis with our clients and in client pitches.
            </p>
          </div>
        </div>
      </div>
    </>
  )
}
export default PreHomePage

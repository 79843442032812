import { Divider } from 'primereact/divider'
import { forwardRef } from 'react'
import { useForm, Controller, SubmitHandler } from 'react-hook-form'
import { InputText } from 'primereact/inputtext'
import { MultiSelect } from 'primereact/multiselect'
import { classNames } from 'primereact/utils'
import { useFormTagData } from '../../common/formHooks'
import {
  UpdateCompany,
  useCompanyUpdateMutation,
  useLazyCompanyExistsByNameQuery,
} from '../../redux/api'
import { useAppDispatch, useAppSelector } from '../../common/hooks'
import TagList, { NORMALIZE_PRESETS } from '../TagList'
import CompanyDetailsSkeleton from './CompanyDetailsSkeleton'
import { Message } from 'primereact/message'
import { setToastMessage } from '../../redux/app'
import { InputTextarea } from 'primereact/inputtextarea'

interface FormInput {
  name: string
  description: string | null
  aliases: string | null
  tags: number[]
}

interface Props {
  company: Company
  toggleEdit: () => void
}

const CompanyDetailsEdit = forwardRef<HTMLFormElement, Props>(
  ({ company, toggleEdit }: Props, ref: React.ForwardedRef<HTMLFormElement>): JSX.Element => {
    const workspaceId = useAppSelector((state) => state.app.workspace!.id)
    const [updateCompany] = useCompanyUpdateMutation()
    const [nameExists] = useLazyCompanyExistsByNameQuery()
    const dispatch = useAppDispatch()

    const {
      control,
      formState: { errors },
      handleSubmit,
      resetField,
    } = useForm<FormInput>({
      reValidateMode: 'onBlur',
      defaultValues: {
        name: company.name,
        description: company.description,
        aliases: company.aliases,
      },
    })

    const formTagData = useFormTagData(company, resetField)

    const onSubmit: SubmitHandler<FormInput> = async (formData) => {
      const updateData: UpdateCompany = {
        name: formData.name,
        description: formData.description,
        aliases: formData.aliases,
        tags: formTagData.convert(formData.tags),
      }
      try {
        await updateCompany({ workspaceId, companyId: company?.id!, updateCompany: updateData })
        toggleEdit()
        dispatch(
          setToastMessage({
            severity: 'success',
            summary: 'Changes saved successfully.',
          }),
        )
      } catch {
        dispatch(
          setToastMessage({
            severity: 'error',
            summary: 'Changes could not be saved.',
          }),
        )
      }
    }

    const capabilities: Capability[] = []
    company?.products.forEach((product) => {
      capabilities.push(...product.capabilities)
    })

    return formTagData.isLoading ? (
      <CompanyDetailsSkeleton />
    ) : (
      <form ref={ref} onSubmit={handleSubmit(onSubmit)} className="p-fluid">
        <div className="card mt-5">
          <div className="formgrid grid">
            <div className="field col-12 md:col-6">
              <label htmlFor="name" className={classNames({ 'p-error': errors.name })}>
                Name:
              </label>
              <Controller
                name="name"
                control={control}
                defaultValue={company?.name}
                rules={{
                  required: { value: true, message: 'Name is a required field' },
                  maxLength: { value: 255, message: 'Name must be less than 256 characters' },
                  validate: async (value) => {
                    if (value === company?.name) return true
                    return (await nameExists({
                      workspaceId,
                      name: value,
                    }).unwrap())
                      ? 'A company with this name already exists'
                      : true
                  },
                }}
                render={({ field, fieldState }) => (
                  <InputText
                    id={field.name}
                    value={field.value}
                    onChange={field.onChange}
                    onBlur={(e) => {
                      field.onChange(e.target.value.trim())
                    }}
                    placeholder="Enter name"
                    className={classNames({ 'p-invalid': fieldState.error })}
                  />
                )}
              />
              {errors.name && <small className="p-error">{errors.name?.message}</small>}
            </div>
          </div>
          <div className="formgrid grid">
            <div className="field col-12 md:col-6">
              <label htmlFor="name" className={classNames({ 'p-error': errors.description })}>
                Description:
              </label>
              <Controller
                name="description"
                control={control}
                defaultValue={company?.description}
                render={({ field, fieldState }) => (
                  <InputTextarea
                    id={field.name}
                    value={field.value || ''}
                    onChange={field.onChange}
                    onBlur={(e) => {
                      field.onChange(e.target.value.trim() === '' ? null : e.target.value.trim())
                    }}
                    placeholder="Enter description (optional)"
                    className={classNames({ 'p-invalid': fieldState.error })}
                    rows={7}
                  />
                )}
              />
              {errors.description && (
                <small className="p-error">{errors.description?.message}</small>
              )}
            </div>
          </div>
        </div>
        <Divider />

        <div className="card mb-5">
          <div className="flex card-container overflow-hidden gap-4">
            <div className="flex-auto col-6">
              <div className="field grid">
                <label
                  htmlFor="aliases"
                  className={classNames(
                    { 'p-error': errors.aliases },
                    'col-12 mb-2 md:col-2 md:mb-0 font-medium',
                  )}
                >
                  Aliases:
                </label>
                <div className="col-12 md:col-10">
                  <Controller
                    name="aliases"
                    control={control}
                    defaultValue={company?.aliases || ''}
                    render={({ field, fieldState }) => (
                      <InputText
                        id={field.name}
                        value={field.value || ''}
                        onChange={field.onChange}
                        onBlur={(e) => {
                          field.onChange(
                            e.target.value.trim() === '' ? null : e.target.value.trim(),
                          )
                        }}
                        placeholder={'Enter aliases (optional)'}
                        className={classNames({ 'p-invalid': fieldState.error })}
                      />
                    )}
                  />
                  {errors.aliases && <small className="p-error">{errors.aliases?.message}</small>}
                </div>
              </div>

              <div className="field grid">
                <label
                  htmlFor="aliases"
                  className={'col-12 mb-2 md:col-2 md:mb-0 font-medium align-items-start'}
                >
                  Capabilities:
                </label>
                <div className="col-12 md:col-10">
                  <TagList
                    items={capabilities}
                    normalize={NORMALIZE_PRESETS.POS}
                    emptyVal={'(none)'}
                    maxShown={10}
                  />
                  <div className="mt-5">
                    <Message
                      severity="warn"
                      text="Capabilities can only be edited in the products of this company."
                      style={{ display: 'inline' }}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="flex-auto col-6">
              <div className="field grid">
                <label
                  htmlFor="tags"
                  className={classNames(
                    { 'p-error': errors.tags },
                    'col-12 mb-2 md:col-2 md:mb-0 font-medium',
                  )}
                >
                  Tags:
                </label>
                <div className="col-12 md:col-10">
                  <Controller
                    name="tags"
                    control={control}
                    defaultValue={formTagData.defaultTags}
                    render={({ field, fieldState }) => (
                      <MultiSelect
                        id={field.name}
                        value={field.value}
                        options={formTagData.options}
                        onChange={(e) => field.onChange(e.value)}
                        optionLabel="label"
                        optionGroupLabel="label"
                        optionGroupChildren="items"
                        placeholder="Select tags (optional)"
                        showSelectAll={true}
                        display="chip"
                        filter={true}
                        resetFilterOnHide={true}
                        scrollHeight={'400px'}
                        className={classNames({ 'p-invalid': fieldState.error })}
                      />
                    )}
                  />
                  {errors.tags && <small className="p-error">{errors.tags?.message}</small>}
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    )
  },
)

export default CompanyDetailsEdit

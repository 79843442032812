import DispatcherButton, { DispatcherButtonPresets } from '../DispatcherButton'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { classNames } from 'primereact/utils'
import { CreateRfi } from '../../redux/api'
import { Toolbar } from 'primereact/toolbar'
import { useFormCapabilityData, useFormTagData } from '../../common/formHooks'
import { MultiSelect } from 'primereact/multiselect'
import { Tree } from 'primereact/tree'
import { useState } from 'react'
import InfoTooltip from '../InfoTooltip'
import { Tooltip } from 'primereact/tooltip'

interface FormData {
  capabilities: { [key: number]: boolean }
  tags: number[]
}

interface Props {
  rfiData: CreateRfi
  setRfiData: React.Dispatch<React.SetStateAction<CreateRfi>>
  setStep: React.Dispatch<React.SetStateAction<number>>
}

const RfiCreateStepTwo = ({ rfiData, setRfiData, setStep }: Props): JSX.Element => {
  const [next, setNext] = useState(1)

  const { control, resetField, handleSubmit } = useForm<FormData>({
    defaultValues: {
      capabilities: [],
      tags: [],
    },
  })

  const formCapabilityData = useFormCapabilityData(rfiData, resetField)
  const formTagData = useFormTagData(rfiData, resetField)

  const submitFormData: SubmitHandler<FormData> = async (formData) => {
    setRfiData({
      ...rfiData,
      capabilities: formCapabilityData.convert(formData.capabilities),
      tags: formTagData.convert(formData.tags),
    })
    setStep((step) => step + next)
  }

  return (
    <form className="p-fluid" onSubmit={handleSubmit(submitFormData)}>
      <Tooltip target=".infoTooltip" />
      <InfoTooltip
        tooltip="Capabilities refer to the categories that pertain to the vendor products/services."
        label={<h3>Capabilities</h3>}
      />
      <p>
        Select the Capability(ies) that apply to your RFI. Your selections here will determine which
        Question Packs are displayed at the next stage.
      </p>
      <div key="capabilities" className="field grid">
        <div className="col-12">
          <Controller
            name="capabilities"
            control={control}
            render={({ field, fieldState }) => (
              <Tree
                id={field.name}
                value={formCapabilityData.options}
                selectionMode="multiple"
                metaKeySelection={false}
                selectionKeys={field.value}
                onSelectionChange={(e) => field.onChange(e.value as { [key: number]: boolean })}
                filter={true}
                className={classNames({ 'p-invalid': fieldState.error })}
              />
            )}
          />
        </div>
      </div>

      <InfoTooltip
        tooltip="Tags are an extra layer of categorization transversal to capabilities."
        label={<h3>Tags</h3>}
      />

      <span>
        <p>
          Select the Tags that apply to your RFI. Your selections here will determine which Question
          Packs are displayed at the next stage.
        </p>
        <ul>
          <li>
            For Responsible Investment questions, please select relevant tags under 'Responsible
            Investment Framework'
          </li>
          <li>
            For Sustainability and Carbon Emissions questions, please select the 'Sustainability'
            tag
          </li>
        </ul>
      </span>
      <div key="tags" className="field grid">
        <div className="col-12">
          <Controller
            name="tags"
            control={control}
            render={({ field, fieldState }) => (
              <MultiSelect
                id={field.name}
                value={field.value}
                onChange={(e) => field.onChange(e.value)}
                placeholder="Select tags (optional)"
                options={formTagData.options}
                optionLabel="label"
                optionGroupLabel="label"
                optionGroupChildren="items"
                display="chip"
                filter={true}
                resetFilterOnHide={true}
                showSelectAll={true}
                scrollHeight={'400px'}
                className={classNames({ 'p-invalid': fieldState.error })}
              />
            )}
          />
        </div>
      </div>
      <Toolbar
        left={
          <DispatcherButton
            buttonProps={{
              label: 'Back',
              icon: 'pi pi-arrow-left',
              type: 'submit',
              onClick: () => setNext(-1),
            }}
            className={DispatcherButtonPresets.OUTLINED_PRIMARY}
          />
        }
        right={
          <DispatcherButton
            buttonProps={{
              label: 'Next',
              icon: 'pi pi-arrow-right',
              iconPos: 'right',
              type: 'submit',
            }}
            className={DispatcherButtonPresets.FILLED_ROUNDED_PRIMARY}
          />
        }
      />
    </form>
  )
}

export default RfiCreateStepTwo

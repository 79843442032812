import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import ReactDOM from 'react-dom/client'
import App from './scenes/App'
import { Provider } from 'react-redux'
import store from './redux/store'
import 'primereact/resources/themes/lara-light-purple/theme.css'
import 'primereact/resources/primereact.min.css'
import 'primeicons/primeicons.css'
import 'primeflex/primeflex.css'
import './styles/primeOverride.scss'
import './styles/index.scss'
import PrimeReact from 'primereact/api'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

PrimeReact.ripple = true

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
)

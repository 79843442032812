import { useAppSelector } from '../../common/hooks'
import { useProductQuery } from '../../redux/api'
import { useParams } from 'react-router-dom'
import ProductDetails from '../../components/Product/ProductDetails'
import ProductDetailsSkeleton from '../../components/Product/ProductDetailsSkeleton'
import ProductToolbar from '../../components/Product/ProductToolbar'
import { useEditMode } from '../../common/dispatcherButtonHooks'
import ProductDetailsEdit from '../../components/Product/ProductDetailsEdit'
import ProductTabs from '../../components/Product/ProductTabs'
import NotFoundPage from '../http/404NotFoundPage'

const ProductsDetailPage = (): JSX.Element => {
  const urlParams = useParams()
  const workspaceId = useAppSelector((state) => state.app.workspace!.id)
  const editMode = useEditMode()

  const { data: product, isLoading: isProductLoading } = useProductQuery({
    workspaceId: workspaceId,
    productId: urlParams.id!,
  })

  return (
    <div className="px-4 py-5 md:px-6 lg:px-8">
      {isProductLoading ? (
        <ProductDetailsSkeleton />
      ) : product === undefined ? (
        <NotFoundPage />
      ) : (
        <>
          <ProductToolbar product={product} editMode={editMode} />
          {editMode.enabled ? (
            <ProductDetailsEdit
              product={product!}
              toggleEdit={editMode.toggle}
              ref={editMode.formRef}
            />
          ) : (
            <>
              <ProductDetails product={product} />
              <ProductTabs product={product} />
            </>
          )}
        </>
      )}
    </div>
  )
}

export default ProductsDetailPage

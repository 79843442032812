import { Column, ColumnProps } from 'primereact/column'
import { Skeleton } from 'primereact/skeleton'
import Creator from '../Creator'
import TagList, { NormalizedItem } from '../TagList'
import DispatcherButton, { DispatcherButtonProps } from '../DispatcherButton'

const defaultHeaderStyle = { backgroundColor: 'white', fontSize: '21px', fontWeight: 700 }

const buildCustomColumn = (
  type: string,
  columnProps: ColumnProps,
  bodyTemplate?: (rowData: any) => JSX.Element,
) => {
  const props: ColumnProps = {
    ...{ headerStyle: defaultHeaderStyle },
    ...columnProps,
    ...{ body: !!bodyTemplate ? bodyTemplate : columnProps.body },
  }
  return <Column key={type} {...props} />
}

/** SKELETON COLUMN ... */

interface SkeletonColumnParams {
  columnProps?: Omit<ColumnProps, 'body'>
}

export type SkeletonColumn = {
  config: SkeletonColumnParams
  build: () => JSX.Element
}

export const skeletonColumn = (params: SkeletonColumnParams = {}): SkeletonColumn => {
  const config = {
    ...{
      columnProps: {},
    },
    ...params,
  }
  const bodyTemplate = () => {
    return <Skeleton width="100%" height="20px" />
  }
  return {
    config: params,
    build: () => buildCustomColumn('SKELETON', config.columnProps, bodyTemplate),
  }
}

/** ... SKELETON COLUMN */

/** SIMPLE COLUMN ... */

interface SimpleColumnParams {
  columnProps?: ColumnProps
}

export type SimpleColumn = {
  config: SimpleColumnParams
  build: (isLoading: boolean) => JSX.Element
}

export const simpleColumn = (params: SimpleColumnParams = {}): SimpleColumn => {
  const config = {
    ...{
      columnProps: {},
    },
    ...params,
  }
  return {
    config: config,
    build: (isLoading) =>
      isLoading ? skeletonColumn(config).build() : buildCustomColumn('SIMPLE', config.columnProps),
  }
}

/** ... SIMPLE COLUMN */

/** CREATOR COLUMN ... */

interface CreatorColumnParams {
  columnProps?: Omit<ColumnProps, 'body'>
  rowDataField?: string
  emptyVal?: string
}

export type CreatorColumn = {
  config: CreatorColumnParams
  build: (isLoading: boolean) => JSX.Element
}

export const creatorColumn = (params: CreatorColumnParams = {}): CreatorColumn => {
  const config = {
    ...{
      columnProps: {},
      rowDataField: 'creator',
    },
    ...params,
  }
  const bodyTemplate = (rowData: any) => {
    return <Creator member={rowData[config.rowDataField]} emptyVal={config.emptyVal} />
  }
  return {
    config: config,
    build: (isLoading) =>
      isLoading
        ? skeletonColumn(config).build()
        : buildCustomColumn('CREATOR', config.columnProps, bodyTemplate),
  }
}

/** ... CREATOR COLUMN */

/** TAGLIST COLUMN ... */

interface TagListColumnParams {
  columnProps?: Omit<ColumnProps, 'body'>
  rowDataSelector?: string | ((rowData: any) => Record<string, any>[])
  normalize?: (item: Record<string, any>) => NormalizedItem
  emptyVal?: string
}

export type TagListColumn = {
  config: TagListColumnParams
  build: (isLoading: boolean) => JSX.Element
}

export const tagListColumn = (params: TagListColumnParams = {}): TagListColumn => {
  const config = {
    ...{
      columnProps: {},
      rowDataSelector: 'taglist',
    },
    ...params,
  }
  const bodyTemplate = (rowData: any) => {
    const items =
      typeof config.rowDataSelector === 'string'
        ? rowData[config.rowDataSelector]
        : config.rowDataSelector(rowData)
    return <TagList items={items} normalize={config.normalize} emptyVal={config.emptyVal} />
  }
  return {
    config: config,
    build: (isLoading) =>
      isLoading
        ? skeletonColumn(config).build()
        : buildCustomColumn('TAGLIST', config.columnProps, bodyTemplate),
  }
}

/** ... TAGLIST COLUMN */

/** ACTIONS COLUMN ... */

interface ActionsColumnParams {
  columnProps?: Omit<ColumnProps, 'body'>
  dispatcherButtonsProps: DispatcherButtonProps[]
}

export type ActionsColumn = {
  config: ActionsColumnParams
  build: (isLoading: boolean) => JSX.Element
}

export const actionsColumn = (params: ActionsColumnParams): ActionsColumn => {
  const config = {
    ...{
      columnProps: {},
    },
    ...params,
  }
  const bodyTemplate = (rowData: any) => {
    return (
      <>
        {config.dispatcherButtonsProps.map((dispatcherButtonProps, index) => (
          <DispatcherButton key={`ACTION_${index}`} {...dispatcherButtonProps} data={rowData} />
        ))}
      </>
    )
  }
  return {
    config: config,
    build: (isLoading) =>
      isLoading
        ? skeletonColumn(config).build()
        : buildCustomColumn('ACTIONS', config.columnProps, bodyTemplate),
  }
}

/** ... ACTIONS COLUMN */

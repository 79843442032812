import { Card } from 'primereact/card'
import { Link } from 'react-router-dom'
import { Toolbar } from 'primereact/toolbar'
import styles from '../../styles/list.module.scss'
import DataTableProvider, { DataTablePropsPresets } from '../DataTable/DataTableProvider'
import { actionsColumn, simpleColumn, tagListColumn } from '../DataTable/columnProvider'
import { useDataTableFilter } from '../../common/hooks'
import {
  useUrlCopyToClipboard,
  useToogleFavorite,
  useNavigateToCreate,
} from '../../common/dispatcherButtonHooks'
import { useDeleteModal } from '../../common/modalHooks'
import { DataTableProps } from 'primereact/datatable'
import { NORMALIZE_PRESETS } from '../TagList'

interface Props {
  data?: Product[]
  toolbarLeft?: JSX.Element
  toolbarRight?: JSX.Element
  hiddenColumns?: number[]
  isLoading?: boolean
  useSorting?: boolean
  showFilter?: boolean
  dataTableProps?: DataTableProps
}

const ProductList = ({
  data,
  toolbarLeft = <h1>Products</h1>,
  toolbarRight,
  hiddenColumns = [],
  isLoading = true,
  useSorting = true,
  showFilter = false,
  dataTableProps = DataTablePropsPresets.listPage(data),
}: Props): JSX.Element => {
  const toggleFavorite = useToogleFavorite('PRODUCT')
  const copyToClipboard = useUrlCopyToClipboard('PRODUCT')
  const deleteProductModal = useDeleteModal('PRODUCT')
  const createProductButton = useNavigateToCreate('PRODUCT')
  const columns = [
    simpleColumn({
      columnProps: {
        header: 'Name',
        field: 'name',
        sortable: useSorting,
        className: styles['first-column'],
        body: (product: Product) => {
          return <Link to={`/product/${product.id}`}>{product.name}</Link>
        },
      },
    }),
    simpleColumn({
      columnProps: {
        header: 'Company',
        field: 'companyName',
        sortable: useSorting,
        className: styles['column'],
        style: { width: '20%' },
        body: (product: Product) => {
          return <Link to={`/company/${product.company.id}`}>{product.company.name}</Link>
        },
      },
    }),
    tagListColumn({
      columnProps: {
        header: 'Markets',
        className: styles['column'],
        style: { width: '30%' },
      },
      rowDataSelector: (product: Product) => [
        product.geos.filter((geo) => geo.type === null),
        product.geos.filter((geo) => geo.type !== null),
      ],
    }),
    tagListColumn({
      columnProps: {
        header: 'Capabilities',
        className: styles['column'],
        style: { width: '30%' },
      },
      rowDataSelector: (product: Product) => product.capabilities,
      normalize: NORMALIZE_PRESETS.POS,
    }),
    actionsColumn({
      columnProps: {
        align: 'right',
        alignHeader: 'center',
        className: styles['action-column'],
      },
      dispatcherButtonsProps: [
        ...toggleFavorite.dispatcherButtonProps,
        ...copyToClipboard.dispatcherButtonProps,
        ...deleteProductModal.dispatcherButtonProps,
      ],
    }),
  ].filter((column, index) => !hiddenColumns.includes(index))

  data = data?.map((product) => {
    return {
      ...product,
      ...{
        companyName: product.company.name as string,
      },
    }
  })
  dataTableProps = { ...dataTableProps, ...{ value: data } }

  if (showFilter) {
    data = data?.map((product) => {
      return {
        ...product,
        ...{
          marketsJoin: product.geos.map((geo) => geo.name).join(' '),
          capabilitiesJoin: product.capabilities.map((capability) => capability.name).join(' '),
        },
      }
    })
    dataTableProps = {
      ...dataTableProps,
      ...{ value: data },
      ...useDataTableFilter(['name', 'marketsJoin', 'capabilitiesJoin']),
    }
  }

  return (
    <>
      <div className={styles.list}>
        <Toolbar left={toolbarLeft} right={toolbarRight || createProductButton.dispatcherButton} />
        <Card>
          <DataTableProvider
            dataTableProps={dataTableProps}
            columns={columns}
            isLoading={isLoading}
          />
        </Card>
      </div>
    </>
  )
}

export default ProductList

import { Card } from 'primereact/card'
import { classNames } from 'primereact/utils'
import { useNavigate } from 'react-router-dom'
import { useToogleFavorite } from '../../common/dispatcherButtonHooks'
import { useAppSelector } from '../../common/hooks'
import { vowelPattern } from '../../consts'
import { useProductQuery } from '../../redux/api'
import styles from '../../styles/favorites.module.scss'
import DispatcherButton from '../DispatcherButton'
import FavoriteCardSkeleton from '../Favorite/FavoriteCardSkeleton'
import TagList, { NORMALIZE_PRESETS } from '../TagList'

interface Props {
  data: Favorite
  customClasses?: string
  customStyles?: React.CSSProperties
}

const ProductFavoriteCard = ({ data, customClasses = '', customStyles }: Props): JSX.Element => {
  const workspaceId = useAppSelector((state) => state.app.workspace!.id)

  const {
    data: productData,
    isLoading,
    isFetching,
  } = useProductQuery({
    workspaceId: workspaceId,
    productId: data._id,
  })

  const navigate = useNavigate()
  const toggleFavorite = useToogleFavorite('PRODUCT', productData)
  const header = (
    <>
      {toggleFavorite.dispatcherButton}
      <DispatcherButton
        buttonProps={{
          icon: 'pi pi-external-link',
          tooltip: 'Open',
        }}
        onClick={() => navigate(`/product/${productData?.id}`)}
      />
    </>
  )

  if (isLoading && isFetching) return <FavoriteCardSkeleton customClasses="col-12 md:col-4" />

  return (
    <Card
      header={header}
      className={classNames(styles.favorites, customClasses)}
      title="Product"
      subTitle={productData?.name}
      style={customStyles}
    >
      <p>
        {productData?.company.name.match(vowelPattern) ? 'an ' : 'a '}
        <strong>{productData?.company.name}</strong> product
      </p>
      <div className="flex flex-column lg:flex-row column-gap-4">
        <div className="font-medium col-12 md:col-3 pl-0">Markets:</div>
        <div className="col pl-0">
          <TagList
            items={[
              (productData?.geos || []).filter((geo) => geo.type === null),
              (productData?.geos || []).filter((geo) => geo.type !== null),
            ]}
            emptyVal={'(none)'}
          />
        </div>
      </div>
      <div className="flex flex-column lg:flex-row column-gap-4">
        <div className="font-medium col-12 md:col-3 pl-0">Capabilities:</div>
        <div className="col pl-0">
          <TagList
            items={productData?.capabilities || []}
            normalize={NORMALIZE_PRESETS.POS}
            emptyVal={'(none)'}
          />
        </div>
      </div>
    </Card>
  )
}

export default ProductFavoriteCard

import React from 'react'
import { useAppSelector } from '../../common/hooks'
import { useProductListByCompanyQuery } from '../../redux/api'
import { useCreateModal } from '../../common/modalHooks'
import ProductList from '../Product/ProductList'

interface Props {
  company: Company
}

const CompanyTabProducts = ({ company }: Props): JSX.Element => {
  const workspaceId = useAppSelector((state) => state.app.workspace!.id)
  const createProductModal = useCreateModal('PRODUCT', company, 'Add Product')
  const { data, isFetching } = useProductListByCompanyQuery({
    workspaceId,
    companyId: company.id,
  })
  return (
    <>
      {createProductModal.modal}
      <ProductList
        data={data?.content}
        toolbarLeft={<h1 className="text-2xl">Products</h1>}
        toolbarRight={createProductModal.dispatcherButton}
        hiddenColumns={[1]}
        isLoading={isFetching}
        showFilter={true}
      />
    </>
  )
}

export default CompanyTabProducts

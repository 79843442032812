import styles from '../../styles/list.module.scss'
import DataTableProvider, { DataTablePropsPresets } from '../DataTable/DataTableProvider'
import { Toolbar } from 'primereact/toolbar'
import { Card } from 'primereact/card'
import { useAppSelector, useDataTableFilter } from '../../common/hooks'
import { actionsColumn, simpleColumn, tagListColumn } from '../DataTable/columnProvider'
import { useUrlCopyToClipboard, useToogleFavorite } from '../../common/dispatcherButtonHooks'
import {
  useCanSendModal,
  useDeleteModal,
  useExtendModal,
  useWithdrawModal,
} from '../../common/modalHooks'
import { Link } from 'react-router-dom'
import { ProgressBar } from 'primereact/progressbar'
import { RfiStatus } from '../../enums'
import { Tag } from 'primereact/tag'
import { NORMALIZE_PRESETS } from '../TagList'
import ProgressTag from '../ProgressTag'

interface Props {
  toolbarLeft?: JSX.Element
  hiddenColumns?: number[]
  isLoading?: boolean
  data?: Participant[]
  showFilter?: boolean
  type?: string
}

const ParticipantList = ({
  toolbarLeft = <h1>Participants</h1>,
  hiddenColumns = [],
  isLoading = true,
  data,
  showFilter = false,
  type = 'COMPANY',
}: Props): JSX.Element => {
  const toggleFavorite = useToogleFavorite('PARTICIPANT')
  const copyToClipboard = useUrlCopyToClipboard('PARTICIPANT')
  const deleteButton = useDeleteModal('PARTICIPANT')
  const extendButton = useExtendModal('PARTICIPANT')
  const withdrawButton = useWithdrawModal('PARTICIPANT')
  const canSendButton = useCanSendModal()

  const colsStandard = [
    simpleColumn({
      columnProps: {
        header: 'Market',
        field: 'rfi.geo.name',
        sortable: true,
        className: styles['column'],
        body: (participant: Participant) => {
          return (
            <div className="tooltip-wrapper">
              <Tag value={participant.rfi.geo.name} className={'bg-bluegray-500'} />
            </div>
          )
        },
      },
    }),
    tagListColumn({
      columnProps: {
        header: 'Capabilities',
        className: styles['column'],
        style: { width: '20%' },
      },
      rowDataSelector: (participant: Participant) => participant.rfi.capabilities,
      normalize: NORMALIZE_PRESETS.POS,
    }),
    simpleColumn({
      columnProps: {
        header: 'Status',
        field: 'status',
        sortable: true,
        className: styles['column'],
        style: { width: '15%' },
        body: (participant: Participant) => {
          return participant.status === RfiStatus.IN_PROGRESS ? (
            <ProgressTag status={participant.status} progress={participant.progress} />
          ) : (
            <Tag
              className="bg-primary-reverse border-1"
              value={String(participant.status).replace(/_/g, ' ')}
            ></Tag>
          )
        },
      },
    }),
    simpleColumn({
      columnProps: {
        header: 'End Date',
        field: 'endDate',
        sortable: true,
        className: styles['column'],
        body: (participant: Participant) => {
          return <span>{participant.endDate}</span>
        },
      },
    }),
    actionsColumn({
      columnProps: {
        align: 'right',
        alignHeader: 'center',
        className: styles['action-column'],
        style: { width: '25%' },
      },
      dispatcherButtonsProps: [
        ...toggleFavorite.dispatcherButtonProps,
        ...copyToClipboard.dispatcherButtonProps,
        ...canSendButton.dispatcherButtonProps,
        ...extendButton.dispatcherButtonProps,
        ...withdrawButton.dispatcherButtonProps,
        ...deleteButton.dispatcherButtonProps,
      ],
    }),
  ]

  const colsCompany = [
    simpleColumn({
      columnProps: {
        header: 'Name',
        field: 'rfi.name',
        sortable: true,
        className: styles['first-column'],
        style: { width: '20%' },
        body: (participant: Participant) => {
          return <Link to={`/participant/${participant.id}`}>{participant.rfi.name}</Link>
        },
      },
    }),
    ...colsStandard,
  ]

  const colsProduct = [
    simpleColumn({
      columnProps: {
        header: 'Name',
        field: 'name',
        sortable: true,
        className: styles['first-column'],
        style: { width: '20%' },
        body: (participant: Participant) => {
          return <Link to={`/participant/${participant.id}`}>{participant.rfi.name}</Link>
        },
      },
    }),
    simpleColumn({
      columnProps: {
        header: 'Product',
        field: 'product.name',
        sortable: true,
        className: styles['column'],
        style: { width: '20%' },
        body: (participant: Participant) => {
          return <Link to={`/product/${participant.product?.id}`}>{participant.product?.name}</Link>
        },
      },
    }),
    ...colsStandard,
  ]

  const columnsCompany = colsCompany.filter((column, index) => !hiddenColumns.includes(index))
  const columnsProduct = colsProduct.filter((column, index) => !hiddenColumns.includes(index))

  let dataTableProps = DataTablePropsPresets.listPage(data, 'createdAt', -1)

  if (showFilter) {
    data = data?.map((participant) => {
      return {
        ...participant,
        ...{
          capabilitiesJoin: participant.rfi.capabilities
            .map((capability) => capability.name)
            .join(' '),
        },
      }
    })
    dataTableProps =
      type === 'COMPANY'
        ? {
            ...DataTablePropsPresets.listPage(data, 'createdAt', -1),
            ...useDataTableFilter([
              'rfi.name',
              'rfi.geo.name',
              'status',
              'capabilitiesJoin',
              'endDate',
            ]),
          }
        : {
            ...DataTablePropsPresets.listPage(data, 'createdAt', -1),
            ...useDataTableFilter([
              'rfi.name',
              'product.name',
              'rfi.geo.name',
              'status',
              'capabilitiesJoin',
              'endDate',
            ]),
          }
  }

  return (
    <>
      {extendButton.modal}
      {canSendButton.modal}
      <div className={styles.list}>
        <Toolbar left={toolbarLeft} />
        <Card>
          <DataTableProvider
            dataTableProps={dataTableProps}
            columns={type === 'COMPANY' ? columnsCompany : columnsProduct}
            isLoading={isLoading}
          />
        </Card>
      </div>
    </>
  )
}

export default ParticipantList

import { useEffect, useMemo, useState } from 'react'
import { useAppSelector } from '../../common/hooks'
import { useLazyOptionsPresetQuery } from '../../redux/api'
import { getDimensionLabel } from '../../common/utils'

interface Props {
  question: Question
  questionConstraint: ConstrainedQuestion
}

interface ValueObject {
  value: string
  isOther?: boolean
}

const styles = {
  noPaddingList: {
    paddingLeft: 0,
    listStylePosition: 'outside' as const,
    listStyleType: 'none' as const,
  },
  marginBottomLi: {
    marginBottom: '5px',
  },
}

const fillMissing = (option: any): { value: string; label: string } =>
  option.label === undefined
    ? { value: option.value, label: option.value }
    : option.value === undefined
      ? { value: option.label, label: option.label }
      : option

const denormalize = (data: { values: ValueObject[] }): { value: string[]; otherValue?: string } => {
  let denormalized: { value: string[]; otherValue?: string } = {
    value: data.values.map((obj) => obj.value),
  }
  const otherObj = data.values.find((obj) => obj.isOther === true)
  if (otherObj !== undefined) {
    denormalized.otherValue = otherObj.value
  }
  return denormalized
}

const QuestionOutputMultiSelect = ({ question, questionConstraint }: Props): JSX.Element => {
  const [queryOptionsPreset] = useLazyOptionsPresetQuery()
  const configSort = question.config?.sort || true
  const configAllowOther = question.config?.allowOther || false
  const configOptionsPresetRef: string | undefined = question.config?.optionsPresetRef || undefined
  const configOptions: any[] = question.config?.options || []
  const [presetOptions, setPresetoptions] = useState<any[]>([])
  const workspaceId = useAppSelector((state) => state.app.workspace!.id)

  useEffect(() => {
    if (configOptionsPresetRef !== undefined) {
      queryOptionsPreset({
        type: 'MULTI_SELECT',
        ref: configOptionsPresetRef,
        workspaceId: workspaceId,
      })
        .unwrap()
        .then((result: any[]) => setPresetoptions(result))
    }
  }, [])

  const options = useMemo(() => {
    let filtered = [
      ...presetOptions.map((option) => fillMissing(option)),
      ...configOptions.map((option) => fillMissing(option)),
    ].reduce((acc: any, obj) => {
      acc[obj.label] = obj
      return acc
    }, {})

    let options = Object.values(filtered) as { value: string; label: string }[]

    if (configSort) {
      options = options.sort((a, b) =>
        a['label']!.toLowerCase() > b['label']!.toLowerCase() ? 1 : -1,
      )
    }

    if (configAllowOther) {
      const otherValue = question.answer?.data.find((item) => item.data?.isOther)?.data?.value
      options.push({ value: otherValue, label: otherValue })
    }

    return options
  }, [presetOptions.length])

  // Render each dimension's multi select values
  const renderDimensionValues = (answerData: any) => {
    const { data } = answerData
    if (!data || data.values === null) return null

    const denormalizedData = denormalize(data)

    const selectedOptions = denormalizedData.value
      .map((value: any) => {
        const foundOption = options.find((option) => option.value === value)
        return foundOption ? foundOption.label : value
      })
      .join(', ')

    const dimensionLabel = getDimensionLabel(
      questionConstraint.dimensions,
      questionConstraint.labels,
      answerData.dimensionIndex,
      answerData.dimensionValue,
    )

    return (
      <li
        style={styles.marginBottomLi}
        key={`dim-${answerData.dimensionIndex}${answerData.dimensionValue}`}
      >
        <strong>{dimensionLabel}</strong>
        {selectedOptions}
      </li>
    )
  }

  return (
    <div>
      <ul style={styles.noPaddingList}>{question.answer?.data.map(renderDimensionValues)}</ul>
    </div>
  )
}

export default QuestionOutputMultiSelect

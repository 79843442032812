import { useState } from 'react'
import { useAppSelector } from '../../common/hooks'
import DispatcherButton, { DispatcherButtonPresets } from '../DispatcherButton'
import { InputText } from 'primereact/inputtext'
import { Controller, SubmitHandler, useForm, useWatch } from 'react-hook-form'
import { classNames } from 'primereact/utils'
import { CreateRfi, useLazyRfiExistsByGeoQuery } from '../../redux/api'
import { Toolbar } from 'primereact/toolbar'
import { InputTextarea } from 'primereact/inputtextarea'
import { useFormGeoDataSingle } from '../../common/formHooks'
import { RadioButton } from 'primereact/radiobutton'
import { Dropdown } from 'primereact/dropdown'
import { Calendar } from 'primereact/calendar'
import InfoTooltip from '../InfoTooltip'
import { Tooltip } from 'primereact/tooltip'
import { RfiType } from '../../enums'

interface FormData {
  name: string
  description: string | null
  type: keyof typeof RfiType
  market: number
  startDate: string
  endDate: string
}

interface Props {
  rfiData: CreateRfi
  setRfiData: React.Dispatch<React.SetStateAction<CreateRfi>>
  setStep: React.Dispatch<React.SetStateAction<number>>
}

const RfiCreateStepOne = ({ rfiData, setRfiData, setStep }: Props): JSX.Element => {
  const workspaceId = useAppSelector((state) => state.app.workspace!.id)
  const [nameExists] = useLazyRfiExistsByGeoQuery()

  const {
    control,
    formState: { errors },
    handleSubmit,
    getValues,
    resetField,
  } = useForm<FormData>({
    reValidateMode: 'onBlur',
    defaultValues: {
      name: rfiData.name,
      description: rfiData.description,
      type: rfiData.type === undefined ? RfiType.COMPANY : rfiData.type,
      market: rfiData.geo?.id,
      startDate: rfiData.startDate,
      endDate: rfiData.endDate,
    },
  })

  const formGeoData = useFormGeoDataSingle(rfiData.geo, resetField)

  const [typeRadio, setTypeRadio] = useState<keyof typeof RfiType | null>(
    rfiData.type === undefined ? RfiType.COMPANY : rfiData.type,
  )
  const startDateWatch = useWatch({
    name: 'startDate',
    control: control,
    defaultValue: rfiData.startDate,
  })

  const submitFormData: SubmitHandler<FormData> = async (formData, e) => {
    setRfiData({
      ...rfiData,
      name: formData.name,
      description: formData.description,
      type: formData.type,
      geo: formGeoData.flat.find((geo) => geo.id === formData.market),
      startDate: formData.startDate,
      endDate: formData.endDate,
      participants: rfiData.type !== formData.type ? [] : rfiData.participants, // we need to clear (incompatible) participants if type changes
    })
    setStep((step) => step + 1)
  }

  return (
    <form className="p-fluid" onSubmit={handleSubmit(submitFormData)}>
      <Tooltip target=".infoTooltip" />
      <div className={'flex align-items-center flex-row'}>
        <h3>General Information</h3>
      </div>
      <div className="card">
        <div className="field grid">
          <label
            htmlFor="name"
            className={classNames(
              { 'p-error': errors.name },
              'col-12 mb-2 md:col-2 md:mb-0 lg:col-2',
            )}
          >
            <InfoTooltip
              label="Name:"
              tooltip="The name you will input here will be displayed as is on the form vendors will receive to complete their RFI. Please choose sensibly."
            />
          </label>
          <div className="col-12 md:col-10 lg:col-10">
            <Controller
              name="name"
              control={control}
              rules={{
                required: { value: true, message: 'Name is a required field' },
                maxLength: { value: 255, message: 'Name must be less than 256 characters' },
                validate: async (value) => {
                  const geoId = getValues('market')
                  if (geoId === undefined) return true
                  return (await nameExists({
                    workspaceId,
                    geoId,
                    name: value,
                  }).unwrap())
                    ? 'An RFI with this name already exists within this market'
                    : true
                },
              }}
              render={({ field, fieldState }) => (
                <InputText
                  id={field.name}
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={(e) => {
                    field.onChange(e.target.value.trim())
                  }}
                  placeholder="Enter name"
                  className={classNames({ 'p-invalid': fieldState.error })}
                />
              )}
            />
            {errors.name && <small className="p-error">{errors.name?.message}</small>}
          </div>
        </div>
        <div className="field grid mb-0">
          <label
            htmlFor="description"
            className={
              'description col-12 mb-2 md:col-2 md:mb-0 md:mt-1 md:pt-2 align-items-start flex-column'
            }
          >
            <InfoTooltip
              label="Description:"
              tooltip="The description you will input here will be displayed as is on the form vendors will receive to complete their RFI. Please choose sensibly."
            />
          </label>
          <div className="col-12 md:col-10 lg:col-10">
            <Controller
              name="description"
              control={control}
              render={({ field }) => (
                <InputTextarea
                  id={field.name}
                  value={field.value || ''}
                  onChange={field.onChange}
                  onBlur={(e) => {
                    field.onChange(e.target.value.trim() === '' ? null : e.target.value.trim())
                  }}
                  placeholder="Enter description (optional)"
                  rows={7}
                />
              )}
            />
          </div>
        </div>
        <div className="field grid">
          <label
            htmlFor="type"
            className={classNames(
              { 'p-error': errors.type },
              'col-12 md:col-2 lg:col-2 align-items-start mt-3',
            )}
          >
            Type:
            <InfoTooltip
              tooltip={`Company: RFI answers will be considered at Company level. For example, Company RFI issued to Meta will encompass Facebook, Instagram, WhatsApp, Messenger, Occulus, etc.\nProduct: RFI answers will be considered at a Product level. If you're interested, for example, to understand Meta's Facebook capabilities separately from Instagram or WhatsApp,\nyou should issue a Product RFI to get more specific information.`}
            />
          </label>
          <div className="col-12 md:col-10 lg:col-10">
            <Controller
              name="type"
              control={control}
              rules={{
                required: { value: true, message: 'Type is a required field' },
              }}
              render={({ field, fieldState }) => (
                <>
                  <div className="field grid mt-3">
                    <div className="col-12 md:col-2 lg:col-2 align-self-center">
                      <RadioButton
                        inputId="typeCompany"
                        name="type"
                        value={'COMPANY'}
                        checked={typeRadio === 'COMPANY'}
                        onChange={(e) => {
                          setTypeRadio(e.value)
                          field.onChange(e.value)
                        }}
                        className={classNames({
                          'p-invalid': fieldState.error && typeRadio === null,
                        })}
                      />
                      <label htmlFor="typeCompany" className={'ml-2'}>
                        Company
                      </label>
                    </div>
                  </div>
                  <div className="field grid">
                    <div className="col-12 md:col-2 lg:col-2 align-self-center">
                      <RadioButton
                        inputId="typeProduct"
                        name="type"
                        value={'PRODUCT'}
                        checked={typeRadio === 'PRODUCT'}
                        onChange={(e) => {
                          setTypeRadio(e.value)
                          field.onChange(e.value)
                        }}
                        className={classNames({
                          'p-invalid': fieldState.error && typeRadio === null,
                        })}
                        // >>>>> TODO: remove once we have product RFIs QP
                        style={{ cursor: 'not-allowed' }}
                        disabled={true}
                        tooltip="coming soon"
                        tooltipOptions={{ position: 'left' }}
                        // <<<<<
                      />
                      <label htmlFor="typeProduct" className={'ml-2'}>
                        <span className="text-400">Product</span>
                      </label>
                    </div>
                  </div>
                </>
              )}
            />
            {errors.type && <small className="p-error">{errors.type?.message}</small>}
          </div>
        </div>
        <div className="field grid">
          <label
            htmlFor="market"
            className={classNames(
              { 'p-error': errors.market },
              'col-12 md:col-2 lg:col-2 align-items-start mt-3',
            )}
          >
            Market:
            <InfoTooltip
              tooltip={`Global: RFI answers will be considered as applicable globally.\nMarket: RFI answers will be considered as applicable for the market selected here only.`}
            />
          </label>
          <div className="col-12 md:col-10 lg:col-10">
            <Controller
              name="market"
              control={control}
              rules={{
                validate: (value) => {
                  return value > 0 ? true : 'Market is a required field'
                },
              }}
              render={({ field, fieldState, formState }) => (
                <>
                  <div className="field grid mt-3">
                    <div className="col-12 md:col-3 lg:col-2 align-self-center">
                      <RadioButton
                        inputId="marketGlobal"
                        name="market"
                        value={'global'}
                        checked={formGeoData.marketRadio === 'global'}
                        onChange={(e) => {
                          formGeoData.setMarketRadio(e.value)
                          field.onChange(
                            formGeoData.flat.find((geo) => geo.type === null)?.id || -1,
                          )
                        }}
                        className={classNames({
                          'p-invalid': fieldState.error && formGeoData.marketRadio === null,
                        })}
                      />
                      <label htmlFor="marketGlobal" className={'ml-2'}>
                        Global
                      </label>
                    </div>
                  </div>
                  <div className="field grid">
                    <div className="col-12 md:col-3 lg:col-2 align-self-center">
                      <RadioButton
                        inputId="marketMarket"
                        name="market"
                        value={'market'}
                        checked={formGeoData.marketRadio === 'market'}
                        onChange={(e) => {
                          formGeoData.setMarketRadio(e.value)
                          field.onChange(0)
                        }}
                        className={classNames({
                          'p-invalid': fieldState.error && formGeoData.marketRadio === null,
                        })}
                      />
                      <label htmlFor="marketMarket" className={'ml-2'}>
                        Market
                      </label>
                    </div>

                    <div className="col-12 mt-3 md:col-9 md:mt-0">
                      <Dropdown
                        value={field.value}
                        options={formGeoData.options}
                        onChange={(e) => {
                          field.onChange(e.value)
                        }}
                        optionLabel="label"
                        optionGroupLabel="label"
                        optionGroupChildren="items"
                        disabled={formGeoData.marketRadio !== 'market'}
                        filter={true}
                        resetFilterOnHide={true}
                        placeholder="Select market"
                        scrollHeight={'400px'}
                        className={classNames({ 'p-invalid': fieldState.error })}
                      />
                    </div>
                  </div>
                </>
              )}
            />
            {errors.market && <small className="p-error">{errors.market?.message}</small>}
          </div>
        </div>
      </div>

      <h3>Runtime</h3>
      <div className="card">
        <div className="field grid">
          <label
            htmlFor="startDate"
            className={classNames(
              { 'p-error': errors.startDate },
              'col-12 mb-2 md:col-2 md:mb-0 lg:col-2 startDate',
            )}
          >
            Start&nbsp;Date:
            <InfoTooltip tooltip="Start date is set by default as today's date but you're welcome to adjust it." />
          </label>
          <div className="col-12 md:col-3">
            <Controller
              name="startDate"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: 'Start Date is a required field',
                },
              }}
              render={({ field, fieldState }) => (
                <Calendar
                  inputId={field.name}
                  showIcon
                  showButtonBar
                  value={field.value === '' ? undefined : new Date(Date.parse(field.value))}
                  onChange={(e) => {
                    if (null !== e.target.value) {
                      const date = e.target.value as Date
                      const normalized = new Date(
                        Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()),
                      )
                      field.onChange(normalized.toISOString().slice(0, 10))
                    }
                  }}
                  dateFormat="yy-mm-dd"
                  className={classNames({ 'p-invalid': fieldState.error })}
                />
              )}
            />
            {errors.startDate && <small className="p-error">{errors.startDate?.message}</small>}
          </div>
        </div>
        <div className="field grid">
          <label
            htmlFor="endDate"
            className={classNames(
              { 'p-error': errors.endDate },
              'col-12 mb-2 md:col-2 md:mb-0 lg:col-2',
            )}
          >
            End&nbsp;Date:
            <InfoTooltip tooltip="End Date is the deadline for this RFI to be completed by all participating companies. You will be able to extend deadlines for each Participants later on, if needed." />
          </label>
          <div className="col-12 md:col-3">
            <Controller
              name="endDate"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: 'End Date is a required field',
                },
                validate: (value) => {
                  const chosenDate = new Date(value)
                  return chosenDate > new Date(startDateWatch) && chosenDate > new Date()
                    ? true
                    : 'End date must be after start date and in the future'
                },
              }}
              render={({ field, fieldState }) => (
                <Calendar
                  inputId={field.name}
                  showIcon
                  showButtonBar
                  value={field.value === '' ? undefined : new Date(Date.parse(field.value))}
                  onChange={(e) => {
                    if (null !== e.target.value) {
                      const date = e.target.value as Date
                      const normalized = new Date(
                        Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()),
                      )
                      field.onChange(normalized.toISOString().slice(0, 10))
                    }
                  }}
                  dateFormat="yy-mm-dd"
                  className={classNames({ 'p-invalid': fieldState.error })}
                  disabled={startDateWatch === ''}
                />
              )}
            />
            {errors.endDate && <small className="p-error">{errors.endDate?.message}</small>}
          </div>
        </div>
      </div>

      <Toolbar
        right={
          <DispatcherButton
            buttonProps={{
              label: 'Next',
              icon: 'pi pi-arrow-right',
              iconPos: 'right',
              type: 'submit',
            }}
            className={DispatcherButtonPresets.FILLED_ROUNDED_PRIMARY}
          />
        }
      />
    </form>
  )
}

export default RfiCreateStepOne
